<div class="col-md-8 offset-md-2 mt-5" *ngIf="!showOubliPass">
    <div class="card">
        <h4 class="card-header">Connexion à <span class="bold-blue-e">e</span>book-card</h4>

        <div class="card-body">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="username">Nom d'utilisateur (adresse email) </label>
                    <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">Nom d'utilisateur requis</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password">Mot de passe</label>
                    <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Le mot de passe est requis</div>
                    </div>
                </div>
                <div *ngIf="errorRetour === '22' || errorRetour === '44'" class="alert alert-danger" >
                    <div class="form-group">
                        <label for="password">Veuillez confirmer votre mot de passe:</label>
                        <input type="password" formControlName="password2" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">Confirmation requise</div>
                        </div>
                        <div *ngIf="errorRetour === '44'">La confirmation n'est pas identique au mot de passe</div>
                    </div>
                </div>
                <button [disabled]="loading" class="btn btn-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Connexion
                </button>&nbsp;&nbsp;&nbsp;&nbsp;<button type="button" class="recup_passe btn btn-link btn-sm" (click)="showOubliPass = !showOubliPass">Récupération du mot de passe</button>
                <button style="font-size: 17px;" class="btn btn-link btn-sm" routerLink="/nouveaucompte">Devenir membre</button>
                
                <div *ngIf="errorRetour === '11'" class="alert alert-danger mt-3 mb-0">Nom d'utilisateur inconnu.<br />Veuillez indiquer une adresse email valide</div>

                <div *ngIf="errorRetour === '33'" class="alert alert-danger mt-3 mb-0">Votre mot de passe ne correspond pas celui indiqué pour ce compte<br />Veuillez l'indiquer à nouveau</div>
            
            </form>
        </div>
    </div>
</div>

<div class="col-md-8 offset-md-2 mt-5" *ngIf="showOubliPass">
    <div class="card">
        <h4 class="card-header">Demande de récupération du mot de passe</h4>
        <div class="card-body">
            <form [formGroup]="passForm2" (ngSubmit)="onSubmitOubli()" *ngIf="oubliPassPhase1">
                <div class="form-group">
                    <label for="username">Nom d'utilisateur (adresse email)</label>
                    <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted2 && f2.username.errors }" />
                    <div *ngIf="submitted2 && f2.username.errors" class="invalid-feedback">
                        <span *ngIf="f2.username.errors.required" class="alert-danger">Veuillez indiquer l'adresse email de votre compte.</span>
                        <span *ngIf="f2.username.errors" class="alert-danger">Veuillez indiquer une adresse email valide</span>
                    </div>
                    <p *ngIf="!messageEmailUnexist">Un mail vous sera envoyé sur cette adresse email afin de terminer la récupération du mot de passe.</p>
                    <p class="alert-danger" *ngIf="messageEmailUnexist">{{messageEmailUnexist}}</p>
                </div>
                <form [formGroup]="reactiveForm">
                    <div>
                        <re-captcha 
                        formControlName="recaptchaReactive" 
                        siteKey={{sitekey}}>
                        </re-captcha>
                    <br>
                    </div>
                </form>
                <button [disabled]="loading" [disabled]="reactiveForm.invalid"  class="btn btn-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Envoyer la demande
                </button>&nbsp;
                <button class="recup_passe btn btn-link btn-sm" (click)="refresh()">Retour à connexion</button>
                <button style="font-size: 17px;" class="btn btn-link btn-sm" routerLink="/nouveaucompte">Devenir membre</button>
                
                <!-- <div class="alert alert-danger mt-3 mb-0">Erreur {{errorRetour}}</div> -->
                <div *ngIf="errorRetour === '44'" class="alert alert-danger mt-3 mb-0">Nom d'utilisateur inconnu.<br />Veuillez indiquer une adresse email valide</div>            
            </form>

            <form [formGroup]="passForm3" (ngSubmit)="onSubmitOubli2()" *ngIf="oubliPassPhase2">
                <div class="form-group">
                    <label for="codemail">Code envoyé par mail</label>
                    <input type="text" formControlName="codemail" class="form-control" placeholder="Insérer le code reçu par mail" [ngClass]="{ 'is-invalid': submitted3 && f3.codemail.errors }" />
                    <div *ngIf="submitted && f3.codemail.errors" class="invalid-feedback">
                        <div *ngIf="f3.codemail.errors.required">Code obligatoire</div>
                    </div>
                </div>
                <div class="form-group" >
                    <label for="password">Nouveau mot de passe</label>
                    <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted3 && f3.password.errors }" />
                    <div *ngIf="submitted && f3.password.errors" class="invalid-feedback">
                        <div *ngIf="f3.password.errors.required">Le mot de passe est requis</div>
                    </div>
                </div>

                    <div class="form-group">
                        <label for="repeatpassword">Veuillez confirmer le mot de passe:</label>
                        <input type="password" formControlName="repeatpassword" class="form-control" [ngClass]="{ 'is-invalid': submitted3 && f3.repeatpassword.errors }" />
                        <div *ngIf="submitted3 && f3.repeatpassword.errors" class="invalid-feedback">
                            <div *ngIf="f3.repeatpassword.errors.required">Confirmation requise</div>
                            <div *ngIf="f3.repeatpassword.errors.mustMatch">Le mot de passe et la confirmation doivent être identiques</div>
                        </div>
                    </div>

                <button [disabled]="loading" class="btn btn-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Sauvegarder
                </button>
                <!-- <div class="alert alert-danger mt-3 mb-0">Erreur {{errorRetour}}</div> -->

                <div *ngIf="errorRetour === '66'" class="alert alert-danger mt-3 mb-0">Le code ne correspond pas à celui envoyé par mail.<br />Veuillez vérifier et renvoyer svp</div>
            
            </form>

            <div class="form-group" *ngIf="newPassFinish">
                <div class="text-center">
                    <h4>Procédure de récupération du mot de passe terminée</h4>
                    <p>Nous vous confirmons que votre nouveau mot de passe a bien été enregistré.</p>
                    <p>Vous pouvez dès à présent l'utiliser pour vous connecter</p>
                    <button type="button" class="btn btn-outline-primary btn-sm" (click) = "newPassFinish = false; showOubliPass = false; oubliPassPhase1 = false; oubliPassPhase2 = false; errorRetour = '' ">Me connecter</button>

                </div>
            </div>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../_helpers/app.constants';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class RestaurantsService {

  constructor(private http: HttpClient) { }

  // RsJS call Observable https://ebookwebservice.azurewebsites.net/restaurants.php?token=cPnFKCqNQLKw
  servListeRestos(): Observable<any> {
      const _apiListeRestos = AppConstants.baseURL + '/restaurantsliste.php?token=cPnFKCqNQLKw&cachebuster=' +
      (Math.floor(Math.random() * 9000) + 1);
      const myObs: Observable<any> = this.http.get(`${_apiListeRestos}`);
      return myObs;
  }

  servRestoDetail(id: number): Observable<any> {
    const _apiRestoDetail = AppConstants.baseURL + '/restodetails.php?token=cPnFKCqNQLKw&id=' + id;
    const myObs2: Observable<any> = this.http.get(`${_apiRestoDetail}`);
    console.log('Retourne detailrestos ');
    return myObs2;
  }

  servRestoResa(id: number): Observable<any> {
    const _apiTypeCooking = AppConstants.baseURL + '/restoreservation.php?token=cPnFKCqNQLKw&id=' + id +
    '&cachebuster=' + (Math.floor(Math.random() * 9000) + 1);
    const myObs3: Observable<any> = this.http.get(`${_apiTypeCooking}`);
    console.log('Accès service Resa ');
    return myObs3;
  }

  // Service pour la liste des restos en accueil avec la map Google en Suise romande
  listeRestoGPS(): Observable<any> {
    const _apiTypeGPSliste = AppConstants.baseURL + '/listemaprestos.php?token=cPnFKCqNQLKw';
    const myObs3: Observable<any> = this.http.get(`${_apiTypeGPSliste}`);
    return myObs3;
  }

  // Service pour la liste des restos en accueil avec la map Google en Suise romande
  joursOuverture(): Observable<any> {
    const _apijourOuverts = AppConstants.baseURL + '/filtredisporesto.php?token=cPnFKCqNQLKw' +
    '&cachebuster=' + (Math.floor(Math.random() * 9000) + 1);
    const myObs3: Observable<any> = this.http.get(`${_apijourOuverts}`);
    return myObs3;
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeLoisirs'
})
export class TypeLoisirsPipe implements PipeTransform {

  transform (value: string): any{ 
    value = value.replace(/[/]/g, "et"); 
    value = value.replace(/[,]/g, " - "); 
    value = undefined ? value : value.substr(0,1).toUpperCase( ) + value.substr(1).toLowerCase( );
    
    
    return value; 

}

}

import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { RestaurantsService } from '../_services/restaurants.service';
import { LoisirsService } from '../_services/loisirs.service';
import { Subscription } from 'rxjs';


@Component({ 
    templateUrl: 'home.component.html',
    styleUrls: ['./home.component.css'] })

export class HomeComponent implements OnInit {
    loading = false;
    loadingloisirs = false;
    restos: any[];
    loisirs: any[];
    imgTitre = 'assets/images/home_picture.jpg';
    latitude = 46.7;
    longitude = 6.977691;
    zoom = 10;
    interval: Subscription;
    restoDefinitif = [];
    loisirsDefinitif = [];
    pourCacherCartes = true;


    constructor(
        private restaurantsService: RestaurantsService, 
        private loisirsService: LoisirsService, 
        private titleService: Title,) 
        {titleService.setTitle("Sortez à 2 payez pour 1"); }

    clickedMarkerRestos(id: string) {
        window.open(`/Restaurant/Details/${id}`);
        }
    clickedMarkerLoisirs(id: string) {
        window.open(`/Loisirs/Details/${id}`);
        }

    detailOffre(){
        window.open('https://ebook-card.com/categorie-shop/toutes-nos-cartes/')
    }

    ngOnInit() {
        this.loading = true;

        this.restaurantsService.listeRestoGPS().subscribe(restos => {
            this.loading = false;
            this.restos = restos;
        });

        this.loisirsService.listeLoisirsGPS().subscribe(restos => {
            this.loadingloisirs = false;
            this.loisirs = restos;
        });

        this.getListeRestos(); // va chercher liste resto à l'avance
        this.getListeLoiris(); // va chercher liste lisoirs à l'avance
    }

    getListeRestos(): void {
        // Si aucune donnée dans sessionStorage on charge la liste
        if (!sessionStorage.getItem('listerestos')) {
            this.interval = this.restaurantsService.servListeRestos().subscribe(restaurants => {
                this.restoDefinitif = restaurants;
                for (let i = this.restoDefinitif.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * i);
                    const temp = this.restoDefinitif[i];
                    this.restoDefinitif[i] = this.restoDefinitif[j];
                    this.restoDefinitif[j] = temp;
                }
                sessionStorage.setItem('listerestos',  JSON.stringify(this.restoDefinitif));
                console.log('getListeRestos terminé');
            });
        }
    }

    // Fonction qui va chercher le service Loisirs. Et tri de manière aléatoire
    getListeLoiris(): void {
        // Si aucune donnée dans sessionStorage on charge la liste
        if (!sessionStorage.getItem('listeloisirs')) {
            this.interval = this.loisirsService.servListeLoisirs().subscribe(lesLoisirs => {
                this.loisirsDefinitif = lesLoisirs;
                for (let i = this.loisirsDefinitif.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * i);
                    const temp = this.loisirsDefinitif[i];
                    this.loisirsDefinitif[i] = this.loisirsDefinitif[j];
                    this.loisirsDefinitif[j] = temp;
                }
                sessionStorage.setItem('listeloisirs',  JSON.stringify(this.loisirsDefinitif));
                console.log('getListeLoiris terminé');
            });
        }
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../_helpers/app.constants';

@Injectable({
  providedIn: 'root'
})
export class TestsService {

  constructor(private http: HttpClient) { }
  // Service pour la liste des restos en accueil avec la map Google en Suise romande
  essaiSecurity(): Observable<any> {
    const token = Date.now();
    const tokenDef = token.toString();
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-AUTH-TOKEN': tokenDef, 'X-UID': 'myuserid' })
    };
    const _apiType = AppConstants.baseURL + '/testsSecurity.php' + '?cachebuster=' + (Math.floor(Math.random() * 9000) + 1);
    const myObs3: Observable<any> = this.http.get(`${_apiType}`, httpOptions);
    // console.log(tokenDef);
    return myObs3;
  }
}
//  `${this.tokenDate}`
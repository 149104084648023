import { Component, OnInit, Input } from '@angular/core';
import { RestaurantsService } from '../_services/restaurants.service';
import { LoisirsService } from '../_services/loisirs.service';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-restaurants-filtres',
  templateUrl: './restaurants-filtres.component.html',
  styleUrls: ['./restaurants-filtres.component.css']
})
export class RestaurantsFiltresComponent implements OnInit  {
  constructor(private ordresService: RestaurantsService, 
              private loisirsService: LoisirsService, 
              private titleService: Title,) 
              {titleService.setTitle("Les restaurants"); }
    cuisines: any;
    listTypeCuisines = [];
    compte = false;
    interval: Subscription;
    filtre = [];
    restos = [];
    restosbis = [];
    restoDefinitif = [];
    cantons = [];
    prix = [];
    listCantons = [];
    compteCantons = false;
    listPrix = [];
    comptePrix = false;
    filtreTypes = [];
    filtreCantons = [];
    filtrePrix = [];
    filtreEquip = [];
    filtreOuverture = [];
    ceMidi = false;
    ceSoir = false;
    midiHeure: string;
    soirHeure: string;
    listeOuverture = [];
    listeEquipements = [];
    loisirsDefinitif = [];
    imgTitre = 'assets/images/Baniere_gastro.jpg';

    joursOuverts = [
      {
        libelle: 'Ce midi (délai 11h45)',
        value: '77'
      },
      {
        libelle: 'Ce soir (délai 19h00)',
        value: '99'
      },
      {
        libelle: 'Lundi',
        value: 0
      },
      {
        libelle: 'Mardi',
        value: 1
      },
      {
        libelle: 'Mercredi',
        value: 2
      },
      {
        libelle: 'Jeudi',
        value: 3
      },
      {
        libelle: 'Vendredi',
        value: 4
      },
      {
        libelle: 'Samedi',
        value: 5
      },
      {
        libelle: 'Dimanche',
        value: 6
      }
    ];

    @Input() restaurants = [];
    @Input() comptResto = false;

    ngOnInit() {
      // this.restaurants = this.route.snapshot.data.products;
        this.getListeRestos();
    }

    getListeRestos(): void {
      // Si aucune donnée dans sessionStorage on charge la liste
      if (!sessionStorage.getItem('listerestos')) {
        // console.log('appel');
        this.interval = this.ordresService.servListeRestos().subscribe(restaurants => {
          this.restoDefinitif = restaurants;
          for (let i = this.restoDefinitif.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * i);
            const temp = this.restoDefinitif[i];
            this.restoDefinitif[i] = this.restoDefinitif[j];
            this.restoDefinitif[j] = temp;
          }
          this.restaurants = this.restoDefinitif;
          sessionStorage.setItem('listerestos',  JSON.stringify(this.restaurants));
          this.restos = this.restoDefinitif;
          this.comptResto = true;
          this.constructTypeCuisines();
          this.constructListCantons();
          this.constructListPrix();
          this.constructListEquip();
       });

      } else {
        // console.log('appel pas' );
        this.restaurants = JSON.parse(sessionStorage.getItem('listerestos'));
        this.restos = this.restaurants;
        this.constructTypeCuisines();
        this.constructListCantons();
        this.constructListPrix();
        this.constructListEquip();
        this.comptResto = true;
      }

      this.interval = this.ordresService.joursOuverture().subscribe(listeouvert => {
        this.listeOuverture = listeouvert;
        this.midiHeure = listeouvert.midiheure;
        this.soirHeure = listeouvert.soirheure;
        this.getListeLoiris(); // Un fois resto là, on va chercher les loisirs

        //console.table(this.listeOuverture);
     });

    }

    // Fonction sur clic de la liste à gauche
    clickFiltreTypes(value: any) {
      if (this.filtreTypes.includes(value)) {
        this.filtreTypes[value] = null;
      } else {
        this.filtreTypes[value] = value;
      }
      this.getListeAfterClick();
    }

    // Fonction sur clic de la liste à gauche
    clickFiltreCantons(value: any) {
      if (this.filtreCantons.includes(value)) {
        this.filtreCantons[value] = null;
      } else {
        this.filtreCantons[value] = value;
      }
      this.getListeAfterClick();
    }

    // Fonction sur clic de la liste à gauche
    clickFiltrePrix(value: any) {
      // 1. On ajoute ou supprime la catégorie du filtre
      if (this.filtrePrix.includes(value)) {
        this.filtrePrix[value] = null;
      } else {
        this.filtrePrix[value] = value;
      }
      // console.log('Prix ' + this.filtrePrix);
      // 2. On appelle la fonction qui va retrier les loisirs
      this.getListeAfterClick();
    }

    // Fonction sur clic de la liste à gauche
    clickFiltreEquip(value: any) {
      if (this.filtreEquip.includes(value)) {
        this.filtreEquip[value] = null;
      } else {
        this.filtreEquip[value] = value;
      }
      this.getListeAfterClick();
    }

    // Fonction sur clic de la liste à gauche
    clickFiltreOuverture(value: any) {
      const laValue = + value;
      if (laValue === 77 || laValue === 99) {
        if (laValue === 77) {
          this.ceMidi = !this.ceMidi;
        }
        if (laValue === 99) {
          this.ceSoir = !this.ceSoir;
        }
      } else {
        if (this.filtreOuverture.includes(laValue)) {
          this.filtreOuverture[laValue] = null;
        } else {
          this.filtreOuverture[laValue] = laValue;
        }
      }
      // console.log('Midi: ' + this.ceMidi + ' Soir: ' + this.ceSoir);
      // console.table('1reFonction ', this.filtreOuverture);

      this.getListeAfterClick();
    }

    // Fonction appelée après un clic dans une catégorie
    // On repasse donc tous les filtres en revue pour construire la  liste
    getListeAfterClick() {
      this.restaurants = []; // On vide la liste des loisirs
      const lesLoisirsBis = []; // Variable de stockage
      const idPourTypes = [];
      const idPourCantons = [];
      const idPouprix = [];
      const idPourOuvert = [];
      const idPourCeMidi = [];
      const idPourCeSoir = [];
      const idPourEquip = [];

      // tslint:disable-next-line: forinrestau
      for (const i in this.restos) {
        const leIDprix = this.restos[i].pricerange_id;
        const leIDtype = this.restos[i].cookingtypesid;  // CECI est un tableau
        const lesIDequip = this.restos[i].equipmentsids;  // CECI est un tableau
        const leIDcanton = this.restos[i].canton_id;
        const leID = this.restos[i].id;
        let isPresent = [];
        // console.table('type3: ', leIDtype );
        // tslint:disable-next-line: forin
        for (const index in this.filtreTypes) {         // Boucle sur les Types de Loisirs
          const myValue = this.filtreTypes[index];
          // console.table('cherche: ' + myValue, leIDtype);
          if (leIDtype.includes(myValue)) {
            // console.log('trouvé!');
            if (!idPourTypes.includes(leID)) {
              idPourTypes[leID] = parseInt(leID, 10);
            }
          }
        }

        // tslint:disable-next-line: forin
        for (const index in this.filtreCantons) {         // Boucle sur les cantons
          const myValue2 = this.filtreCantons[index];
          if (leIDcanton.includes(myValue2)) {
            if (!idPourCantons.includes(leID)) {
              idPourCantons[leID] = parseInt(leID, 10);
            }
          }
        }

        // tslint:disable-next-line: forin
        for (const index in this.filtrePrix) {         // Boucle sur les prix
          const myValue3 = this.filtrePrix[index];
          if (leIDprix.includes(myValue3)) {
            if (!idPouprix.includes(leID)) {
              idPouprix[leID] = parseInt(leID, 10);
            }
          }
        }

        // !! POUR équipements on doit tous les avoir pour ajouter le resto
        for (const index in this.filtreEquip) {         // Boucle sur les équipementss
          if (index) {
            const myValue31 = this.filtreEquip[index];
            if (!lesIDequip.includes(myValue31)) {
              isPresent[myValue31] = 'KO';
            }
          }
        }
        if (!isPresent.includes('KO')) {
          if (!idPourEquip.includes(leID)) {
            idPourEquip[leID] = parseInt(leID, 10);
          }
        }

        /*
        for (const index in this.filtreEquip) {         // Boucle sur les équipementss
          if (index) {
            const myValue31 = this.filtreEquip[index];
            if (lesIDequip.includes(myValue31)) {
              if (!idPourEquip.includes(leID)) {
                idPourEquip[leID] = parseInt(leID, 10);
              }
            }
          }
        }  */

        // tslint:disable-next-line: forin
        for (const index in this.filtreOuverture) {
          const idOuv = this.filtreOuverture[index];
          if (this.listeOuverture[leID].joursouverts) {
            const ouvertThisDay = +this.listeOuverture[leID].joursouverts[idOuv];
            if (ouvertThisDay === 1) {
              if (!idPourOuvert.includes(leID)) {
                idPourOuvert[leID] = parseInt(leID, 10);
              }
            }
          }
        }

        if (this.ceMidi) {
          const ceMidi = +this.listeOuverture[leID].dispomidi;
          if (ceMidi === 1) {
            if (!idPourCeMidi.includes(leID)) {
              idPourCeMidi[leID] = parseInt(leID, 10);
            }
          }
        }

        if (this.ceSoir) {
          const ceSoir = +this.listeOuverture[leID].disposoir;
          if (ceSoir === 1) {
            if (!idPourCeSoir.includes(leID)) {
              idPourCeSoir[leID] = parseInt(leID, 10);
            }
          }
        }


      }
      // console.table('idPourOuvert: ', idPourOuvert);
      // console.table('idPourTypes: ' + idPourTypes);
      // console.table('idPourCantons: ' + idPourCantons);idPourEquip
      // console.table('idPourEquip: ', idPourEquip);

      // ICI, si on a un filtre, on croise les filtres pour construire la liste 
      // console.log('Midi: ' + this.ceMidi + ' Soir: ' + this.ceSoir);
      // console.table('Ouverture ', this.filtreOuverture);

      this.restos.forEach(element => {
          const bonID = +element.id;
          // lesLoisirsBis = lesLoisirsBis.concat(idPourTypes, idPourCantons, idPouprix, idPourOuvert, idPourCeMidi, idPourEquip);

          if (idPourTypes.length > 0 && idPourCantons.length > 0 && idPouprix.length > 0 && idPourOuvert.length > 0 && idPourEquip.length > 0) {
            if (idPourTypes.includes(bonID) && idPourCantons.includes(bonID) && idPouprix.includes(bonID) && idPourOuvert.includes(bonID) && idPourEquip.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourTypes.length > 0 && idPourCantons.length > 0 && idPouprix.length > 0 && idPourOuvert.length > 0) {
            if (idPourTypes.includes(bonID) && idPourCantons.includes(bonID) && idPouprix.includes(bonID) && idPourOuvert.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourTypes.length > 0 && idPourCantons.length > 0 && idPouprix.length > 0 && idPourEquip.length > 0) {
            if (idPourTypes.includes(bonID) && idPourCantons.includes(bonID) && idPouprix.includes(bonID) && idPourEquip.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourTypes.length > 0 && idPouprix.length > 0 && idPourOuvert.length > 0 && idPourEquip.length > 0) {
            if (idPourTypes.includes(bonID) && idPouprix.includes(bonID) && idPourOuvert.includes(bonID) && idPourEquip.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourCantons.length > 0 && idPourOuvert.length > 0 && idPourEquip.length > 0 && idPouprix.length > 0) {
            if (idPourCantons.includes(bonID) && idPourOuvert.includes(bonID) && idPourEquip.includes(bonID) && idPouprix.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourTypes.length > 0 && idPourCantons.length > 0 && idPourOuvert.length > 0 && idPourEquip.length > 0) {
            if (idPourTypes.includes(bonID) && idPourCantons.includes(bonID) && idPourOuvert.includes(bonID) && idPourEquip.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourTypes.length > 0 && idPourCantons.length > 0 && idPouprix.length > 0) {
            if (idPourTypes.includes(bonID) && idPourCantons.includes(bonID) && idPouprix.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourTypes.length > 0 && idPouprix.length > 0 && idPourOuvert.length > 0) {
            if (idPourTypes.includes(bonID) && idPouprix.includes(bonID) && idPourOuvert.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourTypes.length > 0 && idPourCantons.length > 0 && idPourOuvert.length > 0) {
            if (idPourTypes.includes(bonID) && idPourCantons.includes(bonID) && idPourOuvert.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourCantons.length > 0 && idPouprix.length > 0 && idPourOuvert.length > 0) {
            if (idPourCantons.includes(bonID) && idPouprix.includes(bonID) && idPourOuvert.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourEquip.length > 0 && idPourTypes.length > 0 && idPourCantons.length > 0) {
            if (idPourEquip.includes(bonID) && idPourTypes.includes(bonID) && idPourCantons.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourEquip.length > 0 && idPourTypes.length > 0 && idPourOuvert.length > 0) {
            if (idPourEquip.includes(bonID) && idPourTypes.includes(bonID) && idPourOuvert.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourEquip.length > 0 && idPourCantons.length > 0 && idPourOuvert.length > 0) {
            if (idPourEquip.includes(bonID) && idPourCantons.includes(bonID) && idPourOuvert.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourEquip.length > 0 && idPourCantons.length > 0 && idPouprix.length > 0) {
            if (idPourEquip.includes(bonID) && idPourCantons.includes(bonID) && idPouprix.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourEquip.length > 0 && idPouprix.length > 0 && idPourOuvert.length > 0) {
            if (idPourEquip.includes(bonID) && idPouprix.includes(bonID) && idPourOuvert.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourEquip.length > 0 && idPourTypes.length > 0 && idPouprix.length > 0) {
            if (idPourEquip.includes(bonID) && idPourTypes.includes(bonID) && idPouprix.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourTypes.length > 0 && idPourCantons.length > 0) {
            if (idPourTypes.includes(bonID) && idPourCantons.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourTypes.length > 0 && idPouprix.length > 0) {
            if (idPourTypes.includes(bonID) && idPouprix.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourTypes.length > 0 && idPourOuvert.length > 0) {
            if (idPourTypes.includes(bonID) && idPourOuvert.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourCantons.length > 0 && idPouprix.length > 0) {
            if (idPourCantons.includes(bonID) && idPouprix.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourCantons.length > 0 && idPourOuvert.length > 0) {
            if (idPourCantons.includes(bonID) && idPourOuvert.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPouprix.length > 0 && idPourOuvert.length > 0) {
            if (idPouprix.includes(bonID) && idPourOuvert.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourEquip.length > 0 && idPourTypes.length > 0) {
            if (idPourEquip.includes(bonID) && idPourTypes.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourEquip.length > 0 && idPourCantons.length > 0) {
            if (idPourEquip.includes(bonID) && idPourCantons.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourEquip.length > 0 && idPouprix.length > 0) {
            if (idPourEquip.includes(bonID) && idPouprix.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourEquip.length > 0 && idPourOuvert.length > 0) {
            if (idPourEquip.includes(bonID) && idPourOuvert.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourTypes.length > 0 ) {
            if (idPourTypes.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourCantons.length > 0 ) {
            if (idPourCantons.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPouprix.length > 0 ) {
            if (idPouprix.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourOuvert.length > 0 ) {
            if (idPourOuvert.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          }  else if (idPourCeMidi.length > 0 ) {
            if (idPourCeMidi.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourCeSoir.length > 0 ) {
            if (idPourCeSoir.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          } else if (idPourEquip.length > 0 ) {
            if (idPourEquip.includes(bonID)) {
              if (this.ceMidi || this.ceSoir) {
                if (this.ceMidi && idPourCeMidi.includes(bonID) && !this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (!this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir) {
                  lesLoisirsBis.push(element);
                } else if (this.ceMidi && idPourCeSoir.includes(bonID) && this.ceSoir && idPourCeMidi.includes(bonID)) {
                  lesLoisirsBis.push(element);
                }
              } else {
                lesLoisirsBis.push(element);
              }
            }
          }

      });
      if (idPourTypes.length > 0 || idPourCantons.length > 0 || idPouprix.length > 0 ||
          idPourOuvert.length > 0 || idPourCeMidi.length > 0 || idPourCeSoir.length > 0 || idPourEquip.length > 0) {
        this.restaurants = lesLoisirsBis;
        console.log('UPDATED! ');

      } else {
        // this.interval = this.ordresService.servListeRestos().subscribe(loisirsrecus => this.restaurants = loisirsrecus);
        this.restaurants = JSON.parse(sessionStorage.getItem('listerestos'));
      }
    }

    // Construction de la liste des type de cuisines à gauche
    constructTypeCuisines() {
      const arrVerif = [];
      // tslint:disable-next-line: forin
      for (const i in this.restaurants) {
        const arrID = this.restaurants[i].cookingtypesbis;

        arrID.forEach(element => {
          // console.log('item: ' + element);
          const leIDdef = parseInt(element.cookingtype_id, 10);
          const leName = element.name;
          const foo: any = {};
          if (!arrVerif.includes(leIDdef) && leName !== '-') {
              foo.id = leIDdef;
              foo.name = leName;
              arrVerif[leIDdef] = leIDdef;
              // console.log('IDcuisine: ' + leIDdef);
              this.listTypeCuisines.push(foo);
              this.compte = true;
            }
        });
      }
    }
    /* Classe les types de cuisine par ordre alphabétique */
    sortByCuisine(prop: string) {
      return this.listTypeCuisines.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    }

    /* Classe la liste de prix par ID */
    sortByPrix(prop: string) {
      return this.listPrix.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    }

    // Construction de la liste des cantons à gauche
    constructListCantons() {
      const arrVerif = [];
      // tslint:disable-next-line: forin
      for (const i in this.restaurants) {
        const leID = this.restaurants[i].canton_id;
        const leName = this.restaurants[i].canton;
        const foo: any = {};
        if (!arrVerif.includes(leID)) {
          foo.id = leID;
          foo.name = leName;
          arrVerif[leID] = leID;
          this.listCantons.push(foo);
          this.compteCantons = true;
        }
      }
    }

    // Construction de la liste des prix à gauche
    constructListPrix() {
      const arrVerif = [];
      // tslint:disable-next-line: forin
      for (const i in this.restaurants) {
        const leID = this.restaurants[i].pricerange_id;
        const leName = this.restaurants[i].pricerange;
        const foo: any = {};
        if (!arrVerif.includes(leID)) {
          foo.id = leID;
          foo.name = leName;
          arrVerif[leID] = leID;
          this.listPrix.push(foo);
          this.comptePrix = true;
        }
      }
    }

    // Construction de la liste des équipements à gauche
    constructListEquip() {
      const arrVerif = [];
      for (const i in this.restaurants) {
        if (i) {
          for (const j in this.restaurants[i].equipmentsid) {
            if (j) {
              // console.log('j ' + j);
              const leID = this.restaurants[i].equipmentsid[j].id;
              const leName = this.restaurants[i].equipmentsid[j].value;
              const foo: any = {};
              if (!arrVerif.includes(leID)) {
                foo.id = leID;
                foo.name = leName;
                arrVerif[leID] = leID;
                this.listeEquipements.push(foo);
              }
            }
          }
        }
      }
      this.listeEquipements.sort((a, b) => a.name.length - b.name.length);

    }

    // Fonction qui va chercher le service Loisirs. Et tri de manière aléatoire
    getListeLoiris(): void {
      // Si aucune donnée dans sessionStorage on charge la liste
      if (!sessionStorage.getItem('listeloisirs')) {
          this.interval = this.loisirsService.servListeLoisirs().subscribe(lesLoisirs => {
              this.loisirsDefinitif = lesLoisirs;
              for (let i = this.loisirsDefinitif.length - 1; i > 0; i--) {
                  const j = Math.floor(Math.random() * i);
                  const temp = this.loisirsDefinitif[i];
                  this.loisirsDefinitif[i] = this.loisirsDefinitif[j];
                  this.loisirsDefinitif[j] = temp;
              }
              sessionStorage.setItem('listeloisirs',  JSON.stringify(this.loisirsDefinitif));
              console.log('getListeLoiris terminé');
          });
      }
    }
}

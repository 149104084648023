import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
   name: 'jourMoisAnneeHeure'
})
export class CustomDatePipeLong extends
             DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return super.transform(value, "dd.MM.y HH:mm");
  }
}

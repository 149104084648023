<br>
<div class="grid_container">
    <div></div>
    <div class="grandeBoxDetail">
        <br>
        <div class="grid_conditions" style="color: white;">
            <p></p>
            <h1 class="font_title" style="color:white">Conditions générales de vente</h1>
            <br>
            <p></p>
            <p></p>
            <p class="bold-green">Article 1: La société</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">Les présentes conditions générales sont valables pour tous les produits vendus par la société {{entreprise}} au travers de site internet.</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">{{entreprise}} est la société qui gère le site internet <span class="bold-blue-e">e</span>book-card.ch</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">Les présentes conditions générales sont valables pour tous les produits vendus par la société {{entreprise}} au travers de site internet </p>
            
        </div>
        <br>

        <div class="grid_conditions" style="color: white;">
            <p class="puce">&#x2022;</p>
            <p class="bold-green">Article 2: Prix de vente</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">Les prix indiqués sont en Francs Suisse TVA comprise.</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">L’achat d'un code <span class="bold-blue-e">e</span>book-card n'est pas soumis à des frais supplémentaires.</p>
        </div>
        <br>
        <div class="grid_conditions" style="color: white;">
            <p></p>
            <p class="bold-green">Article 3: Code réduction</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">Tous les codes de réductions ne sont pas cumulables pour nos offres.</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">Seul un code de réduction est accepté par client.</p>
        </div>
        <br>
        <div class="grid_conditions" style="color: white;">
            <p></p>
            <p class="bold-green">Article 4: Evolution de nos partenaires</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">Par partenaire s’entend le partenaire restaurateur ou partenaire loisir qui offre un rabais aux clients d’<span class="bold-blue-e">e</span>book-card.ch.</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">Le nombre de partenaires qui offrent une réduction aux clients d’<span class="bold-blue-e">e</span>book-card.ch évolue en permanence.</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">Un partenaire peut se faire exclure d’<span class="bold-blue-e">e</span>book-card.ch à tout moment.</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">Aucun droit ne peut en découler et aucun remboursement partiel ou total ne pourra être accordé.</p>
        </div>
        <br>
        <!--
        <div class="grid_conditions" style="color: white;">
            <p></p>
            <p class="bold-green">Article 5: Perte ou vol</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">En cas de perte ou de vol de la carte, le client doit impérativement avertir <span class="bold-blue-e">e</span>book-card.ch qui bloquera la carte.</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">Le client recevra une nouvelle carte en remplacement jusqu’au terme de validité de la carte perdue ou volée.</p>
        </div>
        <br>
        -->
        <div class="grid_conditions" style="color: white;">
            <p></p>
            <p class="bold-green">Article 5: Protection des données</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">{{entreprise}} respecte la loi suisse sur la protection des données. Seules les données nécessaires au bon accomplissement de ses prestations sont traitées.</p>
        </div>
        <br>
        <div class="grid_conditions" style="color: white;">
            <p></p>
            <p class="bold-green">Article 6: Exceptions</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">L’<span class="bold-blue-e">e</span>book-card est valable tous les jours sauf exceptions sur l’annonce du partenaire concerné. Elle n’est pas valable les jours fériés officiels, et les jours d’événements particuliers des partenaires.</p>
        </div>
        <br>
        <div class="grid_conditions" style="color: white;">
            <p></p>
            <p class="bold-green">Article 7: Condition d’utilisation</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">Uniquement les réservations réalisées par le site internet <span class="bold-blue-e">e</span>book-card.ch donnent droit aux réductions. Si le client se présente chez un partenaire sans avoir effectué une réservation, aucune réduction ne lui sera accordée.</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">Pour bénéficier des réductions le client doit être enregistré sur le site internet <span class="bold-blue-e">e</span>book-card.ch et avoir un code en cours de validité.</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">Les réductions auxquelles le client à droit sont clairement indiquées sur le site internet <span class="bold-blue-e">e</span>book-card.ch et ne sont pas cumulables avec d’autres offres du partenaire.</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">Le partenaire est en droit de fixer des restrictions. Ces dernières seront clairement indiquées. Les restrictions priment sur la règle de base.</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">Le client pourra bénéficier des réductions une seule fois chez chacun des partenaires sur la durée de sa carte.</p>
            <br>
            <p></p>
            <p></p>
            <p style="font-size: 18px;font-weight:bold">Pour la partie gastronomie:</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">Seule une ebook-card sera acceptée par table et par addition.</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">Pour pouvoir prétendre aux réductions, le client devra consommer au moins une boisson payante par personne comptée dans le calcul des réductions. La carafe d’eau est interdite.</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">Le client devra montrer le SMS de confirmation au restaurateur dès qu’il arrive dans l’établissement.</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">Les menus de tous types sont exclus des réductions.</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">Les payements par carte de crédit ou autres moyens de payement que par le cash peuvent être refusés par le partenaire.</p>
        </div>
        <br>
        <div class="grid_conditions" style="color: white;">
            <p></p>
            <p class="bold-green">Article 8: Exclusions</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">{{entreprise}} se réserve le droit d’exclure tout membre à n’importe quel moment d’<span class="bold-blue-e">e</span>book-card.ch.</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">En cas d’exclusion aucun remboursement ne sera effectué.</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">Le partenaire se réserve le droit de refuser un membre <span class="bold-blue-e">e</span>book-card.ch en cas d’abus ou qui ne respecterait pas une de ces règles.</p>
        </div>
        <br>
        <div class="grid_conditions" style="color: white;">
            <p></p>
            <p class="bold-green">Article 9: For juridique</p>
            <p class="puce">&#x2022;</p>
            <p class="text_size">Le for juridique se trouve au même lieu que le siège social de {{entreprise}}, à 2338 Muriaux ( JU ) en Suisse.</p>
           <br>
        </div>
    </div>
    <div></div>
</div>
<br>

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppConstants } from '../_helpers/app.constants';

@Injectable({ providedIn: 'root' })
export class UserService  {
    constructor(private http: HttpClient) { }

    /** GET informations sur l'utilisateur toutes les xx secondes  
    getUserInfos(userid: string): any {
        const token = Date.now();
        const tokenDef = token.toString();
        const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-AUTH-TOKEN': tokenDef, 'X-UID': userid })
        };
        const usersInfos = AppConstants.baseURL + '/usersinfo.php?cachebuster=';
        return timer(0, 15000).pipe(
            switchMap(() =>  this.http.get<any[]>(`${usersInfos}` + (Math.floor(Math.random() * 9000) + 1), httpOptions) ) );
    }

    
    getUserInfosOnce(userid: string): any {
        const token = Date.now();
        const tokenDef = token.toString();
        const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-AUTH-TOKEN': tokenDef, 'X-UID': userid })
        };
        const usersInfos = AppConstants.baseURL + '/usersinfo.php?cachebuster=';
        const myObs3 = this.http.get(`${usersInfos}` + (Math.floor(Math.random() * 9000) + 1), httpOptions);
        return myObs3;
    }  */

    getUserInfos(userid: string): any {
        const token = Date.now();
        const tokenDef = token.toString();
        const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-AUTH-TOKEN': tokenDef, 'X-UID': userid })
        };
        const usersInfos = AppConstants.baseURL + '/usersinfo.php?cachebuster=';
        return timer(0, 15000).pipe(
            switchMap(() =>  this.http.post<any[]>(`${usersInfos}` + (Math.floor(Math.random() * 9000) + 1), {
                userid
              }) ) );
    }


    getUserInfosOnce(userid: string): any {
        const token = Date.now();
        const tokenDef = token.toString();
        const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-AUTH-TOKEN': tokenDef, 'X-UID': userid })
        };
        const usersInfos = AppConstants.baseURL + '/usersinfo.php?cachebuster=';
        const myObs3 = this.http.post(`${usersInfos}` + (Math.floor(Math.random() * 9000) + 1), {
            userid
          });
        return myObs3;
    }

}
<br />
<div class="container boutiquefond" >
  <div *ngIf="!nbProduitsArrives"><img class="img-responsive" width="70px" src="assets/images/wait.png"></div>
  <div *ngIf="nbProduitsArrives">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/boutique/toutes-les-cartes">Boutique</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{name}}</li>
      </ol>
    </nav>
  <div class="row">
    <div class="col-sm">
      <div class="imageProduit"><img class="rounded" src="{{urlBoutique}}{{image[0].src}}" ></div>
    </div>
    <div class="col-sm">
      <div class="detailHaut">
        <p class="nomProduit"><strong>{{name}}</strong></p>
        <h2><strong>{{price | number:'2.2'}} CHF</strong></h2>
        <p><b [innerHTML]="short"></b></p>

        <div>
          <button mat-stroked-button (click)="moinsUn()">-</button>&nbsp;&nbsp;{{nbrProduits}}&nbsp;&nbsp;<button mat-stroked-button (click)="plusUn()" >+</button>
          <button mat-raised-button color="primary" (click)="addCart(leId, name, price)">Ajouter au panier</button>
        </div>
        <div *ngIf="monPanier">
          <div *ngIf="monPanier.length > 0">
            <p >&nbsp;</p>
            <a mat-raised-button color="primary" href="boutique/panier/contenu" ><span class="material-icons">shopping_basket</span>&nbsp;Aller au panier</a>
          </div>
        </div>
      </div>
    </div>
  </div>    
  <hr class="thishr"/>

      <div >
        <div class="detailBas" [innerHTML]="description"></div>
      </div>
  </div>
</div>
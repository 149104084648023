<div id="menuFormulaires" class="menuFormulaires">
  <mat-button-toggle-group #group="matButtonToggleGroup" value="reservations">
    <mat-button-toggle value="retourIndex" (click)="retourListePartenaire()" class="btn-outline-primary" aria-label="Text align left">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="reservations" aria-label="Les réservations" >
      <mat-icon>family_restroom</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="commissions" aria-label="Commissions" >
      <mat-icon>payment</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="baseinfo" aria-label="Editer le compte">
      <mat-icon>create</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="general" aria-label="Disponiblités générales">
      <mat-icon>schedule</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="calendar" aria-label="Vacances et fermetures">
      <mat-icon>calendar_today</mat-icon>
    </mat-button-toggle>
    <!--<mat-button-toggle value="category" aria-label="Types de cuisine ou loisirs">
      <mat-icon>category</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="place" aria-label="Adresse sur Google Map">
      <mat-icon>place</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="collection" aria-label="Les images">
      <mat-icon>collections</mat-icon>
    </mat-button-toggle>-->
  </mat-button-toggle-group>
 <!-- <div class="example-selected-value">Selected value: {{group.value}}</div>-->

</div>

<div id="baseinfo" class="padding_menu container formPrestataire" *ngIf="group.value === 'reservations'">
  <h4 class="titel_menu" *ngIf="isResto">{{leNomPrestataire}} <p>&nbsp;</p><p class="sous_titre">Les réservations à venir</p></h4>
  <h4 class="titel_menu" *ngIf="!isResto">{{leNomPrestataire}} <p>&nbsp;</p><p class="sous_titre">Les réservations à venir</p></h4>
  <!--Partie desktop--> 
  <div *ngFor="let resa of resaFutur.reverse()" class="grid_resa desktop">
    <p class="padding_resa">Date et heure :</p>
    <p class="margin_resa">{{resa.DateTime | jourMoisAnneeHeure}}</p>
    <p *ngIf="isResto" class="padding_resa">Service :</p>
    <p *ngIf="isResto" class="margin_resa"><span *ngIf="resa.Lunch === '1'">Midi</span><span *ngIf="resa.Lunch === '0'">Soir</span></p>
    <p *ngIf="!isResto" class="padding_resa">Partie de la journée :</p>
    <p *ngIf="!isResto" class="margin_resa"><span *ngIf="resa.PartOfTheDay === '0'">Le matin</span><span *ngIf="resa.PartOfTheDay === '1'">L'après-midi</span><span *ngIf="resa.PartOfTheDay === '2'">En soirée</span></p>
    <p *ngIf="isResto" class="padding_resa">Nb de personnes :</p>
    <p *ngIf="isResto" class="margin_resa">{{resa.PersonNumber}}</p>
    <p *ngIf="!isResto" class="padding_resa">Nb de personnes :</p>
    <p *ngIf="!isResto" class="margin_resa"><span *ngIf="resa.AdultsNumber > 0">{{resa.AdultsNumber}} adulte(s) </span> <span *ngIf="resa.ChildrenNumber > 0">{{resa.ChildrenNumber}} enfant(s)</span></p>
    <p class="padding_resa">Code réservation :</p>
    <p class="margin_resa">{{resa.ReservationCode}}</p>
    <p class="padding_resa">Info client :</p>
    <p class="margin_resa">{{resa.UserName}} <br/>{{resa.UserPhone}}</p>
    <div><hr></div>
    <div><hr></div>
  </div>
  <!--Partie mobile-->
  <div *ngFor="let resa of resaFutur.reverse()" class="grid_resa mobile">
    <p class="padding_resa mobile">Date et heure : {{resa.DateTime  | jourMoisAnneeHeure}}</p>
    <p *ngIf="isResto" class="padding_resa">Service : <span *ngIf="resa.Lunch === '1'">Midi</span><span *ngIf="resa.Lunch === '0'">Soir</span></p>
    <p *ngIf="!isResto" class="padding_resa">Partie de la journée : <span *ngIf="resa.PartOfTheDay === '0'">Le matin</span><span *ngIf="resa.PartOfTheDay === '1'">L'après-midi</span><span *ngIf="resa.PartOfTheDay === '2'">En soirée</span></p>
    <p *ngIf="isResto" class="padding_resa">Nb de personnes : {{resa.PersonNumber}}</p>
    <p *ngIf="!isResto" class="padding_resa">Nb de personnes : <span *ngIf="resa.AdultsNumber > 0">{{resa.AdultsNumber}} adulte(s) </span> <span *ngIf="resa.ChildrenNumber > 0">{{resa.ChildrenNumber}} enfant(s)</span></p>
    <p class="padding_resa">Code réservation : {{resa.ReservationCode}}</p>
    <p class="padding_resa">Info client : {{resa.UserName}} <br/><span style="padding-left:85px" >{{resa.UserPhone}}</span> </p>
    <div><hr></div>
  </div>
  <div style="text-align: center;" *ngIf="resaFuturNb < 1">Pas de réservations prévue.</div>
  <br><br>

  <h5 class="sous_titre" >Anciennes réservations</h5>
  <!--Partie desktop-->
  <div *ngFor="let resa of resaPassees.reverse()" class="grid_resa desktop">
    <p class="padding_resa">Date et heure :</p>
    <p class="margin_resa">{{resa.DateTime  | jourMoisAnneeHeure}}</p>
    <p class="padding_resa mobile">Date et heure : {{resa.DateTime  | jourMoisAnneeHeure}}</p>
    <p *ngIf="isResto" class="padding_resa">Service :</p>
    <p *ngIf="isResto" class="margin_resa"><span *ngIf="resa.Lunch === '1'">Midi</span><span *ngIf="resa.Lunch === '0'">Soir</span></p>
    <p *ngIf="!isResto" class="padding_resa">Partie de la journée :</p>
    <p *ngIf="!isResto" class="margin_resa"><span *ngIf="resa.PartOfTheDay === '0'">Le matin</span><span *ngIf="resa.PartOfTheDay === '1'">L'après-midi</span><span *ngIf="resa.PartOfTheDay === '2'">En soirée</span></p>
    <p *ngIf="isResto" class="padding_resa">Nb de personnes :</p>
    <p *ngIf="isResto" class="margin_resa">{{resa.PersonNumber}}</p>
    <p *ngIf="!isResto" class="padding_resa">Nb de personnes :</p>
    <p *ngIf="!isResto" class="margin_resa"><span *ngIf="resa.AdultsNumber > 0">{{resa.AdultsNumber}} adulte(s) </span> <span *ngIf="resa.ChildrenNumber > 0">{{resa.ChildrenNumber}} enfant(s)</span></p>
    <p class="padding_resa">Code réservation :</p>
    <p class="margin_resa">{{resa.ReservationCode}}</p>
    <p class="padding_resa">Info client :</p>
    <p class="margin_resa">{{resa.UserName}} <br/>{{resa.UserPhone}}</p>
    <div><hr></div>
    <div><hr></div>
  </div>
  <!--Partie mobile-->
  <div *ngFor="let resa of resaPassees" class="grid_resa mobile"> <!--Quand met revers() la liste se met pas dans le bon ordre-->
    <p class="padding_resa mobile">Date et heure : {{resa.DateTime  | jourMoisAnneeHeure}}</p>
    <p *ngIf="isResto" class="padding_resa">Service : <span *ngIf="resa.Lunch === '1'">Midi</span><span *ngIf="resa.Lunch === '0'">Soir</span></p>
    <p *ngIf="!isResto" class="padding_resa">Partie de la journée : <span *ngIf="resa.PartOfTheDay === '0'">Le matin</span><span *ngIf="resa.PartOfTheDay === '1'">L'après-midi</span><span *ngIf="resa.PartOfTheDay === '2'">En soirée</span></p>
    <p *ngIf="isResto" class="padding_resa">Nb de personnes : {{resa.PersonNumber}}</p>
    <p *ngIf="!isResto" class="padding_resa">Nb de personnes : <span *ngIf="resa.AdultsNumber > 0">{{resa.AdultsNumber}} adulte(s) </span> <span *ngIf="resa.ChildrenNumber > 0">{{resa.ChildrenNumber}} enfant(s)</span></p>
    <p class="padding_resa">Code réservation : {{resa.ReservationCode}}</p>
    <p class="padding_resa">Info client : {{resa.UserName}} <br/><span style="padding-left:85px" >{{resa.UserPhone}}</span> </p>
    <div><hr></div>
  </div>
  <div style="text-align: center;" *ngIf="resaPasseesNb < 1">Pas d'anciennes réservations de moins d'un mois.</div>
</div>   


<div id="baseinfo" class="padding_menu container formPrestataire" *ngIf="group.value === 'commissions'">
  <h4 class="titel_menu" >{{leNomPrestataire}} > Commissions</h4>
    <div class="row" >
      <div class="col-sm-12" >

        
        <ng-container *ngIf="ordersNb != 0">
          <h5><br />Commissions en cours </h5>
          <table class="table"> 
            <tr >
                <td>Nb ebook-card: {{ordersNb}}</td><td>Montant: {{ordersPrice | number: "1.2-2"}} Frs.</td>
            </tr>
          </table>
        </ng-container>

        <ng-container *ngIf="ordersNb < 1">
          <h5><br />Il n'y a pas de commissions en cours</h5><br>
        </ng-container>

        <ng-container *ngIf="commissionsOldNb != 0">
          <h5>Commissions déjà payées</h5>
          <table class="table"> 
            <tr *ngFor="let comm of lesCommissionsOld">
                <td class="desktop">Nb ebook-card: {{comm.CardsNumber}}</td>
                <td class="mobile">Nb: {{comm.CardsNumber}}</td>
                <td>Creation: {{comm.CreationDate | jourMoisAnnee}}</td> 
                <td>Payé le: {{comm.PaymentDate | jourMoisAnnee}}</td>
                <td>Montant: {{comm.Amount}}</td>
                <!--
                <td>HT: {{comm.HTAmount}}</td>
                <td>TVA: {{comm.VATRate}}</td>
                -->
            </tr>
          </table>
        </ng-container>

        <ng-container *ngIf="commissionsOldNb < 1">
          <h5>Il n'y a pas de commissions payées</h5><br>
        </ng-container>
        
        
        <!--  
        <ng-container *ngIf="commissionsWaitingNb != 0">
          <h5>Anciennes Commissions</h5>
          <table class="table"> 
            <tr *ngFor="let comm of lesCommissionsWaiting">
                <td>Nb ebook-card: {{comm.CardsNumber}}</td><td>Montant: {{comm.Amount}}</td><td>Creation: {{comm.CreationDate | jourMoisAnneeHeure}}</td> <td>PayDate: {{comm.PaymentDate}}</td><td>HT: {{comm.HTAmount}}</td><td>TVA: {{comm.VATRate}}</td>
            </tr>
          </table>
        </ng-container>
        -->

        

      </div>
    </div>
</div>   

<div id="baseinfo" class="padding_menu container formPrestataire" *ngIf="group.value === 'baseinfo'">
  <h4 class="titel_menu" *ngIf="isResto">{{leNomPrestataire}} > Modifier le menu</h4>
  <h4 class="titel_menu" *ngIf="!isResto">{{leNomPrestataire}} > Modifier infos</h4>
  <form [formGroup]="formBase" (ngSubmit)="submitBase()">
    <input type="hidden" class="form-control"  formControlName="Id" >
    <!--<div formGroupName="formBaseDebut">
      <div class="row">
        <div class="col-sm-3">
          <label for="Name"><strong>Nom:*</strong></label>
          <input type="text" class="form-control" formControlName="Name">
          <p *ngIf="submitted && base.Name.errors">
            <span  *ngIf="base.Name.errors.required" class="alert-danger">Le nom est obligatoire</span>
          </p>
        </div>
        <div class="col-sm-3">
          <label for="Address1"><strong>Adresse:*</strong></label>
          <input type="text" class="form-control" formControlName="Address1">
          <p *ngIf="submitted && base.Address1.errors">
            <span  *ngIf="base.Address1.errors.required" class="alert-danger">L'adresse est obligatoire</span>
          </p>
        </div>
        <div class="col-sm-3">
          <label for="Address2"><strong>Adresse (suite):</strong></label>
          <input type="text" class="form-control" formControlName="Address2" >
          
        </div>
      </div>


      <div class="row">
        <div class="col-sm-3">
          <label for="ZipCode"><strong>NPA:*</strong></label>
          <input type="text" class="form-control" formControlName="ZipCode">
          <p *ngIf="submitted && base.ZipCode.errors">
            <span  *ngIf="base.ZipCode.errors.required" class="alert-danger">Le NPA est obligatoire</span>
            <span  *ngIf="base.ZipCode.errors.minlength" class="alert-danger">Le NPA doit comporter 4 chiffres au minimum</span>
          </p>
        </div>
        <div class="col-sm-3">
          <label for="Locality"><strong>Localité:*</strong></label>
          <input type="text" class="form-control" formControlName="Locality">
          <p *ngIf="submitted && base.Locality.errors">
            <span  *ngIf="base.Locality.errors.required" class="alert-danger">Le NPA est obligatoire</span>
          </p>
        </div>
        <div class="col-sm-3">
          <label for="Canton"><strong>Canton:*</strong></label>
          <select class="form-control" formControlName="Canton" >
              <option *ngFor="let canton of cantons" value="{{canton.id}}" >{{canton.value}}</option>
              <p *ngIf="submitted && base.Canton.errors">
                <span  *ngIf="base.Canton.errors.required" class="alert-danger">Le canton est obligatoire</span>
              </p>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3">
          <label for="EmailAddress"><strong>Email:</strong></label>
          <input type="text" class="form-control"  formControlName="EmailAddress" >
          <p *ngIf="submitted && base.EmailAddress.errors">
            <span  *ngIf="base.EmailAddress.errors.email" class="alert-danger">Veuillez entrer une adresse email valide</span>
          </p>
        </div>
        <div class="col-sm-3">
          <label for="WebSite"><strong>Site web:</strong></label>
          <input type="text" class="form-control" formControlName="WebSite">
        </div>
        <div class="col-sm-3">
          <label for="MobilPhone"><strong>Tél. mobile:</strong></label>
          <input type="text" class="form-control" formControlName="MobilPhone">
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3">
          <label for="HomePhone"><strong>Tél.*</strong></label>
          <input type="text" class="form-control" formControlName="HomePhone" >
          <p *ngIf="submitted && base.HomePhone.errors">
            <span  *ngIf="base.HomePhone.errors.required" class="alert-danger">Le tél. fixe est obligatoire</span>
          </p>
        </div>
        <div class="col-sm-3">
          <label for="Fax"><strong>Fax:</strong></label>
          <input type="text" class="form-control" formControlName="Fax">
        </div>
        <div class="col-sm-3">
          <label for="Status"><strong>Status:*</strong></label>
          <select class="form-control" formControlName="Status">
              <option *ngFor="let stat of lesStatus" value="{{stat.id}}"  >{{stat.value}}</option>
          </select>
          <p *ngIf="submitted && base.Status.errors">
            <span  *ngIf="base.Status.errors.required" class="alert-danger">Le status est obligatoire</span>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3">
          <label for="PriceRange"><strong>Gamme de prix:*</strong></label>
          <select class="form-control" formControlName="PriceRange">
            <option *ngFor="let leprix of gammeprix" value="{{leprix.id}}"  >{{leprix.value}}</option>
            <p *ngIf="submitted && base.PriceRange.errors">
              <span  *ngIf="base.PriceRange.errors.required" class="alert-danger">Le status est obligatoire</span>
            </p>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <label for="description"><strong>Description:</strong></label>
          <textarea class="form-control" rows="3" formControlName="description"  value="{{description}}"></textarea>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <label for="conditions"><strong>Conditions spéciales:</strong></label>
          <textarea class="form-control" rows="3" formControlName="conditions"  value="{{conditions}}"></textarea>
        </div>
      </div>
    </div>-->
    
    <div class="row" formGroupName="formBaseCentre">
      <div class="col-sm-12"  *ngIf="!isResto">
        <table class="table table-striped" >
          <thead>
            <tr>
              <th scope="col">#</th>
              <th>Entrée</th>
          <!--<th>Réduction</th>-->
              <th>Age min.</th>
              <th>Age max</th>
            </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Adulte:</th>            
                <td ><input type="number" size="2" min="0" step="0.50" class="form-control" formControlName="prixEntreeA"></td>
           <!--<td><input type="number" size="2" min="0" step="0.50" class="form-control" formControlName="prixReducA"></td>-->
                <td><input type="number" size="2" min="0" step="1" class="form-control" formControlName="prixAgeMinA"></td>
                <td><input type="number" size="2" min="0" step="1" class="form-control" formControlName="prixAgeMaxA">
                  <input type="hidden" formControlName="prixHidenA" ></td>
              </tr>
              <tr>
                <th scope="row">Enfant:</th>
                <td><input type="number" size="2" min="0" step="0.50" class="form-control" formControlName="prixEntreeE"></td>
            <!--<td><input type="number" size="2" min="0" step="0.50" class="form-control" formControlName="prixReducE"></td>-->
                <td><input type="number" size="2" min="0" step="1" class="form-control" formControlName="prixAgeMinE"></td>
                <td><input type="number" size="2" min="0" step="1" class="form-control" formControlName="prixAgeMaxE">
                  <input type="hidden" formControlName="prixHidenE" ></td>
              </tr>
            </tbody>
        </table>
      </div>

      <ng-template [ngIf]="!isResto">
        <div class="col-sm-12" *ngFor="let infos of listeHoraires; index as i">
          <label *ngIf="i === 0" for="horaire1"><strong>Horaire:</strong></label>
          <textarea class="form-control" rows="1" formControlName="horaire{{i}}">{{infos}}</textarea>
        </div>
        <input type="hidden" formControlName="horaireId" >
        <div class="col-sm-12" *ngFor="let infos of listeOuverture; index as i">
          <label class="label" *ngIf="i === 0" for="ouverture1"><strong>Dates d'ouverture:</strong></label>
          <textarea class="form-control" rows="1" formControlName="ouverture{{i}}">{{infos}}</textarea>
        </div>
        <input type="hidden" formControlName="ouvertureId" >
        <div class="col-sm-12" *ngFor="let infos of listeAvantages; index as i">
          <label class="label" *ngIf="i === 0" for="avantage1"><strong>Votre avantage:</strong></label>
          <textarea class="form-control" rows="1" formControlName="avantage{{i}}">{{infos}}</textarea>
        </div>
        <input type="hidden" formControlName="avantageId" >
      </ng-template>

      <ng-template [ngIf]="isResto">
        <div class="col-sm-12" *ngFor="let infos of listeMenus; index as i">
          <label *ngIf="i === 0" for="menu1"><strong>Menus:</strong> </label>
          <textarea class="form-control" rows="1" formControlName="menu{{i}}">{{infos}}</textarea>
          <textarea *ngIf="i === listeMenusNb && i <= 20" class="form-control" rows="1" formControlName="menu{{i+1}}"></textarea>
        </div>
        <input type="hidden" formControlName="menuId" >
      </ng-template>
    </div>

   <div formGroupName="formBaseFin">
      <!--<div class="row">
          <div class="col-sm-12 form-check">
            <input type="checkbox" class="form-check-input" formControlName="actif" [checked]="actif" >
            <label class="form-check-label" for="actif">
              <strong>Actif:</strong>              
            </label>
          </div>
      </div>
      <br />
      <h5>Modes de réservation</h5>
      <div class="row" *ngFor="let infos of arrReservationMode; index as i">
        <div class="col-sm-4 form-check">
          <input type="checkbox" class="form-check-input" formControlName="modeTel" [checked]="infos.modeTel" >
          <label for="modeTel" class="form-check-label">
            <strong>Tél.:</strong>
          </label>
        </div>
        <div class="col-sm-4 form-check">
          <input type="checkbox" class="form-check-input" formControlName="modeSMS" [checked]="infos.modeSMS" >
          <label for="modeSMS" class="form-check-label">
            <strong>SMS:</strong>            
          </label>
        </div>
        <div class="col-sm-4 form-check">
          <input type="checkbox" class="form-check-input" formControlName="modeEmail" [checked]="infos.modeEmail" >
          <label for="modeEmail" class="form-check-label">
            <strong>Email:</strong>
          </label>
        </div>
      </div>
      <br />
      <h5>Informations</h5>
      <div class="row">
        
        <div class="col-sm form-check" *ngFor="let infos of listeEquipements; index as i">
          <input type="checkbox" class="form-check-input" formControlName="equip{{infos.id}}" [checked]="infos.isChecked === 1" id="{{infos.id}}" value="{{infos.id}}" >
          <label for="equip{{infos.id}}" class="form-check-label" nowrap>
            <strong>{{infos.value}}</strong>
          </label>
        </div>
      </div>
      <br />
      <h5>Informations financières</h5>
      <div class="row">
        <div class="col-sm-4">
          <label for="tva"><strong>TVA:</strong></label>
          <textarea class="form-control" rows="1" formControlName="tva"></textarea>
        </div>
        <div class="col-sm-4">
          <label for="tva"><strong>IBAN:</strong></label>
          <textarea class="form-control" rows="1" formControlName="iban"></textarea>
        </div>
        <div class="col-sm-4">
          <label for="tva"><strong>CCP:</strong></label>
          <textarea class="form-control" rows="1" formControlName="ccp"></textarea>
        </div>
        <input type="hidden" formControlName="accountInfoId" >
      </div>-->
  <!--
        <ng-template [ngIf]="isResto">
          <div class="col-sm-12" *ngFor="let infos of listeMenus; index as i">
            <label *ngIf="i === 0" for="menu1"><strong>Menus:</strong></label>
            <textarea class="form-control" rows="1" formControlName="menu{{i}}">{{infos}}</textarea>
          </div>
          <input type="hidden" formControlName="menuId" >
        </ng-template>  -->
    </div><br/>
    <button type="submit" [ngClass]="[valueFormBaseChange ? 'btn btn-sm btn-primary active align-items-right': 'btn btn-sm btn-secondary align-items-right']" [disabled]="!valueFormBaseChange || (formBase.invalid && submitted)" >Enregistrer</button>
  </form>
  
</div>   

<div id="general" class="padding_exceptions container formPrestataire" *ngIf="group.value === 'general'">
  <h4 class="titel_menu">{{leNomPrestataire}} > Gestion des exceptions </h4>
  <br>
  <h6>Sélectionner la date et indiquer le nombre de réservation, la modification sera effective imméditatement</h6>
  
    <form [formGroup]="formGeneral" (ngSubmit)="submitGeneral()">
      <div class="row">
       <!--<div class="col-sm">
          <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col" *ngIf="!isResto">Matin</th>
              <th scope="col">Midi</th>
              <th scope="col">Soir</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let infos of general; index as i">
              <th scope="row" *ngIf="infos.Day === 0">Lundi</th>
              <th scope="row" *ngIf="infos.Day === 1">Mardi</th>
              <th scope="row" *ngIf="infos.Day === 2">Mercredi</th>
              <th scope="row" *ngIf="infos.Day === 3">Jeudi</th>
              <th scope="row" *ngIf="infos.Day === 4">Vendredi</th>
              <th scope="row" *ngIf="infos.Day === 5">Samedi</th>
              <th scope="row" *ngIf="infos.Day === 6">Dimanche</th>
              <td *ngIf="!isResto"><input type="number" size="2" min="0" class="form-control" formControlName="generalMatin{{i}}" value="{{ infos.MatinNbrPossible }}" required></td>
              <td><input type="number" size="2" min="0"  class="form-control" formControlName="generalMidi{{i}}" value="{{ infos.MidiNbrPossible }}" required></td>
              <td><input type="number" size="2" min="0"  class="form-control" formControlName="generalSoir{{i}}" value="{{ infos.SoirNbrPossible }}" required></td>
            </tr>
            </tbody>
          </table>
        </div>-->
        <div class="col-sm">
          <!--<table class="table table-striped">
            <thead>
            <tr>
              <th scope="col" *ngIf="!isResto">Min de pers. par réservation</th>
              <th scope="col">Max de pers. par réservation</th>
              <th scope="col">Délai de réservation</th>
            </tr>
            </thead>
            <tbody>
            <tr >
              <td *ngIf="!isResto"><input type="number" size="2" min="0" class="form-control" formControlName="generalNbMin" value="{{ nbMinPersonnes }}" required></td>
              <td><input type="number" size="2" min="0" class="form-control" formControlName="generalNbMax" value="{{ nbMaxPersonnes }}" required></td>
              <td><input type="number" size="2" min="0" class="form-control" formControlName="generalDelai" value="{{ reservationDelay }}" required></td>
            </tr>
            </tbody>
          </table>
        <h5>Exceptions</h5>-->
        <table class="table table-striped">
          <thead>
          <tr>
            <th scope="col" colspan="2"width="35%">Date</th>
            <th scope="col" *ngIf="!isResto">Matin</th>
            <th scope="col" *ngIf="!isResto">Apès-midi</th>
            <th scope="col" *ngIf="isResto">Midi</th>
            <th scope="col">Soir</th>
          </tr>
          </thead>
          <tbody>      
            <tr  >
              <td class="datepicker" colspan="2"  width="35%">
                <mat-form-field class="size_tab_closed">
                  <input class="form-control" formControlName="generalExcDate0" placeholder="jj-mm-aaaa" matInput [matDatepicker]="picker0" >
                  <mat-datepicker-toggle matSuffix [for]="picker0"></mat-datepicker-toggle>
                  <mat-datepicker #picker0></mat-datepicker>
                </mat-form-field>
              </td>
              <td class="value" *ngIf="!isResto"><input type="number" size="2" min="0" class="form-control" formControlName="generalExcMatin0"></td>
              <td class="value"><input type="number" size="2" min="0" class="form-control" formControlName="generalExcMidi0" ></td>
              <td class="value"><input type="number" size="2" min="0"  class="form-control" formControlName="generalExcSoir0" >
                <input type="hidden" size="2" min="0"  class="form-control" formControlName="generalExcHiden0" ></td>
            </tr>
          <tr  >
            <td class="datepicker" colspan="2"  width="35%">
              <mat-form-field class="size_tab_closed">
                <input class="form-control" formControlName="generalExcDate1" placeholder="jj-mm-aaaa" matInput [matDatepicker]="picker1" >
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
            </td>
            <td class="value" *ngIf="!isResto"><input type="number" size="2" min="0" class="form-control" formControlName="generalExcMatin1"></td>
            <td class="value"><input type="number" size="2" min="0" class="form-control" formControlName="generalExcMidi1" ></td>
            <td class="value"><input type="number" size="2" min="0"  class="form-control" formControlName="generalExcSoir1" >
              <input type="hidden" size="2" min="0"  class="form-control" formControlName="generalExcHiden1" ></td>
          </tr>
          <tr  >
            <td class="datepicker" colspan="2"  width="35%">
              <mat-form-field class="size_tab_closed">
                <input class="form-control" formControlName="generalExcDate2" placeholder="jj-mm-aaaa" matInput [matDatepicker]="picker2" >
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
            </td>
            <td class="value" *ngIf="!isResto"><input type="number" size="2" min="0" class="form-control" formControlName="generalExcMatin2"></td>
            <td class="value"><input type="number" size="2" min="0" class="form-control" formControlName="generalExcMidi2" ></td>
            <td class="value"><input type="number" size="2" min="0"  class="form-control" formControlName="generalExcSoir2" >
              <input type="hidden" size="2" min="0"  class="form-control" formControlName="generalExcHiden2" ></td>
          </tr>
          <tr >
            <td class="datepicker" colspan="2"  width="35%">
              <mat-form-field class="size_tab_closed">
                <input class="form-control" formControlName="generalExcDate3" placeholder="jj-mm-aaaa" matInput [matDatepicker]="picker3" >
                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-datepicker #picker3></mat-datepicker>
              </mat-form-field>
            </td>
            <td class="value" *ngIf="!isResto"><input type="number" size="2" min="0" class="form-control" formControlName="generalExcMatin3"></td>
            <td class="value"><input type="number" size="2" min="0" class="form-control" formControlName="generalExcMidi3" ></td>
            <td class="value"><input type="number" size="2" min="0"  class="form-control" formControlName="generalExcSoir3" >
              <input type="hidden" size="2" min="0"  class="form-control" formControlName="generalExcHiden3"></td>
          </tr>
          </tbody>
      </table>
        </div>
      </div>
      <button type="submit" [ngClass]="[valueFormChange ? 'btn btn-sm btn-primary active align-items-right': 'btn btn-sm btn-secondary align-items-right']" [disabled]="!valueFormChange" >Enregistrer</button>
    </form>
</div>   

<div id="vacances-fermeture" class="padding_calendar formPrestataire" *ngIf="group.value === 'calendar'">
  <h4 class="titel_menu">{{leNomPrestataire}} > Vacances et fermeture</h4>
  
   <form [formGroup]="formCalendrier" (ngSubmit)="submitCalendrier()">
      <div class="row">
       
        <div class="col-sm">
          <h5 class="label">Périodes de vacances</h5>
          <table class="table table-striped">
          <thead>
          <tr>
            <th scope="col" >Début</th>
            <th scope="col">Fin</th>
          </tr>
          </thead>
          <tbody>      
            <tr>
              <td>
                <mat-form-field class="size_tab">
                  <input class="form-control" formControlName="vacancesDebut0" placeholder="jj-mm-aaaa" matInput [matDatepicker]="picker0" >
                  <mat-datepicker-toggle matSuffix [for]="picker0"></mat-datepicker-toggle>
                  <mat-datepicker #picker0></mat-datepicker>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="size_tab">
                  <input class="form-control" formControlName="vacancesFin0" placeholder="jj-mm-aaaa" matInput [matDatepicker]="picker00" >
                  <mat-datepicker-toggle matSuffix [for]="picker00"></mat-datepicker-toggle>
                  <mat-datepicker #picker00></mat-datepicker>
                </mat-form-field></td>
                <input type="hidden" size="2" min="0"  class="form-control" formControlName="vacancesHiden0" >
            </tr>
            <tr >
              <td>
                <mat-form-field class="size_tab">
                  <input class="form-control" formControlName="vacancesDebut1" placeholder="jj-mm-aaaa" matInput [matDatepicker]="picker1" >
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="size_tab" >
                  <input class="form-control" formControlName="vacancesFin1" placeholder="jj-mm-aaaa" matInput [matDatepicker]="picker01" >
                  <mat-datepicker-toggle matSuffix [for]="picker01"></mat-datepicker-toggle>
                  <mat-datepicker #picker01></mat-datepicker>
                </mat-form-field></td>
                <input type="hidden" size="2" min="0"  class="form-control" formControlName="vacancesHiden1" >
            </tr>
            <tr >
              <td>
                <mat-form-field class="size_tab">
                  <input class="form-control" formControlName="vacancesDebut2" placeholder="jj-mm-aaaa" matInput [matDatepicker]="picker2" >
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="size_tab">
                  <input class="form-control" formControlName="vacancesFin2" placeholder="jj-mm-aaaa" matInput [matDatepicker]="picker02" >
                  <mat-datepicker-toggle matSuffix [for]="picker02"></mat-datepicker-toggle>
                  <mat-datepicker #picker02></mat-datepicker>
                </mat-form-field></td>
                <input type="hidden" size="2" min="0"  class="form-control" formControlName="vacancesHiden3" >
            </tr>
            <tr >
              <td>
                <mat-form-field class="size_tab">
                  <input class="form-control" formControlName="vacancesDebut4" placeholder="jj-mm-aaaa" matInput [matDatepicker]="picker4" >
                  <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                  <mat-datepicker #picker4></mat-datepicker>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="size_tab">
                  <input class="form-control" formControlName="vacancesFin4" placeholder="jj-mm-aaaa" matInput [matDatepicker]="picker04" >
                  <mat-datepicker-toggle matSuffix [for]="picker04"></mat-datepicker-toggle>
                  <mat-datepicker #picker04></mat-datepicker>
                </mat-form-field></td>
                <input type="hidden" size="2" min="0"  class="form-control" formControlName="vacancesHiden4" >
            </tr>
          
          </tbody>
      </table>
        </div>

        <div class="col-sm">
          <h5 class="label">Jours de fermeture</h5>
          <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col"></th>
              <th class="padding_day" scope="col" *ngIf="!isResto">Matin</th>
              <th class="padding_day" scope="col" *ngIf="isResto">Midi</th>
              <th class="padding_day" scope="col" *ngIf="!isResto">Après-midi</th>
              <th class="padding_day" scope="col">Soir</th>
            </tr>
            </thead>
            <tbody>
              <tr *ngFor="let infos of fermeture; index as i">
                <th scope="row" *ngIf="infos.day === '0'">Lundi</th>
                <th scope="row" *ngIf="infos.day === '1'">Mardi</th>
                <th scope="row" *ngIf="infos.day === '2'">Mercredi</th>
                <th scope="row" *ngIf="infos.day === '3'">Jeudi</th>
                <th scope="row" *ngIf="infos.day === '4'">Vendredi</th>
                <th scope="row" *ngIf="infos.day === '5'">Samedi</th>
                <th scope="row" *ngIf="infos.day === '6'">Dimanche</th>
                <td class="padding_morning"  *ngIf="!isResto" ><input type="checkbox"class="form-check-input" formControlName="fermeMatin{{i}}" ></td>
                <td class="padding_midday"><input type="checkbox" class="form-check-input" formControlName="fermeMidi{{i}}"  ></td>
                <td class="padding_evening"><input type="checkbox" class="form-check-input" formControlName="fermeSoir{{i}}"  >
                  <input type="hidden" formControlName="fermeHiden{{i}}" ></td>
              </tr>
            </tbody>
        </table>
        </div>
      </div>

      
     
      <button type="submit" [ngClass]="[valueFormCalendrierChange ? 'btn btn-sm btn-primary active align-items-right': 'btn btn-sm btn-secondary align-items-right']" [disabled]="!valueFormCalendrierChange" >Enregistrer</button>
    </form>
</div>  

<!--<div id="category" class="container formPrestataire" *ngIf="group.value === 'category'">
  <h4 *ngIf="!isResto">{{leNomPrestataire}} > Type de loisir</h4>
  <h4 *ngIf="isResto">{{leNomPrestataire}} > Type de cuisine</h4>
  
    <form [formGroup]="formTypes" (ngSubmit)="submitTypes()">
      <div class="row">
        <div class="col-sm">
          <h5>Modifier le type de loisirs</h5>
          <div class="form-check" *ngFor="let infos of types; index as i">
            <label class="form-check-label">
              <input type="checkbox" class="form-check-input" formControlName="type{{i}}" [checked]="infos.choix === '1'" >{{ infos.name }}
                <input type="hidden" formControlName="typeHiden{{i}}" >
            </label>
          </div>          
              
        </div> 
      </div>      
     
      <button type="submit" [ngClass]="[valueFormTypesChange ? 'btn btn-sm btn-primary active align-items-right': 'btn btn-sm btn-secondary align-items-right']" [disabled]="!valueFormTypesChange" >Enregistrer</button>
    </form>

</div>-->  

<div id="place" class="container formPrestataire" *ngIf="group.value === 'place'">
  <h4 class="bold-blue-e">{{leNomPrestataire}}: Géolocalisation</h4>
  
    <form [formGroup]="formMap" (ngSubmit)="submitTypes()">
      <div class="row">
        <div class="col-sm-6">
          <label for="adresse1" class="col-form-label"><strong>Adresse</strong></label>
          <div class="col-sm-10">
            <input type="text" class="form-control" formControlName="adresse1" >
          </div>    
        </div> 
        <div class="col-sm-6">
          <label for="adresse2" class="col-form-label"><strong>Adresse suite</strong></label>
          <div class="col-sm-10">
            <input type="text" class="form-control" formControlName="adresse2" >
          </div>    
        </div> 
      </div>  
      
      <div class="row">
        <div class="col-sm-4">
          <label for="npa" class="col-form-label"><strong>NPA</strong></label>
          <div class="col-sm-10">
            <input type="text" class="form-control" formControlName="npa" >
          </div>    
        </div> 
        <div class="col-sm-4">
          <label for="localite" class="col-form-label"><strong>Localité</strong></label>
          <div class="col-sm-10">
            <input type="text" class="form-control" formControlName="localite" >
          </div>    
        </div> 
        <div class="col-sm-4">
          <label for="canton" class="col-form-label"><strong>Canton</strong></label>
          <div class="col-sm-10">
            <select class="form-control" formControlName="canton" >
              <option *ngFor="let canton of cantons" value="{{canton.id}}" >{{canton.value}}</option>
          </select>
          </div>    
        </div> 
      </div>  

      <div class="row">
        <div class="col-sm-6">
          <label for="latitude" class="col-form-label"><strong>Latitude</strong></label>
          <div class="col-sm-10">
            <input type="text" class="form-control" formControlName="latitude" >
          </div>    
        </div> 
        <div class="col-sm-6">
          <label for="longitude" class="col-form-label"><strong>Longitude</strong></label>
          <div class="col-sm-10">
            <input type="text" class="form-control" formControlName="longitude" >
          </div>    
        </div> 
      </div> 

      <div class="row">
        <div class="col-sm-12">
          <p>&nbsp;</p>
            <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
              <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"></agm-marker>
            </agm-map>     
            <p>&nbsp;</p> 
        </div> 
      </div>

     
      <button type="submit" [ngClass]="[valueFormTypesChange ? 'btn btn-sm btn-primary active align-items-right': 'btn btn-sm btn-secondary align-items-right']" [disabled]="!valueFormTypesChange" >Enregistrer</button>
    </form>

</div> 

<div id="galery" class="container formPrestataire" *ngIf="group.value === 'collection'">
  <h4 >{{leNomPrestataire}} > Galerie d'images</h4>
  
    <form [formGroup]="formImages" (ngSubmit)="submitImages()">
      <!-- GESTION IMAGE 1 -->
      <div class="row form-group">
        <div class="col-sm-2 col-form-label" *ngIf="imageURL01 && imageURL01 !== ''">
          <img [src]="imageURL01" class="rounded img-thumbnail" width="200px" alt="img01" >
        </div>
        <div class="col-sm-10">
          <label for="img01"><strong>Photo principale</strong></label>
          <div class="custom-file">
            <label class="custom-file-label" for="customFile">Choisir une autre image</label>
            <input type="file" accept="image/.jpg" class="custom-file-input" (change)="showPreview1($event)" />
            <input type="hidden" class="form-control" formControlName="img01id">
            <small *ngIf="img01Update" class="text-danger">N'oubliez pas de sauvegarder une fois la photo changée</small>
          </div>
        </div>
      </div>
      <!-- GESTION IMAGE 2 -->
      <div class="row form-group">
        <div class="col-sm-2 col-form-label" *ngIf="imageURL02 && imageURL02 !== ''">
          <img [src]="imageURL02" class="rounded img-thumbnail" width="200px" alt="img02" >
        </div>
        <div class="col-sm-10">
          <label for="img02"><strong>Photo galerie No 1</strong></label>
          <div class="custom-file">
            <label class="custom-file-label" for="customFile">Choisir une autre image</label>
            <input type="file" accept="image/.jpg" class="custom-file-input" (change)="showPreview2($event)" />
            <input type="hidden" class="form-control" formControlName="img02id">
            <small *ngIf="img02Update" class="text-danger">N'oubliez pas de sauvegarder une fois la photo changée</small>
          </div>
        </div>
      </div>
      
      <!-- GESTION IMAGE 3 -->
      <div class="row form-group">
        <div class="col-sm-2 col-form-label" *ngIf="imageURL03 && imageURL03 !== ''">
          <img [src]="imageURL03" class="rounded img-thumbnail" width="200px" alt="img03" >
        </div>
        <div class="col-sm-10">
          <label for="img03"><strong>Photo galerie No 2</strong></label>
          <div class="custom-file">
            <label class="custom-file-label" for="customFile">Choisir une autre image</label>
            <input type="file" accept="image/.jpg" class="custom-file-input" (change)="showPreview3($event)" />
            <input type="hidden" class="form-control" formControlName="img03id">
            <small *ngIf="img03Update" class="text-danger">N'oubliez pas de sauvegarder une fois la photo changée</small>
          </div>
        </div>
      </div>

      <!-- GESTION IMAGE 4 -->
      <div class="row form-group">
        <div class="col-sm-2 col-form-label" *ngIf="imageURL04 && imageURL04 !== ''">
          <img [src]="imageURL04" class="rounded img-thumbnail" width="200px" alt="img04" >
        </div>
        <div class="col-sm-10">
          <label for="img04"><strong>Photo galerie No 3</strong></label>
          <div class="custom-file">
            <label class="custom-file-label" for="customFile">Choisir une autre image</label>
            <input type="file" accept="image/.jpg" class="custom-file-input" (change)="showPreview4($event)" />
            <input type="hidden" class="form-control" formControlName="img04id">
            <small *ngIf="img04Update" class="text-danger">N'oubliez pas de sauvegarder une fois la photo changée</small>
          </div>
        </div>
      </div>

      <!-- GESTION IMAGE 5 -->
      <div class="row form-group">
        <div class="col-sm-2 col-form-label" *ngIf="imageURL05 && imageURL05 !== ''">
          <img [src]="imageURL05" class="rounded img-thumbnail" width="200px" alt="img05" >
        </div>
        <div class="col-sm-10">
          <label for="img05"><strong>Photo galerie No 4</strong></label>
          <div class="custom-file">
            <label class="custom-file-label" for="customFile">Choisir une autre image</label>
            <input type="file" accept="image/.jpg" class="custom-file-input" (change)="showPreview5($event)" />
            <input type="hidden" class="form-control" formControlName="img05id">
            <small *ngIf="img05Update" class="text-danger">N'oubliez pas de sauvegarder une fois la photo changée</small>
          </div>
        </div>
      </div>  
            
      
      <img class="img-responsive" width="70px" src="assets/images/wait.png" *ngIf="download" >
      <div *ngIf="messageErrorImg" class="text-danger">{{messageErrorImg}}</div>
     <button type="submit" [ngClass]="[valueFormImagesChange ? 'btn btn-sm btn-primary active align-items-right': 'btn btn-sm btn-secondary align-items-right']" [disabled]="!valueFormImagesChange" >Enregistrer</button>
      <!-- <button type="submit"  >Enregistrer</button> -->
    </form>

</div>   
<div class="reservationBloc">
<div class="grid_agenda">
  <div class="agenda">
    <ngb-datepicker #d1 [(ngModel)]="model" #c1="ngModel"  [dayTemplate]="customDay" (ngModelChange)="updateMyDate($event)"> 
    </ngb-datepicker>
    <p *ngIf="ceRestoDejaReserve" class="text-warning">Vous avez déjà réservé ce restaurant avec cette carte.</p>
    <p *ngIf="carteType === 1" class="text-warning">Votre carte vous permet de réserver uniquement un loisir. Pas un restaurant.</p>
    <p *ngIf="!aCarteValide" class="text-warning">Vous n'avez pas de carte active. <br />
        <a href="/moncompte">Accédez à votre compte</a></p>
  </div>
  <div *ngIf="model && !ceRestoDejaReserve && carteValide && carteType !== 1">
    <h4 class="bleuebook"> {{ model | date:'dd LLL yyyy' }} </h4>
    <div *ngIf="leDayIsCloed; else openBlock">Ce jour est complet pour ebook-card.ch ou fermé.</div>
    <ng-template #openBlock>
      <h5 class="size_title">Midi</h5>
      <div class="margin_text" *ngIf="midiFerme; else midiOpen">Complet pour ebook-card.ch ou fermé.</div>
      <ng-template #midiOpen>
        <p class="grid_btn">
          <button class="reserve_choix" type="button" [ngClass]="[midi1200 ? 'btn btn-sm btn-primary active': 'btn btn-sm btn-success']" (click)="midiHeure(1200)">12:00</button>
          
          <button class="reserve_choix" type="button" [ngClass]="[midi1230 ? 'btn btn-sm btn-primary active': 'btn btn-sm btn-success']" (click)="midiHeure(1230)">12:30</button>
          
          <button class="reserve_choix" type="button" [ngClass]="[midi1300 ? 'btn btn-sm btn-primary active': 'btn btn-sm btn-success']" (click)="midiHeure(1300)">13:00</button>
        </p>
      </ng-template>
      

      <h5 class="size_title">Soir</h5>
      <div class="margin_text" *ngIf="soirFerme; else soirOpen">Complet pour ebook-card.ch ou fermé.</div>
      <ng-template #soirOpen>
        <p class="grid_btn">
          <button class="reserve_choix" type="button" [ngClass]="[soir1900 ? 'btn btn-sm btn-primary active': 'btn btn-sm btn-success']" (click)="soirHeure(1900)" >19:00</button>
          
          <button class="reserve_choix" type="button" [ngClass]="[soir1930 ? 'btn btn-sm btn-primary active': 'btn btn-sm btn-success']" (click)="soirHeure(1930)">19:30</button>
          
          <button class="reserve_choix" type="button" [ngClass]="[soir2000 ? 'btn btn-sm btn-primary active': 'btn btn-sm btn-success']" (click)="soirHeure(2000)">20:00</button>
          
          <button class="reserve_choix" type="button" [ngClass]="[soir2030 ? 'btn btn-sm btn-primary active': 'btn btn-sm btn-success']"  (click)="soirHeure(2030)" >20:30</button>
          
          <button class="reserve_choix" type="button" [ngClass]="[soir2100 ? 'btn btn-sm btn-primary active': 'btn btn-sm btn-success']"  (click)="soirHeure(2100)" >21:00</button>
        </p>
      </ng-template>
      
        <h5 class="size_title">Nombre de personnes</h5>
        <p class="grid_Nbper">
        <button class="reserve_choix" *ngFor="let nbra of nbrPersonnes"  type="button" [ngClass]="{'btn btn-sm btn-primary active':nbra.class === 'actifa','btn btn-sm btn-success':nbra.class === 'inactifa'}" name="{{nbra.nombre}}" id="personnes" (click)="resaBtnPersonnes($event)">{{nbra.nombre}}</button>
        <!-- <p *ngIf="model"><button type="button" [ngClass]="[nbPerson1 ? 'btn btn-sm btn-success active': 'btn btn-sm btn-success']" (click)="resaNbPersonnes(1)">1</button>&nbsp;&nbsp;<button type="button" [ngClass]="[nbPerson2 ? 'btn btn-sm btn-success active': 'btn btn-sm btn-success']" (click)="resaNbPersonnes(2)">2</button>&nbsp;&nbsp;<button type="button" [ngClass]="[nbPerson3 ? 'btn btn-sm btn-success active': 'btn btn-sm btn-success']"  (click)="resaNbPersonnes(3)">3</button>&nbsp;&nbsp;<button type="button" [ngClass]="[nbPerson4 ? 'btn btn-sm btn-success active': 'btn btn-sm btn-success']" (click)="resaNbPersonnes(4)">4</button></p> -->
        </p>
      </ng-template>
    <p ><button class="reserve_choix" type="button" *ngIf="!leDayIsCloed" [ngClass]="[resrationOK ? 'btn btn-sm btn-primary active': 'btn btn-sm btn-success']" [disabled]="!resrationOK || !carteValide" (click)="open(content)">Réserver</button></p>
  </div>
</div>

<ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled" let-focused="focused">
  <span   class="custom-day" 
          [class.focused]="focused"
          [class.bg-primary]="selected" 
          [class.closed]="isClosed(date) || isClosedNext1(date) || isClosedNext2(date) || isClosedNext3(date) || isClosedNext4(date) || isClosedNext5(date) || isClosedNext6(date) || isClosedNext7(date) || isClosedNext8(date) || isClosedNext9(date) || isClosedNext10(date) || isClosedNext11(date)"
          [class.dateMoitie]="isMoitie(date) || isMoitieNext1(date) || isMoitieNext2(date) || isMoitieNext3(date) || isMoitieNext4(date) || isMoitieNext5(date) || isMoitieNext6(date) || isMoitieNext7(date) || isMoitieNext8(date) || isMoitieNext9(date) || isMoitieNext10(date) || isMoitieNext11(date)"
          [class.dateOuvert]="isOpen(date) || isOpenNext1(date) || isOpenNext2(date) || isOpenNext3(date) || isOpenNext4(date) || isOpenNext5(date) || isOpenNext6(date) || isOpenNext7(date) || isOpenNext8(date) || isOpenNext9(date) || isOpenNext10(date) || isOpenNext11(date)"
          [class.gris]="isOld(date)"
          [class.hidden]="date.month !== currentMonth" 
          [class.text-muted]="disabled">
    {{ date.day }} 
  </span>
</ng-template>

<ng-template #content let-modal>
  <div class="modal-header">
    <!--<h6 class="modal-title" id="modal-basic-title">Réservation ebook-card:({{ reservationmodes }}) / Element pas affiché</h6>-->
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="laDateChoisieDepasse">
      <p>Vous ne pouvez pas réserver pour une date ultérieure à la validité de votre carte ({{currentUserDateFin}})</p>
    </div>
    <div *ngIf="!attendsTelephone && !laDateChoisieDepasse">
      <!--
      <div *ngIf="reservationmodes === '1' || reservationmodes === '3' || reservationmodes === '5' || reservationmodes === '7'">1
        <p class="nom_partenaire">{{ nomPrestataire }} {{reservationmodes}}</p>
        <img style="float:left; margin-right: 10px;" width="70px" src="assets/images/wait.png">
        <p><strong>Dans <span style="color: red;">{{ minutesLeft }} minutes {{ secondsLeft }}</span>, votre réservation sera annulée</strong></p>
        <p class="reserve_date">Date: {{ laDateChoisie2 }}<br />
          Nombre de personnes: {{nbrParticipants}}<br />
          Heure choisie: {{ heureSelectionne }}</p>
        <h4 class="text-center">Téléphonez à {{ nomPrestataire }} <br /><span class="text-success">au {{ numeroTelPrestataire }}</span><br /> et ensuite répondez à la question:</h4>
        <div class="text-center"><p>Avez-vous réussi à joindre le restaurant?</p></div>-->
      <div *ngIf="reservationmodes === '1' || reservationmodes === '3' || reservationmodes === '5' || reservationmodes === '7'">
        <p class="timer"><strong>Dans <span style="color: red;">{{ minutesLeft }} minutes {{ secondsLeft }}</span> votre réservation s'annule</strong></p> 
        <!--<p class="nom_partenaire">{{ nomPrestataire }}</p>-->

        <div class="grid_gif">
          <img class="img_gif"  src="assets/images/resto.gif">
       
          <p class="reserve_date">Date: {{ laDateChoisie2 }}<br />
            Nombre de personnes: {{nbrParticipants}}<br />
            Heure choisie: {{ heureSelectionne }}<br />
            Code de réservation: <strong>{{codeResa}}</strong></p>
        </div>

        <h4 class="text-center">Téléphonez à {{ nomPrestataire }} <br /><span class="nom_partenaire">au {{ numeroTelPrestataire }}</span><br /> et ensuite répondez à la question:</h4>
        <div class="case_refuse"><p>Avez-vous réussi à joindre le restaurant?</p>
          <button type="button" (click)="telOuiNon('oui')" [ngClass]="[telOui ? 'btn btn-sm btn-primary active': 'btn btn-sm btn-success']"  name="OUI" id="oui" >OUI</button>&nbsp;&nbsp;
          <button type="button" (click)="telOuiNon('non')" [ngClass]="[telNon ? 'btn btn-sm btn-primary active': 'btn btn-sm btn-success']"  name="NON" id="non" >NON</button>
        </div>
        <p><input type="checkbox" [checked]="resaRefus" (change)="resaRefusee()" />
          {{ nomPrestataire }} a refusé ma réservation</p>
        <div style="text-align:right"> 
          <button type="button" (click)="postResaToDB()" [ngClass]="[btnEnvoyerActif ? 'btn btn-sm btn-primary active': 'btn btn-sm btn-secondary']" [disabled]="!btnEnvoyerActif"  >Envoyer la réponse</button>
        </div>
      </div>
      <div *ngIf="reservationmodes === '2'|| reservationmodes === '4' || reservationmodes === '6'" >
        <!--<h3>Demande de réservation pour:</h3>-->
        <p class="nom_partenaire_sms">{{ nomPrestataire }}</p>
        <p>Date: {{ laDateChoisie2 }}<br />
          Nombre de personnes: {{nbrParticipants}}<br />
          Heure choisie: {{ heureSelectionne }}<br />
          Code de réservation: <strong>{{codeResa}}</strong><br /><br />
          La réservation se fait sans téléphoner,
          le partenaire reçoit <span style="color: red;">directement un SMS</span> </p><!--Message en plus pour SMS et mail-->
        <button type="button" (click)="postResaToDB()" class="btn btn-sm btn-primary active" >Réserver</button>
      </div>
    </div>
    <div *ngIf="reponseResaRetour">
      <div *ngIf="reponseReservation === 11">
        <p><strong>Votre réservation est confirmée </strong></p>
        <p class="nom_partenaire_sms">{{ nomPrestataire }}</p>
        <p>Date: {{ laDateChoisie2 }}</p>

        <p>Nombre personnes: {{nbrParticipants}}</p>
        <p>Heure: {{ heureSelectionne }}</p>
        <p>Code de réservation: <strong>{{codeResa}}</strong></p>
        <p>Vous allez recevoir un SMS de confirmation avec votre code de réservation à garder précieusement afin d'être montré lorsque vous arrivez sur place.</p>
        <p>Le partenaire reçoit <span style="color: red;">aussi un SMS</span> </p>
        <p></p>
        <p>Consultez la liste de vos réservations <a href="/moncompte">ici</a></p>
      </div>
      <div *ngIf="reponseReservation === 00">
        <p><strong>Impossible de réaliser votre réservation</strong></p>
        <p>Veuillez contacter notre service clientèle info@ebook-card.ch</p>
      </div>
      <div *ngIf="reponseReservation === 22">
        <p><strong>Impossible de réaliser votre réservation</strong></p>
        <p>Vous avez déjà réservé ce restaurant avec votre carte valide. </p>
        <p>Consultez la liste de vos réservations <a href="/moncompte">ici</a></p>
      </div>
      <div *ngIf="reponseReservation === 33">
        <p><strong>Impossible de réaliser votre réservation</strong></p>
        <p>Vous ne possédez pas de carte active et valide. </p>
        <p>Consulter les détails de votre compte <a href="/moncompte">ici</a></p>
      </div>
      <div *ngIf="reponseReservation === 44">
        <p><strong>Votre réservation est annulée</strong></p>
        <p>Dans l'impossibilité de joindre le partenaire par téléphone, votre réservation n'a pas été validée. </p>
        <p>Veuillez essayer plus tard.</p>
        <p>Consulter les détails de votre compte <a href="/moncompte">ici</a></p>
      </div>
      <div *ngIf="reponseReservation === 55">
        <p><strong>Votre réservation est annulée</strong></p>
        <p>Puisque le prestataire a refusé votre réservation, votre demande n'a pas été validée.</p>
        <p>Ebook-card en a été informé.</p>
        <p>Consulter les détails de votre compte <a href="/moncompte">ici</a></p>
      </div>
      <div *ngIf="reponseReservation === 77">
        <p><strong>Votre réservation est annulée</strong></p>
        <p>Vous avez déjà une réservation active au même instant dans un autre restaurant.</p>
        
        <p>Consulter les détails de votre compte <a href="/moncompte">ici</a></p>
      </div>
      <div *ngIf="reponseReservation === 88">
        <p><strong>Votre réservation est annulée</strong></p>
        <p>Vous ne pouvez pas réserver à une date ultérieure à la validité de votre carte.</p>
        
        <p>Consulter les détails de votre compte <a href="/moncompte">ici</a></p>
      </div>
    </div>

  </div>
  <img *ngIf="!reponseResaRetour && !laDateChoisieDepasse" width="70px" src="assets/images/wait.png">
  <div class="modal-footer"  *ngIf="attendsTelephone">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Fermer</button>
  </div>
</ng-template>
</div>
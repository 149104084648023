import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { EncrDecrService } from './_services/encrdecr.service';
import { AppComponent } from './app.component';
import { RestaurantsComponent } from './restaurants/restaurants.component';
import { HttpClientModule, HTTP_INTERCEPTORS  } from '@angular/common/http';
import { RestodetailsComponent } from './restodetails/restodetails.component';
import { RestaurantsFiltresComponent } from './restaurants-filtres/restaurants-filtres.component';
import { LoisirsComponent } from './loisirs/loisirs.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormuserloginComponent } from './formuserlogin/formuserlogin.component';
import { DatePipe } from '@angular/common';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { MatNativeDateModule, MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatListModule } from '@angular/material/list';

import { CustomDatePipe } from './_helpers/custom.datepipe';
import { CustomDatePipeLong } from './_helpers/customlong.datepipe';

import { BasicAuthInterceptor, ErrorInterceptor } from './_helpers';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { MoncompteComponent } from './moncompte/moncompte.component';
import { ReservationComponent } from './reservation/reservation.component';
import { LoisirsFiltresComponent } from './loisirs-filtres/loisirs-filtres.component';
import { LoisirsdetailsComponent } from './loisirsdetails/loisirsdetails.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { ConditionsGeneralComponent } from './conditions-general/conditions-general.component';
import { AboutComponent } from './about/about.component';
import { MyFilterPipe } from './pipes/content-filter.pipe';
import { FaqComponent } from './faq/faq.component';
import { DateForIpadPipe } from './pipes/date-for-ipad.pipe';
import { Dispotoday } from './pipes/dispo-to-day.pipe';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { ReservationloisirComponent } from './reservationloisir/reservationloisir.component';
import { EditpartnerComponent } from './editpartner/editpartner.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { NouveaucompteComponent } from './nouveaucompte/nouveaucompte.component';
import { ContactComponent } from './contact/contact.component';
import { TypeLoisirsPipe } from './pipes/type-loisirs.pipe';
import { BoutiqueListeComponent } from './boutique/boutique-liste.component';
import { BoutiqueDetailComponent } from './boutique/boutique-detail.component';
import { BoutiquePanierComponent } from './boutique/boutique-panier.component';
import { BoutiqueComponent } from './boutique/boutique.component';
import { NgxStripeModule } from 'ngx-stripe';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ShopComponent } from './shop/shop.component';

export const MY_FORMAT: MatDateFormats = {
  parse: {
  dateInput: 'DD/MM/YYYY',
  },
  display: {
  dateInput: 'DD/MM/YYYY',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'DD/MM/YYYY',
  monthYearA11yLabel: 'MMMM YYYY',
  },
  };

@NgModule({
  declarations: [
    AppComponent,
    RestaurantsComponent,
    RestodetailsComponent,
    RestaurantsFiltresComponent,
    LoisirsComponent,
    FormuserloginComponent,
    HomeComponent,
    LoginComponent,
    MoncompteComponent,
    ReservationComponent,
    LoisirsFiltresComponent,
    LoisirsdetailsComponent,
    ConditionsGeneralComponent,
    AboutComponent,
    FaqComponent,
    MyFilterPipe,
    DateForIpadPipe,
    Dispotoday,
    ReservationloisirComponent,
    EditpartnerComponent,
    NouveaucompteComponent,
    CustomDatePipe,
    CustomDatePipeLong,
    ContactComponent,
    TypeLoisirsPipe,
    BoutiqueComponent,
    BoutiqueDetailComponent,
    BoutiquePanierComponent,
    BoutiqueListeComponent,
    ShopComponent
  ],
  imports: [
    BrowserModule,
    MatDialogModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    NgbModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatListModule,
    NgxStripeModule.forRoot('pk_test_XQDSS1POyqBMDMjSZn0BFNaf'),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC2ow0V3BlY_9KA7QYYA8qwT6B0QlygK-E'
    }),
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonToggleModule,
    RecaptchaFormsModule,
    RecaptchaModule
  ],
  exports: [
    MatDatepickerModule,
    NgxStripeModule
  ],
  providers: [EncrDecrService, DatePipe, MatDatepickerModule, GoogleMapsAPIWrapper,
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMAT },
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
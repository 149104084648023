import { FaqComponent } from './faq/faq.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RestaurantsFiltresComponent } from './restaurants-filtres/restaurants-filtres.component';
import { LoisirsFiltresComponent } from './loisirs-filtres/loisirs-filtres.component';
import { RestodetailsComponent } from './restodetails/restodetails.component';
import { LoisirsdetailsComponent } from './loisirsdetails/loisirsdetails.component';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { AuthGuard } from './_helpers';
import { MoncompteComponent } from './moncompte/moncompte.component';
import { ReservationComponent } from './reservation/reservation.component';
import { ConditionsGeneralComponent } from './conditions-general/conditions-general.component';
import { AboutComponent } from './about/about.component';
import { NouveaucompteComponent } from './nouveaucompte/nouveaucompte.component';
import { ContactComponent } from './contact/contact.component';
import { BoutiqueComponent } from './boutique/boutique.component';
import { BoutiqueDetailComponent } from './boutique/boutique-detail.component';
import { BoutiquePanierComponent } from './boutique/boutique-panier.component';
import { ShopComponent } from './shop/shop.component';

const routes: Routes = [

  // { path: 'restaurants', component: RestaurantsFiltresComponent, resolve: { products: Resolver } },
  { path: 'restaurants', component: RestaurantsFiltresComponent },
  { path: 'accueil', component: HomeComponent },
  { path: 'loisirs', component: LoisirsFiltresComponent },
  { path: 'conditions', component: ConditionsGeneralComponent},
  { path: 'Restaurant/Details/:id', component: RestodetailsComponent },
  { path: 'restaurants/:id/:title', component: RestodetailsComponent },
  { path: 'Loisirs/Details/:id', component: LoisirsdetailsComponent },
  { path: 'loisirs/:id/:title', component: LoisirsdetailsComponent },
  { path: '', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'moncompte', component: MoncompteComponent, canActivate: [AuthGuard] },
  { path: 'reservation/:id', component: ReservationComponent, canActivate: [AuthGuard] },
  { path: 'about', component: AboutComponent},
  { path: 'faq', component: FaqComponent},
  { path: 'nouveaucompte', component: NouveaucompteComponent},
  { path: 'contact', component: ContactComponent },
  { path: 'boutique/:category', component: BoutiqueComponent },
  { path: 'shop/:category', component: ShopComponent },
  { path: 'boutique/detail/:id/:titleurl', component: BoutiqueDetailComponent },
  { path: 'boutique/panier/contenu', component: BoutiquePanierComponent, canActivate: [AuthGuard]},
  { path: 'boutique/panier/contenu/paiement', component: BoutiquePanierComponent },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/accueil'
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { UserService, AuthenticationService } from '../_services';
import { AppConstants } from '../_helpers/app.constants';
import { ActivatedRoute } from '@angular/router';
import { User } from '../_models';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-boutique',
  templateUrl: './boutique.component.html',
  styleUrls: ['./boutique.component.css']
})
export class BoutiqueComponent implements OnInit {

  urlBoutique = AppConstants.baseURLShop;
  currentUser: User;
  category: string;
  urlPourAppel: string;
  titreCat: string;
  tabactive = 1;

  constructor(private route: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private infousers: UserService,
              private metaTagService: Meta,
              private titleService: Title) { 
                this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
               }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.category === 'cartes-gastronomiques') {
        this.category = '24';
        this.urlPourAppel = 'listeCartesGastronomie';
        this.titreCat = 'Nos cartes gastronomiques';
        this.tabactive = 1;
      } else if (params.category === 'cartes-loisirs') {
        this.category = '25';
        this.titreCat = 'Nos cartes loisirs';
        this.urlPourAppel = 'listeCartesLoisirs';
        this.tabactive = 2;
      } else if (params.category === 'cartes-mixtes') {
        this.category = '26';
        this.titreCat = 'Nos cartes mixtes';
        this.urlPourAppel = 'listeCartesMixtes';
        this.tabactive = 3;
      } else if (params.category === 'toutes-les-cartes') {
        this.titreCat = 'Toutes nos cartes';
        this.category = '';
        this.urlPourAppel = 'listeCartesToutes';
        this.tabactive = 4;
      } else {
        this.titreCat = 'Toutes nos cartes';
        this.category = '';
        this.urlPourAppel = 'listeCartesToutes';
        this.tabactive = 4;
        console.log('ELSe' + params.category);
      }
    });

    this.titleService.setTitle('Boutique -  ' + this.titreCat + ' - ebook-card.ch');
    this.metaTagService.updateTag(
      { name: 'description', content: 'Acheter une carte ebook-card '  + this.titreCat }
    );
  }

  menuClick(category: number) {
    if (category === 1) {
      this.category = '24';
      this.urlPourAppel = 'listeCartesGastronomie';
      this.titreCat = 'Nos cartes gastronomiques';
    } else if (category === 2) {
      this.category = '25';
      this.titreCat = 'Nos cartes loisirs';
      this.urlPourAppel = 'listeCartesLoisirs';
    } else if (category === 3) {
      this.category = '26';
      this.titreCat = 'Nos cartes mixtes';
      this.urlPourAppel = 'listeCartesMixtes';
    } else if (category === 4) {
      this.category = '';
      this.titreCat = 'Toutes nos cartes';
      this.urlPourAppel = 'listeCartesToutes';
    } else {
      this.category = '24';
      this.urlPourAppel = 'listeCartesGastronomie';
      this.titreCat = 'Nos cartes gastronomiques';
    }
    this.tabactive = category;

    this.titleService.setTitle('Boutique -  ' + this.titreCat + ' - ebook-card.ch');
    this.metaTagService.updateTag(
      { name: 'description', content: 'Acheter une carte ebook-card '  + this.titreCat }
    );
  }
  


}

export class AppConstants {
  // public static get baseURL(): string { return 'https://angular.ebook-card.com/servicesapi'; } // DEV
    public static get baseURL(): string { return 'https://ebook-card.ch/servicesapi'; }

    public static get baseURLShop(): string { return 'https://ebook-card.com'; } // PROD
  // public static get baseURLShop(): string { return 'https://shopebook.azurewebsites.net'; } // TEST


  public static get arrayId() {
    const categories = {
      gastronomie: 24,
      loisirs: 25,
      mixtes: 26,
      cadeaux: 27,
      physique: 28,
      virtuelle: 29,
      prolongation: 30
    }
    return categories;
  }
}


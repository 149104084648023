import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver } from '@angular/core';
import { User } from '../_models';
import { Router } from '@angular/router';
import { UserService, AuthenticationService, TestsService } from '../_services';
import { Subscription } from 'rxjs';
import { EncrDecrService } from '../_services/encrdecr.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../_helpers/app.constants';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-moncompte',
  templateUrl: './moncompte.component.html',
  styleUrls: ['./moncompte.component.css']
})

export class MoncompteComponent implements OnInit, OnDestroy {

  @Input() showDetails = false;

  constructor(private authenticationService: AuthenticationService,
              private infousers: UserService,
              private EncrDecr: EncrDecrService,
              private httpClient: HttpClient,
              private testSecurity: TestsService,
              private titleService: Title,
              private formBuilder: FormBuilder,
              private router: Router) {

    titleService.setTitle("Mon compte");
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    console.log('Rôle: ' + this.currentUser.roleuser);
  }

    profileForm = new FormGroup({
      formfirstName: new FormControl(''),
      formlastName: new FormControl(''),
      formaddress1: new FormControl(''),
      formemail: new FormControl(''),
      formzipcode: new FormControl(''),
      formlocality: new FormControl(''),
      formmobilphone: new FormControl(''),
      formmhomephone: new FormControl('')
    });

  img_mixte = 'assets/images/Prod Mixte.jpg';
  img_loisirs = 'assets/images/carte loisirs 12 mois.jpg';
  img_gastro12 = 'assets/images/gastro_card.jpg';

  cardValidationForm;
  loading = false;
  valueFormChange = false;
  codeValidationChange = false;
  currentUser: User;
  interval: Subscription;
  infosUser = [];
  infosUserBis = [];
  arrDejaResa = [];
  arrDejaResaLoisirs = [];
  reservavenir = [];
  reservavenirLoisirs = [];
  mesLoisirs = [];
  mesLoisirsCount: number;
  mesRestos = [];
  mesRestosCount: number;
  cartevalide = [];
  cartes = [];
  reservpasse = [];
  reservpasseLoisirs = [];
  firstname: string;
  lastname: string;
  address1: string;
  zipcode: string;
  locality: string;
  roleUser: string;
  email: string;
  mobilphone: string;
  homephone: string;
  registrationdate: string;
  userid: number;
  reponseEditCompte: number;
  isResto: number;
  showCompteEdit = false;
  idObjet: number;
  emailvalidated: number;
  messMailValidationEnvoye: string;
  messMailValidationBug: string;
  messReourDev: string;
  mobileExist = '';
  emailExist = '';
  emailIntermediaire = '';
  mobileIntermediaire = '';
  afficheActiveCarte = false;

  // Fonction qui renvoie un mail pour validation de l'adresse email
  testSecuritySend() {
    this.interval = this.testSecurity.essaiSecurity()
        .subscribe(result => {
          // this.infosUser = orders;
          console.log('Retour Security: ' + result );

        });
  }

  editData() {
    this.showCompteEdit = !this.showCompteEdit;
    
    this.profileForm.patchValue({
      formemail: this.email,
      formlastName: this.lastname,
      formfirstName: this.firstname,
      formaddress1: this.address1,
      formzipcode: this.zipcode,
      formlocality: this.locality,
      formmobilphone: this.mobilphone
    });
  this.valueFormChange = false;
  }

  editResto(id) {
    this.showDetails = !this.showDetails;
    this.isResto = 1;
    this.idObjet = id;
    console.log('isREsto: ' + this.isResto);
    console.log('show: ' + this.showDetails);
  }

  editLoisir(id) {
    this.showDetails = !this.showDetails;
    this.isResto = 0;
    this.idObjet = id;
    console.log('isREsto: ' + this.isResto);
    console.log('show: ' + this.showDetails);
  }

  dataFromChild(agreed: boolean) {
    this.showDetails = !this.showDetails;
  }

  // Fonction qui renvoie un mail pour validation de l'adresse email
  submitCardValidation() {
    const code = this.f.codeValidation.value;
    const data = {
      adressemail: this.email,
      firstname: this.firstname,
      lastname:  this.lastname,
      noid: this.userid,
      validationcode: code
    };
    this.codeValidationChange = false;

    const basebath = AppConstants.baseURL + '/postData/cardActivation.php';
    this.httpClient.post<any>(basebath, data).subscribe(
      (res) =>  {
        // this.reponseEditCompte = res;
        if (res === 11) {
          this.messMailValidationEnvoye = 'Votre carte est correctement validée.<br />Vous pouvez l\'utiliser dès à présent.<br/>ATTENTION : Nous n\'envoyons plus de carte';
          this.messMailValidationBug = '';
          this.router.navigate(['/moncompte']);

        } else if (res === 31) {
          this.messMailValidationEnvoye = '';
          this.messMailValidationBug = 'La date de validité est dépassée';
        } else if (res === 32) {
          this.messMailValidationEnvoye = '';
          this.messMailValidationBug = 'Cette carte est déjà activée. Veuillez vérifier vos données';
        } else {
          this.messMailValidationEnvoye = '';
          this.messMailValidationBug = 'Une erreur s\'est produite.<br />Veuillez prendre contact avec ebook-card.<br />Par mail à info@ebook-card.ch';
        }
        console.table('Réponse: ' + res);
        this.messReourDev = res;
      },
      (err) => console.log('Error: ' + err),
    );
  }

  // Fonction qui renvoie un mail pour validation de l'adresse email
  resendMailValidation() {

    const data = {
      adressemail: this.email,
      firstname: this.firstname,
      lastname:  this.lastname,
      noid: this.userid
    };

    const basebath = AppConstants.baseURL + '/postData/sendEmailValidation.php';
    this.httpClient.post<any>(basebath, data).subscribe(
      (res) =>  {
        // this.reponseEditCompte = res;
        if (res === 11) {
          this.messMailValidationEnvoye = 'Un mail a été envoyé. Veuillez consulter ce-dernier pour valider votre adresse';
        } else {
          this.messMailValidationEnvoye = 'Une erreur s\'est produite.';
        }
        console.table('Réponse: ' + res);
      },
      (err) => console.log('Error: ' + err),
    );
  }

  onSubmit(values) {
    // console.log(values);
    for (const value in values) {
      if (values[value] !== '' && values[value] !== undefined) {
        if (value === 'formfirstName') {
          this.firstname = values[value];
          console.log('Prénom modifié');
        }
        if (value === 'formlastName') {
          this.lastname = values[value];
          console.log('Prénom modifié');
        }
        if (value === 'formemail') {
          this.email = values[value];
          console.log('Mail modifié');
        }
        if (value === 'formaddress1') {
          this.address1 = values[value];
          console.log('Adresse modifié');
        }
        if (value === 'formzipcode') {
          this.zipcode = values[value];
          console.log('NPA modifié');
        }
        if (value === 'formlocality') {
          this.locality = values[value];
          console.log('Localité modifié');
        }
        if (value === 'formmobilphone') {
          this.mobilphone = values[value];
          console.log('Mobile modifié');
        }
        if (value === 'formmhomephone') {
          this.homephone = values[value];
          console.log('Tél modifié');
        }
      }
    }

    const data = {
      firstname: this.firstname,
      lastname: this.lastname,
      address1: this.address1,
      userid: this.currentUser.id,
      zipcode: this.zipcode,
      locality: this.locality,
      mobilphone: this.mobilphone,
      homephone: this.homephone,
      email: this.email
    };

    const basebath = AppConstants.baseURL + '/postData/postEditCompte.php?token=cPnFKCqNQLKw';
    this.httpClient.post<any>(basebath, data).subscribe(
      (res) =>  {
        this.reponseEditCompte = res;
        if (res === 11) {
          this.showCompteEdit = !this.showCompteEdit;
          this.getUserData(this.currentUser.id);
        }
        console.log('Réponse: ' + res);
      },
      (err) => console.log('Error: ' + err),
    );
  }


  getUserData(userid: string) {
    this.interval = this.infousers.getUserInfos(userid)
        .subscribe(orders => {
          this.infosUser = orders;
          for (const score of orders) {
            console.log('Info Users chargé' + score.lastname);
            this.arrDejaResa = score.dejareserve;
            this.arrDejaResaLoisirs = score.dejareserveloisirs;
            this.reservavenir = score.reservavenir;
            this.reservavenirLoisirs = score.reservavenirloisirs;
            this.mesLoisirs = score.activities;
            this.mesLoisirsCount = this.mesLoisirs.length;
            this.mesRestos = score.restaurants;
            this.mesRestosCount = this.mesRestos.length;
            this.cartevalide = score.cartevalide;
            this.cartes = score.cartes;
            this.reservpasse = score.reservpasse;
            this.reservpasseLoisirs = score.reservpasseloisirs;
            this.firstname = score.firstname;
            this.lastname = score.lastname;
            this.address1 = score.address1;
            this.zipcode = score.zipcode;
            this.locality = score.locality;
            this.email = score.email;
            this.emailvalidated = score.emailvalidated;
            this.mobilphone = score.mobilphone;
            this.homephone = score.homephone;
            this.registrationdate = score.registrationdate;
            this.userid = score.id;
            this.cartes.pop(); // supprime la carte en cours de la liste
            console.log('RD: ' + this.registrationdate);

            
          }

        });
  }

  ngOnInit() {
      this.loading = true;
      this.getUserData(this.currentUser.id);
      const encrypted = this.EncrDecr.set('123456$#@$^@1ERF', 'rust123');
      const decrypted = this.EncrDecr.get('123456$#@$^@1ERF', encrypted);

      this.cardValidationForm = this.formBuilder.group({
        codeValidation: ['', Validators.required]
      });

      this.cardValidationForm.valueChanges.subscribe(selectedValue  => {
        this.codeValidationChange = true;
      });

      this.profileForm.valueChanges.subscribe(selectedValue  => {
        if (selectedValue.formemail !== this.emailIntermediaire && this.email !== selectedValue.formemail) {
          console.log('Mail changed ' + selectedValue.formemail);
          this.emailIntermediaire = selectedValue.formemail;
          this.onChangeEmail(selectedValue.formemail);
        }
        if (selectedValue.formmobilphone !== this.mobileIntermediaire && this.mobilphone !== selectedValue.formemail) {
          console.log('Mobile changed ' + selectedValue.formmobilphone);
          this.mobileIntermediaire = selectedValue.formmobilphone;
          this.onChangeMobile(selectedValue.formmobilphone);
        }
        this.valueFormChange = true;
        // this.formBaseChange = true;
      });
  }

  // Fonction lors de l'enregistrement des infos de base 
  onChangeEmail(emailForm) {
    const data = {
        email: emailForm,
    };
    console.table(data);
    const basebath = AppConstants.baseURL + '/postData/postVerifEmailDirect.php';
    this.httpClient.post<any>(basebath, data).subscribe(
      (res) =>  {
        // this.reponseEditCompte = res;
        if (res === 11) {
          this.emailExist = '';
        } else if (res === 33) {
          this.emailExist = 'Cette adresse email est déjà utilisée dans un compte eBook-card.';
        }
        // console.table('Réponse: ' + res);
      },
      (err) => console.table('ErrorHere: ' + err),
    );
  }

  // Fonction lors de l'enregistrement des infos de base
  onChangeMobile(mobilPhone) {
    const data = {
        mobile: mobilPhone,
        email: this.email
    };
    console.table(data);
    const basebath = AppConstants.baseURL + '/postData/postVerifMobileDirect.php';
    this.httpClient.post<any>(basebath, data).subscribe(
      (res) =>  {
        // this.reponseEditCompte = res;
        if (res === 11) {
          this.mobileExist = '';
        } else if (res === 33) {
          this.mobileExist = 'Ce numéro de mobile est déjà utilisée dans un compte eBook-card.';
        } 
        console.table('Réponse: ' + res);
      },
      (err) => console.table('ErrorHere: ' + err),
    );
  }

  get f() {
    return this.cardValidationForm.controls;
  }

  // Bouton depuis Activer carte pour modifier compte
  btnModifierMonCompte() {
    const newstr = 'nav-link active';
    document.getElementById('home-tab').className = newstr;
    document.getElementById('home-tab').setAttribute('aria-selected', 'true');

    const newstr2 = 'nav-link';
    document.getElementById('activer-tab').className = newstr2;
    document.getElementById('activer-tab').setAttribute('aria-selected', 'false');

    const newstr3 = 'tab-pane fade active show';
    document.getElementById('home').className = newstr3;
    const newstr4 = 'text-center tab-pane fade';
    document.getElementById('activer').className = newstr4;

    this.editData();
  }


  ngOnDestroy() {
    this.interval.unsubscribe();
  }

}

import { Component, OnInit, OnDestroy, Input, OnChanges } from '@angular/core';
import { BoutiqueService, UserService, AuthenticationService } from '../_services';
import { Subscription } from 'rxjs';
import { AppConstants } from '../_helpers/app.constants';
import { ActivatedRoute } from '@angular/router';
import { User } from '../_models';

@Component({
  selector: 'app-boutique-liste',
  templateUrl: './boutique-liste.component.html',
  styleUrls: ['./boutique.component.css']
})
export class BoutiqueListeComponent implements OnInit, OnDestroy, OnChanges {

  @Input() category: string;
  @Input() urlPourAppel: string;
  @Input() titreCat: string;

  interval: Subscription;
  produitsList = [];
  categoryCadeaux = [];
  categoryPhysique = [];
  categoryProlong = [];
  categoryVirtuelle = [];
  categoryList = [];
  carteValideNb: number;
  nbProduitsArrives = false;
  idProduit = 'gastro12';
  urlBoutique = AppConstants.baseURLShop;
  arrCategoryId = AppConstants.arrayId;
  currentUser: User;
  cartevalide = [];
  cartes = [];
  firstname: string;
  lastname: string;
  address1: string;
  zipcode: string;
  locality: string;
  roleUser: string;
  email: string;
  infosUser = [];
  userid: string;
  desactive = false;

  constructor(private boutiqueServ: BoutiqueService,
              private route: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private infousers: UserService) {
                this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
               }

  ngOnInit() {
    if (this.currentUser ) { // Connexion facultative
      this.getUserData(this.currentUser.id);
    } else {
      this.getProducts(this.urlPourAppel);
    }
    this.getProductsListCron(this.urlPourAppel);
    console.log(this.urlPourAppel);
  }

  ngOnChanges() {
    if (this.currentUser ) { // Connexion facultative
      this.getUserData(this.currentUser.id);
    } else {
      this.getProducts(this.urlPourAppel);
    }
    this.getProductsListCron(this.urlPourAppel);
    console.log('Change ' + this.urlPourAppel);
  }

  determineProducts() {
    if (this.currentUser && this.carteValideNb > 0) {
      console.log('IF 87: ' + this.carteValideNb);
      this.getProductsConnecte(this.urlPourAppel);
    } else {
      this.getProducts(this.urlPourAppel);
    }
  }

  getProductsConnecte(urlPourAppel: string): void {
    this.categoryProlong = [];
    this.categoryCadeaux = [];
    this.nbProduitsArrives = false;
    this.interval = this.boutiqueServ.getProductsList(urlPourAppel)
        .subscribe(prodList => {
          for (const prod in prodList) {
            if (prod) {
              for (const i in prodList[prod].categories) {
                if (i) {
                  if (prodList[prod].categories[i].id === this.arrCategoryId.prolongation ) {
                    this.categoryProlong.push(prodList[prod]);
                  }
                  if (prodList[prod].categories[i].id === this.arrCategoryId.cadeaux) {
                    this.categoryCadeaux.push(prodList[prod]);
                  }
                }
              }
            }
          }
          this.nbProduitsArrives = true;
        });
  }

  getProducts(urlPourAppel: string): void {
    this.categoryPhysique = [];
    this.categoryCadeaux = [];
    this.categoryVirtuelle = [];
    this.interval = this.boutiqueServ.getProductsList(urlPourAppel)
        .subscribe(prodList => {
          for (const prod in prodList) {
            if (prod) {
              for (const i in prodList[prod].categories) {
                if (i) {
                  if (prodList[prod].categories[i].id === this.arrCategoryId.physique) {
                    this.categoryPhysique.push(prodList[prod]);
                  } 
                  if (prodList[prod].categories[i].id === this.arrCategoryId.cadeaux) {
                    this.categoryCadeaux.push(prodList[prod]);
                  } 
                  if (prodList[prod].categories[i].id === this.arrCategoryId.virtuelle) {
                    this.categoryVirtuelle.push(prodList[prod]);
                  }
                }
              }
            }
          }
          this.nbProduitsArrives = true;
        });
  }

  getProductsListCron(urlPourAppel: string): void {
    this.interval = this.boutiqueServ.getProductsListCron(urlPourAppel)
        .subscribe(catList => {
          console.log('CronListeChargé');
        });
  }

  isThisSorte(categories: any, catego: number) {
    let retour = false;
    // console.table(categories);
    for (const i in categories) {
      if (categories[i].id === catego) {
        retour = true;
      }
    }
    return retour;
  }

  getUserData(userid: string) {
    this.interval = this.infousers.getUserInfosOnce(userid)
        .subscribe(orders => {
          this.infosUser = orders;
          for (const score of orders) {
            this.cartevalide = score.cartevalide;
            this.cartes = score.cartes;
            this.firstname = score.firstname;
            this.lastname = score.lastname;
            this.address1 = score.address1;
            this.zipcode = score.zipcode;
            this.locality = score.locality;
            this.email = score.email;
            this.userid = score.id;
          }
          if (this.cartevalide) {
            this.carteValideNb = Object.keys(this.cartevalide).length;
            console.log('carteValideNb:' + this.carteValideNb);
          }
          console.log('Valide 176:' + this.carteValideNb);
          this.determineProducts();
        });

  }

  

  ngOnDestroy() {
    this.interval.unsubscribe();
  }



}

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BoutiqueService } from '../_services';
import { Subscription } from 'rxjs';
import { AppConstants } from '../_helpers/app.constants';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-boutique-detail',
  templateUrl: './boutique-detail.component.html',
  styleUrls: ['./boutique.component.css']
})
export class BoutiqueDetailComponent implements OnInit, OnDestroy {

  interval: Subscription;
  nbProduitsArrives = false;
  idProduct: string;
  titleurl: string;  // pour URL category choisie
  urlBoutique = AppConstants.baseURLShop;
  monPanier = [];
  nbrProduits = 1;
  image = [];
  name: string;
  price: number;
  leId: number;
  short: string;
  description: string;

  constructor(private http: HttpClient,
              private boutiqueServ: BoutiqueService,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              public dialog: MatDialog,
              private metaTagService: Meta,
              private titleService: Title) {

               }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.idProduct = params.id;
      this.titleurl = params.titleurl;
    });
    this.getOneProduct(this.idProduct);
    this.vaChercherPanier();
  }

  moinsUn() {
    if (this.nbrProduits > 1) {
      this.nbrProduits = this.nbrProduits - 1;
    }
  }

  plusUn() {
    this.nbrProduits = this.nbrProduits + 1;
  }

  addCart(idProduit: number, nom: string, prix: number) {
    const nouveauPanier = [];

    if (this.monPanier) {
      console.log('if99');
      const taillePanier = Object.keys(this.monPanier).length;
      let ajoute = false;
      if (taillePanier > 0) {
        for (const i in this.monPanier) {
          if (i) {
            const leID = + this.monPanier[i].id;
            if (leID === idProduit) {
              const ajoutPanier = {
                "id": idProduit,
                "name": nom,
                "price": prix,
                "nombre": this.nbrProduits + 1,
                "image": this.image[0].src,
                "total": prix * (this.nbrProduits + 1)
              };
              nouveauPanier.push(ajoutPanier);
              ajoute = true;
            } else {
              console.log('else117');
              nouveauPanier.push(this.monPanier[i]);
            }
          }
        }
        if (!ajoute) {
          const ajoutPanier = {
            "id": idProduit,
            "name": nom,
            "price": prix,
            "nombre": this.nbrProduits,
            "image": this.image[0].src,
            "total": (prix * this.nbrProduits)
          };
          nouveauPanier.push(ajoutPanier);
        }
      } 
    } else {
      const ajoutPanier = {
        "id": idProduit,
        "name": nom,
        "price": prix,
        "nombre": this.nbrProduits,
        "image": this.image[0].src,
        "total": (prix * this.nbrProduits)
      };
      nouveauPanier.push(ajoutPanier);
    }

    sessionStorage.setItem('lepanier',  JSON.stringify(nouveauPanier));
    this.vaChercherPanier();
    // console.table(this.monPanier);
  }

  getOneProduct(id: string): void {
    this.interval = this.boutiqueServ.getOneProduct(id)
        .subscribe(prodDetail => {
          this.image = prodDetail.images;
          this.name = prodDetail.name;
          this.price = prodDetail.price;
          this.leId = prodDetail.id;
          this.short = prodDetail.short_description;
          this.description = prodDetail.description;
          this.nbProduitsArrives = true;
          console.table(prodDetail);

          this.titleService.setTitle('Boutique -  ' + this.name + ' - ebook-card.ch');
          this.metaTagService.updateTag(
            { name: 'description', content: 'Acheter une carte ebook-card '  + this.name }
          );
        });
  }

  vaChercherPanier(): void {
    this.interval = this.boutiqueServ.getPanier().subscribe(lePanier => {
      this.monPanier = lePanier;

    });
  }


  ngOnDestroy() {
    // a voir
  }



}

<br />
<div class="container boutiquefond" >
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="/boutique/toutes-les-cartes">Boutique</a></li>
      <li class="breadcrumb-item active" aria-current="page">Votre panier</li>
    </ol>
  </nav>
  <h2>Votre panier</h2>
  <div *ngIf="!monPanier">
    <p><strong>Vous n'avez pas de produit dans votre panier.</strong></p>
    <p><a href="/boutique/toutes-les-cartes" >Accédez à notre boutique</a></p>
 </div>


 <table class="table" *ngIf="monPanier">
  <tr>
    <th></th>
    <th>Produit</th>
    <th>Prix</th>
    <th>Quantité</th>
    <th>Sous-total</th>
  </tr>
  <tr *ngFor="let panier of monPanier">
    <td><span class="material-icons removeFrom" aria-label="Supprimer du panier" matTooltip="Supprimer du panier" (click)="removeFromBasket(panier.id)">clear</span></td>
    <td><img class="rounded panierImagePtite" src="{{urlBoutique}}{{panier.image}}" />{{panier.name}}</td>
    <td nowrap>{{panier.price | number:'2.2'}}&nbsp;CHF</td>
    <td nowrap><button mat-stroked-button (click)="moinsUn(panier.id, panier.nombre)">-</button>&nbsp;&nbsp;{{panier.nombre}}&nbsp;&nbsp;<button mat-stroked-button (click)="plusUn(panier.id,panier.nombre)" >+</button></td>
    <td nowrap><strong>{{panier.total | number:'2.2'}} CHF</strong></td>
  </tr>
  <tr>
    <td></td>
    <td></td>
    <td></td>
    <td><strong>TOTAL</strong></td>
    <td nowrap><strong>{{ totalPanier() | number:'2.2' }} CHF</strong></td>
  </tr>
 </table>
<p>4000 0027 6000 3184</p>

 <div *ngIf="monPanier">
   <h5>Valider la commande</h5>
    <div [formGroup]="formCommande" class="grid_form">
      <p><strong>Vos coordonnées</strong></p>
      <p>
        <input type="text" class="form-control" formControlName="firstname" placeholder="Prénom" [readonly]="desactive">&nbsp;&nbsp;
        <input type="text" class="form-control" formControlName="lastname" placeholder="Nom" [readonly]="desactive">
      </p>
      <p>
        <input type="text" class="form-control" formControlName="adresserue" placeholder="Rue et No" [readonly]="desactive"> 
      </p>
      <p>
        <input type="text" class="form-control" formControlName="adressenpa" placeholder="NPA" [readonly]="desactive">&nbsp;&nbsp;
        <input type="text" class="form-control" formControlName="adresselocality" placeholder="Localité" [readonly]="desactive"> 
      </p>
      <input type="email" class="form-control" formControlName="email" placeholder="Email" [readonly]="desactive">
      <br />
      <p>Si vos coordonnées ci-dessus ne sont pas exactes, merci de les modifier dans "Mon compte" 
        <a style="color:#8AC43F;padding-top: 5px;" [routerLink]="'/moncompte'" >(accéder à "Mon compte")</a>. Vous pourrez ensuite revenir sur cette page en cliquant sur le panier (<a style="color: #009de2;padding-top: 5px;" [routerLink]="'/boutique/panier/contenu'" >
          <span class="material-icons">shopping_basket</span>
        </a>   ) tout en haut</p>

        <div *ngIf="currentUser">
          <mat-label>Choisissez la date de début de validété de cette carte</mat-label>
          <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
      <p>&nbsp;</p>
      <p><strong>Veuillez insérer vos données de paiement par carte</strong></p>
      <p  class="form-control" >
        <ngx-stripe-card
          [options]="cardOptions"
          [elementsOptions]="elementsOptions"
        ></ngx-stripe-card></p>
        <p *ngIf="messageErrPay">{{ messageErrPay }}</p>
        <button type="submit" [ngClass]="[formCommande.valid ? 'btn btn-sm btn-primary active': 'btn btn-sm btn-secondary']" [disabled]="!formCommande.valid" (click)="pay()">
          PAY
        </button>
        <br />
    </div>
  </div>

</div>



  

      
      








<br>
<div class="grid_container">
    <div></div>
    <div class="grandeBoxDetail">
        <H3 class="title_faq">FAQ</H3>
        <div class="accordion" id="accordionExample">
            <div class="card">
                <div class="card-header" id="heading1">
                    <h2 class="mb-0 test">
                        <button style="color: yellowgreen;font-size: 60%;padding-top: 0px;
              padding-bottom: 0px; text-align: left;" class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse1">
                            <a> Est-ce que l'on peut offrir les cartes en action ?</a>
                        </button>
                    </h2>
                </div>
                <div id="collapse1" class="collapse" aria-labelledby="heading1" data-parent="#accordionExample">
                    <div class="card-body">
                        Oui les cartes en action sont des cartes qui ne sont pas nominatives, elles peuvent être
                        offertes ou pour soi-même.
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading2">
                    <h2 class="mb-0 test">
                        <button style="text-align: left;color: yellowgreen;font-size: 60%;padding-top: 0px;
                padding-bottom: 0px;" class="btn btn-link" type="button" data-toggle="collapse"
                            data-target="#collapse2">
                            <a>Qu’est-ce que <span class="bold-blue-e">e</span>book-card ?</a>
                        </button>
                    </h2>
                </div>
                <div id="collapse2" class="collapse" aria-labelledby="heading2" data-parent="#accordionExample">
                    <div class="card-body">
                        C’est une carte qui vous permet de découvrir des restaurants et des loisirs de tous styles en
                        vous offrant un rabais.
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading3">
                    <h2 class="mb-0 test">
                        <button style="text-align: left;color: yellowgreen;font-size: 60%;padding-top: 0px;
                padding-bottom: 0px;" class="btn btn-link" type="button" data-toggle="collapse"
                            data-target="#collapse3">
                            <a>Comment trouver un restaurant ou un loisir ?</a>
                        </button>
                    </h2>
                </div>
                <div id="collapse3" class="collapse" aria-labelledby="heading3" data-parent="#accordionExample">
                    <div class="card-body">
                        <a> Vous allez dans l’onglet “Les restaurants” ou "Les loisirs", et vous trouverez l’intégralité
                            des partenaires dans lesquels vous pouvez bénéficier des pourcentages.</a>
                        <br>
                        <a style="color:#231F20">-</a>
                        <br>
                        <a>Les restaurants peuvent être triés par : type de cuisine, canton, gamme de prix, équipement,
                            jour d’ouverture.</a>
                        <br>
                        <a style="color:#231F20">-</a>
                        <br>
                        <a>Les loisirs peuvent être triés par : les cantons, informations, type de loisirs, gamme de
                            prix, jours d'ouverture.</a>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading4">
                    <h2 class="mb-0 test">
                        <button style="text-align: left;color: yellowgreen;font-size: 60%;padding-top: 0px;
                padding-bottom: 0px;" class="btn btn-link" type="button" data-toggle="collapse"
                            data-target="#collapse4">
                            <a>Comment savoir si il y a de la place dans le restaurant ou le loisir ?</a>
                        </button>
                    </h2>
                </div>
                <div id="collapse4" class="collapse" data-parent="#accordionExample">
                    <div class="card-body">
                        Le site <span class="bold-blue-e">e</span>book-card.ch informe en temps réel des places
                        disponibles.
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading5">
                    <h2 class="mb-0 test">
                        <button style="text-align:left ;color: yellowgreen;font-size: 60%;padding-top: 0px;
                padding-bottom: 0px;" class="btn btn-link" type="button" data-toggle="collapse"
                            data-target="#collapse5">
                            <a>Est-ce que la réservation est obligatoire ?</a>
                        </button>
                    </h2>
                </div>
                <div id="collapse5" class="collapse" data-parent="#accordionExample">
                    <div class="card-body">
                        Oui, la réservation est obligatoire pour bénéficier des rabais. Elle se fait sur le site <span
                            class="bold-blue-e">e</span>book-card.ch. Le sms doit être présentés dès
                        votre arrivée.
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading6">
                    <h2 class="mb-0 test">
                        <button style="text-align:left; color: yellowgreen;font-size: 60%;padding-top: 0px;
                padding-bottom: 0px;" class="btn btn-link" type="button" data-toggle="collapse"
                            data-target="#collapse6">
                            <a>Quels sont les conditions pour pouvoir bénéficier des rabais chez les restaurateurs ?</a>
                        </button>
                    </h2>
                </div>
                <div id="collapse6" class="collapse" data-parent="#accordionExample">
                    <div class="card-body">
                        <a> 1. S’enregistrer et acheter un code gastronomie sur le site <span
                                class="bold-blue-e">e</span>book-card.ch.</a>
                        <br>
                        <a>2. Sélectionner un restaurant partenaire sur la liste. </a>
                        <br>
                        <a>3. Réserver une table sur le site <span class="bold-blue-e">e</span>book-card.ch. </a>
                        <br>
                        <a>4. Présenter le sms de réservation dès votre arrivée.</a>
                        <br>
                        <a>SANS SMS PAS DE REDUCTION</a>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading7">
                    <h2 class="mb-0 test">
                        <button style="text-align:left; color: yellowgreen;font-size: 60%;padding-top: 0px;
                padding-bottom: 0px;" class="btn btn-link" type="button" data-toggle="collapse"
                            data-target="#collapse7">
                            <a>Comment payer l'addition ?</a>
                        </button>
                    </h2>
                </div>
                <div id="collapse7" class="collapse" data-parent="#accordionExample">
                    <div class="card-body">
                        <a>Le payement de l'addition par carte de crédit ou de débit n'est pas accepté chez les
                            restaurateurs.</a>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading8">
                    <h2 class="mb-0 test">
                        <button style="text-align:left; color: yellowgreen;font-size: 60%;padding-top: 0px;
                padding-bottom: 0px;" class="btn btn-link" type="button" data-toggle="collapse"
                            data-target="#collapse8">
                            <a>Comment se font les rabais chez les restaurateurs ?</a>
                        </button>
                    </h2>
                </div>
                <div id="collapse8" class="collapse" data-parent="#accordionExample">
                    <div class="card-body">
                        <a>Ils se font uniquement sur la nourriture, entrée, plat et dessert et suivant le nombre de
                            personnes.<br><br>
                            1 personne 40%<br>
                            2 personnes 50%<br> 
                            3 personnes 40%<br>
                            4 personnes 30%<br>
                            5 ou 6 personnes 20%<br><br> 
                            Il n’y a pas de rabais sur les boissons et vous devez prendre des boissons
                            payantes. Vous ne pouvez pas prendre de carafe d’eau.</a>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading9">
                    <h2 class="mb-0 test">
                        <button style="text-align:left; color: yellowgreen;font-size: 60%;padding-top: 0px;
                padding-bottom: 0px;" class="btn btn-link" type="button" data-toggle="collapse"
                            data-target="#collapse9">
                            <a>Comment se font les rabais chez les partenaires loisirs ?</a>
                        </button>
                    </h2>
                </div>
                <div id="collapse9" class="collapse" data-parent="#accordionExample">
                    <div class="card-body">
                        <a>Une entrée payante = une entrée gratuite de 2 à 6 personnes selon le principe suivant:</a>
                        <br>
                        <a style="color:#231F20">-</a>
                        <br>
                        <a>Une entrée payante pour un adulte = une entrée gratuite pour un adulte ou un enfant<br>
                            une entrée payante pour un enfant = une entrée gratuite pour un enfant<br>
                            sur la gratuité, l’adulte l’emporte sur l’enfant
                        </a>
                        <br>
                        <a style="color:#231F20">-</a>
                        <br>
                        <a>Pour les activités particulières, certains partenaires ont des conditions spéciales, à voir
                            sur l’annonce.</a>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading10">
                    <h2 class="mb-0 test">
                        <button style="text-align:left; color: yellowgreen;font-size: 60%;padding-top: 0px;
                padding-bottom: 0px;" class="btn btn-link" type="button" data-toggle="collapse"
                            data-target="#collapse10">
                            <a> Quels sont les conditions pour pouvoir bénéficier des rabais pour les loisirs ?</a>
                        </button>
                    </h2>
                </div>
                <div id="collapse10" class="collapse" data-parent="#accordionExample">
                    <div class="card-body">
                        <a>1. S’enregistrer et acheter un code sur le site <span class="bold-blue-e">e</span>book-card.ch.</a>
                        <br>
                        <a>2. Sélectionner un partenaire loisirs sur la liste.</a>
                        <br>
                        <a>3. Réserver une activité sur le site <span class="bold-blue-e">e</span>book-card.ch. </a>
                        <br>
                        <a>4. Présenter à la caisse le sms de réservation avec le prix indiqué sur celui-ci.</a>
                        <br>
                        <a>SANS SMS PAS DE REDUCTION</a>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading11">
                    <h2 class="mb-0 test">
                        <button style="text-align:left; color: yellowgreen;font-size: 60%;padding-top: 0px;
                padding-bottom: 0px;" class="btn btn-link" type="button" data-toggle="collapse"
                            data-target="#collapse11">
                            <a>La liste des restaurants partenaires change-t-elle ?</a>
                        </button>
                    </h2>
                </div>
                <div id="collapse11" class="collapse" data-parent="#accordionExample">
                    <div class="card-body">
                        <a>Oui, elle évolue et grandit régulièrement. L’équipe d’<span class="bold-blue-e">e</span>book-card travaille quotidiennement afin de pouvoir vous proposer un choix de plus en plus varié de restaurants. Il est aussi normal que, durant la période de votre carte, des restaurants ferment ou changent de propriétaires, mais ils seront remplacés par d’autres restaurants et vous aurez toujours plus d'établissements à choix.</a>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading11">
                    <h2 class="mb-0 test">
                        <button style="text-align:left; color: yellowgreen;font-size: 60%;padding-top: 0px;
                padding-bottom: 0px;" class="btn btn-link" type="button" data-toggle="collapse"
                            data-target="#collapse11">
                            <a>Puis-je utiliser plusieurs cartes par table ?</a>
                        </button>
                    </h2>
                </div>
                <div id="collapse11" class="collapse" data-parent="#accordionExample">
                    <div class="card-body">
                        <a>Non, une carte est valable par réservation et par table.</a>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading13">
                    <h2 class="mb-0 test">
                        <button style="text-align:left ;color: yellowgreen;font-size: 60%;padding-top: 0px;
                padding-bottom: 0px;" class="btn btn-link" type="button" data-toggle="collapse"
                            data-target="#collapse13">
                            <a>La liste des partenaires loisirs change-t-elle ?</a>
                        </button>
                    </h2>
                </div>
                <div id="collapse13" class="collapse" data-parent="#accordionExample">
                    <div class="card-body">
                        <a>Oui, elle évolue et grandit régulièrement. L’équipe d’<span class="bold-blue-e">e</span>book-card travaille quotidiennement afin de pouvoir vous proposer un choix de plus en plus varié de loisirs. Durant la période de votre carte, des loisirs peuvent fermer ou changer de propriétaires, ils seront remplacés par d’autres partenaires et vous aurez toujours plus de loisirs à choix.</a>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading14">
                    <h2 class="mb-0 test">
                        <button style="text-align:left ;color: yellowgreen;font-size: 60%;padding-top: 0px;
                padding-bottom: 0px;" class="btn btn-link" type="button" data-toggle="collapse"
                            data-target="#collapse14">
                            <a>Le site <span class="bold-blue-e">e</span>book-card.ch est-il sécurisé ?</a>
                        </button>
                    </h2>
                </div>
                <div id="collapse14" class="collapse" data-parent="#accordionExample">
                    <div class="card-body">
                        <a>Oui, c’est une de nos priorité. Toutes les étapes du site sont sécurisées.</a>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading15">
                    <h2 class="mb-0 test">
                        <button style="text-align:left; color: yellowgreen;font-size: 60%;padding-top: 0px;
                padding-bottom: 0px;" class="btn btn-link" type="button" data-toggle="collapse"
                            data-target="#collapse15">
                            <a>Après l’achat de la carte, que se passe-t-il ?</a>
                        </button>
                    </h2>
                </div>
                <div id="collapse15" class="collapse" data-parent="#accordionExample">
                    <div class="card-body">
                        <a>C’est très simple !<br> 
                            je me connecte à “mon compte” sur le site <span class="bold-blue-e">e</span>book-card,  j'active mon code et je réserve directement une table ou un loisir, le jour même.</a>
                        <br>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading16">
                    <h2 class="mb-0 test">
                        <button style="text-align:left; color: yellowgreen;font-size: 60%;padding-top: 0px;
                padding-bottom: 0px;" class="btn btn-link" type="button" data-toggle="collapse"
                            data-target="#collapse16">
                            <a>Quelles sont les dates de validité ?</a>
                        </button>
                    </h2>
                </div>
                <div id="collapse16" class="collapse" data-parent="#accordionExample">
                    <div class="card-body">
                        <a>Les cartes gastronomiques sont valables 3, 6 ou 12 mois, à partir de la date d'activation.</a>
                        <br>
                        <a style="color:#231F20">-</a>
                        <br>
                        <a>Les cartes loisirs ou mixtes sont valables 12 mois, à partir de la date d'activation.</a>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading17">
                    <h2 class="mb-0 test">
                        <button style="text-align:left; color: yellowgreen;font-size: 60%;padding-top: 0px;
                padding-bottom: 0px;" class="btn btn-link" type="button" data-toggle="collapse"
                            data-target="#collapse17">
                            <a>Où est valable ma carte ?</a>
                        </button>
                    </h2>
                </div>
                <div id="collapse17" class="collapse" data-parent="#accordionExample">
                    <div class="card-body">
                        <a>Les <span class="bold-blue-e">e</span>book-card sont valable partout en Suisse romande chez tous nos partenaires.</a>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading18">
                    <h2 class="mb-0 test">
                        <button style="text-align:left; color: yellowgreen;font-size: 60%;padding-top: 0px;
                padding-bottom: 0px;" class="btn btn-link" type="button" data-toggle="collapse"
                            data-target="#collapse18">
                            <a>Combien de fois puis-je utiliser ma carte ?</a>
                        </button>
                    </h2>
                </div>
                <div id="collapse18" class="collapse" data-parent="#accordionExample">
                    <div class="card-body">
                        <a>Autant de fois que vous le souhaitez, mais une seule fois chez chacun de nos partenaires.</a>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading19">
                    <h2 class="mb-0 test">
                        <button style="text-align:left; color: yellowgreen;font-size: 60%;padding-top: 0px;
                padding-bottom: 0px;" class="btn btn-link" type="button" data-toggle="collapse"
                            data-target="#collapse19">
                            <a>Est-ce que la carte est valable tous les jours ?</a>
                        </button>
                    </h2>
                </div>
                <div id="collapse19" class="collapse" data-parent="#accordionExample">
                    <div class="card-body">
                        <a>Oui, la carte est valable tous les jours, sauf les jours de fête pour les restaurants, ou éventuellement les jours indiqués sur les conditions spéciales du partenaire concerné.</a>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading20">
                    <h2 class="mb-0 test">
                        <button style="text-align:left ;color: yellowgreen;font-size: 60%;padding-top: 0px;
                padding-bottom: 0px;" class="btn btn-link" type="button" data-toggle="collapse"
                            data-target="#collapse20">
                            <a>Peut-on offrir la carte en cadeau, et comment faire ?</a>
                        </button>
                    </h2>
                </div>
                <div id="collapse20" class="collapse" data-parent="#accordionExample">
                    <div class="card-body">
                        <a>Oui. il vous suffit tout simplement d'acheter une carte cadeau depuis notre homepage</a>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading21">
                    <h2 class="mb-0 test">
                        <button style="text-align:left; color: yellowgreen;font-size: 60%;padding-top: 0px;
                padding-bottom: 0px;" class="btn btn-link" type="button" data-toggle="collapse"
                            data-target="#collapse21">
                            <a>Quelles sont les moyens de payement ?</a>
                        </button>
                    </h2>
                </div>
                <div id="collapse21" class="collapse" data-parent="#accordionExample">
                    <div class="card-body">
                        <a>La carte est payable d’avance, sur le site <span class="bold-blue-e">e</span>book-card.ch, complètement sécurisé, par cartes Visa, Mastercard, TWINT ou virement bancaire.</a>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading22">
                    <h2 class="mb-0 test">
                        <button style="text-align:left ;color: yellowgreen;font-size: 60%;padding-top: 0px;
                padding-bottom: 0px;" class="btn btn-link" type="button" data-toggle="collapse"
                            data-target="#collapse22">
                            <a>Combien de temps met la carte pour arriver chez moi ?</a>
                        </button>
                    </h2>
                </div>
                <div id="collapse22" class="collapse" data-parent="#accordionExample">
                    <div class="card-body">
                        <a>Vous achetez un code que vous pouvez activer directement. Il n'y a pas de délais d'attente</a>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading23">
                    <h2 class="mb-0 test">
                        <button style="text-align:left ;color: yellowgreen;font-size: 60%;padding-top: 0px;
                padding-bottom: 0px;" class="btn btn-link" type="button" data-toggle="collapse"
                            data-target="#collapse23">
                            <a>Est-ce que ma carte est transmissible ?</a>
                        </button>
                    </h2>
                </div>
                <div id="collapse23" class="collapse" data-parent="#accordionExample">
                    <div class="card-body">
                        <a>Non, la carte est personnelle et intransmissible. La personne se verra refuser les avantages si le nom et prénom ne correspondent pas à son identité. Et c'est toujours le SMS qui fait foi.</a>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading24">
                    <h2 class="mb-0 test">
                        <button style="text-align:left ;color: yellowgreen;font-size: 60%;padding-top: 0px;
                padding-bottom: 0px;" class="btn btn-link" type="button" data-toggle="collapse"
                            data-target="#collapse24">
                            <a>Doit-on présenter le SMS au restaurateur lors de notre arrivée ?</a>
                        </button>
                    </h2>
                </div>
                <div id="collapse24" class="collapse" data-parent="#accordionExample">
                    <div class="card-body">
                        <a>C'est le sms reçu sur votre téléphone portable qui fait foi pour bénéficier de la réduction, il doit être présenté dés l’arrivée du client dans l’établissement. Le restaurateur contrôlera le numéro de réservation.</a>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading25">
                    <h2 class="mb-0 test">
                        <button style="text-align:left ;color: yellowgreen;font-size: 60%;padding-top: 0px;
                padding-bottom: 0px;" class="btn btn-link" type="button" data-toggle="collapse"
                            data-target="#collapse25">
                            <a>Doit-on présenter le SMS à la caisse du loisir lors de notre arrivée ?</a>
                        </button>
                    </h2>
                </div>
                <div id="collapse25" class="collapse" data-parent="#accordionExample">
                    <div class="card-body">
                        <a>Oui, car sur le sms reçu sur votre téléphone portable il y a le prix à payer pour bénéficier de la réduction.</a>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading26">
                    <h2 class="mb-0 test">
                        <button style="text-align:left ;color: yellowgreen;font-size: 60%;padding-top: 0px;
                padding-bottom: 0px;" class="btn btn-link" type="button" data-toggle="collapse"
                            data-target="#collapse26">
                            <a>Puis-je utiliser ma carte le jour même de l’achat ?</a>
                        </button>
                    </h2>
                </div>
                <div id="collapse26" class="collapse" data-parent="#accordionExample">
                    <div class="card-body">
                        <a>OUI</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div></div>
</div>
<br>
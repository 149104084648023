import { Component, Input, OnInit, OnDestroy  } from '@angular/core';
import { User } from '../_models';
import { UserService, AuthenticationService } from '../_services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-restaurants',
  templateUrl: './restaurants.component.html',
  styleUrls: ['./restaurants.component.css']
})

export class RestaurantsComponent  implements OnInit, OnDestroy {

  consomme = 'assets/images/logo consomme.png';
  
  searchText: string;
  currentUser: User;
  interval: Subscription;
  infosUser = [];
  arrDejaResa = [];

  constructor(private authenticationService: AuthenticationService,
              private infousers: UserService) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    //console.log('Deja ' + this.currentUser.dejareserve);
    }
    @Input() restaurants = [];
    @Input() comptResto: boolean;

    getUserData(userid: string) {
      this.interval = this.infousers.getUserInfos(userid)
          .subscribe(orders => {
            this.infosUser = orders;
            for (const score of orders) {
              console.table(score.dejareserve);
              this.arrDejaResa = score.dejareserve;
            }
          });
    }
    ngOnInit() {
      if (this.currentUser) {
        this.getUserData(this.currentUser.id);
      }

    }
    ngOnDestroy() {
      if (this.interval) {
        this.interval.unsubscribe();
      }
    }

}


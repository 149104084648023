import { Component, OnInit } from '@angular/core';
import { UserloginService } from '../_services/userlogin.service';
import { Subscription } from 'rxjs';
import { UserService, AuthenticationService } from '../_services';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../_helpers/app.constants';
import { User } from '../_models';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  formContact;
  formContactChange = false;
  currentUser: User;
  currentUserRole: string;
  currentUserId: string;
  infosUser = [];
  interval: Subscription;
  messageOKAffiche: string;
  envoyeOK = false;

  constructor(private authenticationService: AuthenticationService,
              private infousers: UserService,
              private httpClient: HttpClient,
              private formBuilder: FormBuilder,
              private titleService: Title,) { titleService.setTitle("Contactez-nous"); }

  ngOnInit() {
    this.formContact = this.formBuilder.group({
      qui: ['', Validators.required],
      nom: ['', Validators.required],
      prenom: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      tel: [''],
      noebook: [''],
      message: ['', Validators.required]
    });
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      this.currentUserRole = x.roleuser;
      this.currentUserId = x.id;
      }
    );
    this.getUserData(this.currentUser.id);

    this.formContact.valueChanges.subscribe(selectedValue  => {
      this.formContactChange = true;
    });
  }

  get fcon() {
    return this.formContact.controls;
  }

  getUserData(userid: string) {
    this.interval = this.infousers.getUserInfos(userid)
        .subscribe(orders => {
          this.infosUser = orders;
        });
  }

  // Fonction qui renvoie un mail pour validation de l'adresse email
  submitMessage() {

    const data = {
      qui: this.fcon.qui.value,
      nom: this.fcon.nom.value,
      prenom:  this.fcon.prenom.value,
      email: this.fcon.email.value,
      telephone: this.fcon.tel.value,
      ebookno: this.fcon.noebook.value,
      message: this.fcon.message.value
    };

    const basebath = AppConstants.baseURL + '/postData/postmessagecontact.php';
    this.httpClient.post<any>(basebath, data).subscribe(
      (res) =>  {
        // this.reponseEditCompte = res;
        if (res === 11) {
          this.messageOKAffiche =
          'Votre message a bien été envoyé à ebook-card.<br />Nous vous répondrons dans les plus brefs délais.<br />L\'équipe ebook-card';
          this.envoyeOK = true;
        } else {
          this.messageOKAffiche = 'Une erreur s\'est produite.<br />Veuillez encore essayer plus tard';
          this.envoyeOK = false;
        }
        console.table('Réponse: ' + res);
      },
      (err) => console.log('Error: ' + err),
    );
  }

}

import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-conditions-general',
  templateUrl: './conditions-general.component.html',
  styleUrls: ['./conditions-general.component.css']
})
export class ConditionsGeneralComponent implements OnInit {

  entreprise = "Concept Card Sàrl"

  constructor(private titleService: Title,) { titleService.setTitle("Conditions générales de vente");}

  ngOnInit() {
  }

}

import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { PartnerInfoServiceService } from '../_services';
import { Subscription } from 'rxjs';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { AppConstants } from '../_helpers/app.constants';
import { AuthenticationService } from '../_services';
import { User } from '../_models';

@Component({
  selector: 'app-editpartner',
  templateUrl: './editpartner.component.html',
  styleUrls: ['./editpartner.component.css']
})
export class EditpartnerComponent implements OnInit {
  @Input() idPartner: string;
  @Input() isResto: number;
  @Input() idObjet: number;
  @Output() showDetails = new EventEmitter<boolean>();
  interval: Subscription;
  lesInfos: any;
  infosPresta = [];
  name: string;
  dateNow: string;
  general = [];
  nbMinPersonnes: number;
  nbMaxPersonnes: number;
  reservationDelay: number;
  valueFormChange = false;
  valueFormCalendrierChange = false;
  valueFormTypesChange = false;
  valueFormBaseChange = false;
  valueFormImagesChange = false;
  reponseEditCompte: number;
  menuGeneral = true;
  exceptions = [];
  fermeture = [];
  vacances = [];
  types = [];
  resaPassees = [];
  resaFutur = [];
  resaPasseesNb: number;
  resaFuturNb: number;
  currentUser: User;
  currentUserId: string;
  leNomPrestataire: string;
  model;
  nom: string;
  adresse1: string;
  adresse2: string;
  npa: number;
  localite: string;
  cantons = [];
  email: string;
  siteweb: string;
  mobilephone: number;
  homephone: number;
  fax: number;
  lesStatus = [];
  status: number;
  gammeprix = [];
  description: string;
  conditions: string;
  descriptionId: string;
  conditionsId: string;
  leCanton: string;
  prix: string;
  leCantonStr: string;
  actif: number;
  prixListeAdultes = [];
  prixListeEnfants = [];
  listeOuverture = [];
  listeAvantages = [];
  listeHoraires = [];
  listeMenus = [];
  listeMenusNb: number;
  listeEquipements = [];
  equipementsId = [];
  reservationMode: number;
  accountInfos = [];
  arrReservationMode = [];
  ccp: string;
  iban: string;
  tva: string;
  imageURL01: string;
  imageURL02: string;
  imageURL03: string;
  imageURL04: string;
  imageURL05: string;
  messageErrorImg: string;
  arrPhotos = [];
  img01Update = false;
  img02Update = false;
  img03Update = false;
  img04Update = false;
  img05Update = false;
  title = 'AGM project';
  latitude: number;
  longitude: number;
  zoom = 15;
  lesCommissionsOld = [];
  lesCommissionsWaiting = [];
  commissionsWaitingNb: number;
  commissionsOldNb: number;
  ordersNb: number;
  ordersPrice: number;
  lesOrders = [];

  constructor(private infoPartner: PartnerInfoServiceService,
              private httpClient: HttpClient,
              private formBuilder: FormBuilder,
              private authenticationService: AuthenticationService) {
  }
  formGeneral;
  formCalendrier;
  formTypes;
  formBase;
  formBaseDebut;
  formBaseCentre;
  formBaseFin;
  formMap;
  submitted = false;
  formImages: FormGroup;

  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  download = false;


   ngOnInit() {
      // console.log('INIT');
      this.authenticationService.currentUser.subscribe(x => {
        this.currentUser = x;
        this.currentUserId = x.id;
        }
      );
      this.getPrestaDetails();
      this.getCommissions();
      this.formGeneral = new FormGroup({
        generalMatin0: new FormControl(''), generalMidi0: new FormControl(''), generalSoir0: new FormControl(''),
        generalMatin1: new FormControl(''), generalMidi1: new FormControl(''), generalSoir1: new FormControl(''),
        generalMidi2: new FormControl(''),  generalMatin2: new FormControl(''), generalSoir2: new FormControl(''),
        generalMatin3: new FormControl(''), generalMidi3: new FormControl(''), generalSoir3: new FormControl(''),
        generalMatin4: new FormControl(''), generalMidi4: new FormControl(''), generalSoir4: new FormControl(''),
        generalMatin5: new FormControl(''), generalMidi5: new FormControl(''), generalSoir5: new FormControl(''),
        generalMatin6: new FormControl(''), generalMidi6: new FormControl(''), generalSoir6: new FormControl(''),
        generalNbMin: new FormControl(''), generalNbMax: new FormControl(''), generalDelai: new FormControl(''),
        generalExcDate0: new FormControl(''), generalExcMatin0: new FormControl(''), generalExcMidi0: new FormControl(''),
        generalExcSoir0: new FormControl(''), generalExcHiden0: new FormControl(''), generalExcDate1: new FormControl(),
        generalExcMatin1: new FormControl(''), generalExcMidi1: new FormControl(''), generalExcSoir1: new FormControl(''),
        generalExcHiden1: new FormControl(''), generalExcDate2: new FormControl(), generalExcMatin2: new FormControl(''),
        generalExcMidi2: new FormControl(''), generalExcSoir2: new FormControl(''), generalExcHiden2: new FormControl(''),
        generalExcDate3: new FormControl(''), generalExcMatin3: new FormControl(''), generalExcMidi3: new FormControl(''),
        generalExcSoir3: new FormControl(''), generalExcHiden3: new FormControl('')
      });
      this.formCalendrier = new FormGroup({
        vacancesDebut0: new FormControl(''), vacancesFin0: new FormControl(''), vacancesHiden0: new FormControl(''),
        vacancesDebut1: new FormControl(''), vacancesFin1: new FormControl(''), vacancesHiden1: new FormControl(''),
        vacancesDebut2: new FormControl(''), vacancesFin2: new FormControl(''), vacancesHiden2: new FormControl(''),
        vacancesDebut3: new FormControl(''), vacancesFin3: new FormControl(''), vacancesHiden3: new FormControl(''),
        vacancesDebut4: new FormControl(''), vacancesFin4: new FormControl(''), vacancesHiden4: new FormControl(''),
        fermeMatin0: new FormControl(''), fermeMidi0: new FormControl(''), fermeSoir0: new FormControl(''),
        fermeHiden0: new FormControl(''), fermeMatin1: new FormControl(''), fermeMidi1: new FormControl(''),
        fermeSoir1: new FormControl(''), fermeHiden1: new FormControl(''), fermeMatin2: new FormControl(''),
        fermeMidi2: new FormControl(''), fermeSoir2: new FormControl(''), fermeHiden2: new FormControl(''),
        fermeMatin3: new FormControl(''), fermeMidi3: new FormControl(''), fermeSoir3: new FormControl(''),
        fermeHiden3: new FormControl(''), fermeMatin4: new FormControl(''), fermeMidi4: new FormControl(''),
        fermeSoir4: new FormControl(''), fermeHiden4: new FormControl(''), fermeMatin5: new FormControl(''),
        fermeMidi5: new FormControl(''), fermeSoir5: new FormControl(''), fermeHiden5: new FormControl(''),
        fermeMatin6: new FormControl(''), fermeMidi6: new FormControl(''), fermeSoir6: new FormControl(''),
        fermeHiden6: new FormControl('')
      });
      this.formTypes = new FormGroup({
        type0: new FormControl(''), type1: new FormControl(''), type2: new FormControl(''), type3: new FormControl(''),
        type4: new FormControl(''), type5: new FormControl(''), type6: new FormControl(''), type7: new FormControl(''),
        typeHiden0: new FormControl(''), typeHiden1: new FormControl(''), typeHiden2: new FormControl(''), typeHiden3: new FormControl(''),
        typeHiden4: new FormControl(''), typeHiden5: new FormControl(''), typeHiden6: new FormControl(''), typeHiden7: new FormControl(''),

      });
      this.formBase = this.formBuilder.group({
        Id: [''],
        formBaseDebut: this.formBuilder.group({
          Name: ['', Validators.required],
          Address1: ['', Validators.required],
          Address2: [''],
          ZipCode: ['', [Validators.required, Validators.minLength(4)]],
          Locality: ['', Validators.required],
          Canton: ['', Validators.required],
          EmailAddress: ['', Validators.email],
          WebSite: [''], MobilPhone: [''],
          HomePhone: ['', Validators.required],
          Fax: [''], Status: [''],
          PriceRange: [''],
          description: [''], conditions: ['']
        }),
        formBaseCentre: this.formBuilder.group({
          prixEntreeA: [''], prixReducA: [''],
          prixAgeMinA: [''], prixAgeMaxA: [''],
          prixHidenA: [''],
          prixEntreeE: [''], prixReducE: [''],
          prixAgeMinE: [''], prixAgeMaxE: [''],
          prixHidenE: [''],
          ouverture0: [''], ouverture1: [''],
          ouverture2: [''], ouverture3: [''],
          ouverture4: [''], ouvertureId: [''],
          horaire0: [''], horaire1: [''],
          horaire2: [''], horaire3: [''],
          horaire4: [''], horaireId: [''],
          avantage0: [''], avantage1: [''],
          avantage2: [''], avantage3: [''],
          avantage4: [''], avantageId: [''],
          menu0: [''], menu1: [''],
          menu2: [''], menu3: [''],
          menu4: [''], menu5: [''],
          menu6: [''], menu7: [''],
          menu8: [''], menu9: [''],
          menu10: [''], menu11: [''],
          menu12: [''], menu13: [''],
          menu14: [''], menu15: [''],
          menu16: [''], menu17: [''],
          menu18: [''], menu19: [''],
          menu20: [''], menu21: [''], menuId: ['']
        }),
        formBaseFin: this.formBuilder.group({
          actif: [''],
          modeTel: [''], modeSMS: [''], modeEmail: [''], equip1: [''],
          equip2: [''], equip4: [''], equip8: [''], equip16: [''], equip32: [''], equip64: [''], equip128: [''], equip256: [''],
          equip512: [''], equip1024: [''], equip2048: [''], equip4096: [''], equip8192: [''],
          tva: [''], iban: [''], ccp: [''], accountInfoId: [''],
        }),
      });

      this.formImages = this.formBuilder.group({
        img01: [null],
        img01id: [null],
        img02: [null],
        img02id: [null],
        img03: [null],
        img03id: [null],
        img04: [null],
        img04id: [null],
        img05: [null],
        img05id: [null]
      });

      this.formMap = this.formBuilder.group({
        adresse1: [null],
        adresse2: [null],
        npa: [null],
        localite: [null],
        canton: [null],
        longitude: [null],
        latitude: [null]
      });


      this.formGeneral.valueChanges.subscribe(selectedValue  => {
        // console.log('form value changed');
        this.valueFormChange = true;
      });

      this.formCalendrier.valueChanges.subscribe(selectedValue  => {
        // console.log('form value changed');
        this.valueFormCalendrierChange = true;
      });

      this.formTypes.valueChanges.subscribe(selectedValue  => {
        // console.log('form value changed');
        this.valueFormTypesChange = true;
      });

      this.formBase.valueChanges.subscribe(selectedValue  => {
        // console.log('form value changed');
        this.valueFormBaseChange = true;
      });

      this.formImages.valueChanges.subscribe(selectedValue  => {
        // console.log('form value changed');
        this.valueFormImagesChange = true;
      });
      // this.setCurrentLocation();
    }
    get base() {
      // console.table(this.formBase.controls.formBaseDebut.controls);
      return this.formBase.controls.formBaseDebut.controls;
    }

    showPreview1(event) {
      const file = (event.target as HTMLInputElement).files[0];
      this.formImages.patchValue({
        img01: file
      });
      this.formImages.get('img01').updateValueAndValidity();
      // File Preview
      const reader = new FileReader();
      reader.onload = () => {
        this.imageURL01 = reader.result as string;
      };
      reader.readAsDataURL(file);
      this.valueFormImagesChange = true;
      this.img01Update = true;
    }
    showPreview2(event) {
      const file = (event.target as HTMLInputElement).files[0];
      this.formImages.patchValue({
        img02: file
      });
      this.formImages.get('img02').updateValueAndValidity();
      // File Preview
      const reader = new FileReader();
      reader.onload = () => {
        this.imageURL02 = reader.result as string;
      };
      reader.readAsDataURL(file);
      this.valueFormImagesChange = true;
      this.img02Update = true;
    }
    showPreview3(event) {
      const file = (event.target as HTMLInputElement).files[0];
      this.formImages.patchValue({
        img03: file
      });
      this.formImages.get('img03').updateValueAndValidity();
      // File Preview
      const reader = new FileReader();
      reader.onload = () => {
        this.imageURL03 = reader.result as string;
      };
      reader.readAsDataURL(file);
      this.valueFormImagesChange = true;
      this.img03Update = true;
    }
    showPreview4(event) {
      const file = (event.target as HTMLInputElement).files[0];
      this.formImages.patchValue({
        img04: file
      });
      this.formImages.get('img04').updateValueAndValidity();
      // File Preview
      const reader = new FileReader();
      reader.onload = () => {
        this.imageURL04 = reader.result as string;
      };
      reader.readAsDataURL(file);
      this.valueFormImagesChange = true;
      this.img04Update = true;
    }
    showPreview5(event) {
      const file = (event.target as HTMLInputElement).files[0];
      this.formImages.patchValue({
        img05: file
      });
      this.formImages.get('img05').updateValueAndValidity();
      // File Preview
      const reader = new FileReader();
      reader.onload = () => {
        this.imageURL05 = reader.result as string;
      };
      reader.readAsDataURL(file);
      this.valueFormImagesChange = true;
      this.img05Update = true;
    }

  getCommissions() {
    console.log('Current ID: ' + this.currentUserId);
    const isRestoStr = this.isResto.toString();
    const idObjetStr = this.idObjet.toString();
    this.interval = this.infoPartner.getCommissions(this.currentUserId, idObjetStr, isRestoStr)
          .subscribe(commi => {
            this.lesCommissionsOld = commi[0].commissionsold;
            this.lesCommissionsWaiting = commi[0].commissionswaiting;
            this.ordersNb = commi[0].ordersnb;
            this.ordersPrice = commi[0].ordersprice/2;
            this.lesOrders = commi[0].orders;
            this.commissionsWaitingNb = this.lesCommissionsWaiting.length;
            this.commissionsOldNb = this.lesCommissionsOld.length;
          });
          
  }

   getPrestaDetails(): void {

    this.interval = this.infoPartner.servPrestaDetail(this.idPartner, this.isResto, this.idObjet)
        .subscribe(infospartenaire => {
          this.lesInfos = infospartenaire;
          this.general = this.lesInfos[0].general;
          this.nbMinPersonnes = this.lesInfos[0].min;
          this.nbMaxPersonnes = this.lesInfos[0].max;
          this.reservationDelay = this.lesInfos[0].delai;
          this.leNomPrestataire = this.lesInfos[0].name;
          this.exceptions = this.lesInfos[0].exceptions;
          this.vacances = this.lesInfos[0].vacances;
          this.fermeture = this.lesInfos[0].fermeture;
          this.types = this.lesInfos[0].types;
          this.resaPassees = this.lesInfos[0].reservationspasse;
          this.resaFutur = this.lesInfos[0].reservations;
          this.resaPasseesNb = this.resaPassees.length;
          this.resaFuturNb = this.resaFutur.length;
          this.nom = this.lesInfos[0].name;
          this.adresse1 = this.lesInfos[0].adresse1;
          this.adresse2 = this.lesInfos[0].adresse2;
          this.npa = this.lesInfos[0].npa;
          this.localite = this.lesInfos[0].localite;
          this.cantons = this.lesInfos[0].cantons;
          this.leCanton = this.lesInfos[0].canton;
          this.latitude = + this.lesInfos[0].latitude;
          this.longitude = + this.lesInfos[0].longitude;
          this.email = this.lesInfos[0].email;
          this.siteweb = this.lesInfos[0].website;
          this.mobilephone = this.lesInfos[0].mobilephone;
          this.homephone = this.lesInfos[0].homephone;
          this.fax = this.lesInfos[0].fax;
          this.lesStatus = this.lesInfos[0].lesStatus;
          this.status = + this.lesInfos[0].status;
          this.gammeprix = this.lesInfos[0].gammeprix;
          this.prix = this.lesInfos[0].prix;
          this.description = this.lesInfos[0].description;
          this.conditions = this.lesInfos[0].conditions;
          this.descriptionId = this.lesInfos[0].descriptionId;
          this.conditionsId = this.lesInfos[0].conditionsId;

          this.prixListeAdultes = this.lesInfos[0].listeprix.adult;
          this.prixListeEnfants = this.lesInfos[0].listeprix.enfant;
          this.listeOuverture = this.lesInfos[0].ouverture;
          this.listeAvantages = this.lesInfos[0].texteligne1;
          this.listeHoraires = this.lesInfos[0].horaires;
          this.listeMenus = this.lesInfos[0].menu;
          this.listeMenusNb = this.listeMenus.length - 1;
          this.actif = + this.lesInfos[0].active;
          this.reservationMode = this.lesInfos[0].reservationMode;
          this.listeEquipements = this.lesInfos[0].lesEquipements;
          this.equipementsId = this.lesInfos[0].listeequipments;
          this.accountInfos = this.lesInfos[0].accountInfos;
          this.arrReservationMode = this.lesInfos[0].reservationMode;
          this.arrPhotos = this.lesInfos[0].photos;

          // console.table(['PRix: ', this.prixListeAdultes[0].Price] );

          this.formMap.patchValue({
              adresse1: this.adresse1,
              adresse2: this.adresse2,
              npa: this.npa,
              localite: this.localite,
              canton: this.leCanton,
              latitude: this.latitude,
              longitude: this.longitude,
          });

          // Mise à jour donnée ID poour Base infos
          this.formBase.patchValue({
            formBaseDebut: {
              Description_Id: this.lesInfos[0].descriptionId,
              SpecialCondition_Id: this.lesInfos[0].conditionsId,
              Name: this.nom,
              Address1: this.adresse1,
              Address2: this.adresse2,
              ZipCode: this.npa,
              Locality: this.localite,
              Canton: this.leCanton,
              EmailAddress: this.email,
              WebSite: this.siteweb,
              MobilPhone: this.mobilephone,
              HomePhone: this.homephone,
              Fax: this.fax,
              Status: this.status,
              PriceRange: this.prix
            }
          });
          this.valueFormBaseChange = false;
          // Uniquement pour Loisirs
          if (this.isResto === 0) {
            this.formBase.patchValue({
              formBaseCentre: {
                prixEntreeA: this.prixListeAdultes[0].Price,
                prixReducA: this.prixListeAdultes[0].DiscountPrice,
                prixAgeMinA: this.prixListeAdultes[0].AgeMin,
                prixAgeMaxA: this.prixListeAdultes[0].AgeMax,
                prixHidenA: this.prixListeAdultes[0].Id,
                prixEntreeE: this.prixListeEnfants[0].Price,
                prixReducE: this.prixListeEnfants[0].DiscountPrice,
                prixAgeMinE: this.prixListeEnfants[0].AgeMin,
                prixAgeMaxE: this.prixListeEnfants[0].AgeMax,
                prixHidenE: this.prixListeEnfants[0].Id,
                horaire0: this.listeHoraires[0],
                horaire1: this.listeHoraires[1],
                horaire2: this.listeHoraires[2],
                horaire3: this.listeHoraires[3],
                horaire4: this.listeHoraires[4],
                horaireId: this.lesInfos[0].horairesId,
                ouverture0: this.listeOuverture[0],
                ouverture1: this.listeOuverture[1],
                ouverture2: this.listeOuverture[2],
                ouverture3: this.listeOuverture[3],
                ouverture4: this.listeOuverture[4],
                ouvertureId: this.lesInfos[0].ouvertureId,
                avantage0: this.listeAvantages[0],
                avantage1: this.listeAvantages[1],
                avantage2: this.listeAvantages[2],
                avantage3: this.listeAvantages[3],
                avantage4: this.listeAvantages[4],
                avantageId: this.lesInfos[0].texteligne1Id,
              }
            });
            this.valueFormBaseChange = false;
          } else {  // Que resto
            this.formBase.patchValue({
              formBaseCentre: {
                menu0: this.listeMenus[0],
                menu1: this.listeMenus[1],
                menu2: this.listeMenus[2],
                menu3: this.listeMenus[3],
                menu4: this.listeMenus[4],
                menu5: this.listeMenus[5],
                menu6: this.listeMenus[6],
                menu7: this.listeMenus[7],
                menu8: this.listeMenus[8],
                menu9: this.listeMenus[9],
                menu10: this.listeMenus[10],
                menu11: this.listeMenus[11],
                menu12: this.listeMenus[12],
                menu13: this.listeMenus[13],
                menu14: this.listeMenus[14],
                menu15: this.listeMenus[15],
                menu16: this.listeMenus[16],
                menu17: this.listeMenus[17],
                menu18: this.listeMenus[18],
                menu19: this.listeMenus[19],
                menu20: this.listeMenus[20],
                menu21: this.listeMenus[21],

                menuId: this.lesInfos[0].menuId
              }
            });
            this.valueFormBaseChange = false;
          }
          // Commun loisirs et restos
          this.formBase.patchValue({
            formBaseFin: {
              ccp: this.accountInfos[0].Ccp,
              iban: this.accountInfos[0].IBAN,
              tva: this.accountInfos[0].NoTVA,
              accountInfoId: this.accountInfos[0].Id,
              actif: this.actif,
              modeTel: this.arrReservationMode[0].modeTel,
              modeSMS: this.arrReservationMode[0].modeSMS,
              modeEmail: this.arrReservationMode[0].modeEmail,
            }
          });
          this.valueFormBaseChange = false;


          // Boucle pour mise à jour Vacances
          for (let i = 0; i < this.arrPhotos.length; i++) {
            if (i === 0) {
              this.imageURL01 = this.arrPhotos[i].Url;
              this.formImages.patchValue({
                img01id: this.arrPhotos[i].Id
              });
            } else if (i === 1) {
              this.imageURL02 = this.arrPhotos[i].Url;
              this.formImages.patchValue({
                img02id: this.arrPhotos[i].Id
              });
            } else if (i === 2) {
              this.imageURL03 = this.arrPhotos[i].Url;
              this.formImages.patchValue({
                img03id: this.arrPhotos[i].Id
              });
            } else if (i === 3) {
              this.imageURL04 = this.arrPhotos[i].Url;
              this.formImages.patchValue({
                img04id: this.arrPhotos[i].Id
              });
            } else if (i === 4) {
              this.imageURL05 = this.arrPhotos[i].Url;
              this.formImages.patchValue({
                img05id: this.arrPhotos[i].Id
              });
            }
            this.valueFormImagesChange = false;
          }
          // Boucle pour mise à jour Equipements
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < this.listeEquipements.length; i++) {
            if (this.listeEquipements[i].id === 2) {
              this.formBase.patchValue({
                formBaseFin: {
                  equip2: 1
                }
              });
            } else if (this.listeEquipements[i].id === 4 && this.listeEquipements[i].isChecked === 1) {
              this.formBase.patchValue({
                formBaseFin: {
                  equip4: 1
                }
              });
            } else if (this.listeEquipements[i].id === 8 && this.listeEquipements[i].isChecked === 1) {
              this.formBase.patchValue({
                formBaseFin: {
                  equip8: 1
                }
              });
            } else if (this.listeEquipements[i].id === 16 && this.listeEquipements[i].isChecked === 1) {
              this.formBase.patchValue({
                formBaseFin: {
                  equip16: 1
                }
              });
            } else if (this.listeEquipements[i].id === 32 && this.listeEquipements[i].isChecked === 1) {
              this.formBase.patchValue({
                formBaseFin: {
                  equip32: 1
                }
              });
            } else if (this.listeEquipements[i].id === 64 && this.listeEquipements[i].isChecked === 1) {
              this.formBase.patchValue({
                formBaseFin: {
                  equip64: 1
                }
              });
            } else if (this.listeEquipements[i].id === 128 && this.listeEquipements[i].isChecked === 1) {
              this.formBase.patchValue({
                formBaseFin: {
                  equip128: 1
                }
              });
            } else if (this.listeEquipements[i].id === 256 && this.listeEquipements[i].isChecked === 1) {
              this.formTypes.patchValue({
                equip256: 1,
              });
            } else if (this.listeEquipements[i].id === 512 && this.listeEquipements[i].isChecked === 1) {
              this.formBase.patchValue({
                formBaseFin: {
                  equip512: 1
                }
              });
            } else if (this.listeEquipements[i].id === 1024 && this.listeEquipements[i].isChecked === 1) {
              this.formBase.patchValue({
                formBaseFin: {
                  equip1024: 1
                }
              });
            } else if (this.listeEquipements[i].id === 2048 && this.listeEquipements[i].isChecked === 1) {
              this.formBase.patchValue({
                formBaseFin: {
                  equip2048: 1
                }
              });
            } else if (this.listeEquipements[i].id === 4096 && this.listeEquipements[i].isChecked === 1) {
              this.formBase.patchValue({
                formBaseFin: {
                  equip4096: 1
                }
              });
            } else if (this.listeEquipements[i].id === 8192 && this.listeEquipements[i].isChecked === 1) {
              this.formBase.patchValue({
                formBaseFin: {
                  equip8192: 1
                }
              });
            }
            this.valueFormTypesChange = false;

          }
          this.valueFormBaseChange = false;

          // Boucle pour mise à jour Exceptions
          for (let i = 0; i < this.types.length; i++) {
            if (i === 0) {
              this.formTypes.patchValue({
                typeHiden0: this.types[i].id,
              });
            } else if (i === 1) {
              this.formTypes.patchValue({
                typeHiden1: this.types[i].id,
              });
            } else if (i === 2) {
              this.formTypes.patchValue({
                typeHiden2: this.types[i].id,
              });
            } else if (i === 3) {
              this.formTypes.patchValue({
                typeHiden3: this.types[i].id,
              });
            } else if (i === 4) {
              this.formTypes.patchValue({
                typeHiden4: this.types[i].id,
              });
            } else if (i === 5) {
              this.formTypes.patchValue({
                typeHiden5: this.types[i].id,
              });
            } else if (i === 6) {
              this.formTypes.patchValue({
                typeHiden6: this.types[i].id,
              });
            } else if (i === 7) {
              this.formTypes.patchValue({
                typeHiden7: this.types[i].id,
              });
            } else if (i === 8) {
              this.formTypes.patchValue({
                typeHiden8: this.types[i].id,
              });
            } else if (i === 9) {
              this.formTypes.patchValue({
                typeHiden9: this.types[i].id,
              });
            }
            this.valueFormTypesChange = false;
          }

          // Boucle pour mise à jour Fermetures
          console.table(this.fermeture);
          
          for (let i = 0; i <= 6; i++) {
            if (this.fermeture[i].day === '0') {
              this.formCalendrier.patchValue({
                fermeMatin0: +this.fermeture[i].morning,
                fermeMidi0: +this.fermeture[i].midday,
                fermeSoir0: +this.fermeture[i].evening,
                fermeHiden0: this.fermeture[i].id
              });
            } else if (this.fermeture[i].day === '1') {
              this.formCalendrier.patchValue({
                fermeMatin1: +this.fermeture[i].morning,
                fermeMidi1: +this.fermeture[i].midday,
                fermeSoir1: +this.fermeture[i].evening,
                fermeHiden1: this.fermeture[i].id
              });
            } else if (this.fermeture[i].day === '2') {
              this.formCalendrier.patchValue({
                fermeMatin2: +this.fermeture[i].morning,
                fermeMidi2: +this.fermeture[i].midday,
                fermeSoir2: +this.fermeture[i].evening,
                fermeHiden2: this.fermeture[i].id
              });
            } else if (this.fermeture[i].day === '3') {
              this.formCalendrier.patchValue({
                fermeMatin3: +this.fermeture[i].morning,
                fermeMidi3: +this.fermeture[i].midday,
                fermeSoir3: +this.fermeture[i].evening,
                fermeHiden3: this.fermeture[i].id
              });
            } else if (this.fermeture[i].day === '4') {
              this.formCalendrier.patchValue({
                fermeMatin4: +this.fermeture[i].morning,
                fermeMidi4: +this.fermeture[i].midday,
                fermeSoir4: +this.fermeture[i].evening,
                fermeHiden4: this.fermeture[i].id
              });
            } else if (this.fermeture[i].day === '5') {
              this.formCalendrier.patchValue({
                fermeMatin5: +this.fermeture[i].morning,
                fermeMidi5: +this.fermeture[i].midday,
                fermeSoir5: +this.fermeture[i].evening,
                fermeHiden5: this.fermeture[i].id
              });
            } else if (this.fermeture[i].day === '6') {
              this.formCalendrier.patchValue({
                fermeMatin6: +this.fermeture[i].morning,
                fermeMidi6: +this.fermeture[i].midday,
                fermeSoir6: +this.fermeture[i].evening,
                fermeHiden6: this.fermeture[i].id
              });
            }
            this.valueFormCalendrierChange = false;
          } 

          // Boucle pour mise à jour Vacances
          for (let i = 0; i < this.vacances.length; i++) {
            if (i === 0) {
              this.formCalendrier.patchValue({
                vacancesDebut0: this.vacances[i].startdate,
                vacancesFin0: this.vacances[i].enddate,
                vacancesHiden0: this.vacances[i].id
              });
            } else if (i === 1) {
              this.formCalendrier.patchValue({
                vacancesDebut1: this.vacances[i].startdate,
                vacancesFin1: this.vacances[i].enddate,
                vacancesHiden1: this.vacances[i].id
              });
            } else if (i === 2) {
              this.formCalendrier.patchValue({
                vacancesDebut2: this.vacances[i].startdate,
                vacancesFin2: this.vacances[i].enddate,
                vacancesHiden2: this.vacances[i].id
              });
            } else if (i === 3) {
              this.formCalendrier.patchValue({
                vacancesDebut3: this.vacances[i].startdate,
                vacancesFin3: this.vacances[i].enddate,
                vacancesHiden3: this.vacances[i].id
              });
            } else if (i === 4) {
              this.formCalendrier.patchValue({
                vacancesDebut4: this.vacances[i].startdate,
                vacancesFin4: this.vacances[i].enddate,
                vacancesHiden4: this.vacances[i].id
              });
            }
            this.valueFormCalendrierChange = false;
          }
          // Boucle pour mise à jour Exceptions
          for (let i = 0; i < this.exceptions.length; i++) {
            if (i === 0) {
              this.formGeneral.patchValue({
                generalExcMatin0: this.exceptions[i].matin,
                generalExcDate0: this.exceptions[i].date,
                generalExcMidi0: this.exceptions[i].midi,
                generalExcSoir0: this.exceptions[i].soir,
                generalExcHiden0: this.exceptions[i].id,
              });
            } else if (i === 1) {
              this.formGeneral.patchValue({
                generalExcMatin1: this.exceptions[i].matin,
                generalExcDate1: this.exceptions[i].date,
                generalExcMidi1: this.exceptions[i].midi,
                generalExcSoir1: this.exceptions[i].soir,
                generalExcHiden1: this.exceptions[i].id,
              });
            } else if (i === 2) {
              this.formGeneral.patchValue({
                generalExcMatin2: this.exceptions[i].matin,
                generalExcDate2: this.exceptions[i].date,
                generalExcMidi2: this.exceptions[i].midi,
                generalExcSoir2: this.exceptions[i].soir,
                generalExcHiden2: this.exceptions[i].id,
              });
            } else if (i === 3) {
              this.formGeneral.patchValue({
                generalExcMatin3: this.exceptions[i].matin,
                generalExcDate3: this.exceptions[i].date,
                generalExcMidi3: this.exceptions[i].midi,
                generalExcSoir3: this.exceptions[i].soir,
                generalExcHiden3: this.exceptions[i].id,
              });
            }
            this.valueFormChange = false;
          }
        });

  }

// Fonction submit form de la galerie d'images
submitImages() {
  this.download = true;
  // this.submitted = true;

  if (this.formImages.invalid) {
    return;
  }

  const formData: any = new FormData();
  formData.append('img01', this.formImages.get('img01').value);
  formData.append('img02', this.formImages.get('img02').value);
  formData.append('img03', this.formImages.get('img03').value);
  formData.append('img04', this.formImages.get('img04').value);
  formData.append('img05', this.formImages.get('img05').value);
  formData.append('img01id', this.formImages.value.img01id);
  formData.append('img02id', this.formImages.value.img02id);
  formData.append('img03id', this.formImages.value.img03id);
  formData.append('img04id', this.formImages.value.img04id);
  formData.append('img05id', this.formImages.value.img05id);

  const myGets = '&id=' + this.idObjet + '&resto=' + this.isResto;
  // console.table(this.formImages.value);
  // console.table(formData.value);

  const basebath = AppConstants.baseURL + '/postPartnerModif/postPhotos.php?token=cPnFKCqNQLKw' + myGets;
  this.httpClient.post<any>(basebath, formData).subscribe(
    (res) =>  {
      // this.reponseEditCompte = res;
      if (res === 11) {
        this.retourListePartenaire();
      } else if (res === 55) {
        this.messageErrorImg = 'Aucune photo envoyée';
      } else if (res === 66 || res === 65) {
        this.messageErrorImg = 'Problème lors de l\'envoie de la photo';
      } else if (res === 77) {
        this.messageErrorImg = 'Erreur lors de l\'envoie de la photo';
      } else if (res === 88) {
        this.messageErrorImg = 'Aucune erreur détectée';
      }

      // console.table('Réponse: ' + res);
      this.download = false;
    },
    (err) => console.table('Error: ' + err),
  );

}

// Fonction lors de l'enregistrement des infos de base
submitBase() {
  // console.table(this.formBase.value.formBaseCentre);
  // console.table(this.formBase.value.formBaseDebut);
  // console.table(this.formBase.value.formBaseFin);
  this.submitted = true;

  if (this.formBase.invalid) {
    return;
  }

  const data = {
    formvalue: this.formBase.value.formBaseDebut,
    formvalueCentre: this.formBase.value.formBaseCentre,
    formvalueFin: this.formBase.value.formBaseFin,
    isResto: this.isResto,
    idObjet: this.idObjet,
    idPartner: this.idPartner,
    descriptionId: this.descriptionId,
    conditionsId: this.conditionsId
  };

  const basebath = AppConstants.baseURL + '/postPartnerModif/postBaseInfos.php?token=cPnFKCqNQLKw';
  this.httpClient.post<any>(basebath, data).subscribe(
    (res) =>  {
      // this.reponseEditCompte = res;
      if (res === 11) {
        // this.retourListePartenaire();
        this.getPrestaDetails();
      }
      // console.table('Réponse: ' + res);
    },
    (err) => console.log('Error: ' + err),
  );
}

// Fonction lors de l'enregistrement du type de loisirs / cuisine
submitTypes() {
 //  console.table(this.formTypes.value);

  const data = {
    formvalue: this.formTypes.value,
    isResto: this.isResto,
    idObjet: this.idObjet,
    idPartner: this.idPartner,
  };

  const basebath = AppConstants.baseURL + '/postPartnerModif/postTypes.php?token=cPnFKCqNQLKw';
  this.httpClient.post<any>(basebath, data).subscribe(
    (res) =>  {
      // this.reponseEditCompte = res;
      if (res === 11) {
        // this.retourListePartenaire();
        this.getPrestaDetails();
      }
      // console.table('Réponse: ' + res);
    },
    (err) => console.log('Error: ' + err),
  );

}

// Fonction lors de l'enregistrement des infos Vacances et fermeture
submitCalendrier() {
  const values = this.formCalendrier.value;
  // console.table(this.formCalendrier.value);

  const data = {
    formvalue: this.formCalendrier.value,
    isResto: this.isResto,
    idObjet: this.idObjet,
    idPartner: this.idPartner,
  };

  const basebath = AppConstants.baseURL + '/postPartnerModif/postVacances-fermeture.php?token=cPnFKCqNQLKw';
  this.httpClient.post<any>(basebath, data).subscribe(
    (res) =>  {
      // this.reponseEditCompte = res;
      if (res === 11) {
        // this.showCompteEdit = !this.showCompteEdit;
        // this.getUserData(this.currentUser.id);
        // this.retourListePartenaire();
        this.getPrestaDetails();
      }
      // console.table('Réponse: ' + res);
    },
    (err) => console.log('Error: ' + err),
  );

}

  // Fonction lors de l'enregistrement des General
  submitGeneral() {
    const values = this.formGeneral.value;
    // console.table(this.formGeneral.value);

    const arrExceptions: string[][] =
    [['x', 'x', 'x', 'x', 'x'], ['x', 'x', 'x', 'x', 'x'], ['x', 'x', 'x', 'x', 'x'], ['x', 'x', 'x', 'x', 'x']];

    const semaine: string[][] =
    [['x', 'x', 'x'], ['x', 'x', 'x'], ['x', 'x', 'x'], ['x', 'x', 'x'], ['x', 'x', 'x'], ['x', 'x', 'x'], ['x', 'x', 'x']];

    for (const value in values) {
      if (values[value] !== '' && values[value] !== undefined) {
        for (let i = 0; i <= 6; i++) {
          if (value === ('generalMatin' + i)) {
            semaine[i][0] = values[value];
            // console.log('Matin' + i + 'modifié');
          } else if (value === ('generalMidi' + i)) {
            semaine[i][1] = values[value];
            // console.log('Midi' + i + 'modifié');
          } else if (value === ('generalSoir' + i)) {
            semaine[i][2] = values[value];
            // console.log('Soir' + i + 'modifié');
          } else if (value === ('generalExcDate' + i)) {
            arrExceptions[i][0] = values[value];
          } else if (value === ('generalExcMatin' + i)) {
            arrExceptions[i][1] = values[value];
          } else if (value === ('generalExcMidi' + i)) {
            arrExceptions[i][2] = values[value];
          } else if (value === ('generalExcSoir' + i)) {
            arrExceptions[i][3] = values[value];
          } else if (value === ('generalExcHiden' + i)) {
            arrExceptions[i][4] = values[value];
          } else if (value === ('generalNbMin')) {
            this.nbMinPersonnes = values[value];
          } else if (value === ('generalNbMax')) {
            this.nbMaxPersonnes = values[value];
          } else if (value === ('generalDelai')) {
            this.reservationDelay = values[value];
          }

        }
      }
    }
    // console.table(semaine);
    // console.table(arrExceptions);
    // console.table([this.nbMinPersonnes, this.nbMaxPersonnes, this.reservationDelay]);


    const data = {
      isResto: this.isResto,
      idObjet: this.idObjet,
      idPartner: this.idPartner,
      lasemaine: semaine,
      exceptions: arrExceptions,
      minPersonnes: this.nbMinPersonnes,
      maxPersonnes: this.nbMaxPersonnes,
      delayReservation: this.reservationDelay
    };

    const basebath = AppConstants.baseURL + '/postPartnerModif/postGeneral.php?token=cPnFKCqNQLKw';
    this.httpClient.post<any>(basebath, data).subscribe(
      (res) =>  {
        // this.reponseEditCompte = res;
        if (res === 11) {
          // this.retourListePartenaire();
          this.getPrestaDetails();
        }
        // console.log('Réponse: ' + res);
      },
      (err) => console.log('Error: ' + err),
    );

  }

  retourListePartenaire() {
    // console.log('Emitter enclanché');
    if (this.showDetails) {
      this.showDetails.emit(false);
    } else if (!this.showDetails) {
      this.showDetails.emit(true);
    }
  }

  tryitagain() {
    console.log('Tryit enclanché');
  }


}

<br />
<br />
<div class="col-md-6 offset-md-3 mt-5">
    <div class="container nouveauCpte text-center" *ngIf="nouveauCompteOK">
        <div  class="card">
            <h2 style="color: black;">Compte créé avec succès</h2>
            <p style="color: black;">Nous vous confirmons la création de votre compte.</p>
            <p style="color: black;">Un mail vous a été envoyé. Veuillez le consulter afin de confirmer votre adresse email. </p>
            <p></p>
            <p style="color: black;">Merci de votre confiance:<br /><em>L'équipe ebook-Card</em></p>
        </div>
    </div>
</div>
<div class="grid_container">
<div></div> <!--Permet de gérer le vide à gauche de la card-->
<div  class="card">
    <div class="container nouveauCpte" *ngIf="!nouveauCompteOK">
    <h2 style="color: black; background-color: white;text-align: center;" class="card-header">Devenir membre (gratuit)</h2>
    <br>
    <form [formGroup]="formBase" (ngSubmit)="submitBase()">
        <div class="form-group row">
        <label for="email" class="col-sm-12 col-form-label label_formul">Adresse email (identifiant):<span style="color: red; ">*</span></label>
        <div class="col-sm-12">
            <input type="text" class="form-control" formControlName="email">
            <p *ngIf="submitted && base.email.errors">
                <span *ngIf="base.email.errors.required" class="alert-danger">Identifiant obligatoire</span>
                <span *ngIf="base.email.errors" class="alert-danger">Veuillez indiquer une adresse email valide</span>
            </p>
            <p *ngIf="emailExist">
                <span class="alert-danger">{{emailExist}}</span>
            </p>
        </div>
        </div>
        <div class="form-group row">
        <label for="password" class="col-sm-12 col-form-label label_formul">Mot de passe:<span style="color: red; ">*</span></label>
        <div class="col-sm-12">
            <input type="password" class="form-control" formControlName="password">
            <p *ngIf="chackPassword && base.password.errors">
                <span *ngIf="base.password.errors.required" class="alert-danger">Mot de passe obligatoire</span>
                <span *ngIf="base.password.errors.minlength" class="alert-danger">Le mot de passe doit comporter au moins 6 caractères</span>
            </p>
        </div>
        </div>
        <div class="form-group row">
            <label for="repeatpassword" class="col-sm-12 col-form-label label_formul">Confirmation du mot de passe:<span style="color: red; ">*</span></label>
            <div class="col-sm-12">
                <input type="password" class="form-control" formControlName="repeatpassword">
                <p *ngIf="chackRepeatPassword && base.repeatpassword.errors">
                    <span *ngIf="base.repeatpassword.errors.required" class="alert-danger">Répéter le mot de passe</span>
                    <span *ngIf="base.repeatpassword.errors.mustMatch" class="alert-danger">Le mot de passe doit être le même les deux fois</span>
                </p>
            </div>
        </div>

        <div class="form-group row desktop"> <!--Classe deskop toute la div est invisble sur mobile-->
            <label for="firstName" class="col-sm-6 col-form-label label_formul">Nom:<span style="color: red; ">*</span></label>
            <label for="lastName" class="col-sm-6 col-form-label label_formul">Prénom:<span style="color: red; ">*</span></label>
            <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="firstName">
                <p *ngIf="submitted && base.firstName.errors">
                    <span  *ngIf="base.firstName.errors.required" class="alert-danger">Le nom est obligatoire</span>
                </p>
            </div>
            <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="lastName">
                <p *ngIf="submitted && base.lastName.errors">
                    <span  *ngIf="base.lastName.errors.required" class="alert-danger">Le prénom est obligatoire</span>
                </p>
            </div>
        </div>

        <!--Version mobile-->
        <div class="form-group row mobile">
            <label for="firstName" class="col-sm-12 col-form-label label_formul">Nom:<span style="color: red; ">*</span></label>
            <div class="col-sm-12">
                <input type="text" class="form-control" formControlName="firstName">
            </div>
            <p *ngIf="submitted && base.firstName.errors">
                <span  *ngIf="base.firstName.errors.required" class="alert-danger">Le nom est obligatoire</span>
            </p>
        </div>
        <div class="form-group row mobile">
            <label for="lastName" class="col-sm-12 col-form-label label_formul">Prénom:<span style="color: red; ">*</span></label>
            <div class="col-sm-12">
                <input type="text" class="form-control" formControlName="lastName">
                <p *ngIf="submitted && base.lastName.errors">
                    <span  *ngIf="base.lastName.errors.required" class="alert-danger">Le prénom est obligatoire</span>
                </p>
            </div>
        </div>
        <!--Fin version mobile-->

        <div class="form-group row desktop"> <!--Classe deskop toute la div est invisble sur mobile-->
            <label for="country" class="col-sm-4 col-form-label label_formul">Pays:<span style="color: red; ">*</span></label>
            <label for="mobilPhone" class="col-sm-8 col-form-label label_formul">Tél. mobile:<span style="color: red; ">*</span></label>
            
            <div class="col-sm-4">
                <select formControlName="country" class="form-control" value="0">
                    <option value="0" selected>Suisse</option>
                    <option value="1">France</option>
                    <option value="2">Allemagne</option>
                    <option value="3">Italie</option>
                </select>
                <p *ngIf="submitted && base.country.errors">
                    <span  *ngIf="base.country.errors.required" class="alert-danger">Le pays est obligatoire</span>
                </p>
            </div>
            <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="mobilPhone" placeholder="Exemple: 079 000 00 00">
                <p *ngIf="submitted && base.mobilPhone.errors">
                    <span  *ngIf="base.mobilPhone.errors.required" class="alert-danger">Le No de portable est obligatoire</span>
                </p>
                <p *ngIf="mobileExist">
                    <span class="alert-danger">{{mobileExist}}</span>
                </p>
            </div>
        </div>

        <!--Version mobile-->
        <div class="form-group row mobile">
            <label for="country" class="col-sm-12 col-form-label label_formul">Pays:<span style="color: red; ">*</span></label>
            <div class="col-sm-12">
                <select formControlName="country" class="form-control" value="0">
                    <option value="0" selected>Suisse</option>
                    <option value="1">France</option>
                    <option value="2">Allemagne</option>
                    <option value="3">Italie</option>
                </select>
                <p *ngIf="submitted && base.country.errors">
                    <span  *ngIf="base.country.errors.required" class="alert-danger">Le pays est obligatoire</span>
                </p>
            </div>
        </div>
        <div class="form-group row mobile">
            <label for="mobilPhone" class="col-sm-12 col-form-label label_formul">Tél. mobile:<span style="color: red; ">*</span></label>
            <div class="col-sm-12">
                <input type="text" class="form-control" formControlName="mobilPhone" placeholder="Exemple: 079 000 00 00">
                <p *ngIf="submitted && base.mobilPhone.errors">
                    <span  *ngIf="base.mobilPhone.errors.required" class="alert-danger">Le No de portable est obligatoire</span>
                </p>
            </div>
        </div>
        <!--Fin version mobile-->
       
        <div class="form-group row">
            <label for="adresse1" class="col-sm-12 col-form-label label_formul">Rue et N°:<span style="color: red; ">*</span></label>
            <div class="col-sm-12">
                <input type="text" class="form-control" formControlName="adresse1">
                <p *ngIf="submitted && base.adresse1.errors">
                    <span  *ngIf="base.adresse1.errors.required" class="alert-danger">L'adresse est obligatoire</span>
                </p>
            </div>
        </div>
        <div class="form-group row desktop"><!--Classe deskop toute la div est invisble sur mobile-->
            <label for="zipCode" class="col-sm-4 col-form-label label_formul">Code postal:<span style="color: red; ">*</span></label>
            <label for="locality" class="col-sm-8 col-form-label label_formul">Localité:<span style="color: red; ">*</span></label>
            <div class="col-sm-4">
                <input type="text" class="form-control" formControlName="zipCode">
                <p *ngIf="checkZipcode && base.zipCode.errors">
                    <span *ngIf="base.zipCode.errors.required" class="alert-danger">Le NPA est obligatoire</span>
                    <span *ngIf="base.zipCode.errors.minlength" class="alert-danger">Le NPA doit comporter au moins 4 chiffres</span>
                    <span *ngIf="base.zipCode.errors.maxlength" class="alert-danger">Le NPA doit comporter au moins 6 chiffres maximum</span>
                </p>
            </div>
            <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="locality">
                <p *ngIf="submitted && base.locality.errors">
                    <span  *ngIf="base.locality.errors.required" class="alert-danger">Le localité est obligatoire</span>
                </p>
            </div>
        </div>

        <!--Version mobile-->
        <div class="form-group row mobile">
            <label for="zipCode" class="col-sm-12 col-form-label label_formul">No postal:<span style="color: red; ">*</span></label>
            <div class="col-sm-12">
                <input type="text" class="form-control" formControlName="zipCode">
                <p *ngIf="checkZipcode && base.zipCode.errors">
                    <span *ngIf="base.zipCode.errors.required" class="alert-danger">Le NPA est obligatoire</span>
                    <span *ngIf="base.zipCode.errors.minlength" class="alert-danger">Le NPA doit comporter au moins 4 chiffres</span>
                    <span *ngIf="base.zipCode.errors.maxlength" class="alert-danger">Le NPA doit comporter au moins 6 chiffres maximum</span>
                </p>
            </div>
        </div>
        <div class="form-group row mobile">
            <label for="locality" class="col-sm-12 col-form-label label_formul">Localité:<span style="color: red; ">*</span></label>
            <div class="col-sm-12">
                <input type="text" class="form-control" formControlName="locality">
                <p *ngIf="submitted && base.locality.errors">
                    <span  *ngIf="base.locality.errors.required" class="alert-danger">Le localité est obligatoire</span>
                </p>
            </div>
        </div>

        <!--Fin version mobile-->

        <div class="form-group row">
        <p style="color:black; text-align: right; margin-bottom: 0px;" class="col-sm-12"><span style="color: red; ">*</span> = Champ obligatoire</p>  
            
        </div>
        <p *ngIf="messageRetour">
            <span class="alert-danger">{{messageRetour}}</span>
        </p>
       
        <!--
        <p *ngIf="formBase.invalid">INVALID</p>
        <p *ngIf="!formBase.invalid">VALID</p> 
        <p *ngIf="submitted">Submitted</p> 
        <p *ngIf="!submitted">Not submitted</p>  -->
        <div style="text-align: right; margin-bottom: -10px;">
        <button type="submit" [ngClass]="[formBaseChange ? 'btn btn-sm btn-primary active align-items-left': 'btn btn-sm btn-secondary align-items-left']" [disabled]="formBase.invalid && submitted" >Enregistrer</button> 
        </div>
    <br />
    </form>
    </div>
</div>
</div>
<br><br><br>





<!--

<div class="container nouveauCpte text-center" *ngIf="nouveauCompteOK">
    <h2>Compte créé avec succès</h2>
    <p>Nous vous confirmons la création de votre compte.</p>
    <p>Un mail vous a été envoyé. Veuillez le consulter afin de confirmer votre adresse email. </p>
    <p></p>
    <p>Merci de votre confiance:<br /><em>L'équipe eBook-Card</em></p>
</div>

<div class="container nouveauCpte" *ngIf="!nouveauCompteOK">
    <h2>Devenir membre (gratuit)</h2>
    <form [formGroup]="formBase" (ngSubmit)="submitBase()">
        <div class="form-group row">
        <label for="email" class="col-sm-4 col-form-label text-right">Adresse email (identifiant):*</label>
        <div class="col-sm-6">
            <input type="text" class="form-control" formControlName="email">
            <p *ngIf="submitted && base.email.errors">
                <span *ngIf="base.email.errors.required" class="alert-danger">Identifiant obligatoire</span>
                <span *ngIf="base.email.errors" class="alert-danger">Veuillez indiquer une adresse email valide</span>
            </p>
            <p *ngIf="emailExist">
                <span class="alert-danger">{{emailExist}}</span>
            </p>
        </div>
        </div>
        <div class="form-group row">
        <label for="password" class="col-sm-4 col-form-label text-right">Mot de passe:*</label>
        <div class="col-sm-6">
            <input type="password" class="form-control" formControlName="password">
            <p *ngIf="submitted && base.password.errors">
                <span *ngIf="base.password.errors.required" class="alert-danger">Mot de passe obligatoire</span>
                <span *ngIf="base.password.errors.minlength" class="alert-danger">Le mot de passe doit comporter au moins 6 caractères</span>
            </p>
        </div>
        </div>
        <div class="form-group row">
            <label for="repeatpassword" class="col-sm-4 col-form-label text-right">Confirmation du mot de passe:*</label>
            <div class="col-sm-6">
                <input type="password" class="form-control" formControlName="repeatpassword">
                <p *ngIf="submitted && base.repeatpassword.errors">
                    <span *ngIf="base.repeatpassword.errors.required" class="alert-danger">Répéter le mot de passe</span>
                    <span *ngIf="base.repeatpassword.errors.mustMatch" class="alert-danger">Le mot de passe doit être le même les deux fois</span>
                </p>
            </div>
        </div>
        <div class="form-group row">
            <label for="firstName" class="col-sm-4 col-form-label text-right">Nom:*</label>
            <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="firstName">
            </div>
            <p *ngIf="submitted && base.firstName.errors">
                <span  *ngIf="base.firstName.errors.required" class="alert-danger">Le nom est obligatoire</span>
            </p>
        </div>
        <div class="form-group row">
            <label for="lastName" class="col-sm-4 col-form-label text-right">Prénom:*</label>
            <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="lastName">
                <p *ngIf="submitted && base.lastName.errors">
                    <span  *ngIf="base.lastName.errors.required" class="alert-danger">Le prénom est obligatoire</span>
                </p>
            </div>
        </div>
        <div class="form-group row">
            <label for="birthDate" class="col-sm-4 col-form-label text-right">Date de naissance:*</label>
            <div class="col-sm-6">
                <input type="date" class="form-control"  formControlName="birthDate">
            </div>
        </div>
        <div class="form-group row">
            <label for="adresse1" class="col-sm-4 col-form-label text-right">Adresse:*</label>
            <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="adresse1">
                <p *ngIf="submitted && base.adresse1.errors">
                    <span  *ngIf="base.adresse1.errors.required" class="alert-danger">L'adresse est obligatoire</span>
                </p>
            </div>
        </div>
        <div class="form-group row">
            <label for="adresse2" class="col-sm-4 col-form-label text-right">Adresse suite:</label>
            <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="adresse2">
            </div>
        </div>
        <div class="form-group row">
            <label for="zipCode" class="col-sm-4 col-form-label text-right">No postal:*</label>
            <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="zipCode">
                <p *ngIf="submitted && base.zipCode.errors">
                    <span *ngIf="base.zipCode.errors.required" class="alert-danger">Le NPA est obligatoire</span>
                    <span *ngIf="base.zipCode.errors.minlength" class="alert-danger">Le NPA doit comporter au moins 4 chiffres</span>
                    <span *ngIf="base.zipCode.errors.maxlength" class="alert-danger">Le NPA doit comporter au moins 6 chiffres maximum</span>
                </p>
            </div>
        </div>
        <div class="form-group row">
            <label for="locality" class="col-sm-4 col-form-label text-right">Localité:*</label>
            <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="locality">
                <p *ngIf="submitted && base.locality.errors">
                    <span  *ngIf="base.locality.errors.required" class="alert-danger">Le localité est obligatoire</span>
                </p>
            </div>
        </div>
        <div class="form-group row">
            <label for="country" class="col-sm-4 col-form-label text-right">Pays:*</label>
            <div class="col-sm-6">
                <select formControlName="country" class="form-control" value="0">
                    <option value="0" selected>Suisse</option>
                    <option value="1">France</option>
                    <option value="2">Allemagne</option>
                    <option value="3">Italie</option>
                </select>
                <p *ngIf="submitted && base.country.errors">
                    <span  *ngIf="base.country.errors.required" class="alert-danger">Le pays est obligatoire</span>
                </p>
            </div>
        </div>
        <div class="form-group row">
            <label for="mobilPhone" class="col-sm-4 col-form-label text-right">Tél. mobile:*</label>
            <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="mobilPhone" placeholder="Exemple: 079 000 00 00">
                <p *ngIf="submitted && base.mobilPhone.errors">
                    <span  *ngIf="base.mobilPhone.errors.required" class="alert-danger">Le No de portable est obligatoire</span>
                </p>
            </div>
        </div>
        <div class="form-group row">
            <label for="homePhone" class="col-sm-4 col-form-label text-right">Tél. fixe:</label>
            <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="homePhone" placeholder="Exemple: 021 000 00 00">
            </div>
        </div>
        <div class="form-group row">
            <label for="reductionCode" class="col-sm-4 col-form-label text-right">Code de réduction:</label>
            <div class="col-sm-6">
            <input type="text" class="form-control" formControlName="reductionCode">
            </div>
        </div>
        <p *ngIf="messageRetour">
            <span class="alert-danger">{{messageRetour}}</span>
        </p>
        
        <p *ngIf="formBase.invalid">INVALID</p>
        <p *ngIf="!formBase.invalid">VALID</p> 
        <p *ngIf="submitted">Submitted</p> 
        <p *ngIf="!submitted">Not submitted</p>  
        <button type="submit" [ngClass]="[formBaseChange ? 'btn btn-sm btn-primary active align-items-right': 'btn btn-sm btn-secondary align-items-right']" [disabled]="formBase.invalid && submitted" >Enregistrer</button> 
    <br />
    </form>
</div>-->
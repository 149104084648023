<br />
<div class="grid_container">
  <div></div>
  <div *ngFor="let restaurant of restaurantsDetail">
    <div class="desktop">
      <div class="green-arrow padding-none">
        <div class="text_canton">
          {{ restaurant.canton }}
        </div>
      </div>
      <br>
    </div>
    <div class="grid_haut flex_haut">
      <div class="txtblanc">
        <h3 class="bleuebook font_title">{{ restaurant.name }}</h3>
        <p class="size_adresse">{{ restaurant.address1 }}</p>
         <p class="size_ville"> {{ restaurant.zipcode }} {{ restaurant.locality }}
        </p>
      </div>
      <div class="cookingtypes">
        Cuisine {{ restaurant.cookingtypes | lowercase   }}
      </div>
      <div class="mobile">
        <div></div>
        <div class="green-arrow padding-none">
          <div class="text_canton">
            <h4 class="size_canton">{{ restaurant.canton }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="desktop grid_img_agenda">
      <div class="grid_img">
        <ng-container *ngIf="restaurant.photossupp">
          <div *ngFor="let image of restaurant.photosLWS">
            <img [src]="image" class="img-fluid imgdetail" (click)="changImgSrc($event)" />
          </div>
        </ng-container>
      </div>
      <div></div>
    </div>
    <div class="grid_img_agenda">
      <div>
        <img src="{{ restaurant.photoLWS }}" class="img_size" />
      </div>
      <!-- deuxième grille gris avec dispos-->
      <div class="card fondGrisFonce">
        <!-- partie pour le flip card  -->
        <input type="checkbox" id="card1" class="more" aria-hidden="true"> 
        <div class="content">
          <div class="front">
            <div class="bleuebook dispo_title">
              Disponibilités
            </div>
           <div><!--Grille et flex pour agenda-->
              <ng-container *ngIf="disporesto">
                <div class="grid_general">
                  <div>
                    <a ></a>
                  </div>
                  <div class="grid_day font_day">
                    <a  *ngFor="let dayday of jourEnteteCalendrier" class="flex_day">{{ dayday }}</a>
                  </div>
                  <a class="desktop"></a> <!--Utilisé pour gérer espace après agenda / pas vilisble sur mobile-->
                </div>
                <div class="grid_general">
                  <div class="font_white">
                    <a >Midi</a>
                  </div>
                  <label for="card1" aria-hidden="true">
                    <div class="grid_day">
                      <a *ngFor="let dispomid of disponibiltesMidi"  class="font_size_agenda mat-calendar-body-label flex_day" [ngClass]="{
                        'dateFerme':dispomid.dispo === 0,
                            'dateMoitie':dispomid.dispo === 1,
                            'dateOuvert':dispomid.dispo === 2
                        }">{{ dispomid.day }}</a>
                    </div>
                   </label>
                  <a class="desktop"></a> <!--Utilisé pour gérer espace après agenda  / pas vilisble sur mobile-->
                </div>
                <div class="grid_general">
                  <div class="font_white">
                    <a >Soir</a>
                  </div>
                    <label for="card1" aria-hidden="true">
                      <div class="grid_day">
                        <a *ngFor="let dispoevening of disponibiltesSoir"  class="font_size_agenda mat-calendar-body-label flex_day" [ngClass]="{
                              'dateFerme':dispoevening.dispo === 0,
                              'dateMoitie':dispoevening.dispo === 1,
                              'dateOuvert':dispoevening.dispo === 2
                          }">{{ dispoevening.day }}</a>
                      </div>
                    </label>
                  <a class="desktop"></a> <!--Utilisé pour gérer espace après agenda  / pas vilisble sur mobile-->
                </div>
                <!-- Partie web site - jours fermés - bouton -->
                <div class="grid_dispo2">
                  <div class="margin_site">
                    <p class="dispo_menu_resto" *ngIf="restaurant.website"><a style="color:#8AC43F;"
                      href="{{ restaurant.website }}" target="_blank">Les menus du restaurant</a>
                    </p>
                    <div *ngIf="restaurant.closingdays">
                      <h5 class="bleuebook dispo_margin_bottom">Jours de fermeture</h5>
                      <p *ngIf="restaurant.closingdays" class="jour_ferme txtblanc">{{ restaurant.closingdays }}</p>
                    </div> 
                  </div>
                 <div class="btnreserver">
                    <label for="card1" class="btn-green" aria-hidden="true">Réserver</label>
                 </div>
                </div>
              <!--Fin partie web site - jours fermés - bouton -->
              </ng-container>
            </div>
          </div>
          <div class="back">
            <app-reservation *ngIf="currentUser" [id]="restaurant.id"></app-reservation>
            <app-login  *ngIf="!currentUser" [id]="restaurant.id"></app-login>
          </div>
        </div>
        <!-- Fin de partie pour le flip-->
        
      <div class="space"></div>
    </div> 
  </div>
  <div class="mobile grid_img_agenda" style="padding-top:0px;">
    <div class="grid_img">
      <ng-container *ngIf="restaurant.photossupp">
        <div *ngFor="let image of restaurant.photosLWS">
          <img [src]="image" class="img-fluid imgdetail" (click)="changImgSrc($event)" />
        </div>
      </ng-container>
    </div>
    <div></div>
  </div>
    <div>
      <div class="grid_bas grandeBoxDetail">
        <div class="description_padding txtblanc ">
          <h5 style="font-family: raleway;" class="description_titre bleuebook">Description</h5>
          <p class="card-text description" [innerHTML]="restaurant.description_id"></p>
          <div class="desktop grid_conditions"> <!--Conditions pour mobile imbriqué dans la div description-->
            <div *ngIf="restaurant.specialCondition_id">
              <div class="grid_conditions  box-gradient-green">
                  <div class="padding_conditionTXT">
                      <span class="special-condition-title">Conditions spéciales avec <span class="ebook-card-no-wrap"><span class="bold-blue-e">e</span>book-card</span></span> <br>
                      <span class="font_conditionTXT" [innerHTML]="restaurant.specialCondition_id"></span>
                  </div>
              </div>
            </div>
          </div>
        <!-- <p class="card-text description">{{ restaurant.description_id }}</p> -->
        </div>
        <div>
          <div class="menus">
            <h3 class="bold_blue">Le chef vous propose</h3>
            <div class="menuid"  [innerHTML]="restaurant.menus_id.replace('<li></li>', '')"></div>
          </div>
        </div>
        <div class="plan_padding ">
          <h5 class="plan_titre bleuebook">Le plan d'accès</h5>
          <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" style="height: 180px;">
            <agm-marker [latitude]="lat" [longitude]="lng" [iconUrl]="'/assets/images/map_pointer.png'" [markerDraggable]="true"></agm-marker>
          </agm-map>
          <br />
          <h5 class="equipement_titre bleuebook">Equipements</h5>
          <ng-container *ngIf="restaurant.equipmentsbase">
            <span *ngFor="let equipement of restaurant.equipments" class="">
              <img [src]="equipement" class="pictos img-fluid pictolisteresto" />
            </span>
          </ng-container>
        </div>
      </div>
    </div>
<!--Spécial condition - specialCondition_id affiche desktop-->
<div *ngIf="restaurant.specialCondition_id" id="repair">
  <div class="mobile grid_conditions  box-gradient-green">
      <div class="flex_img_conditions">
          <img class="" src="../assets/images/sphere_bleue.png">
      </div>
      <div class="padding_conditionTXT">
          <span class="special-condition-title">Conditions spéciales dans ce restaurant avec <span class="ebook-card-no-wrap"><span class="bold-blue-e">e</span>book-card</span></span> <br>
          <span class="font_conditionTXT" [innerHTML]="restaurant.specialCondition_id"></span>
      </div>
  </div>
<br>
</div>
  </div>
</div>

<br>
<input type="search" class="form-control filter-list-input margin_search" placeholder="Recherche par loisir ou ville" aria-label="Filter" name="search" [(ngModel)]="searchText" >
<div *ngIf="!compteLoisirs" class="spinner-border text-success" role="status">
  <span class="sr-only">...</span>
</div>
<div></div>
<!--partie mobile-->
<div class=" cadre_margin">
  <div *ngFor="let loisirs of lesLoisirs | myFilter:searchText:['locality', 'name', 'zipcode']; let i = index" >
    <div class="Widget mobile row no-gutters ficherestos grid_flex">
      <div class="img_center">
        <a routerLink="/loisirs/{{ loisirs.id }}/{{ loisirs.nameurl }}" target="_blank">
          <img src="{{ loisirs.photoLWS }}" class="img-thumbnail rounded-circle" alt="...">
        </a>
      </div>
      <div>
        <a routerLink="/loisirs/{{ loisirs.id }}/{{ loisirs.nameurl }}" target="_blank">
          <h5 class="card_title">{{ loisirs.name }}</h5>
        </a>
      </div>
      <div>
        <div>
          <p class="text_locality">{{ loisirs.zipcode }}  {{ loisirs.locality }}</p>
        </div>
        <div class="text_info" *ngIf="loisirs.activitytype">
          <p class="margin">
            {{ loisirs.activitytype | typeLoisirs}}
          </p>
          <p class="margin">
            Game de prix: {{ loisirs.pricerange }}
          </p>
          <span *ngFor="let equipement of loisirs.equipments" class="">
            <img [src]="equipement" class="img-fluid pictolisteresto" style="border-radius:100px; max-width:85%;" />
          </span>
          <div>
            <span *ngFor="let deja of arrDejaResa">
              <img *ngIf="deja.id === loisirs.id" src="{{ consomme }}" class="img_conso" alt="Déjà consommé">
            </span>
          </div>
        </div>
      </div>
    </div>
<!--Partie desktop-->
    <div class="desktop no-gutters ficherestos grid_flex">
      <div class="img_center">
        <a routerLink="/loisirs/{{ loisirs.id }}/{{ loisirs.nameurl }}" target="_blank">
          <img src="{{ loisirs.photoLWS }}" class="img_loisir" alt="{{ loisirs.name }}">
        </a>
      </div>
      <div class="flex_list card_body">
        <a>
          <a routerLink="/loisirs/{{ loisirs.id }}/{{ loisirs.nameurl }}" target="_blank">
            <h5 class="card_title">{{ loisirs.name }}</h5>
          </a>
          <p class="text_info_adresse">{{ loisirs.address1 }}</p>
          <p class="text_info_adresse">{{ loisirs.zipcode }} {{ loisirs.locality }}</p>
        </a>
        <a *ngIf="loisirs.activitytype">
          <p class="text_info margin"> {{ loisirs.activitytype | typeLoisirs}}</p>
        </a>
        <a>
          <p class="text_info"> Prix {{ loisirs.pricerange }} </p>
        </a>
        <a class="text_info margin">
          <span *ngFor="let equipement of loisirs.equipments" class="">
            <img [src]="equipement" class="img-fluid pictolisteresto" style="border-radius:100px; max-width:85%;" />
          </span>
        </a>
      </div>
      <div>
        <span *ngFor="let deja of arrDejaResa">
          <img *ngIf="deja.id === loisirs.id" src="{{ consomme }}" class="img_conso" alt="Déjà consommé">
        </span>
      </div>
      <div class="flex_detail">
        <a routerLink="/loisirs/{{ loisirs.id }}/{{ loisirs.nameurl }}" class="btn-green" target="_blank">Voir détail</a>
      </div>
    </div>
    <div class="desktop hr_no_dsiplay">
      <hr class="hr-gray-soft">
    </div>
  </div>
</div>

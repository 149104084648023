import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BoutiqueService, UserService, AuthenticationService } from '../_services';
import { Subscription, Observable } from 'rxjs';
import { AppConstants } from '../_helpers/app.constants';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions, PaymentIntent } from '@stripe/stripe-js';
import { MatDialog } from '@angular/material/dialog';
import { switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { User } from '../_models';
import { Meta, Title } from '@angular/platform-browser';
// STRIPE 
// pk_live_3hiVm5h8pjGJR5kfgRBZd1jR
 
// 5136590432109318

// pk_test_XQDSS1POyqBMDMjSZn0BFNaf
// TES: 4000 0027 6000 3184

@Component({
  selector: 'app-boutique-panier',
  templateUrl: './boutique-panier.component.html',
  styleUrls: ['./boutique.component.css']
})
export class BoutiquePanierComponent implements OnInit, OnDestroy {
  @ViewChild(StripeCardComponent, { static: false }) card: StripeCardComponent;
 
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'fr'
  };
  formCommande: FormGroup;

  interval: Subscription;
  produitDetail = [];
  nbProduitsArrives = false;
  idProduit = 'gastro12';
  idProduct: string;
  titleurl: string;  // pour URL category choisie
  urlBoutique = AppConstants.baseURLShop;
  monPanier = [];
  messageErrPay: string;
  orderID = 0;
  currentUser: User;
  cartevalide = [];
  cartes = [];
  firstname: string;
  lastname: string;
  address1: string;
  zipcode: string;
  locality: string;
  roleUser: string;
  email: string;
  infosUser = [];
  userid: string;
  desactive = false;
  carteValideNb: number;
  minDate: Date;
  maxDate: Date;
  
  constructor(private http: HttpClient,
    
              private authenticationService: AuthenticationService,
              private infousers: UserService,
              private boutiqueServ: BoutiqueService,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              private stripeService: StripeService,
              public dialog: MatDialog,
              private metaTagService: Meta,
              private titleService: Title) {
                this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

                // Ces trois lignes pour fixer la date d'activation min et max (+3 mois)
                const laDate = new Date();
                this.minDate = new Date();
                this.maxDate = new Date(laDate.getFullYear(), laDate.getMonth() + 3, laDate.getDate());
               }

  ngOnInit() {
    this.vaChercherPanier();
    this.formCommande = this.fb.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      adressenpa: ['', [Validators.required]],
      adresselocality: ['', [Validators.required]],
      adresserue: ['', [Validators.required]],
      email: ['', [Validators.required]],
    });
    if (this.currentUser && this.cartevalide) { // Connexion facultative
      console.log('Confirmé current et cartevalide')
      this.desactive = true;
      this.getUserData(this.currentUser.id);
    }

  }

  totalPanier() {
    let leTotal = 0;
    for (const i in this.monPanier) {
      if (i) {
        const ceTotal = +this.monPanier[i].total;
        leTotal += ceTotal;
      }
    }
    return leTotal;
  }

  moinsUn(idDuProduit: number, nombre: number) {
    if (nombre > 1) {
      nombre = nombre - 1;
      this.updateCart(idDuProduit,nombre);
    }
  }

  plusUn(idDuProduit: number, nombre: number) {
    nombre = nombre + 1;
    this.updateCart(idDuProduit, nombre);
  }

  updateCart(idProduit: number, nombre: number) {
    const nouveauPanier = [];
    for (const i in this.monPanier) {
      if (i) {
        const leID = + this.monPanier[i].id;
        if (leID === idProduit) {
          const ajoutPanier = {
            "id": idProduit,
            "name": this.monPanier[i].name,
            "price": this.monPanier[i].price,
            "nombre": nombre,
            "image": this.monPanier[i].image,
            "total": this.monPanier[i].price * nombre
          };
          nouveauPanier.push(ajoutPanier);
        } else {
          nouveauPanier.push(this.monPanier[i]);
        }
      }
    }

    sessionStorage.setItem('lepanier',  JSON.stringify(nouveauPanier));
    this.vaChercherPanier();
    console.table(this.monPanier);
  }

  removeFromBasket(idProduit: number) {
    const nouveauPanier = [];
    console.log('vider: ' + nouveauPanier);
    for (const i in this.monPanier) {
      if (i) {
        const leID = + this.monPanier[i].id;
        console.log('leID:' + leID + 'idProduit:' + idProduit );
        if (leID === idProduit) {
          // Je ne fais rien
          console.log('Je ne fais rien');
        } else {
          nouveauPanier.push(this.monPanier[i]);
        }
      }
    }
    if (nouveauPanier.length === 0) {
      sessionStorage.removeItem('lepanier');
    } else {
      sessionStorage.setItem('lepanier',  JSON.stringify(nouveauPanier));
    }
    this.vaChercherPanier();
    console.table(this.monPanier);
  }

  vaChercherPanier(): void {
    this.interval = this.boutiqueServ.getPanier().subscribe(lePanier => {
      this.monPanier = lePanier;

      this.titleService.setTitle('Boutique -  Votre panier en cours - ebook-card.ch');
      this.metaTagService.updateTag(
        { name: 'description',
          content: 'Acheter une carte ebook-card et consultez votre panier' }
      );
    });
  }

  // 4000 0027 6000 3184
  pay(): void {
    this.messageErrPay = '';

    if (this.formCommande.valid) {
      const total = this.totalPanier();
      const firstname = this.formCommande.get('firstname').value;
      const lastname = this.formCommande.get('lastname').value;
      const email = this.formCommande.get('email').value;

      const data = {
        amount: total,
        idproduit: this.idProduit,
        customer_name: firstname + ' ' + lastname,
        customer_email: email,
        nomproduit: 'Ebook commande',
        order_id: this.orderID
      };

      this.createPaymentIntent(data)
        .pipe(
          switchMap((pi) =>
            this.stripeService.confirmCardPayment(pi.client_secret, {
              payment_method: {
                card: this.card.element,
                billing_details: {
                  name: firstname + ' ' + lastname,
                },
              },
            })
          )
        )
        .subscribe((result) => {
          if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            console.log(result.error.message);
            this.messageErrPay = result.error.message;
          } else {
            // The payment has been processed!
            this.messageErrPay = '';
            if (result.paymentIntent.status === 'succeeded') {
              console.log('Status: ' + result.paymentIntent.status);
              this.firstStepOrder(result.paymentIntent.id);
            }
          }
        });
    } else {
      console.log('XX ' + this.formCommande);
    }
  }

  createPaymentIntent(data: any): Observable<PaymentIntent> {
    const amount = data.amount;
    const idproduit = data.idproduit;
    const customer_name = data.customer_name;
    const customer_email = data.customer_email;
    const order_id = data.order_id;
    const nomproduit = data.nomproduit;
    let detailCommande = '';
    const lePanier = this.monPanier;

    for (const i in this.monPanier) {
      if (i) {
        const nb = this.monPanier[i].nombre;
        const nom = this.monPanier[i].name;
        const desc = '' + nb + ' fois ' + nom + ' ';
        detailCommande = detailCommande.concat(desc.toString());
      }
    }

    const apiURL = AppConstants.baseURL + '/stripe/createclientsecret.php';
    return this.http.post<PaymentIntent>(apiURL,
      { amount, idproduit, customer_name, customer_email, order_id, nomproduit, detailCommande, lePanier }
    );

  }

  firstStepOrder(paymentid: string): any {
    let detailCommande = '';
    let idCommande = [];
    let retourCommande = [];

    for (const i in this.monPanier) {
      if (i) {
        const leid = this.monPanier[i].id;
        const nb = this.monPanier[i].nombre;
        const nom = this.monPanier[i].name;
        const leId = {
          id: leid,
          nbr: nb
        };
        idCommande.push(leId);
        const desc = '' + nb + ' fois ' + nom + ' ';
        detailCommande = detailCommande.concat(desc.toString());
      }
    }

    const data = {
      clientnom: 'Cretenet',
      clientprenom: 'Laurent',
      clientmail: 'laurent@ebook-card.ch',
      detailcommande: detailCommande,
      totalpanier: this.totalPanier(),
      adresserue: 'Numa-Droz 70',
      adressenpa: '2300',
      adresselocality: 'La Chaux-de-Fonds',
      idcommandes: idCommande,
      paymentidstripe: paymentid
    };


    const apiURL = AppConstants.baseURL + '/woocommerce/neworder.php';
    this.http.post<any>(apiURL, data).subscribe(
      (res) =>  {
        console.table(res);
        retourCommande = res;
        this.orderToStripe(res.id, paymentid);
      },
      (err) => console.log('Erreur:' + err),
    );

    return retourCommande;
  }

  orderToStripe(orderID: string, lepaymentid: string) {
    const data = {
      paymentid: lepaymentid,
      orderid: orderID
    };


    const apiURL = AppConstants.baseURL + '/stripe/updatePayment.php';
    this.http.post<any>(apiURL, data).subscribe(
      (res) =>  {
        console.table(res);

      },
      (err) => console.log('Erreur:' + err),
    );

  }

  getUserData(userid: string) {
    this.interval = this.infousers.getUserInfos(userid)
        .subscribe(orders => {
          this.infosUser = orders;
          console.log('Info Users chargé');
          for (const score of orders) {
            this.cartevalide = score.cartevalide;
            this.cartes = score.cartes;
            this.firstname = score.firstname;
            this.lastname = score.lastname;
            this.address1 = score.address1;
            this.zipcode = score.zipcode;
            this.locality = score.locality;
            this.email = score.email;
            this.userid = score.id;
          }
          this.formCommande.patchValue({
            firstname: this.firstname,
            lastname: this.lastname,
            adresserue: this.address1,
            adressenpa: this.zipcode,
            adresselocality: this.locality,
            email: this.email
          });
          if (this.cartevalide) {
            this.carteValideNb = Object.keys(this.cartevalide).length;
            console.log('carteValideNb:' + this.carteValideNb);
          }

        });
  }


  ngOnDestroy() {
    // a voir
  }



}

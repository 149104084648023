import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoisirsService } from '../_services/loisirs.service';
import { RestaurantsService } from '../_services/restaurants.service';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-loisirs-filtres',
  templateUrl: './loisirs-filtres.component.html',
  styleUrls: ['./loisirs-filtres.component.css']
})
export class LoisirsFiltresComponent implements OnInit {
  title = 'Home Component Title';

  constructor(private ordresService: LoisirsService, 
              private restaurantsService: RestaurantsService,
              titleService: Title,) { titleService.setTitle("Les loisirs");}

  joursOuverts = [
    {
      libelle: 'Lundi',
      value: 0
    },
    {
      libelle: 'Mardi',
      value: 1
    },
    {
      libelle: 'Mercredi',
      value: 2
    },
    {
      libelle: 'Jeudi',
      value: 3
    },
    {
      libelle: 'Vendredi',
      value: 4
    },
    {
      libelle: 'Samedi',
      value: 5
    },
    {
      libelle: 'Dimanche',
      value: 6
    }
  ];

  @Input() lesLoisirs = [];
  @Input() compteLoisirs = false;
  typeloisirs: any;
  interval: Subscription;
  loisirs = [];
  loisirsbis = [];
  filtre = [];
  filtreTypes = [];
  filtreCantons = [];
  filtrePrix = [];
  filtreEquip = [];
  filtreOuverture = [];
  loisirsDefinitif = [];
  imgTitre = 'assets/images/loisirs2019pourleweb-ptit.jpg';
  listTypeLoisirs = [];
  listCantons = [];
  listPrix = [];
  compte = false;
  compteCantons = false;
  comptePrix = false;
  listeEquipements = [];
  listeOuverture = [];
  restoDefinitif = [];
  aujourdhui = 0;

  ngOnInit() {
    this.getListeLoiris();
    this.getAujourdhui();
    this.title = 'document title'
      console.log(this.title)
  }

  getAujourdhui() {
    let d = new Date();
    this.aujourdhui = d.getDay() - 1;
  }

  // Fonction qui va chercher le service Loisirs. Et tri de manière aléatoire
  getListeLoiris(): void {
    // Si aucune donnée dans sessionStorage on charge la liste
    if (!sessionStorage.getItem('listeloisirs')) {
      console.log('appeln:92');
      this.interval = this.ordresService.servListeLoisirs().subscribe(lesLoisirs => {
        this.loisirsDefinitif = lesLoisirs;
        for (let i = this.loisirsDefinitif.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * i);
          const temp = this.loisirsDefinitif[i];
          this.loisirsDefinitif[i] = this.loisirsDefinitif[j];
          this.loisirsDefinitif[j] = temp;
        }
        // console.log('appeln:92');
        // console.table(this.loisirsDefinitif);
        this.lesLoisirs = this.loisirsDefinitif;
        sessionStorage.setItem('listeloisirs',  JSON.stringify(this.lesLoisirs));
        this.loisirs = this.loisirsDefinitif;
        this.compteLoisirs = true;
        this.constructTypeLoisirs();
        this.constructListCantons();
        this.constructListPrix();
        this.constructListEquip();
     });

    } else {
        // console.log('appel pas' );
        this.lesLoisirs = JSON.parse(sessionStorage.getItem('listeloisirs'));
        this.loisirs = this.lesLoisirs;
        this.constructTypeLoisirs();
        this.constructListCantons();
        this.constructListPrix();
        this.constructListEquip();
        this.compteLoisirs = true;
    }

    this.interval = this.ordresService.joursOuverture().subscribe(listeouvert => {
      this.listeOuverture = listeouvert;
      this.getListeRestos(); // un fois les loisirs téléchargés, on va chercher les resto à l'avance
      // console.table(this.listeOuverture);
    });
  }

  // Fonction sur clic de la liste à gauche
  clickFiltreTypes(value: any) {
    // 1. On ajoute ou supprime la catégorie du filtre
    if (this.filtreTypes.includes(value)) {
      this.filtreTypes[value] = null;
    } else {
      this.filtreTypes[value] = value;
    }
    // console.log('Types ' + this.filtreTypes);
    // 2. On appelle la fonction qui va retrier les loisirs
    this.getListeAfterClick();
  }

  // Fonction sur clic de la liste à gauche
  clickFiltreCantons(value: any) {
    // 1. On ajoute ou supprime la catégorie du filtre
    if (this.filtreCantons.includes(value)) {
      this.filtreCantons[value] = null;
    } else {
      this.filtreCantons[value] = value;
    }
    // console.log('Cantons ' + this.filtreCantons);
    // 2. On appelle la fonction qui va retrier les loisirs
    this.getListeAfterClick();
  }

  // Fonction sur clic de la liste à gauche
  clickFiltrePrix(value: any) {
    // 1. On ajoute ou supprime la catégorie du filtre
    if (this.filtrePrix.includes(value)) {
      this.filtrePrix[value] = null;
    } else {
      this.filtrePrix[value] = value;
    }
    // console.log('Prix ' + this.filtrePrix);
    // 2. On appelle la fonction qui va retrier les loisirs
    this.getListeAfterClick();
  }

  // Fonction sur clic de la liste à gauche
  clickFiltreEquip(value: any) {
    if (this.filtreEquip.includes(value)) {
      this.filtreEquip[value] = null;
    } else {
      this.filtreEquip[value] = value;
    }
    this.getListeAfterClick();
  }

  // Fonction sur clic de la liste à gauche
  clickFiltreOuverture(value: any) {
    const laValue = + value;
    if (this.filtreOuverture.includes(laValue)) {
      this.filtreOuverture[laValue] = null;
    } else {
      this.filtreOuverture[laValue] = laValue;
    }
    this.getListeAfterClick();
  }

  // Fonction appelée après un clic dans une catégorie
  // On repasse donc tous les filtres en revue pour construire la  liste
  getListeAfterClick() {
    let compteFiltre = 0; // Pour vérifier s'il y a qqc de cliqué
    this.lesLoisirs = []; // On vide la liste des loisirs
    const lesLoisirsBis = []; // Variable de stockage
    const idPourTypes = [];
    const idPourCantons = [];
    const idPouprix = [];
    const maListeDefin = [];
    const arrGlobalID: number[] = [];
    const idPourEquip = [];
    const idPourOuvert = [];

    // tslint:disable-next-line: forinrestau
    for (const i in this.loisirs) {
      const leIDprix = this.loisirs[i].pricerange_id;
      const leIDtype = this.loisirs[i].activitytypeid;
      const leIDcanton = this.loisirs[i].canton_id;
      const lesIDequip = this.loisirs[i].equipmentsids;  // CECI est un tableau
      const leID = this.loisirs[i].id;
      let isPresent = [];

      // tslint:disable-next-line: forin
      for (const index in this.filtreTypes) {         // Boucle sur les Types de Loisirs
        const myValue = this.filtreTypes[index];
        if (leIDtype.includes(myValue)) {
          // lesLoisirsBis.push(this.loisirs[i]);
          if (!idPourTypes.includes(leID)) {
            idPourTypes[leID] = parseInt(leID, 10);
          }
          compteFiltre++;
        }
      }

      // tslint:disable-next-line: forin
      for (const index in this.filtreCantons) {         // Boucle sur les cantons
        const myValue2 = this.filtreCantons[index];
        if (leIDcanton.includes(myValue2)) {
          // lesLoisirsBis.push(this.loisirs[i]);
          if (!idPourCantons.includes(leID)) {
            idPourCantons[leID] = parseInt(leID, 10);
          }
          compteFiltre++;
        }
      }

      // tslint:disable-next-line: forin
      for (const index in this.filtrePrix) {         // Boucle sur les prix
        const myValue3 = this.filtrePrix[index];
        if (leIDprix.includes(myValue3)) {
          // lesLoisirsBis.push(this.loisirs[i]);
          if (!idPouprix.includes(leID)) {
            idPouprix[leID] = parseInt(leID, 10);
          }
          compteFiltre++;
        }
      }

      // !! POUR équipements on doit tous les avoir pour ajouter le resto
      for (const index in this.filtreEquip) {         // Boucle sur les équipementss
        if (index) {
          const myValue31 = this.filtreEquip[index];
          if (!lesIDequip.includes(myValue31)) {
            isPresent[myValue31] = 'KO';
          }
        }
      }
      if (!isPresent.includes('KO')) {
        if (!idPourEquip.includes(leID)) {
          idPourEquip[leID] = parseInt(leID, 10);
        }
      }

      // tslint:disable-next-line: forin
      for (const index in this.filtreOuverture) {
        const idOuv = this.filtreOuverture[index];
        if (this.listeOuverture[leID].joursouverts) {
          const ouvertThisDay = +this.listeOuverture[leID].joursouverts[idOuv];
          if (ouvertThisDay === 1) {
            if (!idPourOuvert.includes(leID)) {
              idPourOuvert[leID] = parseInt(leID, 10);
            }
          }
        }
      }

    }
    // console.table('idPourTypes: ' + idPourTypes);
    // console.table('idPourCantons: ' + idPourCantons);
    // console.table('idPouprix: ' + idPouprix);

    this.loisirs.forEach(element => {
      const bonID = +element.id;
      // lesLoisirsBis = lesLoisirsBis.concat(idPourTypes, idPourCantons, idPouprix, idPourOuvert, idPourCeMidi, idPourEquip);

      if (idPourTypes.length > 0 && idPourCantons.length > 0 && idPouprix.length > 0 && idPourOuvert.length > 0 && idPourEquip.length > 0) {
        if (idPourTypes.includes(bonID) && idPourCantons.includes(bonID) && idPouprix.includes(bonID) && idPourOuvert.includes(bonID) && idPourEquip.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourTypes.length > 0 && idPourCantons.length > 0 && idPouprix.length > 0 && idPourOuvert.length > 0) {
        if (idPourTypes.includes(bonID) && idPourCantons.includes(bonID) && idPouprix.includes(bonID) && idPourOuvert.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourTypes.length > 0 && idPourCantons.length > 0 && idPouprix.length > 0 && idPourEquip.length > 0) {
        if (idPourTypes.includes(bonID) && idPourCantons.includes(bonID) && idPouprix.includes(bonID) && idPourEquip.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourTypes.length > 0 && idPouprix.length > 0 && idPourOuvert.length > 0 && idPourEquip.length > 0) {
        if (idPourTypes.includes(bonID) && idPouprix.includes(bonID) && idPourOuvert.includes(bonID) && idPourEquip.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourCantons.length > 0 && idPourOuvert.length > 0 && idPourEquip.length > 0 && idPouprix.length > 0) {
        if (idPourCantons.includes(bonID) && idPourOuvert.includes(bonID) && idPourEquip.includes(bonID) && idPouprix.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourTypes.length > 0 && idPourCantons.length > 0 && idPourOuvert.length > 0 && idPourEquip.length > 0) {
        if (idPourTypes.includes(bonID) && idPourCantons.includes(bonID) && idPourOuvert.includes(bonID) && idPourEquip.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourTypes.length > 0 && idPourCantons.length > 0 && idPouprix.length > 0) {
        if (idPourTypes.includes(bonID) && idPourCantons.includes(bonID) && idPouprix.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourTypes.length > 0 && idPouprix.length > 0 && idPourOuvert.length > 0) {
        if (idPourTypes.includes(bonID) && idPouprix.includes(bonID) && idPourOuvert.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourTypes.length > 0 && idPourCantons.length > 0 && idPourOuvert.length > 0) {
        if (idPourTypes.includes(bonID) && idPourCantons.includes(bonID) && idPourOuvert.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourCantons.length > 0 && idPouprix.length > 0 && idPourOuvert.length > 0) {
        if (idPourCantons.includes(bonID) && idPouprix.includes(bonID) && idPourOuvert.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourEquip.length > 0 && idPourTypes.length > 0 && idPourCantons.length > 0) {
        if (idPourEquip.includes(bonID) && idPourTypes.includes(bonID) && idPourCantons.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourEquip.length > 0 && idPourTypes.length > 0 && idPourOuvert.length > 0) {
        if (idPourEquip.includes(bonID) && idPourTypes.includes(bonID) && idPourOuvert.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourEquip.length > 0 && idPourCantons.length > 0 && idPourOuvert.length > 0) {
        if (idPourEquip.includes(bonID) && idPourCantons.includes(bonID) && idPourOuvert.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourEquip.length > 0 && idPourCantons.length > 0 && idPouprix.length > 0) {
        if (idPourEquip.includes(bonID) && idPourCantons.includes(bonID) && idPouprix.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourEquip.length > 0 && idPouprix.length > 0 && idPourOuvert.length > 0) {
        if (idPourEquip.includes(bonID) && idPouprix.includes(bonID) && idPourOuvert.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourEquip.length > 0 && idPourTypes.length > 0 && idPouprix.length > 0) {
        if (idPourEquip.includes(bonID) && idPourTypes.includes(bonID) && idPouprix.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourTypes.length > 0 && idPourCantons.length > 0) {
        if (idPourTypes.includes(bonID) && idPourCantons.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourTypes.length > 0 && idPouprix.length > 0) {
        if (idPourTypes.includes(bonID) && idPouprix.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourTypes.length > 0 && idPourOuvert.length > 0) {
        if (idPourTypes.includes(bonID) && idPourOuvert.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourCantons.length > 0 && idPouprix.length > 0) {
        if (idPourCantons.includes(bonID) && idPouprix.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourCantons.length > 0 && idPourOuvert.length > 0) {
        if (idPourCantons.includes(bonID) && idPourOuvert.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPouprix.length > 0 && idPourOuvert.length > 0) {
        if (idPouprix.includes(bonID) && idPourOuvert.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourEquip.length > 0 && idPourTypes.length > 0) {
        if (idPourEquip.includes(bonID) && idPourTypes.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourEquip.length > 0 && idPourCantons.length > 0) {
        if (idPourEquip.includes(bonID) && idPourCantons.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourEquip.length > 0 && idPouprix.length > 0) {
        if (idPourEquip.includes(bonID) && idPouprix.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourEquip.length > 0 && idPourOuvert.length > 0) {
        if (idPourEquip.includes(bonID) && idPourOuvert.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourTypes.length > 0 ) {
        if (idPourTypes.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourCantons.length > 0 ) {
        if (idPourCantons.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPouprix.length > 0 ) {
        if (idPouprix.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      } else if (idPourOuvert.length > 0 ) {
        if (idPourOuvert.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      }  else if (idPourEquip.length > 0 ) {
        if (idPourEquip.includes(bonID)) {
          lesLoisirsBis.push(element);
        }
      }

    });
    if (idPourTypes.length > 0 || idPourCantons.length > 0 || idPouprix.length > 0 ||
      idPourOuvert.length > 0 || idPourEquip.length > 0) {
    this.lesLoisirs = lesLoisirsBis;
    console.log('UPDATED! ');

  } else {
    // this.interval = this.ordresService.servListeRestos().subscribe(loisirsrecus => this.restaurants = loisirsrecus);
    this.lesLoisirs = JSON.parse(sessionStorage.getItem('listeloisirs'));
  }
}

    /*
    idPourTypes.forEach(element => {
      const IDenInt = + element;   // pour convertir en INT
      if (!arrGlobalID.includes(IDenInt)) {
        // si on a des cantons on croise les données
        if (idPourCantons.length > 0) {
          if (idPourCantons.includes(IDenInt)) {
            // si on a des Prix on croise les données
            if (idPouprix.length > 0) {
              if (idPouprix.includes(IDenInt)) {
                arrGlobalID[IDenInt] =  IDenInt;
              }
            } else {
              arrGlobalID[IDenInt] =  IDenInt;
            }
          }
        } else {
          if (idPouprix.length > 0) {
            if (idPouprix.includes(IDenInt)) {
              arrGlobalID[IDenInt] =  IDenInt;
            }
          } else {
            arrGlobalID[IDenInt] =  IDenInt;
          }
        }
      }
    });

    // Boucle pour
    idPourCantons.forEach(element => {
      const IDenInt = + element;   // pour convertir en INT
      if (!arrGlobalID.includes(IDenInt)) {
        // si on a des cantons on croise les données
        if (idPourTypes.length > 0) {
          if (idPourTypes.includes(IDenInt)) {
            // si on a des Prix on croise les données
            if (idPouprix.length > 0) {
              if (idPouprix.includes(IDenInt)) {
                arrGlobalID[IDenInt] =  IDenInt;
              }
            } else {
              arrGlobalID[IDenInt] =  IDenInt;
            }
          }
        } else {
          if (idPouprix.length > 0) {
            if (idPouprix.includes(IDenInt)) {
              arrGlobalID[IDenInt] =  IDenInt;
            }
          } else {
            arrGlobalID[IDenInt] =  IDenInt;
          }
        }
      }
    });
    idPouprix.forEach(element => {
      const IDenInt = + element;   // pour convertir en INT
      if (!arrGlobalID.includes(IDenInt)) {
        // si on a des cantons on croise les données
        if (idPourTypes.length > 0) {
          if (idPourTypes.includes(IDenInt)) {
            // si on a des Prix on croise les données
            if (idPourCantons.length > 0) {
              if (idPourCantons.includes(IDenInt)) {
                arrGlobalID[IDenInt] =  IDenInt;
              }
            } else {
              arrGlobalID[IDenInt] =  IDenInt;
            }
          }
        } else {
          if (idPourCantons.length > 0) {
            if (idPourCantons.includes(IDenInt)) {
              arrGlobalID[IDenInt] =  IDenInt;
            }
          } else {
            arrGlobalID[IDenInt] =  IDenInt;
          }
        }
      }
    });
    console.table('arrGlobalID: ' + arrGlobalID);
    console.log('Compte2: ' + compteFiltre);
    if (compteFiltre > 0) {
      this.loisirs.forEach(element => {
        const bonID = +element.id;
        arrGlobalID.forEach(loisirID => {
          if (loisirID === bonID) {
            lesLoisirsBis.push(element);
          }
        });
      });

      console.log('Final: ' + compteFiltre);
      this.lesLoisirs = lesLoisirsBis;
    } else {
      this.interval = this.ordresService.servListeLoisirs().subscribe(loisirsrecus => this.lesLoisirs = loisirsrecus);
    }
  } */


  // Construction de la liste d'activés à gauche
  /*
  EX_constructTypeLoisirs() {
    const arrVerif = [];
    // tslint:disable-next-line: forin
    for (const i in this.lesLoisirs) {
      const leID = this.lesLoisirs[i].activitytype_id;
      const leName = this.lesLoisirs[i].activitytype;
      const foo: any = {};
      if (!arrVerif.includes(leID)) {
        foo.id = leID;
        foo.name = leName;
        arrVerif[leID] = leID;
        this.listTypeLoisirs.push(foo);
        this.compte = true;
      }
    }
  }  */

   // Construction de la liste des type de cuisines à gauche
   constructTypeLoisirs() {
    const arrVerif = [];
    // tslint:disable-next-line: forin
    for (const i in this.lesLoisirs) {
      const arrID = this.lesLoisirs[i].activitytypebis;

      arrID.forEach(element => {
        // console.log('item: ' + element);
        const leIDdef = parseInt(element.activity_id, 10);
        const leName = element.name;
        const foo: any = {};
        if (!arrVerif.includes(leIDdef) && leName !== '-') {
            foo.id = leIDdef;
            foo.name = leName;
            arrVerif[leIDdef] = leIDdef;
            // console.log('IDcuisine: ' + leIDdef);
            this.listTypeLoisirs.push(foo);
            this.compte = true;
          }
      });
    }
  }

  // Construction de la liste des cantons à gauche
  constructListCantons() {
    const arrVerif = [];
    // tslint:disable-next-line: forin
    for (const i in this.lesLoisirs) {
      const leID = this.lesLoisirs[i].canton_id;
      const leName = this.lesLoisirs[i].canton;
      const foo: any = {};
      if (!arrVerif.includes(leID)) {
        foo.id = leID;
        foo.name = leName;
        arrVerif[leID] = leID;
        this.listCantons.push(foo);
        this.compteCantons = true;
      }
    }
  }

  // Construction de la liste des prix à gauche
  constructListPrix() {
    const arrVerif = [];
    // tslint:disable-next-line: forin
    for (const i in this.lesLoisirs) {
      const leID = this.lesLoisirs[i].pricerange_id;
      const leName = this.lesLoisirs[i].pricerange;
      const foo: any = {};
      if (!arrVerif.includes(leID)) {
        foo.id = leID;
        foo.name = leName;
        arrVerif[leID] = leID;
        this.listPrix.push(foo);
        this.comptePrix = true;
      }
    }
  }

/* Classe la liste de prix par ID */
sortByPrix(prop: string) {
  return this.listPrix.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
}


  // Construction de la liste des équipements à gauche
  constructListEquip() {
    const arrVerif = [];
    for (const i in this.lesLoisirs) {
      if (i) {
        for (const j in this.lesLoisirs[i].equipmentsid) {
          if (j) {
            // console.log('j ' + j);
            const leID = this.lesLoisirs[i].equipmentsid[j].id;
            const leName = this.lesLoisirs[i].equipmentsid[j].value;
            const foo: any = {};
            if (!arrVerif.includes(leID)) {
              foo.id = leID;
              foo.name = leName;
              arrVerif[leID] = leID;
              this.listeEquipements.push(foo);
            }
          }
        }
      }
    }
    this.listeEquipements.sort((a, b) => a.name.length - b.name.length);
  }


  getListeRestos(): void {
    // Si aucune donnée dans sessionStorage on charge la liste
    if (!sessionStorage.getItem('listerestos')) {
        this.interval = this.restaurantsService.servListeRestos().subscribe(restaurants => {
            this.restoDefinitif = restaurants;
            for (let i = this.restoDefinitif.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * i);
                const temp = this.restoDefinitif[i];
                this.restoDefinitif[i] = this.restoDefinitif[j];
                this.restoDefinitif[j] = temp;
            }
            sessionStorage.setItem('listerestos',  JSON.stringify(this.restoDefinitif));
            console.log('getListeRestos terminé');
        });
    }
}

}

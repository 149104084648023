import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams  } from '@angular/common/http';
import { timer, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppConstants } from '../_helpers/app.constants';

@Injectable({ providedIn: 'root' })
export class BoutiqueService {

  APIKEY = 'ck_0ad11c3a8a1fb76cbbd9e91bdbbc18a5fe41b3cc';
  APISECRET = 'cs_36b327c69f8177d9b17fa0e299bd62e032b9ad95';

  APIKEYTEST = 'ck_463a55fa5db8d31a3bec3157d2e5edf5704a874a';
  APISECRETTEST = 'cs_727e509f07a384a4a953bd7ad5729eda1b8187f5';

  access = '?consumer_key=' + this.APIKEYTEST + '&consumer_secret=' + this.APISECRETTEST;

  constructor(private http: HttpClient, ) {  }
  /*
  httpOptions = {
    headers: new HttpHeaders({
      Authorization: 'Basic' + this.APIKEYTEST + this.APISECRETTEST,
    })
  }  */

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  } 

    /** GET informations sur l'utilisateur toutes les xx secondes  */
    getProductsList(urlAppel: string): Observable<any> {
      const _apiLaLIsteProduits = AppConstants.baseURL + '/woocommerce/' + urlAppel + '.json?cachebuster=' +
        (Math.floor(Math.random() * 9000) + 1) ;
      const myObs: Observable<any> = this.http.get(`${_apiLaLIsteProduits}`);
      return myObs;
    }

    /** GET informations sur l'utilisateur toutes les xx secondes  */
    getProductsListCron(urlAppel: string): Observable<any> {
      const _apiLPourCron = AppConstants.baseURL + '/woocommerce/' + urlAppel + '.php' ;
      const myObs: Observable<any> = this.http.get(`${_apiLPourCron}`);
      return myObs;
    }

    /** GET informations sur l'utilisateur toutes les xx secondes  */
    getOneProduct(id: string): Observable<any> {
      const params = new HttpParams()
        .set('consumer_key', this.APIKEYTEST)
        .set('consumer_secret', this.APISECRETTEST);
      const _apiProduit = AppConstants.baseURLShop + '/wp-json/wc/v3/products/' + id ;
      const myObs: Observable<any> = this.http.get(`${_apiProduit}`, {params});
      return myObs;
    }

    /** GET informations sur l'utilisateur toutes les xx secondes  */
    getPanier(): Observable<any> {
      let myObs: Observable<any>;
      if (sessionStorage.getItem('lepanier')) {
        myObs = JSON.parse(sessionStorage.getItem('lepanier'));
        console.table(myObs);
      }
      return of(myObs);
    }


    /** GET informations sur l'utilisateur toutes les xx secondes  */
    getCategoryList(): Observable<any> {
      const _apiLaLIsteCategory = AppConstants.baseURLShop + '/wp-json/wc/v3/products/categories' + this.access;
      const myObs: Observable<any> = this.http.get(`${_apiLaLIsteCategory}`, this.httpOptions);
      return myObs;
    }
}

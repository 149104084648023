        <div *ngIf="!nbProduitsArrives"><img class="img-responsive" width="70px" src="assets/images/wait.png"></div>
        <ng-container *ngIf="nbProduitsArrives">
            <h2>{{titreCat}}</h2>
            <div class="sorteCarte">
              <!--__________ DEBUT CARTES PHYSIQUES_______________-->
              <div class="example-card" *ngIf="categoryPhysique.length > 0" >
                <div>
                  <h3>Cartes physiques</h3>
                  <p>Vous recevez votre code par
                    e-mail. Activez votre code sur
                    notre site et recevez une carte
                    par poste.</p>
                </div>
                  <div *ngFor="let produit of categoryPhysique">
                    <div class="contentcards">
                      <img mat-card-image src="{{urlBoutique}}{{produit.images[0].src}}" class="imagesCards" alt="{{produit.name}}">

                        <p>{{produit.name}}</p>
                        <h4>{{produit.price | number:'2.2'}} CHF</h4>
                    </div>
                    <div class="footercards">
                      <a mat-button class="btnDetail" href="/boutique/detail/{{produit.id}}/{{produit.slug}}">Détail</a>
                      <p><b [innerHTML]="produit.short_description"></b></p>
                    </div>
                  </div>
              </div>
              <!--__________ FIN CARTES PHYSIQUES_______________-->

              <!--__________ DEBUT CARTES CADEAUX _______________-->
              <div class="example-card" *ngIf="categoryCadeaux.length > 0" >
                <div>
                  <h3>Cartes cadeaux</h3>
                  <p>Vous recevez une carte dans
                    un emballage cadeau par poste.
                    Le bénéficiaire pourra l’activer
                    quand il le souhaite</p>
                </div>
                  <div *ngFor="let produit of categoryCadeaux">
                    <div class="contentcards">
                      <img mat-card-image src="{{urlBoutique}}{{produit.images[0].src}}" class="imagesCards" alt="{{produit.name}}">

                        <p>{{produit.name}}</p>
                        <h4>{{produit.price | number:'2.2'}} CHF</h4>
                    </div>
                    <div class="footercards">
                      <a mat-button class="btnDetail" href="/boutique/detail/{{produit.id}}/{{produit.slug}}">Détail</a>
                      <p><b [innerHTML]="produit.short_description"></b></p>
                    </div>
                  </div>
              </div>
              <!--__________ FIN CARTES CADEAUX _______________-->

              <!--__________ DEBUT CARTES VIRTUELLES _______________-->
              <div class="example-card" *ngIf="categoryVirtuelle.length > 0" >
                <div>
                  <h3>Cartes virtuelle</h3>
                  <p>Vous recevez votre code par
                    e-mail. Activez votre code sur
                    notre site. AUCUNE carte ne
                    sera envoyée</p>
                </div>
                  <div *ngFor="let produit of categoryVirtuelle">
                    <div class="contentcards">
                      <img mat-card-image src="{{urlBoutique}}{{produit.images[0].src}}" class="imagesCards" alt="{{produit.name}}">

                        <p>{{produit.name}}</p>
                        <h4>{{produit.price | number:'2.2'}} CHF</h4>
                    </div>
                    <div class="footercards">
                      <a mat-button class="btnDetail" href="/boutique/detail/{{produit.id}}/{{produit.slug}}">Détail</a>
                      <p><b [innerHTML]="produit.short_description"></b></p>
                    </div>
                  </div>
              </div>
              <!--__________ FIN CARTES VIRTUELLES _______________-->


              <!--__________ DEBUT CARTES PROLONGATION _______________-->
              <div class="example-card" *ngIf="categoryProlong.length > 0" >
                <div>
                  <h3>Prolongez votre carte actuelle</h3>
                  <p>Vous choisissez la date de prolongation</p>
                </div>
                  <div *ngFor="let produit of categoryProlong">
                    <div class="contentcards">
                      <img mat-card-image src="{{urlBoutique}}{{produit.images[0].src}}" class="imagesCards" alt="{{produit.name}}">

                        <p>{{produit.name}}</p>
                        <h4>{{produit.price | number:'2.2'}} CHF</h4>
                    </div>
                    <div class="footercards">
                      <a mat-button class="btnDetail" href="/boutique/detail/{{produit.id}}/{{produit.slug}}">Détail</a>
                      <p><b [innerHTML]="produit.short_description"></b></p>
                    </div>
                  </div>
              </div>
              <!--__________ FIN CARTES PROLONGATION _______________-->


            </div>
        </ng-container>
        <div style="clear: both"></div>

import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoisirsService } from '../_services/loisirs.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { User } from '../_models';
import { AuthenticationService } from '../_services';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-loisirsdetails',
  templateUrl: './loisirsdetails.component.html',
  styleUrls: ['./loisirsdetails.component.css']
})
export class LoisirsdetailsComponent implements OnInit, OnDestroy {
  idLoisir: number;
  loisirDetail: any;
  date = new Date();
  jourEnteteCalendrier = [];
  disponibiltesMatin = [];
  disponibiltesMidi = [];
  disponibiltesSoir = [];
  interval: Subscription;
  title: string;
  lat: number;
  lng: number;
  zoom: number;
  valueAdult = 0;
  valueEnfant = 0;
  leTotal = 0;
  leTotalStr = '';
  discountVert = '';
  nbrmaxpersonperbooking: number;
  nbrminpersonperbooking: number;
  prixEnfant = 0;
  prixAdult = 0;
  reductionEnfant = 0;
  reductionAdult = 0;
  rabaisMax: number;
  messagePersonnes: string;
  dispoloisir: any;
  currentUser: User;
  interditEnfants = false;
  interditAdultes = false;

  constructor(private ordresService: LoisirsService,
              private route: ActivatedRoute,
              private datePipe: DatePipe,
              private authenticationService: AuthenticationService,
              private metaTagService: Meta,
              private titleService: Title) { }

  ngOnInit() {
    this.idLoisir = +this.route.snapshot.paramMap.get('id');
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x)
    this.getLoisirDetail();
    this.getLoisirDispo();

    this.date.setDate( this.date.getDate());
    const day0 = this.jourEnFrancais(this.datePipe.transform(this.date, 'EEEEEE'));
    this.date.setDate( this.date.getDate() + 1 );
    const day1 = this.jourEnFrancais(this.datePipe.transform(this.date, 'EEEEEE'));
    this.date.setDate( this.date.getDate() + 1 );
    const day2 = this.jourEnFrancais(this.datePipe.transform(this.date, 'EEEEEE'));
    this.date.setDate( this.date.getDate() + 1 );
    const day3 = this.jourEnFrancais(this.datePipe.transform(this.date, 'EEEEEE'));
    this.date.setDate( this.date.getDate() + 1 );
    const day4 = this.jourEnFrancais(this.datePipe.transform(this.date, 'EEEEEE'));
    this.date.setDate( this.date.getDate() + 1 );
    const day5 = this.jourEnFrancais(this.datePipe.transform(this.date, 'EEEEEE'));
    this.date.setDate( this.date.getDate() + 1 );
    const day6 = this.jourEnFrancais(this.datePipe.transform(this.date, 'EEEEEE'));

    this.jourEnteteCalendrier = [
      day0,
      day1,
      day2,
      day3,
      day4,
      day5,
      day6,
    ];
  }

  // Subscription au service qui fournit les détail du loisir
  getLoisirDetail(): void {
    this.interval = this.ordresService.servLoisirsDetail(this.idLoisir)
        .subscribe(loisirDetailService => {
          this.loisirDetail = loisirDetailService;
          this.title = this.loisirDetail[0].name;
          this.titleService.setTitle(this.title + ' à ' + this.loisirDetail[0].locality + ' avec ebook-card.ch');
          this.metaTagService.updateTag(
            { name: 'description', content: '50% sur votre réservation ' + this.title + ' à ' + this.loisirDetail[0].locality + ' une entrée payante = une entrée gratuite de 2 à 6 personnes + de 200 partenaires ' }
          );
          this.lat = this.loisirDetail[0].latitude;
          this.lng = this.loisirDetail[0].longitude;
          this.nbrmaxpersonperbooking = this.loisirDetail[0].nbrmaxpersonperbooking;
          this.nbrminpersonperbooking = this.loisirDetail[0].nbrminpersonperbooking;
          // SI Le prix adulte exite
          if (this.loisirDetail[0].listeprix[0]) {
            this.prixAdult =  +parseFloat(this.loisirDetail[0].listeprix[0].prix).toFixed(2);
          } else {
            this.interditAdultes = true;
            this.prixAdult =  0;
          }
          // Si le prix enfants existe
          if (this.loisirDetail[0].listeprix[1]) {
            this.prixEnfant = +parseFloat(this.loisirDetail[0].listeprix[1].prix).toFixed(2);
          } else {
            this.interditEnfants = true;
            this.prixEnfant =  0;
          }
          if (this.loisirDetail[0].listeprix[0]) {
            this.reductionAdult =  +parseFloat(this.loisirDetail[0].listeprix[0].reduction).toFixed(2);
          }
          if (this.loisirDetail[0].listeprix[1]) {
            this.reductionEnfant = +parseFloat(this.loisirDetail[0].listeprix[1].reduction).toFixed(2);
          }
          
          // console.log('Red Adultes' + this.loisirDetail[0].listeprix[0].prix);
          if (this.reductionAdult > 0) {
            this.rabaisMax = this.nbrmaxpersonperbooking * this.reductionAdult;
            console.log('rm: ' + this.rabaisMax.toFixed(2));
          } else {
            this.rabaisMax = this.nbrmaxpersonperbooking * (this.prixAdult / 2);
            console.log('rm: ' + this.rabaisMax.toFixed(2));
          }
          this.zoom = 15;
          // console.log('Prix Adultes' + this.prixAvecCentimes(this.prixAdult));
          this.prixAdult = + this.prixAvecCentimes(this.prixAdult);
          this.prixEnfant = + this.prixAvecCentimes(this.prixEnfant);
        });
        
  }

  prixAvecCentimes(x) {
    return Number.parseFloat(x).toFixed(2);
  }
  // Subscription au service qui fournit le premier calendrier sur 7 jours
  getLoisirDispo(): void {
    this.interval = this.ordresService.servLoisirsDispo(this.idLoisir)
        .subscribe(dispoloisir => {
          this.dispoloisir = dispoloisir;
          this.disponibiltesMatin = dispoloisir.dispomatin;
          this.disponibiltesMidi = dispoloisir.dispomidi;
          this.disponibiltesSoir = dispoloisir.disposoir;
        });
  }

  changImgSrc(event) {
    this.loisirDetail[0].photoLWS = event.target.src;
  }

  onClickAdult(event: any) { // without type info
    this.valueAdult = parseInt(event.target.value, 10);
    const ensembleTotal = this.valueAdult + this.valueEnfant;
    if (ensembleTotal > this.nbrmaxpersonperbooking) {
      this.messagePersonnes = 'Le maximum de personnes est ' + this.nbrmaxpersonperbooking;
      this.valueAdult = this.nbrmaxpersonperbooking - this.valueEnfant;
    } else {
      this.messagePersonnes = '';
      this.onClickCalcul();
    }
  }

  onClickEnfant(event: any) { // without type info
    this.valueEnfant = parseInt(event.target.value, 10);
    const ensembleTotal = this.valueAdult + this.valueEnfant;
    if (ensembleTotal > this.nbrmaxpersonperbooking) {
      this.messagePersonnes = 'Le maximum de personnes est ' + this.nbrmaxpersonperbooking;
      this.valueEnfant = this.nbrmaxpersonperbooking - this.valueAdult;
    } else {
      this.messagePersonnes = '';
      this.onClickCalcul();
    }
  }

  onClickCalcul() { // without type info
    // si le prestataire fonctionne avec les réduction et pas système normal
    console.log('Redu A: ' + this.reductionAdult);
    if (this.reductionAdult > 0) {
      const prixNormal: number = (this.valueEnfant * this.prixEnfant) + (this.valueAdult * this.prixAdult);
      const leRabais: number = (this.valueEnfant * this.reductionEnfant) + (this.valueAdult * this.reductionAdult);

      this.leTotal = prixNormal - leRabais;
      this.discountVert = leRabais.toFixed(2);
      this.leTotal = + this.leTotal.toFixed(2);
      this.leTotalStr = this.leTotal.toFixed(2);

    } else {
      const totalPersonnes: number = this.valueAdult + this.valueEnfant;
      const adultPlusEnfant: number = this.prixAdult + this.prixEnfant;
  
      // Faire un calcul pour connaître: le rabais, le prix normal.
      // Avec ça on peut tout calculer
      const prixNormal: number = (this.valueEnfant * this.prixEnfant) + (this.valueAdult * this.prixAdult);
      const nbAdultePayant = Math.ceil(this.valueAdult / 2);
      const nbEnfantPayant = Math.ceil(this.valueEnfant / 2);
      // console.log('valueAdult%2: ' + this.valueAdult % 2);
      // console.log('valueEnfant%2: ' + this.valueEnfant % 2);
  
  
      // Si il n'y a pas le nombre minimum de personnes.... else
      if (totalPersonnes < this.nbrminpersonperbooking) {
        const leTotalEnfants = this.valueEnfant * this.prixEnfant;
        const leTotalAdults = this.valueAdult * this.prixAdult;
        this.leTotal = leTotalAdults + leTotalEnfants;
        const zero = 0;
        this.discountVert = zero.toFixed(2);
      } else {
  
        // 1. On fixe prix total enfants et adultes.
        // Puis, s'il y a un adulte de plus que enfants on baisse le prix enfants
        const totalInterAdult = nbAdultePayant * this.prixAdult;
        let totalInterEnfant = nbEnfantPayant * this.prixEnfant;
        const valueAdultModulus = this.valueAdult % 2; // Pour savoir s'il y a un adulte en trop pour offrir à un gosse
        const valueEnfantModulus = this.valueEnfant % 2; // Pour savoir s'il y a un adulte en trop pour offrir à un gosse
        if (this.valueAdult > 1 && valueAdultModulus === 1 && valueEnfantModulus === 1 &&  this.valueEnfant > 0) {
          totalInterEnfant = totalInterEnfant - this.prixEnfant;
        }
        if ((this.valueAdult === 1 && this.valueEnfant === 1) ||
            (this.valueAdult === 1 && this.valueEnfant === 3) ||
            this.valueAdult === 1 && this.valueEnfant === 5 ||
            this.valueAdult === 1 && this.valueEnfant === 7 ||
            this.valueAdult === 1 && this.valueEnfant === 9) {
          totalInterEnfant = totalInterEnfant - this.prixEnfant;
        }
        this.leTotal = totalInterEnfant + totalInterAdult;
        const disountVertInt = prixNormal - this.leTotal;
        this.discountVert = disountVertInt.toFixed(2);
      }
      this.leTotal = + this.prixAvecCentimes(this.leTotal);
      this.leTotalStr = this.leTotal.toFixed(2);
      console.log('nbAdultePayant: ' + nbAdultePayant);
      console.log('nbEnfantPayant: ' + nbEnfantPayant);
      console.log('prixNormal: ' + prixNormal);
      console.log('total pers: ' + totalPersonnes);
    }
    
  }

  jourEnFrancais(day: string) {
    let dayReturn: string;
    if (day === 'Mo' ) {
      dayReturn = 'Lu';
    } else if (day === 'Tu') {
      dayReturn = 'Ma';
    } else if (day === 'We') {
      dayReturn = 'Me';
    } else if (day === 'Th') {
      dayReturn = 'Je';
    } else if (day === 'Fr') {
      dayReturn = 'Ve';
    } else if (day === 'Sa') {
      dayReturn = 'Sa';
    } else if (day === 'Su') {
      dayReturn = 'Di';
    }
    return dayReturn;
  }

  dateClass = (d: Date) => {
    const date = d.getDate();
    return (date === 1 || date === 24) ? 'dateOuvert' : undefined;
    // return (date === 2 || date === 13) ? 'example-custom-date-classBis' : undefined;
  }

  ngOnDestroy() {
    this.interval.unsubscribe();
}

}

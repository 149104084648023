import { Component } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { UserloginService } from '../_services/userlogin.service';
import { Subscription } from 'rxjs';
import { EncrDecrService } from '../_services/encrdecr.service';

@Component({
  selector: 'app-formuserlogin',
  templateUrl: './formuserlogin.component.html',
  styleUrls: ['./formuserlogin.component.css']
})
export class FormuserloginComponent  {
  interval: Subscription;
  constructor(private userloginService: UserloginService, private EncrDecr: EncrDecrService) { }
    profileForm = new FormGroup({
    identifiant: new FormControl(''),
    password: new FormControl(''),
  });
  conversionEncryptOutput: string;
  conversionDecryptOutput: string;
  plainText: string;
  encryptText: string;
  encPassword: string;
  decPassword: any;

  onSubmit() {

    const encrypted = this.EncrDecr.set('123456$#@$^@1ERF', this.profileForm.value.password);
    // const decrypted = this.EncrDecr.get('123456$#@$^@1ERF', encrypted);
    console.log('Encrypted: ' + encrypted);

    // This line update form value before we send it
    this.profileForm.patchValue({
      password: encrypted
    });

    /* this.userloginService.postDataUserServ(this.profileForm.value).subscribe(res => {
    console.warn(this.profileForm.value);

    }); */
  }
}

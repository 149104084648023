import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { User } from '../_models';
import { UserService, AuthenticationService } from '../_services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loisirs',
  templateUrl: './loisirs.component.html',
  styleUrls: ['./loisirs.component.css']
})
export class LoisirsComponent implements OnInit, OnDestroy {

  consomme = 'assets/images/logo consomme loisir.png';
  searchText: string;
  currentUser: User;
  interval: Subscription;
  infosUser = [];
  arrDejaResa = [];

  constructor(private authenticationService: AuthenticationService,
              private infousers: UserService) {
                this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
               }

  @Input() lesLoisirs = [];
  @Input() compteLoisirs: boolean;

  getUserData(userid: string) {
    this.interval = this.infousers.getUserInfos(userid)
        .subscribe(orders => {
          this.infosUser = orders;
          for (const score of orders) {
            console.table(score.dejareserveloisirs);
            this.arrDejaResa = score.dejareserveloisirs;
          }
        });
  }

  ngOnInit() {
    if (this.currentUser) {
      this.getUserData(this.currentUser.id);
    }
  }

  ngOnDestroy() {
    if (this.interval) {
      this.interval.unsubscribe();
    }
  }

}


<br />
<div class="grid_container">
  <div></div>  
  <div *ngFor="let loisir of loisirDetail">
    <div class="mobile"> <!--Ligne canton visible sur mobile-->
      <div class="green-arrow">
        <div class="text_canton">
          <h5>{{ loisir.canton }}</h5>
        </div>
      </div>
    </div>
    <div class="grid_haut flex_haut">
      <div class="txtblanc">
        <h3 class="font_title ">{{ loisir.name }}</h3>
        <p class="font_adresse">{{ loisir.address1 }}</p>
        <p class="font_ville">{{ loisir.zipcode }}  {{ loisir.locality }}</p>
      </div>
      <div class="loisirtypes">
        {{ loisir.activitytype | typeLoisirs }}
      </div>
      <div class="desktop grid_canton"> <!--Ligne canton visible sur desktop-->
        <div></div>
        <div class="green-arrow padding-none">
          <div class="text_canton">
            <h4 class="size_canton">{{ loisir.canton }}</h4>
          </div>
        </div>
      </div>
    </div>
    <!--Petites images en version mobile-->
    <div class="mobile grid_img_agenda" style="padding-top:0px;">
      <div class="flex_img">
          <ng-container *ngIf="loisir.photossupp">
              <div *ngFor="let image of loisir.photosLWS">
                <img [src]="image" class="img-fluid imgdetail" (click)="changImgSrc($event)" />
              </div>
          </ng-container>
      </div>
    </div> 
    <!-- Deuxième partie avec photo et réservaiton -->
    <div class="grid_img_agenda">
      <div>
          <img src="{{ loisir.photoLWS }}" class="img_size" />
      </div>
      <!-- deuxième grille grise avec dispos-->
      <div class="card fondGrisFonce">
        <!-- partie pour le flip card  -->
        <input type="checkbox" id="card1" class="more" aria-hidden="true">
        <div class="content">
          <div class="front">
            <div class="bleuebook dispo_title">
              Disponibilités
            </div>
            <div>  <!--Grille et flex pour agenda-->
              <ng-container *ngIf="dispoloisir">
                <div class="grid_general">
                  <div>
                    <a ></a>
                  </div>
                  <div class="grid_day font_day">
                    <a  *ngFor="let dayday of jourEnteteCalendrier" class="flex_day">{{ dayday }}</a>
                  </div>
                  <a class="mobile"></a> <!--Utilisé pour gérer espace après agenda / pas vilisble sur mobile via grid-->
                </div>
                <div class="grid_general">
                  <div class="font_white">
                    <a >Matin</a>
                  </div>
                  <label for="card1" aria-hidden="true">
                    <div class="grid_day">
                      <a *ngFor="let dispomat of disponibiltesMatin"  class="font_size_agenda mat-calendar-body-label flex_day" [ngClass]="{
                        'dateFerme':dispomat.dispo === 0,
                        'dateMoitie':dispomat.dispo === 1,
                        'dateOuvert':dispomat.dispo === 2
                        }">{{ dispomat.day }}</a>
                    </div>
                </label>
                  <a class="mobile"></a> <!--Utilisé pour gérer espace après agenda  / pas vilisble sur mobile via grid-->
                </div>
                <div class="grid_general">
                  <div class="font_white mobile">
                    <a >Ap.-midi</a>
                  </div>
                  <div class="font_white desktop">
                    <a >Après-midi</a>
                  </div>
                  <label for="card1" aria-hidden="true">
                    <div class="grid_day">
                      <a *ngFor="let dispomid of disponibiltesMidi"  class="font_size_agenda mat-calendar-body-label flex_day" [ngClass]="{
                        'dateFerme':dispomid.dispo === 0,
                        'dateMoitie':dispomid.dispo === 1,
                        'dateOuvert':dispomid.dispo === 2
                        }">{{ dispomid.day }}</a>
                    </div>
                  </label>
                  <a></a> <!--Utilisé pour gérer espace en colone après agenda / pas vilisble sur mobile-->
                </div>
                <div class="grid_general">
                  <div class="font_white">
                    <a >Soir</a>
                  </div>
                  <label for="card1" aria-hidden="true">
                    <div class="grid_day">
                      <a *ngFor="let dispoevening of disponibiltesSoir" class="font_size_agenda mat-calendar-body-label flex_day" [ngClass]="{
                        'dateFerme':dispoevening.dispo === 0,
                        'dateMoitie':dispoevening.dispo === 1,
                        'dateOuvert':dispoevening.dispo === 2
                        }">{{ dispoevening.day }}</a>
                    </div>
                  </label>
                  <a></a> <!--Utilisé pour gérer espace colone après agenda / pas vilisble sur mobile-->
                </div>
                <!-- Partie web site - jours fermés - bouton -->
                <div class="grid_dispo2"> 
                  <div class="margin_carte_loisrirs" >
                    <p class="dispo_site_loisir" *ngIf="loisir.website"><a style="color:#8AC43F;" 
                      href="{{ loisir.website }}" target="_blank">Le lien du loisir</a>
                    </p>
                    
                    <div> 
                      <a *ngIf="loisir.closingdays">
                        <h5 class="bleuebook dispo_margin_bottom">Jours de fermeture</h5>
                        <p *ngIf="loisir.closingdays" class="jour_ferme txtblanc">{{ loisir.closingdays }}</p>
                      </a> 
                    </div>
                  </div>
                  <div class="btnreserver">
                    <label for="card1" class="btn-green" aria-hidden="true">
                      Réserver
                    </label>
                  </div>
                </div>
                <!-- Fin partie web site - jours fermés - bouton -->
              </ng-container>
            </div>
            <!--Fin grille et flex pour agenda-->
          </div>
          <div class="back"> <!--Agenda qui se retourne-->
            <app-reservationloisir *ngIf="currentUser" [id]="loisir.id"></app-reservationloisir>
            <app-login  *ngIf="!currentUser" [id]="loisir.id"></app-login>
          </div>
        </div>
        <!-- Fin de partie pour le flip-->
        <div class="space"></div> <!--Assure la hauteur partie grise sous l'agenda-->
    </div> 
  </div>   
  <div class="desktop grid_img_agenda" style="padding-top:0px;">
    <div class="grid_img">
      <ng-container *ngIf="loisir.photossupp">
        <div *ngFor="let image of loisir.photosLWS">
          <img [src]="image" class="img-fluid imgdetail" (click)="changImgSrc($event)" />
        </div>
      </ng-container>
    </div>
    <div></div>
  </div>  

  <!-- Troisième partie avec menu, description, carte, équipemenets -->
    <div class="grandeBoxDetail grid_bas">
      <div class="description_padding txtblanc">
        <h5 class="bleuebook">Description</h5>
        <p class="card-text description" [innerHTML]="loisir.description_id"></p>
        <div class="mobile grid_conditions"> <!--Conditions pour mobile imbriqué dans la div description-->
          <div *ngIf="loisir.specialCondition_id">
            <div class="grid_conditions box-gradient-green">
                <div class="padding_conditionTXT">
                    <span class="special-condition-title">Conditions spéciales avec <span class="ebook-card-no-wrap"><span class="bold-blue-e">e</span>book-card</span></span> <br>
                    <span class="font_conditionTXT" [innerHTML]="loisir.specialCondition_id"></span>
                </div>
            </div>
          </div>
        </div>
        <br><br><br><br>
      </div>
      <div class="item1 col-sm detailBlocCentre">
        <div class="losirsprix txtblanc">
            <div>
                <div class="badgeReducMax">
                    <img class="taille_badge" src="../assets/images/badge_reduc_max_large.png">
                </div>
                <div class="flex_rabais badgeReducMax_Text">
                    <span class="rabais_max">Rabais max</span>
                    <a class="rabais_max_montant" *ngIf="loisir.zipcode < 9999" >{{ rabaisMax.toFixed(2) }} CHF</a>
                    <a class="rabais_max_montant" *ngIf="loisir.zipcode > 9999" >{{ rabaisMax.toFixed(2) }} €</a>
                </div>
              <h5 class="bleuebook">Prix</h5>
              <table class="loisirtxt tableprix">
                <tr *ngFor="let prix of loisir.listeprix">
                  <td [innerHTML]="prix.client"></td>
                  <td class="tableprixtxt">{{ prix.prixtxt }}</td>
                </tr>
                <tr ></tr>
              </table>
              <h5 class=" bleuebook">Horaire</h5>
              <p>
                <span class="loisirtxt" [innerHTML]="loisir.schedule_id"></span>
              </p>
              <h5 class=" bleuebook">Date d'ouverture</h5>
              <p>
                <span class="loisirtxt" [innerHTML]="loisir.openingDates_id"></span>
              </p>
              <h5 class=" bleuebook">Votre avantage</h5>
              <p>
                <span class="loisirtxt" [innerHTML]="loisir.textlines1_id"></span>
              </p>
            </div>
          </div>
      </div>
      <div id="grid_map" class="plan_padding">
        <div class="padding_map">
          <h5 class="bleuebook">Le plan d'accès</h5>
          <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
            <agm-marker [latitude]="lat" [longitude]="lng" [iconUrl]="'/assets/images/map_pointer_activity.png'" [markerDraggable]="true"></agm-marker>
          </agm-map>
          <br />
        </div>
        <div class="padding_calc">
          <h5 class="info bleuebook">Information</h5>
          <ng-container *ngIf="loisir.equipmentsbase">
              <span *ngFor="let equipement of loisir.equipments">
                  <img [src]="equipement" class="pictos img-fluid " />
                </span> 
          </ng-container>
          <br />
          <h5 class="font_calcprix bleuebook">Calcul du prix</h5>
          <div class="calculPrix">
              <div class="badgeReducMax2">
                  <img src="../assets/images/badge_reduc_max.png">
              </div>
              <div class="flex_rabais badgeReducMax2_Text">
                  <a class="rabais">Rabais</a>
                  <a class="price" *ngIf="loisir.zipcode < 9999" >{{ discountVert }} CHF</a>
                  <a class="price" *ngIf="loisir.zipcode > 9999" >{{ discountVert }} €</a>
              </div>
              <div style="display:block" *ngIf="!interditAdultes">Adulte nb <input (change)="onClickAdult($event)" type="number" min="0" max="{{ nbrmaxpersonperbooking }}" maxlength="1" id="adultNumber" value="{{ valueAdult }}"></div>
              <div style="display:block" *ngIf="!interditEnfants">Enfant nb <input (change)="onClickEnfant($event)" type="number" min="0" max="{{ nbrmaxpersonperbooking }}" maxlength="1" id="childNumber"  value="{{ valueEnfant }}"></div>
              <div class="marge_bas_calculateur">Prix total <input type="text" readonly="" id="totalPrice" value="{{ leTotalStr }}"></div>
          </div>
          <div class="text-success">{{messagePersonnes}}</div>
        </div>
      </div>
    </div>
    <br>
    <!--Spécial condition - specialCondition_id affiche desktop-->
    <div *ngIf="loisir.specialCondition_id">
        <div class="desktop grid_conditions  box-gradient-green">
            <div class="flex_img_conditions">
                <img class="" src="../assets/images/sphere_bleue.png">
            </div>
            <div class="padding_conditionTXT">
                <span class="special-condition-title">Conditions spéciales dans ce loisir avec <span class="bold-blue-e">e</span>book-card</span><br>
                <span class="font_conditionTXT" [innerHTML]="loisir.specialCondition_id"></span>
            </div>
        </div>
      <br>
    </div>
  </div>
</div> 
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConstants } from '../_helpers/app.constants';

@Injectable({
  providedIn: 'root'
})
export class UserloginService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private httpClient: HttpClient) { }

  baseUrl = AppConstants.baseURL + '/login.php?token=cPnFKCqNQLKw&cachebuster=' + (Math.floor(Math.random() * 9000) + 1);
  public postDataUserServ(obj) {
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(obj, null, 4));
    return this.httpClient.post(this.baseUrl, obj, {
      headers: new HttpHeaders({
           'Content-Type':  'application/json',
         })
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, BoutiqueService } from './_services';
import { User } from './_models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  isMenuCollapsed = true;
  currentUser: User;
  imgTitre = 'assets/images/slider2019pourleweb.jpg';
  interval: Subscription;
  
  an = (new Date()).getUTCFullYear();
  monPanier = [];

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private boutiqueServ: BoutiqueService ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
        this.interval = this.boutiqueServ.getPanier().subscribe(lePanier => {
          this.monPanier = lePanier;
        });
        console.log('Done');
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
        sessionStorage.removeItem('lepanier');
    }

    vaChercherPanier(): void {
      this.interval = this.boutiqueServ.getPanier().subscribe(lePanier => {
        this.monPanier = lePanier;
      });
    }

    ngOnInit() {
      // this.vaChercherPanier();

    }

    

}

import { Component, OnInit} from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MustMatch } from '../_helpers/must-match.validator';
import { EncrDecrService } from '../_services/encrdecr.service';
import { AppConstants } from '../_helpers/app.constants';

@Component({
  selector: 'app-nouveaucompte',
  templateUrl: './nouveaucompte.component.html',
  styleUrls: ['./nouveaucompte.component.css']
})
export class NouveaucompteComponent implements OnInit {
  formBase;
  formBaseChange = false;
  submitted = false;
  messageRetour = '';
  emailExist = '';
  nouveauCompteOK = false;
  mobileExist = '';
  emailIntermediaire = '';
  mobileIntermediaire = '';
  password = '';
  repeatpassword = '';
  chackPassword = false;
  chackRepeatPassword = false;
  checkFirstname = false;
  checkLastname = false;
  zipCode = '';
  checkZipcode = false;

  constructor(  private httpClient: HttpClient,
                private formBuilder: FormBuilder,
                private EncrDecr: EncrDecrService) { }

  ngOnInit() {

    this.formBase = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      adresse1: ['', Validators.required],
      adresse2: [''],
      mobilPhone: ['', Validators.required],
      homePhone: [''],
      zipCode: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(6)]],
      locality: ['', Validators.required],
      country: ['', Validators.required],
      birthDate: [''],
      reductionCode: [''],
      password: ['', [Validators.required, Validators.minLength(6)]],
      repeatpassword: ['', Validators.required],
    }, {
      validator: MustMatch('password', 'repeatpassword')
    });

    this.formBase.valueChanges.subscribe(selectedValue  => {
      if (selectedValue.email !== this.emailIntermediaire) {
        console.log('Mail changed ' + selectedValue.email);
        this.emailIntermediaire = selectedValue.email;
        this.onChangeEmail(selectedValue.email);
      }
      if (selectedValue.mobilPhone !== this.mobileIntermediaire) {
        console.log('Mobile changed ' + selectedValue.mobilPhone);
        this.mobileIntermediaire = selectedValue.mobilPhone;
        this.onChangeMobile(selectedValue.mobilPhone);
      }
      // Si le mot de passe change
      if (selectedValue.password !== this.password) {
        this.password = selectedValue.password;
        (async () => {
          await this.delay(1000);
          this.chackPassword = true;
          this.chackRepeatPassword = true;
        })();

      }
      // si la confirmation mot de passe change
      if (selectedValue.repeatpassword !== this.repeatpassword) {
        this.repeatpassword = selectedValue.repeatpassword;
        (async () => {
          await this.delay(1000);
          this.chackPassword = true;
          this.chackRepeatPassword = true;
        })();
      }

      // si le NPA change
      if (selectedValue.zipCode !== this.zipCode) {
        this.zipCode = selectedValue.zipCode;
        (async () => {
          await this.delay(1000);
          this.checkZipcode = true;
        })();
      }
      this.formBaseChange = true;
    });

  
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  get base() {
    // console.table(this.formBase.controls.formBaseDebut.controls); 
    return this.formBase.controls;
  }

  // Fonction lors de l'enregistrement des infos de base
submitBase() {
  this.submitted = true;
  const encryptedPass2 = this.EncrDecr.set('123456$#@$^@1ERF', this.base.password.value);


  if (this.formBase.invalid) {
    console.log('INVALID');
    return;
  }

  const data = {
      email: this.base.email.value,
      password: encryptedPass2,
      firstname: this.base.firstName.value,
      lastname: this.base.lastName.value,
      birthdate: this.base.birthDate.value,
      address1: this.base.adresse1.value,
      adress2: this.base.adresse2.value,
      zipcode: this.base.zipCode.value,
      locality: this.base.locality.value,
      country: this.base.country.value,
      mobilphone: this.base.mobilPhone.value,
      homephone: this.base.homePhone.value,
      reductioncode: this.base.reductionCode.value
  };
  console.table(data);
  const basebath = AppConstants.baseURL + '/postData/postNouveauCompte.php?token=cPnFKCqNQLKw';
  this.httpClient.post<any>(basebath, data).subscribe(
    (res) =>  {
      // this.reponseEditCompte = res;
      if (res === 11) {
        this.nouveauCompteOK = true;
        this.emailExist = '';
        this.mobileExist = '';
      } else if (res === 44) {
        this.messageRetour = 'Une erreur s\'est produit lors de l\'enregistrement';
      } else if (res === 33) {
        this.emailExist = 'Cette adresse email est déjà utilisée dans un compte eBook-card.';
        this.mobileExist = '';
      } else if (res === 34) {
        this.mobileExist = 'Ce numéro de mobile est déjà utilisée dans un compte eBook-card.';
        this.emailExist = '';
      }
      console.table('Réponse: ' + res);
    },
    (err) => console.table('ErrorHere: ' + err),
  );
  
}

  // Fonction lancée si le mail change et va directement vérifier en ligne
  onChangeEmail(emailForm) {
    const data = {
        email: emailForm,
    };
    console.table(data);
    const basebath = AppConstants.baseURL + '/postData/postVerifEmailDirect.php';
    this.httpClient.post<any>(basebath, data).subscribe(
      (res) =>  {
        // this.reponseEditCompte = res;
        if (res === 11) {
          this.emailExist = '';
        } else if (res === 33) {
          this.emailExist = 'Cette adresse email est déjà utilisée dans un compte eBook-card.';
        }
        // console.table('Réponse: ' + res);
      },
      (err) => console.table('ErrorHere: ' + err),
    );
  }

  // Fonction lancée si le mobile change et va directement vérifier en ligne
  onChangeMobile(mobilPhone) {
    const data = {
        mobile: mobilPhone,
    };
    console.table(data);
    const basebath = AppConstants.baseURL + '/postData/postVerifMobileDirect.php';
    this.httpClient.post<any>(basebath, data).subscribe(
      (res) =>  {
        // this.reponseEditCompte = res;
        if (res === 11) {
          this.mobileExist = '';
        } else if (res === 33) {
          this.mobileExist = 'Ce numéro de mobile est déjà utilisée dans un compte eBook-card.';
        } 
        // console.table('Réponse: ' + res);
      },
      (err) => console.table('ErrorHere: ' + err),
    );
  }


}

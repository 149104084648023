import { Component, OnInit } from '@angular/core';
import { AppConstants } from '../_helpers/app.constants';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthenticationService } from '../_services';
import { User } from '../_models';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.css']
})
export class ShopComponent implements OnInit {


  srcframe: string;
  laSrc: SafeResourceUrl;
  currentUser: User;
  currentUserRole: string;
  currentUserId: string;

  constructor(private route: ActivatedRoute,
              private sanitizer: DomSanitizer,
              private authenticationService: AuthenticationService,) { 
                this.authenticationService.currentUser.subscribe(x => {
                  this.currentUser = x;
                  this.currentUserRole = x.roleuser;
                  this.currentUserId = x.id;
                  }
                );
              }

  ngOnInit(): void {
      // this.srcframe = 'https://ebook-card.com/' + this.route.snapshot.params.category + '/'; https://angular.ebook-card.com/wp/boutique/
      this.srcframe = '/wp/boutique/?id=' + this.currentUserId;
      // this.srcframe = '/wp/' + this.route.snapshot.params.category + '/';
      this.laSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.srcframe);
      
      var iframe = document.getElementById("frameshop");
      var iWindow = (<HTMLIFrameElement> iframe).contentWindow;

      const go = iWindow.postMessage('ANG10: ' + this.currentUserId, '*');
      
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, timer } from 'rxjs';
import { AppConstants } from '../_helpers/app.constants';
import { switchMap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class PartnerInfoServiceService {

  constructor(private http: HttpClient) { }

  servPrestaDetail(idPartner: string, isResto: number, idObjet: number): Observable<any> {
    const apiPartnerInfos = AppConstants.baseURL + '/partnerinfos.php?token=cPnFKCqNQLKw&id=' + idPartner +
    '&isresto=' + isResto + '&idobjet=' + idObjet + '&cachebuster=' + (Math.floor(Math.random() * 9000) + 1);
    const myObs2: Observable<any> = this.http.get(`${apiPartnerInfos}`);
    // console.log('Service infos Presta ');
    return myObs2;
  }

  /* PAS BESOIN DE GARDER CE VIEUX TRUC
  getCommissions(userid: string, partnerid: string, isresto: string): any {
    const CommissionsInfos = AppConstants.baseURL + '/commissions.php?cachebuster=' + (Math.floor(Math.random() * 9000) + 1);
    const myObs: Observable<any> = this.http.get(`${CommissionsInfos}`, httpOptions);
    return myObs;

  } */

  /** LES COMMISSIONS  */
  getCommissions(userid: string, partnerid: string, isresto: string): any {
    const CommissionsInfos = AppConstants.baseURL + '/commissions.php?cachebuster=' + (Math.floor(Math.random() * 9000) + 1);
    return this.http.post<any>(CommissionsInfos, {
      userid, partnerid, isresto
    });
  }

}

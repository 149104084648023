import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../_helpers/app.constants';

@Injectable({
  providedIn: 'root'
})
export class LoisirsService {

  constructor(private http: HttpClient) { }
  
  servListeLoisirs(): Observable<any> {
    const apiListeLoisirs = AppConstants.baseURL + '/loisirsliste.php?token=cPnFKCqNQLKw&cachebuster=' +
      (Math.floor(Math.random() * 9000) + 1);

      return this.http.post(apiListeLoisirs, {
        headers: new HttpHeaders({
             'Content-Type':  'application/json'
           })
      });

  }

  servLoisirsDetail(id: number): Observable<any> {
    const apiLoisirsDetail = AppConstants.baseURL + '/loisirsdetail.php?token=cPnFKCqNQLKw&id=' + id;
    const myObs2: Observable<any> = this.http.get(`${apiLoisirsDetail}`);
    console.log('Retourne detailloisirs');
    return myObs2;
  }

  // Ce service donne le premier calendrier sur une semaine
  servLoisirsDispo(id: number): Observable<any> {
    const apiRestoDispo = AppConstants.baseURL + '/desponibiliteloisirs.php?token=cPnFKCqNQLKw&id=' + id;
    const myObs2: Observable<any> = this.http.get(`${apiRestoDispo}`);
    console.log('Serv Calendrier 7 jours ');
    return myObs2;
  }

  servLoisirsResa(id: number): Observable<any> {
    const apiLoisirsResa = AppConstants.baseURL + '/loisirreservation.php?token=cPnFKCqNQLKw&id=' + id +
    '&cachebuster=' + (Math.floor(Math.random() * 9000) + 1);
    const myObs3: Observable<any> = this.http.get(`${apiLoisirsResa}`);
    console.log('Accès service Resa ');
    return myObs3;
  }

  // Service pour la liste des restos en accueil avec la map Google en Suise romande
  listeLoisirsGPS(): Observable<any> {
    const _apiTypeGPSliste = AppConstants.baseURL + '/listemaploisirs.php?token=cPnFKCqNQLKw';
    const myObs3: Observable<any> = this.http.get(`${_apiTypeGPSliste}`);
    return myObs3;
  }

  // Service pour la liste des restos en accueil avec la map Google en Suise romande
  joursOuverture(): Observable<any> {
    const apijourOuverts = AppConstants.baseURL + '/filtredispoloisir.php?token=cPnFKCqNQLKw' +
    '&cachebuster=' + (Math.floor(Math.random() * 9000) + 1);
    const myObs3: Observable<any> = this.http.get(`${apijourOuverts}`);
    return myObs3;
  }
}

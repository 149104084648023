<div class="container col-md-8 offset-md-2 mt-5">
    <div class="card contact">
        <h4>Contactez-nous</h4>
        <div class="card-body">
            <h4>
                <span class="bold-blue-e">e</span>book-card<br/>
                <br />
            </h4>
            <div class="messageretour" *ngIf="envoyeOK" [innerHTML]="messageOKAffiche"></div>
          <form [formGroup]="formContact" (ngSubmit)="submitMessage()" *ngIf="!envoyeOK">

                <fieldset class="form-group">
                    <div class="row">
                      <legend class="col-form-label col-sm-4 pt-0">Vous êtes</legend>
                      <div class="col-sm-8">
                        <div class="form-check">
                          <input class="form-check-input" type="radio" formControlName="qui" id="particulier" value="particulier" checked>
                          <label class="form-check-label" for="particulier">
                            Un particulier
                          </label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" formControlName="qui" id="partenaire" value="partenaire">
                          <label class="form-check-label" for="partenaire">
                            Un partenaire
                          </label>
                        </div>
                      </div>
                    </div>
                  </fieldset>

                <div class="form-group row">
                    <label for="nom" class="col-sm-4 col-form-label">Nom*</label>
                    <div class="col-sm-8">
                      <input formControlName="nom" class="form-control" id="nom">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="prenom" class="col-sm-4 col-form-label">Prénom*</label>
                    <div class="col-sm-8">
                      <input formControlName="prenom" class="form-control" id="prenom">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="email" class="col-sm-4 col-form-label">Email*</label>
                    <div class="col-sm-8">
                      <input formControlName="email" class="form-control" id="email">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="tel" class="col-sm-4 col-form-label">Téléphone</label>
                    <div class="col-sm-8">
                      <input formControlName="tel" class="form-control" id="tel">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="noebook" class="col-sm-4 col-form-label">No de votre ebook-card</label>
                    <div class="col-sm-8">
                      <input formControlName="noebook" class="form-control" id="noebook">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="message" class="col-sm-4 col-form-label">Message*</label>
                    <div class="col-sm-8">
                      <textarea  formControlName="message" class="form-control" id="message"></textarea>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="submit" class="col-sm-4 col-form-label">*Champs obligatoires</label>
                    <div class="col-sm-8">
                      <button type="submit" [disabled]="!formContact.valid" class="btn btn-primary">Envoyer</button>
                    </div>
                </div>
            
            </form>
        </div>
    </div>
</div>
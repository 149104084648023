<!--The content below is only a placeholder and can be replaced.-->




<nav id="menuHead" class=" navbar navbar_padding fixed-top navbar-expand-md navbar-dark bg-dark" >


  <!-- Just an image -->
  <nav id="logo" class="navbar navbar_padding navbar-dark bg-dark">
    <a class="navbar_padding navbar-brand navbar_margin" routerLink="/">
      <img src="../assets/images/logo.png" class="logo_size" alt="Sorter à deux payer pour un">
    </a>
  </nav>
  <button style="color: white;" class="navbar-toggler" type="button" data-toggle="collapse" aria-expanded="false" aria-label="Toggle navigation" (click)="isMenuCollapsed = !isMenuCollapsed"> 
    &#9776;
  </button>

  <div id="menuMobile" class="mobile">
    <a class="nav-link" routerLink="/restaurants"><img src="../assets/images/Picto_resto.png" class="img_size_picto" alt="connexion ebook-card"></a>
    <a class="nav-link" routerLink="/loisirs"><img src="../assets/images/Picto_loisir.png" class="img_size_picto" alt="connexion ebook-card"></a>
    <a class="font_size nav-link" routerLink="/moncompte"><img src="../assets/images/Picto_cadeau.png" class="img_size_picto" alt="connexion ebook-card"></a>
    <!-- <a class="font_size nav-link" routerLink="/shop/boutique"><span class="material-icons">shopping_cart</span></a> -->
  </div>

  <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse justify-content-end">
    <ul class="ulflex navbar-nav">
      <li class="ulflex nav-item">
        <a style="color: white;" class="font_size padding_picto nav-link"  [routerLink]="'/restaurants'" (click)="isMenuCollapsed = true">
          <img src="../assets/images/Picto_resto.png" class="img_size_picto " alt="connexion ebook-card">
        </a>
        <a style="color: white;" class="setting_resto nav-link" [routerLink]="'/restaurants'" (click)="isMenuCollapsed = true">Restaurants</a>
      </li>
      <li class="ulflex nav-item">
        <a style="color: white;" class="font_size padding_picto nav-link" [routerLink]="'/loisirs'" (click)="isMenuCollapsed = true">
          <img src="../assets/images/Picto_loisir.png" class="img_size_picto " alt="connexion ebook-card">
        </a>
        <a style="color: white;" class="setting_lois nav-link" [routerLink]="'/loisirs'" (click)="isMenuCollapsed = true">Loisirs</a>
      </li>
      <li class="ulflex nav-item">
        <a style="color:#8AC43F;padding-top: 5px;" class="font_size padding_picto nav-link" [routerLink]="'/moncompte'" (click)="isMenuCollapsed = true">
          <img src="../assets/images/Picto_cadeau.png" class="img_size_picto " alt="connexion ebook-card">
        </a>
        <a style="color:#8AC43F" class="setting_activer nav-link" [routerLink]="'/moncompte'" (click)="isMenuCollapsed = true">Activer carte cadeau</a>
      </li>
      <!-- <li class="ulflex nav-item">
        <a style="color: #009de2;padding-top: 5px;" class="font_size padding_picto nav-link" [routerLink]="'/shop/boutique'" (click)="isMenuCollapsed = true">
          <span class="material-icons">shopping_cart</span>
        </a>
        <a style="color: #009de2" class="setting_activer nav-link" [routerLink]="'/shop/boutique'"monPanier (click)="isMenuCollapsed = true">Boutique</a>
        
      </li> -->
      <!-- <li class="ulflex nav-item" *ngIf="monPanier">
        <a style="color: #009de2;padding-top: 5px;" class="font_size padding_picto nav-link" [routerLink]="'/boutique/panier/contenu'" (click)="isMenuCollapsed = true">
          <span class="material-icons">shopping_basket</span>
        </a>        
      </li>-->

     
    
      <li style="padding-left: 8px;" class="mobile nav-item  flex_picto" *ngIf="!currentUser" >
        <a class="nav-link img_connex" [routerLink]="'/login'" (click)="isMenuCollapsed = true"><img src="../assets/images/connex.png" class="img_size" alt="connexion ebook-card"></a>
        <a class="nav-link connexion" [routerLink]="'/login'" (click)="isMenuCollapsed = true">Connexion</a>
      </li>
      <li style="padding-left: 8px; padding-top: 4px;" class="mobile nav-item flex_picto" *ngIf="currentUser" >
        <a class="nav-link img_mc" [routerLink]="'/moncompte'" (click)="isMenuCollapsed = true"><img class="img_size_mc" src="../assets/images/parametre1.png"></a>
        <a class="nav-link mon_compte" [routerLink]="'/moncompte'" (click)="isMenuCollapsed = true">Mon compte</a>
      </li>
      <li style="padding-left: 8px; padding-top: 4px;"  class="mobile nav-item flex_picto" *ngIf="currentUser" >
        <!--<a style="color: white;" class="font_size nav-link" (click)="logout()" href="#"><i class="fas fa-user-slash"></i> Déconnexion</a>-->
        <a class="nav-link img_deconect" (click)="logout()" href="#"><img class="img_size_deconnect" src="../assets/images/deconnecter_v1.png"></a>
        <a class="nav-link mon_compte" (click)="logout()" href="#" >Déconnexion</a>
      </li>
      <li>
        <a class="mobile img_size_fb" href="https://www.facebook.com/ebookcard" target="_blank"><img src="../assets/images/logo_facebook.png" class="img_size_fb" alt="connexion ebook-card"></a>
      </li>
    </ul>
  </div>
    
  <div>
    <ul class="navbar-nav flex_picto"> 
      <li class="desktop nav-item flex_picto" *ngIf="!currentUser" > 
      <a href="https://www.facebook.com/ebookcard" target="_blank"><img src="../assets/images/logo_facebook.png" class="img_size_fb" alt="connexion ebook-card"></a>
      <a class="nav-link img_connex" routerLink="/login"><img src="../assets/images/connex.png" class="img_size" alt="connexion ebook-card"></a>
      <a class="nav-link connexion" routerLink="/login">Connexion</a>
      </li>
      <li class="desktop" *ngIf="currentUser">
        <a href="https://www.facebook.com/ebookcard" target="_blank"><img src="../assets/images/logo_facebook.png" class="img_size_fb" alt="connexion ebook-card"></a>
      </li>
      <li class="desktop nav-item" *ngIf="currentUser" >
        <a style="color: white;" class="nav-link img_compte" routerLink="/moncompte"><img style="width: 40px;" src="../assets/images/parametre1.png"></a>
      </li>
      <li class="desktop nav-item" *ngIf="currentUser" >
        <a style="color: white;" class="nav-link img_compte" (click)="logout()" href="#"><img style="width: 36px;" src="../assets/images/deconnecter_v1.png"></a>
      </li>
    </ul>
  </div>
</nav>

<br />
<br />


<div id="maincontent">
<!-- nav -->
<router-outlet></router-outlet>
</div>
<footer class="grid_footer">
  <div></div>
  <div class="flex_footer">
    <a class="mobile" routerLink="/about" style="color:white">À propos</a>
    <a class="desktop" routerLink="/about" style="color:white">À propos de nous</a>
    <p class="puce">&#x2022;</p>
    <a routerLink="/contact" style="color:white">Contact</a>
    <p class="puce">&#x2022;</p>
    <a routerLink="/faq" style="color:white">FAQ</a>
    <p class="puce">&#x2022;</p>
    <a routerLink="/conditions" style="color:white">Conditions générales</a>
  </div>
  <div class="footer_aligne">
    &copy;  {{an}} <span class="bold-blue-e">e</span>book-card.ch
</div>

</footer>


import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JournalService {

  httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  /*
  constructor(private http: HttpClient) { }
  private journal = 'https://ebookwebservice.azurewebsites.net/journal.php?typelog=';

  getJournal(data): any {
      return timer(0, 30000).pipe(
          switchMap(() =>  this.http.get<any[]>(`${this.journal}` + typelog +
          '&cachebuster=' + (Math.floor(Math.random() * 9000) + 1)) ) );
  }  */
}

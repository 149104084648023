<form [formGroup]="profileForm" (ngSubmit)="onSubmit()" class="formuser">
  <div class="form-group">
    <label>
      Identifiant: <input type="text" formControlName="identifiant" class="form-control" required>
    </label>
    <label>
      Mot de passe: <input type="password" formControlName="password" class="form-control" />
    </label>
    <button type="submit" [disabled]="!profileForm.valid" class="button">Go</button>
  </div>
</form>
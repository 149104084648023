import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MustMatch } from '../_helpers/must-match.validator';
import { EncrDecrService } from '../_services/encrdecr.service';
import { AuthenticationService } from '../_services';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../_helpers/app.constants';


@Component({selector: 'app-login', 
            styleUrls: ['./login.component.css'],
            templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
    
    public reactiveForm: FormGroup = new FormGroup({
        recaptchaReactive: new FormControl(null, Validators.required)
    });
    sitekey = '6LckNxwTAAAAACCivhIsPbgFO-o1tO776EHsWRR5';

    loginForm: FormGroup;
    passForm2: FormGroup;
    passForm3: FormGroup;
    loading = false;
    submitted = false;
    submitted2 = false;
    submitted3 = false;
    returnUrl: string;
    errorRetour = '';
    showOubliPass = false;
    oubliPassPhase1 = true;
    oubliPassPhase2 = false;
    newPassFinish = false;
    messageEmailUnexist = '';
    lEmail = '';

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private EncrDecr: EncrDecrService,
        private httpClient: HttpClient) 
        
        {  
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            password2: ['']
        });

        this.passForm2 = this.formBuilder.group({
            username: ['', [Validators.required, Validators.email]]
        });

        this.passForm3 = this.formBuilder.group({
            codemail: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]],
            repeatpassword: ['', Validators.required],
        }, {
          validator: MustMatch('password', 'repeatpassword')
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }
    get f2() { return this.passForm2.controls; }
    get f3() { return this.passForm3.controls; }

    // Recharge la page sur le lien /login dans récupération mot de passe
    refresh(): void { window.location.reload(); }

    onSubmitOubli() {
        this.submitted2 = true;

        // stop here if form is invalid
        if (this.passForm2.invalid) {
            console.log('INVALID');
            return;
        }
        console.log('VALID');
        this.lEmail = this.f2.username.value;

        const data = {
            email: this.f2.username.value,
        };
        this.loading = true;
        const basebath = AppConstants.baseURL + '/postData/postOubliPasse.php';
        this.httpClient.post<any>(basebath, data).subscribe(
            (res) =>  {
                if (res === 11) {
                    this.oubliPassPhase2 = true;
                    this.oubliPassPhase1 = false;
                    this.loading = false;
                    this.passForm3.patchValue({
                        codemail: '',
                        password: '',
                        repeatpassword: ''
                      });
                } else if (res === 33) {
                    this.messageEmailUnexist = 'Cette adresse email n\'existe pas chez ebook-card.';
                    this.loading = false;
                } else if (res === 44) {
                    this.messageEmailUnexist = 'Une erreur s\'est produite';
                    this.loading = false;
                }
                console.table('Réponse: ' + res);
            },
            error => {
                this.errorRetour = error;
                this.loading = false;
            }
        );
    }

    onSubmitOubli2() {
        this.submitted3 = true;

        // stop here if form is invalid
        if (this.passForm3.invalid) {
            console.log('INVALID');
            return;
        }
        console.log('VALID');
        this.loading = true;
        const newPassEncrypt = this.EncrDecr.set('123456$#@$^@1ERF', this.f3.password.value);

        const data = {
            email: this.lEmail,
            code: this.f3.codemail.value,
            passencypt: newPassEncrypt
        };

        const basebath = AppConstants.baseURL + '/postData/postOubliPasse2.php';
        this.httpClient.post<any>(basebath, data).subscribe(
            (res) =>  {
                if (res === 11) {
                    this.oubliPassPhase2 = false;
                    this.oubliPassPhase1 = false;
                    this.newPassFinish = true;
                } else if (res === 44) {
                    this.messageEmailUnexist = 'Une erreur s\'est produite';
                }
                console.table('Réponse: ' + res);
                this.loading = false;
                this.errorRetour = res;
            },
            error => {
                console.table('Error: ' +  this.errorRetour);
                this.loading = false;
            }
        );

    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        let encryptedPass2 = '';
        // Maintenant, selon le password de confirmation est renseigné on l'encrypte
        if (this.f.password2.value) {
            encryptedPass2 = this.EncrDecr.set('123456$#@$^@1ERF', this.f.password2.value);
        }
        const encryptedPass = this.EncrDecr.set('123456$#@$^@1ERF', this.f.password.value);
        // console.log('PW:' + encryptedPass );

        // const decrypted = this.EncrDecr.get('123456$#@$^@1ERF', encrypted);
        this.authenticationService.login(this.f.username.value, encryptedPass, encryptedPass2)
            .pipe(first())
            .subscribe(
                data => { // redirection après login en fonction de la page
                    
                    console.table('Réponse: ' + data);
                    if (this.router.url.startsWith('/restaurants')) {
                        this.router.navigate([this.router.url]);
                    } else if (this.router.url.startsWith('/loisirs')) {
                        this.router.navigate([this.router.url]);
                    } else {
                        this.router.navigate(['/moncompte']);
                    }
                    
                },
                error => {
                    this.errorRetour = error;
                    this.loading = false;
                });
    }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { RestaurantsService } from '../_services/restaurants.service';
import { DisporestosService } from '../_services/disporestos.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { User } from '../_models';
import { AuthenticationService } from '../_services';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-restodetails',
  templateUrl: './restodetails.component.html',
  styleUrls: ['./restodetails.component.css'],
})
export class RestodetailsComponent implements OnInit, OnDestroy {
  restaurantsDetail: any;
  disporesto: any;
  interval: Subscription;
  idResto: number;
  disponibiltesMatin = [];
  disponibiltesMidi = [];
  disponibiltesSoir = [];
  jourEnteteCalendrier = [];
  date = new Date();
  srcPhotoSolo: string;
  title: string;
  lat: number;
  lng: number;
  zoom: number;

  currentUser: User;

  // tslint:disable-next-line: max-line-length
  constructor(private ordresService: RestaurantsService,
              private dispoService: DisporestosService,
              private route: ActivatedRoute,
              private datePipe: DatePipe,
              private authenticationService: AuthenticationService,
              private metaTagService: Meta,
              private titleService: Title) { }


  ngOnInit() {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    console.log(this.currentUser);

    //this.idResto = +this.route.snapshot.paramMap.get('id');

    this.route.params.subscribe(params => {
      this.idResto = params.id;
    });

    this.getRestoDetail();
    this.getRestoDispo();
    this.date.setDate( this.date.getDate());
    const day0 = this.jourEnFrancais(this.datePipe.transform(this.date, 'EEEEEE'));
    this.date.setDate( this.date.getDate() + 1 );
    const day1 = this.jourEnFrancais(this.datePipe.transform(this.date, 'EEEEEE'));
    this.date.setDate( this.date.getDate() + 1 );
    const day2 = this.jourEnFrancais(this.datePipe.transform(this.date, 'EEEEEE'));
    this.date.setDate( this.date.getDate() + 1 );
    const day3 = this.jourEnFrancais(this.datePipe.transform(this.date, 'EEEEEE'));
    this.date.setDate( this.date.getDate() + 1 );
    const day4 = this.jourEnFrancais(this.datePipe.transform(this.date, 'EEEEEE'));
    this.date.setDate( this.date.getDate() + 1 );
    const day5 = this.jourEnFrancais(this.datePipe.transform(this.date, 'EEEEEE'));
    this.date.setDate( this.date.getDate() + 1 );
    const day6 = this.jourEnFrancais(this.datePipe.transform(this.date, 'EEEEEE'));

    this.jourEnteteCalendrier = [
      day0,
      day1,
      day2,
      day3,
      day4,
      day5,
      day6,
    ];
  }

  getRestoDetail(): void {
      // const site = this.route.snapshot.params.site;
      this.interval = this.ordresService.servRestoDetail(this.idResto)
          .subscribe(restaurantsDetail => {
            this.restaurantsDetail = restaurantsDetail;
            this.lat = this.restaurantsDetail[0].latitude;
            this.lng = this.restaurantsDetail[0].longitude;
            this.title = this.restaurantsDetail[0].name;
            this.zoom = 15;
            this.titleService.setTitle(this.title + ' - ' + this.restaurantsDetail[0].locality + ' | ebook-card.ch');
            this.metaTagService.updateTag(
                { name : 'description', content: 'Réserver au ' + this.title +' - ' + this.restaurantsDetail[0].locality + ' à 50% pour deux personnes  '}
              //{ name: 'description', content: this.restaurantsDetail[0].description_id }
            );
            console.log(this.title);
          });
  }

  getRestoDispo(): void {
    this.interval = this.dispoService.servRestoDispo(this.idResto)
        .subscribe(disporesto => {
          this.disporesto = disporesto;
          this.disponibiltesMatin = disporesto.dispomidi;
          this.disponibiltesMidi = disporesto.dispomidi;
          this.disponibiltesSoir = disporesto.disposoir;
        });
  }

  changImgSrc(event) {
    this.restaurantsDetail[0].photoLWS = event.target.src;
  }

  ngOnDestroy() {
      this.interval.unsubscribe();
  }

  jourEnFrancais(day: string) {
    let dayReturn: string;
    if (day === 'Mo' ) {
      dayReturn = 'Lu';
    } else if (day === 'Tu') {
      dayReturn = 'Ma';
    } else if (day === 'We') {
      dayReturn = 'Me';
    } else if (day === 'Th') {
      dayReturn = 'Je';
    } else if (day === 'Fr') {
      dayReturn = 'Ve';
    } else if (day === 'Sa') {
      dayReturn = 'Sa';
    } else if (day === 'Su') {
      dayReturn = 'Di';
    }
    return dayReturn;
}

  dateClass = (d: Date) => {
    const date = d.getDate();
    return (date === 1 || date === 24) ? 'dateOuvert' : undefined;
    // return (date === 2 || date === 13) ? 'example-custom-date-classBis' : undefined;
  }
}

<input type="search" class="form-control filter-list-input margin_search" placeholder="Recherche par restaurant ou ville" aria-label="Filter" name="search" [(ngModel)]="searchText" >
<div *ngIf="!comptResto" class="spinner-border text-success" role="status">
  <span class="sr-only">...</span>
</div>
<div></div>
<!--partie mobile-->
<div class="cadre_margin">
  <div *ngFor="let restaurant of restaurants | myFilter:searchText:['locality', 'name', 'zipcode']; let i = index">
    <div class="Widget mobile row no-gutters ficherestos grid_flex"> <!-- Partie mobile -->
      <div class="img_center">
        <a routerLink="/restaurants/{{ restaurant.id }}/{{ restaurant.nameurl }}" target="_blank">
          <img src="{{ restaurant.photoLWS }}" class="img-thumbnail rounded-circle" alt="{{ restaurant.name }}" />
        </a>
      </div>
      <div>
        <a routerLink="/restaurants/{{ restaurant.id }}/{{ restaurant.nameurl }}" target="_blank">
          <h5 class="card_title">{{ restaurant.name }} </h5>
        </a>
      </div>
      <div>
        <div>
          <p class="text_locality">{{ restaurant.zipcode }}  {{ restaurant.locality }}</p>
        </div>
        <div class="text_info" *ngIf="restaurant.cookingtypes">
          <p class="margin">
            Cuisine {{ restaurant.cookingtypes  | lowercase   }}
          </p>
          <span *ngFor="let equipement of restaurant.equipments" class="">
            <img [src]="equipement" class="img-fluid pictolisteresto" style="border-radius:100px; max-width:85%;" />
          </span>
        <div>
          <span *ngFor="let deja of arrDejaResa">
            <img *ngIf="deja.id === restaurant.id" src="{{ consomme }}" class="img_conso" alt="Déjà consommé">
          </span>
        </div>
        </div>
      </div>
    </div>
    <!--partie desktop-->
    <div class="desktop no-gutters ficherestos grid_flex">
      <div class="img_center">
        <a routerLink="/restaurants/{{ restaurant.id }}/{{ restaurant.nameurl }}"target="_blank">
          <img src="{{ restaurant.photoLWS }}" class="img_resto" alt="{{ restaurant.name }}">
        </a>
      </div>
      <div class="flex_resto card_body">
        <a>
          <a routerLink="/restaurants/{{ restaurant.id }}/{{ restaurant.nameurl }}" target="_blank" >
          <h5 class="card_title">{{ restaurant.name }}</h5>
          </a>
          <p class="text_info marge">{{ restaurant.address1 }}</p>
          <p class="text_info marge">{{ restaurant.zipcode }}  {{ restaurant.locality }}</p>
         </a>
        <a *ngIf="restaurant.cookingtypes">
          <p class="text_info marge">Cuisine {{ restaurant.cookingtypes | lowercase  }} </p>
        </a>  
        <a class="text_info marge">
          <span *ngFor="let equipement of restaurant.equipments" class="">
            <img [src]="equipement" class="img-fluid pictolisteresto" style="border-radius:100px; max-width:85%;" />
          </span>
        </a>
      </div>
      <div>
        <span *ngFor="let deja of arrDejaResa">
          <img *ngIf="deja.id === restaurant.id" src="{{ consomme }}" class="img_conso" alt="Déjà consommé">
        </span>
      </div>
      <div class="flex_detail">
        <a routerLink="/restaurants/{{ restaurant.id }}/{{ restaurant.nameurl }}" target="_blank" class="btn-green">Voir détail</a>
      </div>
    </div>
    <div class="desktop hr_no_dsiplay">
      <hr class="hr-gray-soft">
    </div>
  </div>
</div>

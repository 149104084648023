<br class="desktop">
<br>
<div class="grid_body">
    <div></div>
    <div class="desktop">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home"
                    aria-selected="true">Mon compte</a>
            </li>
            <li class="nav-item" *ngIf="!cartevalide">
                <a class="nav-link" id="activer-tab" data-toggle="tab" href="#activer" role="tab"
                    aria-controls="activer" aria-selected="false">Activer carte</a>
            </li>
            
            <li class="nav-item" *ngIf="cartevalide">
                <a class="nav-link" id="carte-tab" data-toggle="tab" href="#carte" role="tab" aria-controls="carte"
                    aria-selected="false">Carte en cours</a>
            </li>
        
            <li class="nav-item" *ngIf="reservavenir || reservpasse">
                <a class="nav-link" id="reserve1-tab" data-toggle="tab" href="#reserve1" role="tab"
                    aria-controls="reserve1" aria-selected="false">Réservations restaurants</a>
            </li>
            <li class="nav-item" *ngIf="reservavenirLoisirs || reservpasseLoisirs">
                <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab"
                    aria-controls="contact" aria-selected="false">Réservations loisirs</a>
            </li>
            <li class="nav-item" *ngIf="currentUser.roleuser === 'Partner' || currentUser.roleuser === 'Admin' || currentUser.roleuser === 'Manager'">
                <a class="nav-link" id="partner-tab" data-toggle="tab" href="#partner" role="tab"
                    aria-controls="contact" aria-selected="false">Données partenaire</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div style="background-color: white;" class="tab-pane fade show active " id="home" role="tabpanel"
                aria-labelledby="home-tab">
                <div class="card-body">
                    <h5 class="card_title_depart card-title" *ngIf="currentUser.roleuser == 'Accounting' ">{{ firstname }} {{ lastname }}
                        <button type="button" class="btn edit btn-outline-primary" (click)="editData()"><i
                                class="fas fa-pencil-alt"></i> Modifier</button>
                    </h5>
                    <h5 class="card_title_depart card-title" *ngIf="currentUser.roleuser !== 'Accounting' ">{{ firstname }} {{ lastname }} <!-- <small style="color: red;">{{ currentUser.roleuser }}</small> -->
                        <button type="button" class="btn edit btn-outline-primary" (click)="editData()"><i
                                class="fas fa-pencil-alt"></i> Modifier</button>
                    </h5>
                    <br>
                    <div class="grid_card_page">
                        <!-- ICI les données de Mon compte -->
                        <div>
                            <div *ngFor="let carte of cartevalide"><img *ngIf="carte.Destination == 0"
                                    src="{{ img_gastro12 }}" /></div>
                            <div *ngFor="let carte of cartevalide"><img *ngIf="carte.Destination == 1"
                                    src="{{ img_loisirs }}" /></div>
                            <div *ngFor="let carte of cartevalide"><img *ngIf="carte.Destination == 2"
                                    src="{{ img_mixte }}" /></div>
                            <div *ngIf="cartevalide" class="name_for_img">{{ firstname }} {{ lastname }}</div>
                            <div class="valide_for_img" *ngFor="let carte of cartevalide">
                                N°:{{carte.CardId}} Valable du :{{ carte.StartValidity | dateForIpad:'DD.MM.YYYY' }} au
                                {{ carte.EndValidity | dateForIpad:'DD.MM.YYYY'}} </div>
                        </div>
                        <div class="grid_card" *ngIf="loading">
                            <ng-container *ngIf="!showCompteEdit">
                            <p class="padding_info card-text align_right">Adresse :</p>
                            <p class="card-text">{{ address1 }}</p>
                            <p><!-- <button type="submit" (click)="testSecuritySend()" class="btn btn-outline-danger" >testSecuritySend</button>--></p>
                            <p> {{ zipcode }} {{ locality }}</p>
                            <p class="padding_info card-text align_right">Adresse mail:</p>
                            <p class="card-text">{{ email }} </p>
                            <p class="padding_info card-text align_right" *ngIf="emailvalidated != 1"></p>
                            <p class="card-text text-danger" *ngIf="emailvalidated != 1"><span *ngIf="!messMailValidationEnvoye">Vous n'avez pas validé votre adresse</span><br />
                                <button *ngIf="!messMailValidationEnvoye" type="submit" (click)="resendMailValidation()" class="btn btn-outline-danger" >Renvoyer un mail de confirmation</button>
                                <span *ngIf="messMailValidationEnvoye">{{messMailValidationEnvoye}}</span>
                            </p>
                            <p class="padding_info card-text align_right">Tél : </p>
                            <p class="card-text">{{ mobilphone }} {{ homephone }}</p>
                            <p class="padding_info card-text align_right">Inscrit depuis le:</p>
                            <p class="card-text">{{ registrationdate | dateForIpad:'DD.MM.YYYY'}} </p>
                            <p class="padding_info card-text align_right" *ngIf="cartevalide==null">Numéro
                                de
                                carte:</p>
                            <p class="card-text text-danger" *ngIf="cartevalide==null">Aucune carte
                                n'est active</p>
                            <p class="padding_info card-text align_right" *ngFor="let carte of cartevalide">
                                Numéro de carte:</p>
                            <p style="font-weight: 900;" class="card-text"
                                *ngFor="let carte of cartevalide">{{ carte.CardId }} </p>
                            </ng-container>
                            <ng-container *ngIf="showCompteEdit">
                                <form class="grid_form" [formGroup]="profileForm" (ngSubmit)="onSubmit(profileForm.value)">
                                    <p>
                                        <label>Adresse email:</label>
                                        <input type="email" class="form-control" formControlName="formemail" value="{{ email }}" required>
                                        <span *ngIf="reponseEditCompte === 22" class="text-danger"><br/>Email invalide. Veuillez le resaisir.</span>
                                        <span *ngIf="emailExist" class="alert-danger">{{emailExist}}</span>
                                    </p>
                                    <p></p> <!--Ajoute un vide après mail (5%)-->
                                    <p></p> <!--Ajoute une case vide-->
                                    <p>
                                        <label>Nom:</label>
                                        <input type="text" class="form-control" formControlName="formlastName" value="{{ lastname }}" required> 
                                    </p>
                                    <p></p><!--Ajoute un vide après Nom (5%)-->
                                    <p>
                                        <label>Prénom:</label>
                                        <input type="text" class="form-control" formControlName="formfirstName" value="{{ firstname }}" required>
                                    </p>
                                    <p>
                                        <label>Rue et numéro:</label>
                                        <input type="text" class="form-control" formControlName="formaddress1" value="{{ address1 }}">
                                    </p>
                                    <p></p> <!--Ajoute un vide après Rue (5%)-->
                                    <p></p> <!--Ajoute une case vide-->
                                    <p>
                                        <label>NPA:</label>
                                        <input type="text" class="form-control" formControlName="formzipcode" value="{{ zipcode }}">
                                    </p>
                                    <p></p><!--Ajoute un vide après Nom (5%)-->
                                    <p>
                                        <label>Localité:</label>
                                        <input type="text" class="form-control" formControlName="formlocality" value="{{ locality }}">
                                    </p>
                                    <p>
                                        <label>Tél. portable:</label>
                                        <input type="text" class="form-control" formControlName="formmobilphone" value="{{ mobilphone }}">
                                        <span *ngIf="mobileExist" class="alert-danger">{{mobileExist}}</span>
                                    </p>
                                    <p></p><!--Ajoute un vide après Nom (5%)-->
                                    <p class="btn_enregistrer">
                                        <button type="submit" [ngClass]="[valueFormChange ? 'btn btn-sm btn-primary active': 'btn btn-sm btn-secondary']" [disabled]="!valueFormChange" >Enregistrer</button>
                                       
                                    </p>
                                </form>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <!--ICI activer carte quand on a pas-->
            <div style="background-color: white;" class="text-center tab-pane fade" id="activer" role="tabpanel"
                aria-labelledby="activer-tab">
                <div class="card-body">                    
                        <form *ngIf="afficheActiveCarte" class="form" [formGroup]="cardValidationForm" (ngSubmit)="submitCardValidation()">
                            <div class="text-center" style="width: 100%; min-height: 200px">
                                <h5 class="card_title_depart text-bleu-ebook" >Activer une carte</h5>
                                <label>Code de la carte: </label>
                                <input type="text" class=" text-center btn_activer_card" maxlenght="10" length="9" formControlName="codeValidation">
                                <br />
                                <button type="submit" [ngClass]="[codeValidationChange ? 'btn btn-sm btn-primary active': 'btn btn-sm btn-secondary']" [disabled]="!codeValidationChange" >Activer la carte</button>
                            </div>
                        </form>
                        <div *ngIf="!afficheActiveCarte">
                            <p>Avant d'activer une carte, veuillez confirmer vos coordonnées.</p>
                            <p>{{firstname}} {{lastname}}<br/>
                                {{address1}}<br />
                                {{zipcode}} {{locality}}</p>
                                <button style="margin-bottom: 10px;" type="submit" class="btn btn-sm btn-primary" (click)="afficheActiveCarte = true"  >L'adresse est exacte</button><br />
                                <a aria-selected="false" data-toggle="tab" href="#home" role="tab" aria-controls="home" (click)="btnModifierMonCompte()" >Modifier mes coordonnées dans "Mon compte"</a>
                        </div>
                        
                    <p class="text-success text-center" *ngIf="messMailValidationEnvoye" [innerHTML]="messMailValidationEnvoye"></p>
                    <p class="text-danger text-center" *ngIf="messMailValidationBug" [innerHTML]="messMailValidationBug"></p>
                    
                </div>
            </div>
            
            <!--ICI carte en cours quand on en a une-->  
            <div style="background-color: white;" class="tab-pane fade" id="carte" role="tabpanel"
                aria-labelledby="carte-tab">
                <div class="card-body">
                    <br>
                    <div class="grid_card_page">
                        <div>
                            <div *ngFor="let carte of cartevalide"><img *ngIf="carte.Destination == 0"
                                    src="{{ img_gastro12 }}" /></div>
                            <div *ngFor="let carte of cartevalide"><img *ngIf="carte.Destination == 1"
                                    src="{{ img_loisirs }}" /></div>
                            <div *ngFor="let carte of cartevalide"><img *ngIf="carte.Destination == 2"
                                    src="{{ img_mixte }}" /></div>
                            <div class="name_for_img">{{ firstname }} {{ lastname }}</div>
                            <div class="valide_for_img" *ngFor="let carte of cartevalide">
                                N°:{{carte.CardId}} Valable du :{{ carte.StartValidity | dateForIpad:'DD.MM.YYYY'}} au
                                {{ carte.EndValidity | dateForIpad:'DD.MM.YYYY'}} </div>
                        </div>
                        <div *ngIf="cartevalide">
                            <div class="grid_card" *ngFor="let carte of cartevalide">
                                <p class="padding_info  card-text align_right">Numéro de carte:</p>
                                <p class="card-text">{{ carte.CardId }} </p>
                                <p class="padding_info card-text align_right">Début de validité:</p>
                                <p class="card-text"> {{ carte.StartValidity | dateForIpad:'DD.MM.YYYY'}}</p>
                                <p style="font-weight:900" class="padding_info card-text align_right">Fin de validité:</p>
                                <p style="font-weight:900" class="card-text"> {{ carte.EndValidity | dateForIpad:'DD.MM.YYYY'}}</p>
                                <p class="padding_info  card-text align_right">Type de carte:</p>
                                <p class="card-text" *ngIf="carte.Type == 1">Virtuelle</p>
                                <p class="card-text" *ngIf="carte.Type == 2">Normale / physique</p>
                                <p class="card-text" *ngIf="carte.Type == 3">Normale / physique</p>
                                <p class="padding_info  card-text align_right">Carte pour :</p>
                                <p class="card-text" *ngIf="carte.Destination == 0">Restaurants</p>
                                <p class="card-text" *ngIf="carte.Destination == 1">Loisirs</p>
                                <p class="card-text" *ngIf="carte.Destination == 2">Restaurants & loisirs</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--ICI ancienne(s) carte(s)-->
            <div style="background-color: white;" class="tab-pane fade" id="profile" role="tabpanel"
                aria-labelledby="profile-tab">
                <div class="card-body" *ngIf="cartes">
                    <br>
                    <div></div> <!-- Div utiliser pour affficher la carte -->
                    <div *ngIf="cartes">
                        <div class="grid_card_ac" *ngFor="let carte of cartes.reverse()">
                            <p class="padding_info_ac  card-text align_right" style="font-weight: 900;">Numéro de carte:</p>
                            <p class="card-text" style="font-weight: 900;">{{ carte.CardId }} </p>
                            <p class="padding_info_ac  card-text align_right">Type de carte:</p>
                            <p class="card-text" *ngIf="carte.Type == 1">Virtuelle</p>
                            <p class="card-text" *ngIf="carte.Type == 2">Normale / physique</p>
                            <p class="card-text" *ngIf="carte.Type == 3">Normale / physique</p>
                            <p class="padding_info_ac  card-text align_right">Carte pour :</p>
                            <p class="card-text" *ngIf="carte.Destination == 0">Restaurants</p>
                            <p class="card-text" *ngIf="carte.Destination == 1">Loisirs</p>
                            <p class="card-text" *ngIf="carte.Destination == 2">Restaurants & loisirs</p>
                            <p class="padding_info_ac  card-text align_right">Date de début:</p>
                            <p class="card-text">{{ carte.StartValidity |dateForIpad:'DD.MM.YYYY'}}</p>
                            <p class="padding_info_ac  card-text align_right">Date de fin:</p>
                            <p class="card-text">{{ carte.EndValidity | dateForIpad:'DD.MM.YYYY'}}</p>
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
            <!--ICI réservation(s) restaurant à venir quand on en a-->
            <div style="background-color: white;" class="tab-pane fade" id="reserve1" role="tabpanel"
                aria-labelledby="reserve1-tab">
                <div class="card-body" *ngIf="reservavenir">
                    <div></div> <!-- Div utiliser pour affficher la carte -->
                    <div *ngIf="reservavenir">
                        <h5 class="padding_title card-title">Réservation(s) en cours</h5>
                        <div class="grid_reserv" *ngFor="let resas of reservavenir.reverse()">
                            <p class="date_size"  [innerHTML]="resas.Name"></p>
                            <div class="grid_card_ac">
                                <p class="padding_info_reserve card-text align_right">Date: </p>
                                <p class="card-text">{{ resas.DateTime | dateForIpad:'DD.MM.YYYY à HH:mm'  }}</p>
                                <p class="padding_info_reserve card-text align_right">Nb de personnes: </p>
                                <p class="card-text">{{ resas.PersonNumber }}</p>
                                <p class="padding_info_reserve card-text align_right">Code réservation: </p>
                                <p class="card-text">{{ resas.ReservationCode }}</p>
                                <p class="padding_info_reserve card-text align_right">Numéro de carte: </p>
                                <p class="card-text">{{ resas.CardNumber }}</p>
                                <br>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style="margin-top: 0px;">
                <!--ICI Ancienne(s) réservation restaurant quand on a-->
                <div class="card-body" *ngIf="reservpasse">
                    <div></div> <!-- Div utiliser pour affficher la carte -->
                    <div *ngIf="cartes">
                        <h5 class="padding_title card-title">Ancienne(s) réservation(s)</h5>
                        <div class="grid_reserv" *ngFor="let resas of reservpasse.reverse()">
                            <p class=" date_size" [innerHTML]="resas.Name"></p>
                            <div class="grid_card_ac">
                                <p class="padding_info_reserve card-text align_right">Date: </p>
                                <p class="card-text" >{{ resas.DateTime | dateForIpad:'DD.MM.YYYY à HH:mm'}}</p>
                                <p class="padding_info_reserve card-text align_right">Nb de personnes: </p>
                                <p class="card-text">{{ resas.PersonNumber }}</p>
                                <p class="padding_info_reserve card-text align_right">Code réservation: </p>
                                <p class="card-text">{{ resas.ReservationCode }}</p>
                                <p class="padding_info_reserve card-text align_right">Numéro de carte: </p>
                                <p class="card-text">{{ resas.CardNumber }}</p>
                                <br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--ICI réservation(s) loisirs à venir quand on en a-->
            <div style="background-color: white;" class="tab-pane fade" id="contact" role="tabpanel"
                aria-labelledby="contact-tab">
                <div class="card-body" *ngIf="reservavenirLoisirs">
                    <div></div> <!-- Div utiliser pour affficher la carte -->
                    <div *ngIf="reservavenirLoisirs">
                        <h5 class="padding_title card-title">Réservation(s) en cours</h5>
                        <div class="grid_reserv" *ngFor="let resas of reservavenirLoisirs.reverse()">
                            <p class="date_size" [innerHTML]="resas.Name"></p>
                            <div class="grid_card_ac">
                                <p class="padding_info_reserve card-text align_right">Date: </p> 
                                <p class="card-text">{{ resas.DateTime | dateForIpad:'DD.MM.YYYY' }}
                                    <span *ngIf="resas.PartOfTheDay === '0'"> - le matin</span>
                                    <span *ngIf="resas.PartOfTheDay === '1'"> - l'après-midi</span>
                                    <span *ngIf="resas.PartOfTheDay === '2'"> - en soirée</span>
                                </p>
                                <p class="padding_info_reserve card-text align_right">Nb de d'adultes: </p>
                                <p class="card-text">{{ resas.AdultsNumber }}</p>
                                <p class="padding_info_reserve card-text align_right">Nb d'enfants: </p>
                                <p class="card-text">{{ resas.ChildrenNumber }}</p>
                                <p class="padding_info_reserve card-text align_right">Code réservation: </p>
                                <p class="card-text">{{ resas.ReservationCode }}</p>
                                <p class="padding_info_reserve card-text align_right">Numéro de carte: </p>
                                <p class="card-text">{{ resas.CardNumber }}</p>
                                <br>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style="margin-top: 0px;">
                <!--ICI Ancienne(s) réservation losirs quand on a-->
                <div class="card-body" *ngIf="reservpasseLoisirs">
                    <div></div> <!-- Div utiliser pour affficher la carte -->
                    <div *ngIf="cartes">
                        <h5 class="padding_title card-title">Ancienne(s) réservation(s)</h5>
                        <div class="grid_reserv" *ngFor="let resas of reservpasseLoisirs.reverse()">
                            <p class="date_size" [innerHTML]="resas.Name"></p>
                            <div class="grid_card_ac">
                                <p class="padding_info_reserve card-text align_right">Date: </p> 
                                <p class="card-text">{{ resas.DateTime | dateForIpad:'DD.MM.YYYY' }}
                                    <span *ngIf="resas.PartOfTheDay === '0'"> - le matin</span>
                                    <span *ngIf="resas.PartOfTheDay === '1'"> - l'après-midi</span>
                                    <span *ngIf="resas.PartOfTheDay === '2'"> - en soirée</span>
                                </p>
                                <p class="padding_info_reserve card-text align_right">Nb de d'adultes: </p> 
                                <p class="card-text" >{{ resas.AdultsNumber }} </p>
                                <p class="padding_info_reserve card-text align_right">Nb d'enfants: </p> 
                                <p class="card-text" >{{ resas.ChildrenNumber }}</p>
                                <p class="padding_info_reserve card-text align_right">Code réservation: </p>
                                <p class="card-text" >{{ resas.ReservationCode }}</p>
                                <p class="padding_info_reserve card-text align_right">Numéro de carte: </p>
                                <p class="card-text">{{ resas.CardNumber }}</p>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--ICI pour les information du partenaire restos & loisirs-->
            <div style="background-color: white;" class="tab-pane fade" id="partner" role="tabpanel"
                aria-labelledby="partner-tab">
                <div *ngIf="!showDetails">
                    <div class="card-body" *ngIf="mesLoisirs || mesRestos">
                        <div></div> <!-- Div utiliser pour affficher la carte -->
                        <div *ngIf="mesLoisirsCount > 0">
                            <h4 class="text-bleu-ebook partenaire_name">Mes loisirs</h4>
                            <div *ngFor="let loissir of mesLoisirs.reverse()">
                                <p class="date_size">{{ loissir.Name }} </p>     
                                <p style="text-align: center;">{{ loissir.Address1 }}<br />
                                        {{ loissir.ZipCode }} {{ loissir.Locality }} 
                                </p>
                                <p >
                                    <button type="button" class="btn edit btn-outline-primary" (click)="editLoisir(loissir.Id)">
                                        <i class="fas fa-pencil-alt"> Modifier</i>
                                    </button>
                                </p>
                                <br>
                                <div><hr></div>
                            </div>
                        </div>
                        <br/>
                        <div *ngIf="mesRestosCount > 0">
                            <h4 class="text-bleu-ebook partenaire_name">Mes restaurants</h4>
                            <div *ngFor="let restos of mesRestos.reverse()">
                                <p class="date_size">{{ restos.Name }}</p>
                                <p style="text-align: center;">{{ restos.Address1 }}<br />
                                        {{ restos.ZipCode }} {{ restos.Locality }} 
                                </p>
                                <p>
                                    <button type="button" class="btn edit btn-outline-primary" (click)="editResto(restos.Id)">
                                        <i class="fas fa-pencil-alt"> Modifier</i>
                                    </button>
                                </p>
                                <br>
                                <div><hr></div>
                            </div>
                        </div>
                    </div>
                </div>
                <app-editpartner *ngIf="showDetails" (showDetails)="dataFromChild($event)" [idPartner]="currentUser.id" [idObjet]="idObjet" [isResto]="isResto"></app-editpartner>
        </div>
        <br>
        <div></div>
    </div>
</div>
<!--Version mobile-->
<div class="mobile">
    <div class="accordion" id="accordionExample">
        <div class="card">
            <div class="card-header" id="headingOne">
                <h2 class="mb-0">
                    <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne"
                        aria-expanded="true" aria-controls="collapseOne">
                        <h5 style="color: black;"> Mon compte </h5>
                    </button>
                </h2>
            </div>
            <!--Mon compte-->
            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                <div class="card-body">
                    <button type="button" class="btn edit btn-outline-primary" (click)="editData()"><i
                        class="fas fa-pencil-alt"></i> Modifier</button>
                    <h5 class="card_title_depart card-title" *ngIf="currentUser.roleuser !== 'Accounting' ">{{ firstname }} {{ lastname }} <!-- <small style="color:red ;">{{ currentUser.roleuser }}</small> --></h5>
                    <h5 class=" card_title_depart card-title"*ngIf="currentUser.roleuser == 'Accounting' ">{{ firstname }} {{ lastname }}
                    </h5>
                    <br>
                    <div class="grid_moncompte">
                        <p class=" card-text align_right">Adresse:</p>
                        <p class=" card-text align_right">{{ address1 }}</p>
                        <p class=" card-text align_right"></p>
                        <p class=" mobile_adresse">{{ zipcode }} {{ locality }}</p>
                        <p class=" card-text align_right">Adresse mail:</p>
                        <p class=" card-text align_right">{{ email }}</p>

                        <p class="card-text align_right" *ngIf="emailvalidated != 1"></p>
                        <p class="card-text align_right text-danger" *ngIf="emailvalidated != 1"><span *ngIf="!messMailValidationEnvoye">Vous n'avez pas validé votre adresse</span><br />
                            <button *ngIf="!messMailValidationEnvoye" type="submit" (click)="resendMailValidation()" class="btn btn-outline-danger" >Renvoyer un mail de confirmation</button>
                            <span *ngIf="messMailValidationEnvoye">{{messMailValidationEnvoye}}</span>
                        </p>

                        <p class=" card-text align_right">Tél.:</p>
                            <p class=" card-text align_right">{{ mobilphone }}
                                {{ homephone }} </p>
                        <p class=" card-text align_right">Inscrit depuis le:</p>
                        <p class=" card-text align_right">{{ registrationdate | dateForIpad:'DD.MM.YYYY' }}</p>
                        <p class="padding_info card-text align_right" *ngIf="cartevalide==null">N° de carte:</p>
                        <p class="card-text text-danger" *ngIf="cartevalide==null">Aucune carte n'est active</p>
                        <div class="grid_card" *ngFor="let carte of cartevalide">
                            <p style="font-weight: 900;" class="padding_info card-text align_right">N° de carte:</p>
                        </div>
                        <div class="grid_card" *ngFor="let carte of cartevalide">
                            <p style="font-weight: 900;" class="padding_info card-text align_right">{{ carte.CardId }}</p>
                        </div>
                    </div>
                </div>
                <!--Partie formulaire-->
                <div *ngIf="loading">
                    <ng-container *ngIf="showCompteEdit">
                        <form class="padding_form" [formGroup]="profileForm" (ngSubmit)="onSubmit(profileForm.value)">
                            <hr>
                            <p style="font-weight: 900;">Modifier vos données</p>
                            <p>
                                <label>Adresse email (user):</label>
                                <input style="padding-left: 2px;" type="email" class="form-control" formControlName="formemail" value="{{ email }}" required>
                                <span *ngIf="reponseEditCompte === 22" class="text-danger"><br/>Email invalide. Veuillez le resaisir.</span>
                                <span *ngIf="emailExist" class="alert-danger">{{emailExist}}</span>
                            </p>
                            <p>
                                <label>Nom:</label>
                                <input style="padding-left: 2px;" type="text" class="form-control" formControlName="formlastName" value="{{ lastname }}" required> 
                            </p>
                            <p>
                                <label>Prénom:</label>
                                <input style="padding-left: 2px;" type="text" class="form-control" formControlName="formfirstName" value="{{ firstname }}" required>
                            </p>
                            <p>
                                <label>Rue et numéro:</label>
                                <input style="padding-left: 2px;" type="text" class="form-control" formControlName="formaddress1" value="{{ address1 }}">
                            </p>
                            <p>
                                <label>NPA:</label>
                                <input style="padding-left: 2px;" type="text" class="form-control" formControlName="formzipcode" value="{{ zipcode }}">
                            </p>
                            <p>
                                <label>Localité:</label>
                                <input style="padding-left: 2px;" type="text" class="form-control" formControlName="formlocality" value="{{ locality }}">
                            </p>
                            <p>
                                <label>Tél. portable (+417x xxx xx xx):</label>
                                <input style="padding-left: 2px;" type="text" class="form-control" formControlName="formmobilphone" value="{{ mobilphone }}">
                                <span *ngIf="mobileExist" class="alert-danger">{{mobileExist}}</span>
                            </p>
                            <p class="btn_enregistrer">
                                <button type="submit" [ngClass]="[valueFormChange ? 'btn btn-sm btn-primary active': 'btn btn-sm btn-secondary']" [disabled]="!valueFormChange" >Enregistrer</button>
                                
                            </p>
                        </form>
                    </ng-container>
                    
                    <!--FIN partie formulaire-->
                </div>
                <!--Détermine l'image de la carte-->
                <div style="text-align: center;">
                    <div *ngFor="let carte of cartevalide"><img *ngIf="carte.Destination == 0"
                            src="{{ img_gastro12 }}" /></div>
                    <div *ngFor="let carte of cartevalide"><img *ngIf="carte.Destination == 1"
                            src="{{ img_loisirs }}" /></div>
                    <div *ngFor="let carte of cartevalide"><img *ngIf="carte.Destination == 2"
                            src="{{ img_mixte }}" /></div>
                    <div *ngIf="cartevalide" class="name_for_img">{{ firstname }}
                        {{ lastname }}</div>
                    <div class="valide_for_img" *ngFor="let carte of cartevalide">
                        N°:{{carte.CardId}} Valable du :{{ carte.StartValidity | dateForIpad:'DD.MM.YYYY'}} au
                        {{ carte.EndValidity | dateForIpad:'DD.MM.YYYY'}} </div>
                </div>
                <br>
            </div>
            <!--Fin de mon compte-->
            <!--Onglet activer carte quand il n'y a pas de carte active-->
            <div class="card-header" id="heading1" *ngIf="!cartevalide">
                <h2 class="mb-0">
                    <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse2"
                        aria-expanded="true" aria-controls="collapse2">
                        <h5 style="color: black;"> Activer carte </h5>
                    </button>
                </h2>
            </div>
            <div  id="collapse2" class="collapse" aria-labelledby="heading2" data-parent="#accordionExample">
                <form *ngIf="afficheActiveCarte" class="form" [formGroup]="cardValidationForm" (ngSubmit)="submitCardValidation()">
                    <div class="text-center" style="width: 100%; min-height: 200px">
                        <h5 class="card_title_depart text-bleu-ebook" >Activer une carte</h5>
                        <label>Code de la carte: </label>
                        <input type="text" class=" text-center btn_activer_card" maxlenght="10" length="9" formControlName="codeValidation">
                        <br />
                        <button type="submit" [ngClass]="[codeValidationChange ? 'btn btn-sm btn-primary active': 'btn btn-sm btn-secondary']" [disabled]="!codeValidationChange" >Activer la carte</button>
                    </div>
                </form>
             
                
                <div style="text-align: center;" *ngIf="!afficheActiveCarte">
                    <p>Avant d'activer une carte, veuillez confirmer vos coordonnées.</p>
                    <p>{{firstname}} {{lastname}}<br/>
                        {{address1}}<br />
                        {{zipcode}} {{locality}}</p>
                        <button style="margin-bottom: 10px;" type="submit" class="btn btn-sm btn-primary" (click)="afficheActiveCarte = true"  >L'adresse est exacte</button><br />
                        <a aria-selected="false" data-toggle="tab" href="#" role="tab" aria-controls="home" (click)="btnModifierMonCompte()" >Modifier mon adresse dans "Mon compte"</a>
                </div>
                
                <!--Formulaire pour modifer adresse avant activation carte-->
                <div *ngIf="loading">
                    <ng-container *ngIf="showCompteEdit">
                        <form class="padding_form" [formGroup]="profileForm" (ngSubmit)="onSubmit(profileForm.value)">
                            <hr>
                            <p style="font-weight: 900;">Modifier vos données</p>
                            <p>
                                <label>Adresse email (user):</label>
                                <input style="padding-left: 2px;" type="email" class="form-control" formControlName="formemail" value="{{ email }}" required>
                                <span *ngIf="reponseEditCompte === 22" class="text-danger"><br/>Email invalide. Veuillez le resaisir.</span>
                                <span *ngIf="emailExist" class="alert-danger">{{emailExist}}</span>
                            </p>
                            <p>
                                <label>Nom:</label>
                                <input style="padding-left: 2px;" type="text" class="form-control" formControlName="formlastName" value="{{ lastname }}" required> 
                            </p>
                            <p>
                                <label>Prénom:</label>
                                <input style="padding-left: 2px;" type="text" class="form-control" formControlName="formfirstName" value="{{ firstname }}" required>
                            </p>
                            <p>
                                <label>Rue et numéro:</label>
                                <input style="padding-left: 2px;" type="text" class="form-control" formControlName="formaddress1" value="{{ address1 }}">
                            </p>
                            <p>
                                <label>NPA:</label>
                                <input style="padding-left: 2px;" type="text" class="form-control" formControlName="formzipcode" value="{{ zipcode }}">
                            </p>
                            <p>
                                <label>Localité:</label>
                                <input style="padding-left: 2px;" type="text" class="form-control" formControlName="formlocality" value="{{ locality }}">
                            </p>
                            <p>
                                <label>Tél. portable (+417x xxx xx xx):</label>
                                <input style="padding-left: 2px;" type="text" class="form-control" formControlName="formmobilphone" value="{{ mobilphone }}">
                                <span *ngIf="mobileExist" class="alert-danger">{{mobileExist}}</span>
                            </p>
                            <p class="btn_enregistrer">
                                <button type="submit" [ngClass]="[valueFormChange ? 'btn btn-sm btn-primary active': 'btn btn-sm btn-secondary']" [disabled]="!valueFormChange" >Enregistrer</button>
                                
                            </p>
                        </form>
                    </ng-container>
                </div>
                <!--Formulaire pour modifer adresse avant activation carte-->
                <p class="text-success text-center" *ngIf="messMailValidationEnvoye" [innerHTML]="messMailValidationEnvoye"></p>
                <p class="text-danger text-center" *ngIf="messMailValidationBug" [innerHTML]="messMailValidationBug"></p>
            </div>

            <!--Carte en cours-->
            <div class="card-header" id="heading1" *ngIf="cartevalide">
                <h2 class="mb-0">
                    <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse1"
                        aria-expanded="true" aria-controls="collapse1">
                        <h5 style="color: black;"> Carte en cours </h5>
                    </button>
                </h2>
            </div>
            <div id="collapse1" class="collapse" aria-labelledby="heading1" data-parent="#accordionExample">
                <div class="card-body" *ngIf="cartevalide">
                    <div>
                        <div class="grid_card" *ngFor="let carte of cartevalide">
                            <p style="font-weight: 900;" class="padding_info card-text align_right">Numéro de carte: {{ carte.CardId }}
                            </p>
                            <p class="padding_info card-text align_right">Début de validité:
                                {{ carte.StartValidity | dateForIpad:'DD.MM.YYYY'}}</p>
                            <p style="font-weight: 900;" class="padding_info card-text align_right">Fin de validité:
                                {{ carte.EndValidity | dateForIpad:'DD.MM.YYYY'}}</p>
                            <p class="card-text" *ngIf="carte.Type == 1">Type de carte: Virtuelle</p>
                            <p class="card-text" *ngIf="carte.Type == 2">Type de carte: Normale / physique</p>
                            <p class="card-text" *ngIf="carte.Type == 3">Type de carte: Normale / physique</p>
                            <p class="card-text" *ngIf="carte.Destination == 0">Carte pour: Restaurants</p>
                            <p class="card-text" *ngIf="carte.Destination == 1">Carte pour: Loisirs</p>
                            <p class="card-text" *ngIf="carte.Destination == 2">Carte pour: Restaurants &
                                loisirs</p>
                        </div>
                        <br>
                        <div style="text-align: center;">
                            <div *ngFor="let carte of cartevalide"><img *ngIf="carte.Destination == 0"
                                    src="{{ img_gastro12 }}" /></div>
                            <div *ngFor="let carte of cartevalide"><img *ngIf="carte.Destination == 1"
                                    src="{{ img_loisirs }}" /></div>
                            <div *ngFor="let carte of cartevalide"><img *ngIf="carte.Destination == 2"
                                    src="{{ img_mixte }}" /></div>
                            <div class="name_for_img">{{ firstname }} {{ lastname }}</div>
                            <div class="valide_for_img" *ngFor="let carte of cartevalide">
                                N°:{{carte.CardId}} Valable du :{{ carte.StartValidity | dateForIpad:'DD.MM.YYYY'}} au
                                {{ carte.EndValidity | dateForIpad:'DD.MM.YYYY'}} </div>
                        </div>
                        <br>
                    </div>
                </div>
            </div>

            
            <!--Réservation restaurant en cours et ancienne (onglet visible si on a une valeur)-->
            <div class="card-header" id="headingThree" *ngIf="reservavenir || reservpasse">
                <h2 class="mb-0">
                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                        data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <h5 style="color: black;"> Réservations restaurants </h5>
                    </button>
                </h2>
            </div>
            <!--Réservations restaurant en cours-->
            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                <div class="card-body">
                    <div class="card-body" *ngIf="reservavenir">
                        <h5 class=" card_title_depart card-title">Réservation(s) en cours</h5>
                        <br>
                        <div class="grid_reserv" *ngFor="let resas of reservavenir.reverse()">
                            <p class="date_size" [innerHTML]="resas.Name"></p>
                            <div class="grid_card">
                                <p class="card-text align_right">Date: {{ resas.DateTime | dateForIpad:'DD.MM.YYYY à HH:mm'  }}</p>
                                <p class="card-text align_right">Nb de personnes : {{ resas.PersonNumber }}</p>
                                <p class="card-text align_right">Code réservation : {{ resas.ReservationCode }}</p>
                                <p class="card-text align_right">Numéro de carte: {{ resas.CardNumber }}</p>
                                <br>
                            </div>
                        </div>
                    </div>
                    <hr style="margin-top: 0px;">
                    <!--Anciennes réservations restaurant-->
                    <div class="card-body" *ngIf="reservpasse">
                        <h5 class=" card_title_depart card-title">Ancienne(s) réservation(s)</h5>
                        <br>
                        <div class="grid_reserv" *ngFor="let resas of reservpasse.reverse()">
                            <p class=" date_size" [innerHTML]="resas.Name"></p>
                            <div class="grid_card">
                                <p class="card-text align_right">Date: {{ resas.DateTime | dateForIpad:'DD.MM.YYYY à HH:mm'}}</p>
                                <p class="card-text align_right">Nb de personnes: {{ resas.PersonNumber }}</p>
                                <p class="card-text align_right">Code réservation: {{ resas.ReservationCode }}</p>
                                <p class="card-text align_right">Numéro de carte : {{ resas.CardNumber }}</p>
                                <br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Réservation loisirs en cours et ancienne-->
            <div class="card-header" id="headingThree" *ngIf="reservavenirLoisirs || reservpasseLoisirs">
                <h2 class="mb-0">
                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse4"
                        aria-expanded="false" aria-controls="collapse4">
                        <h5 style="color: black;"> Réservations loisirs</h5>
                    </button>
                </h2>
            </div>
            <!--Réservations loisir en cours-->
            <div id="collapse4" class="collapse" aria-labelledby="heading4" data-parent="#accordionExample">
                <div class="card-body" *ngIf="reservavenirLoisirs || reservpasseLoisirs">
                    <div class="card-body" *ngIf="reservavenirLoisirs">
                        <h5 class=" card_title_depart card-title">Réservation(s) en cours</h5>
                        <br>
                        <div class="grid_reserv" *ngFor="let resas of reservavenirLoisirs.reverse()">
                            <p class="date_size" [innerHTML]="resas.Name"></p>
                            <div class="grid_card">
                                <p class="card-text align_right">Date: {{ resas.DateTime | dateForIpad:'DD.MM.YYYY' }}
                                    <span *ngIf="resas.PartOfTheDay === '0'">Le matin</span>
                                    <span *ngIf="resas.PartOfTheDay === '1'">L'après-midi</span>
                                    <span *ngIf="resas.PartOfTheDay === '2'">En soirée</span>
                                </p>
                                <p class="card-text align_right">Nb de d'adultes: {{ resas.AdultsNumber }}</p>
                                <p class="card-text align_right">Nb d'enfants: {{ resas.ChildrenNumber }}</p>
                                <p class="card-text align_right">Code réservation:{{ resas.ReservationCode }}</p>
                                <p class="card-text align_right">Numéro de carte : {{ resas.CardNumber }}</p>
                                <br>
                            </div>
                        </div>
                    </div>
                    <hr style="margin-top: 0px;">
                    <!--Anciennes réservations loisir-->
                    <div class="card-body" *ngIf="reservpasseLoisirs">
                        <h5 class=" card_title_depart card-title">Ancienne(s) réservation(s)</h5>
                        <br>
                        <div class="grid_reserv" *ngFor="let resas of reservpasseLoisirs">
                            <p class="date_size" [innerHTML]="resas.Name"></p>
                            <div class="grid_card">
                                <p class="card-text align_right">Date: {{ resas.DateTime | dateForIpad:'DD.MM.YYYY' }}
                                    <span *ngIf="resas.PartOfTheDay === '0'">Le matin</span>
                                    <span *ngIf="resas.PartOfTheDay === '1'">L'après-midi</span>
                                    <span *ngIf="resas.PartOfTheDay === '2'">En soirée</span>
                                </p>
                                <p class="card-text align_right">Nb de d'adultes: {{ resas.AdultsNumber }}</p>
                                <p class="card-text align_right">Nb d'enfants: {{ resas.ChildrenNumber }}</p>
                                <p class="card-text align_right">Code réservation : {{ resas.ReservationCode }}</p>
                                <p class="card-text align_right">Numéro de carte : {{ resas.CardNumber }}</p>
                                <br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <!--Partie Partenaire -->
            <div class="card-header" id="headingThree" *ngIf="currentUser.roleuser === 'Partner' || currentUser.roleuser === 'Admin' || currentUser.roleuser === 'Manager'">
                <h2 class="mb-0">
                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse5"
                        aria-expanded="false" aria-controls="collapse5">
                        <h5 style="color: black;"> Données partenaire</h5>
                    </button>
                </h2>
            </div>
             <!--ICI pour les information du partenaire restos & loisirs-->
             <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordionExample">
             <div *ngIf="!showDetails">
                 <div class="card-body" *ngIf="mesLoisirs || mesRestos">
                     <div *ngIf="mesLoisirsCount > 0">
                         <h4 style="margin-bottom: 25px;" class="text-bleu-ebook partenaire_name">Mes loisirs</h4>
                         <div class="grid_reserv" *ngFor="let loissir of mesLoisirs.reverse()">
                             <p style="font-weight: 900;">{{ loissir.Name }}</p>
                             <p style="margin-bottom: 0px;">Adresse: {{ loissir.Address1 }} </p>    
                             <p style="padding-left: 69px;"> {{ loissir.ZipCode }} {{ loissir.Locality }}</p>
                             <p ><button type="button" class="btn edit btn-outline-primary" (click)="editLoisir(loissir.Id)"><i
                                class="fas fa-pencil-alt"></i></button></p>
                             <br>
                         </div>
                     </div>
                     <hr style="margin-top: 0px;">
                     <div *ngIf="mesRestosCount > 0">
                         <h4 style="margin-bottom: 25px;" class="text-bleu-ebook partenaire_name">Mes restaurants</h4>
                         <div class="grid_reserv" *ngFor="let restos of mesRestos.reverse()">
                             <p style="font-weight: 900;">{{ restos.Name }}</p>  
                             <p style="margin-bottom: 0px;">Adresse: {{ restos.Address1 }}</p>    
                             <p style="padding-left: 69px;"> {{ restos.ZipCode }} {{ restos.Locality }} </p>
                             <p class="card-text"><button type="button" class="btn edit btn-outline-primary" (click)="editResto(restos.Id)"><i
                                class="fas fa-pencil-alt"></i></button></p>
                             <br>
                         </div>
                     </div>
                 </div>
             </div>
             <app-editpartner *ngIf="showDetails" (showDetails)="dataFromChild($event)" [idPartner]="currentUser.id" [idObjet]="idObjet" [isResto]="isResto"></app-editpartner>
     </div>
            
        </div>
    </div>
</div>
<br>
<!--
<p class="aligne_hauteur" [innerHTML]="resas.Name"></p>
-->
<br />
<div class="container" >
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active':tabactive === 1}"  data-toggle="tab" href="#gastro" role="tab" 
          aria-selected="true" (click)="menuClick(1)">Gastronomie</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active':tabactive === 2}" data-toggle="tab" href="#loisirs" role="tab" 
          aria-selected="false"  (click)="menuClick(2)">Loisirs</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active':tabactive === 3}" data-toggle="tab" href="#mixtes" role="tab" 
          aria-selected="false"  (click)="menuClick(3)">Mixtes</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active':tabactive === 4}" data-toggle="tab" href="#toutes" role="tab" 
          aria-selected="false"  (click)="menuClick(4)">Toutes nos cartes</a>
    </li>
  </ul>
</div>
<div class="container boutiquefond" >

  <app-boutique-liste 
      [category]="category"
      [urlPourAppel]="urlPourAppel" 
      [titreCat]="titreCat">
    </app-boutique-liste>
    
</div>


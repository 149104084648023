<br> 

<div class="grid_container">
    <div></div>
    <div class="bold_blue">
    À propos de nous
    </div>
    <div></div>
    <div></div>
    <p class="font_white"><span class="bold-blue-e">e</span>book-card.ch a été créé en 2013 avec l’ambition de révolutionner notre façon d’aller au restaurant à prix préfèrentiels avec une solution numérique novatrice de réservation en ligne en mode 2.0.</p>
    <br><br>
    <div></div>
    <p class="font_white">Quatre ans plus tard, en 2017, <span class="bold-blue-e">e</span>book-card.ch lance la partie dédiée aux loisirs et a une ambition de développement toujours aussi forte pour vous faire partager ces bons moments avec la possibilité d’avoir une seule carte pour découvrir la gastronomie et les loisirs sans limite géographique.</p>
    <br><br>
    <div></div>
    <p class="font_white">Notre dynamique startup a pour mission d’être au cœur de l’innovation technologique pour offrir une prestation à la hauteur des exigences des clients comme des partenaires..</p>
    <br><br>
    <div></div>
    <p class="font_white">Maintenant c’est à vous d’en découvrir tous les avantages.</p>
    <div></div>
    <div></div>
    <div></div>
</div>
<div class="grid_bas">
    <p></p>
    <p></p>
    <img class="img_size" src="./assets/images/logo_smiley_2017web.png" alt="">
</div>
<br>
<br>
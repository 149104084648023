<div  class="margin_menu">
  <div id="carouselExampleSlidesOnly" class="carousel slide padding-none" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="{{ imgTitre }}" class="d-block w-100" alt="...">
      </div>
    </div>
  </div>
</div>

<div class="bod">
  <div class="grid_flex">
    <div class="gauche"></div>
    <div class="col col_padding">
      <div class="box-green">Affiner la recherche</div>
      <div class="accordion" id="accordionExample">
        <div class="card text-white"> 
          <div class="card-header" id="headingOne">
            <h2 class="mb-0 title_filter">
              <button class="btn btn-link title_filter title_h3" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Type de cuisine
              </button>
            </h2>
          </div>
          <!-- Partie filtre ouvert par défaut pour les desktop -->  
          <div id="collapseOne" class="collapse show desktop" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div class="card-body" *ngIf="compte">
              <div class="form-check" *ngFor="let cuisine of sortByCuisine('name')">
                <div [style.display]="!listTypeCuisines ? 'block' : 'none'"><img class="img-responsive" width="70px" src="assets/images/wait.png"></div>
                <input class="form-check-input" type="checkbox" value="{{ cuisine.id }}" (click)="clickFiltreTypes(cuisine.id)" id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                  {{cuisine.name}}
                </label>
              </div>
            </div>
          </div>
          <!-- Fin partie filtre ouvert par défaut pour les desktop -->
          <!-- Partie filtre fermé par défaut pour les mobiles -->
          <div id="collapseOne" class="collapse mobile" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div class="card-body" *ngIf="compte">
              <div class="form-check" *ngFor="let cuisine of listTypeCuisines">
                <div [style.display]="!listTypeCuisines ? 'block' : 'none'"><img class="img-responsive" width="70px" src="assets/images/wait.png"></div>
                <input class="form-check-input" type="checkbox" value="{{ cuisine.id }}" (click)="clickFiltreTypes(cuisine.id)" id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                  {{cuisine.name}}
                </label>
              </div>
            </div>
          </div>
          <!-- Fin partie filtre fermé par défaut pour les mobiles -->
        </div>
        <div class="card fondGrisFonce text-white">
          <div class="card-header" id="headingTwo">
            <h2 class="mb-0 title_filter">
              <button class="btn btn-link title_filter title_h3" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Canton
              </button>
            </h2>
          </div>
          <!-- Partie filtre ouvert par défaut pour les desktop -->
          <div id="collapseTwo" class="collapse show desktop" aria-labelledby="headingTwo" data-parent="#accordionExample">
            <div class="card-body" *ngIf="compteCantons">
              <div class="form-check" *ngFor="let canton of listCantons">
                <div [style.display]="!listCantons ? 'block' : 'none'"><img class="img-responsive" width="70px" src="assets/images/wait.png"></div>
                <input class="form-check-input" type="checkbox" value="{{ canton.id }}" (click)="clickFiltreCantons(canton.id)" id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                  {{canton.name}}
                </label>
              </div>
            </div>
          </div>
          <!-- Fin partie filtre ouvert par défaut pour les desktop -->
          <!-- Partie filtre fermé par défaut pour les mobiles -->
          <div id="collapseTwo" class="collapse mobile" aria-labelledby="headingTwo" data-parent="#accordionExample">
            <div class="card-body" *ngIf="compteCantons">
              <div class="form-check" *ngFor="let canton of listCantons">
                <div [style.display]="!listCantons ? 'block' : 'none'"><img class="img-responsive" width="70px" src="assets/images/wait.png"></div>
                <input class="form-check-input" type="checkbox" value="{{ canton.id }}" (click)="clickFiltreCantons(canton.id)" id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                  {{canton.name}}
                </label>
              </div>
            </div>
          </div>
          <!-- Fin artie filtre fermé par défaut pour les mobiles -->
        </div>
        <div class="card fondGrisFonce text-white">
          <div class="card-header" id="headingThree">
            <h2 class="mb-0 title_filter">
              <button class="btn btn-link title_filter title_h3" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Gamme de prix
              </button>
            </h2>
          </div>
          <!-- Partie filtre ouvert par défaut pour les desktop -->
          <div id="collapseThree" class="collapse show desktop" aria-labelledby="headingThree" data-parent="#accordionExample">
            <div class="card-body">
              <div class="form-check" *ngFor="let prix of sortByPrix('id')">
                <div [style.display]="!listPrix ? 'block' : 'none'"><img class="img-responsive" width="70px" src="assets/images/wait.png"></div>
                <input class="form-check-input" type="checkbox" value="{{ prix.id }}" (click)="clickFiltrePrix(prix.id)" id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                  {{prix.name}}
                </label>
              </div>
            </div>
          </div>
          <!-- Fin partie filtre ouvert par défaut pour les desktop -->
          <!-- Partie filtre fermé par défaut pour les mobiles -->
          <div id="collapseThree" class="collapse mobile" aria-labelledby="headingThree" data-parent="#accordionExample">
            <div class="card-body">
              <div class="form-check" *ngFor="let prix of listPrix">
                <div [style.display]="!listPrix ? 'block' : 'none'"><img class="img-responsive" width="70px" src="assets/images/wait.png"></div>
                <input class="form-check-input" type="checkbox" value="{{ prix.id }}" (click)="clickFiltrePrix(prix.id)" id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                  {{prix.name}}
                </label>
              </div>
            </div>
          </div>
          <!-- Fin partie filtre fermé par défaut pour les mobiles -->
        </div>

        <div class="card fondGrisFonce text-white">
          <div class="card-header" id="headingEquip">
            <h2 class="mb-0 title_filter">
              <button class="btn btn-link title_filter title_h3" type="button" data-toggle="collapse" data-target="#collapseEquip" aria-expanded="false" aria-controls="collapseEquip">
                Equipements
              </button>
            </h2>
          </div>
          <!-- Partie filtre ouvert par défaut pour les desktop -->
          <div id="collapseEquip" class="collapse show desktop" aria-labelledby="headingEquip" data-parent="#accordionExample">
            <div class="card-body">
              <div class="form-check" *ngFor="let equip of listeEquipements">
                <div [style.display]="!listeEquipements ? 'block' : 'none'"><img class="img-responsive" width="70px" src="assets/images/wait.png"></div>
                <input class="form-check-input" type="checkbox" value="{{equip.id}}" (click)="clickFiltreEquip(equip.id)" id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                  {{equip.name}} 
                </label>
              </div>
            </div>
          </div>
          <!-- Fin partie filtre ouvert par défaut pour les desktop -->
          <!-- Partie filtre fermé par défaut pour les mobiles -->
          <div id="collapseEquip" class="collapse mobile" aria-labelledby="headingEquip" data-parent="#accordionExample">
            <div class="card-body">
              <div class="form-check" *ngFor="let equip of listeEquipements">
                <div [style.display]="!listeEquipements ? 'block' : 'none'"><img class="img-responsive" width="70px" src="assets/images/wait.png"></div>
                
                <input class="form-check-input" type="checkbox" value="{{equip.id}}" (click)="clickFiltreEquip(equip.id)" id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                  {{equip.name}} 
                </label>
              </div>
            </div>
          </div>
          <!-- Fin partie filtre fermé par défaut pour les mobiles -->
          
        </div>

        <div class="card fondGrisFonce text-white">
          <div class="card-header" id="headingFour">
            <h2 class="mb-0 title_filter">
              <button class="btn btn-link title_filter title_h3" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                Jours d'ouverture
              </button>
            </h2>
          </div>
          <!-- Partie filtre ouvert par défaut pour les desktop -->
          <div id="collapseFour" class="collapse show desktop" aria-labelledby="headingFour" data-parent="#accordionExample">
            <div class="card-body">
              <div class="form-check" *ngFor="let ouvert of joursOuverts">
                <div [style.display]="!joursOuverts ? 'block' : 'none'"><img class="img-responsive" width="70px" src="assets/images/wait.png"></div>
                <input *ngIf="midiHeure === 'KO' && ouvert.value === '77'" class="form-check-input" type="checkbox" value="{{ ouvert.value }}" disabled id="defaultCheck1">
                <input *ngIf="midiHeure === 'OK' && ouvert.value === '77'" class="form-check-input" type="checkbox" value="{{ ouvert.value }}" (click)="clickFiltreOuverture(ouvert.value)" id="defaultCheck1">
                <input *ngIf="soirHeure === 'KO' && ouvert.value === '99'" class="form-check-input" type="checkbox" value="{{ ouvert.value }}" disabled id="defaultCheck1">
                <input *ngIf="soirHeure === 'OK' && ouvert.value === '99'" class="form-check-input" type="checkbox" value="{{ ouvert.value }}" (click)="clickFiltreOuverture(ouvert.value)" id="defaultCheck1">
                <input *ngIf="ouvert.value !== '77' && ouvert.value !== '99'" class="form-check-input" type="checkbox" value="{{ ouvert.value }}" (click)="clickFiltreOuverture(ouvert.value)" id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                  {{ouvert.libelle}} 
                </label>
              </div>
            </div>
          </div>
          <!-- Fin partie filtre ouvert par défaut pour les desktop -->
          <!-- Partie filtre fermé par défaut pour les mobiles -->
          <div id="collapseFour" class="collapse mobile" aria-labelledby="headingFour" data-parent="#accordionExample">
            <div class="card-body">
              <div class="form-check" *ngFor="let ouvert of joursOuverts">
                <div [style.display]="!joursOuverts ? 'block' : 'none'"><img class="img-responsive" width="70px" src="assets/images/wait.png"></div>
                
                <input class="form-check-input" type="checkbox" value="{{ ouvert.value }}" (click)="clickFiltreOuverture(ouvert.value)" id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                  {{ouvert.libelle}} 
                </label>
              </div>
            </div>
          </div>
          <!-- Fin partie filtre fermé par défaut pour les mobiles -->
          
        </div>




        
        <!--
        <div class="card fondGrisFonce text-white">
                <div class="card-header" id="headingFour">
                    <h2 class="mb-0">
                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                        Equipements
                    </button>
                    </h2>
                </div>
                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                    <div class="card-body">
                            Pas encore développé
                    </div>
                </div>
        </div> -->
      </div>
    </div>
    <div>
      <app-restaurants [restaurants]="restaurants" [comptResto]="comptResto"></app-restaurants>
    </div>
  </div>
</div>

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerConfig, NgbDate, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { LoisirsService } from '../_services/loisirs.service';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from '../_services';
import { UserService } from '../_services';
import { User } from '../_models';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AppConstants } from '../_helpers/app.constants';

@Component({
  selector: 'app-reservationloisir',
  templateUrl: './reservationloisir.component.html',
  styleUrls: ['./reservationloisir.component.css'],
  providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}] // define custo
})

export class ReservationloisirComponent implements OnInit, OnDestroy {

  constructor(config: NgbDatepickerConfig,
              private route: ActivatedRoute,
              private datePipe: DatePipe,
              private ordresService: LoisirsService,
              private authenticationService: AuthenticationService,
              private infousers: UserService,
              private modalService: NgbModal,
              private httpClient: HttpClient) {
    const now = new Date();
    this.yearhMin = + now.getFullYear();
    this.monthMin = + now.getMonth() + 1;
    this.dayMin = + now.getDate();
    const max = this.add_months(now, 11); // Ajoute 11 mois à  now pour le max du calendrier
    this.yearhMax = + max.getFullYear();
    this.monthMax = + max.getMonth() + 1;
    this.dayMax = + max.getDate();
    config.minDate = {year: this.yearhMin, month: this.monthMin, day: this.dayMin};
    config.maxDate = {year: this.yearhMax, month: this.monthMax, day: this.dayMax};

    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      this.currentUserId = x.id;
      }
    );


  }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  // ________ DEBUT DECLARATION DES VARIABLES __________________________________//
  basebath = AppConstants.baseURL + '/postData/postreservationloisir.php?token=cPnFKCqNQLKw';
  basebathCancel = AppConstants.baseURL + '/postData/postreservationrannulee.php?token=cPnFKCqNQLKw';
  currentUser: User;
  currentUserRole: string;
  currentUserId: string;
  currentUserDateFin: string;
  peutAnnuler = true;
  infosUser = [];
  ceLoisirDejaReserve = false;
  laDateChoisieDepasse = false;
  arrDejaResa = [];
  monthMin = 0; yearhMin = 0; dayMin = 0; monthMax = 0; yearhMax = 0; dayMax = 0;
  vientMatin = false;
  vientMidi = false;
  vientSoir = false;
  telOui = false;
  telNon = false;
  btnEnvoyerActif = false;
  resaRefus = false;
  nbPerson1 = false; nbPerson2 = false; nbPerson3 = false; nbPerson4 = false; nbPerson5 = false; nbPerson6 = false;
  resrationOK = false;
  nbPersonnesSelectionne = 0;
  nbrmaxpersonperbooking: number;
  nbrminpersonperbooking: number;
  isResto = 0;
  nbrmaxAdults: number;
  nbrmaxEnfants: number;
  nbrtotalPers = 0;
  heureSelectionne: string;
  heureBaseDeDonnes: string;
  model: NgbDateStruct;
  idResto: number;
  listeMoitie = []; listeClosed = []; listeOpen = [];
  listeMoitieNext1 = []; listeClosedNext1 = []; listeOpenNext1 = [];
  listeMoitieNext2 = []; listeClosedNext2 = []; listeOpenNext2 = [];
  listeMoitieNext3 = []; listeClosedNext3 = []; listeOpenNext3 = [];
  listeMoitieNext4 = []; listeClosedNext4 = []; listeOpenNext4 = [];
  listeMoitieNext5 = []; listeClosedNext5 = []; listeOpenNext5 = [];
  listeMoitieNext6 = []; listeClosedNext6 = []; listeOpenNext6 = [];
  listeMoitieNext7 = []; listeClosedNext7 = []; listeOpenNext7 = [];
  listeMoitieNext8 = []; listeClosedNext8 = []; listeOpenNext8 = [];
  listeMoitieNext9 = []; listeClosedNext9 = []; listeOpenNext9 = [];
  listeMoitieNext10 = []; listeClosedNext10 = []; listeOpenNext10 = [];
  listeMoitieNext11 = []; listeClosedNext11 = []; listeOpenNext11 = [];
  currentMonthDays = []; nextMonthDay = []; nextMonthDay2 = [];
  interval: Subscription;
  arrCarteValide = [];
  dispoService = [];
  carteValide: number;
  aCarteValide = [];
  carteNumber: number;
  carteType: number;
  nbrAdultsArr = [];
  nbrEnfantsArr = [];
  partoftheday: number;
  reservationmodes: string;
  nbrAdult = 0;
  nbrEnfant = 0;
  messagePersonnes: string;
  leDayIsCloed = false;
  matinFerme = false;
  midiFerme = false;
  soirFerme = false;
  leTableauPersonnes = false;
  attendsTelephone = false;
  jsonArrayPers = [];
  arrInfosLoisirs = [];
  laDateChoisie = '';
  laDateChoisie2 = '';
  laDateChoisie3 = '';
  nomPrestataire = '';
  secondsLeft: number;
  minutesLeft: number;
  intervalTimer;
  numeroTelPrestataire = '';
  closeResult: string;
  reponseReservation: number;
  reponseResaRetour = false;
  closeModal = false;
  valueAdult = 0;
  valueEnfant = 0;
  prixEnfant = 0;
  prixAdult = 0;
  reductionEnfant = 0;
  reductionAdult = 0;
  leTotal = 0;
  leTotalStr = '';
  discountVert = 0;
  listMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];  // Tableau pour tomber sur le bon mois mëme avec 13
  thisMonth = this.listMonth[new Date().getMonth()];
  nextMonth1 = this.listMonth[new Date().getMonth() + 1];
  nextMonth2 = this.listMonth[new Date().getMonth() + 2];
  nextMonth3 = this.listMonth[new Date().getMonth() + 3];
  nextMonth4 = this.listMonth[new Date().getMonth() + 4];
  nextMonth5 = this.listMonth[new Date().getMonth() + 5];
  nextMonth6 = this.listMonth[new Date().getMonth() + 6];
  nextMonth7 = this.listMonth[new Date().getMonth() + 7];
  nextMonth8 = this.listMonth[new Date().getMonth() + 8];
  nextMonth9 = this.listMonth[new Date().getMonth() + 9];
  nextMonth10 = this.listMonth[new Date().getMonth() + 10];
  nextMonth11 = this.listMonth[new Date().getMonth() + 11];
  today = new Date();
  leJour: number = + this.datePipe.transform(this.today, 'dd');
  codeResa: number;
  // ________ FIN DECLARATION DES VARIABLES __________________________________// const leJour = + this.datePipe.transform(dt, 'dd');

  isDisabled = (date: NgbDate, current: {month: number}) => date.month !== current.month;
  isOld = (current: {day: number, month: number}) => current.day < this.leJour  && current.month === this.thisMonth;

  isClosed = (current: {day: number, month: number}) => this.listeClosed.includes(current.day) && current.month === this.thisMonth;
  isMoitie = (current: {day: number, month: number}) => this.listeMoitie.includes(current.day) && current.month === this.thisMonth;
  isOpen =   (current: {day: number, month: number}) => this.listeOpen.includes(current.day) && current.month === this.thisMonth;


  isClosedNext1 = (current: {day: number, month: number}) =>
    this.listeClosedNext1.includes(current.day) && current.month === this.nextMonth1
  isMoitieNext1 = (current: {day: number, month: number}) =>
    this.listeMoitieNext1.includes(current.day) && current.month === this.nextMonth1
  isOpenNext1 = (current: {day: number, month: number}) =>
    this.listeOpenNext1.includes(current.day) && current.month === this.nextMonth1

  isClosedNext2 = (current: {day: number, month: number}) =>
    this.listeClosedNext2.includes(current.day) && current.month === this.nextMonth2
  isMoitieNext2 = (current: {day: number, month: number}) =>
    this.listeMoitieNext2.includes(current.day) && current.month === this.nextMonth2
  isOpenNext2 = (current: {day: number, month: number}) =>
    this.listeOpenNext2.includes(current.day) && current.month === this.nextMonth2

  isClosedNext3 = (current: {day: number, month: number}) =>
    this.listeClosedNext3.includes(current.day) && current.month === this.nextMonth3
  isMoitieNext3 = (current: {day: number, month: number}) =>
    this.listeMoitieNext3.includes(current.day) && current.month === this.nextMonth3
  isOpenNext3 = (current: {day: number, month: number}) =>
    this.listeOpenNext3.includes(current.day) && current.month === this.nextMonth3

  isClosedNext4 = (current: {day: number, month: number}) =>
    this.listeClosedNext4.includes(current.day) && current.month === this.nextMonth4
  isMoitieNext4 = (current: {day: number, month: number}) =>
    this.listeMoitieNext4.includes(current.day) && current.month === this.nextMonth4
  isOpenNext4 = (current: {day: number, month: number}) =>
    this.listeOpenNext4.includes(current.day) && current.month === this.nextMonth4

  isClosedNext5 = (current: {day: number, month: number}) =>
    this.listeClosedNext5.includes(current.day) && current.month === this.nextMonth5
  isMoitieNext5 = (current: {day: number, month: number}) =>
    this.listeMoitieNext5.includes(current.day) && current.month === this.nextMonth5
  isOpenNext5 = (current: {day: number, month: number}) =>
    this.listeOpenNext5.includes(current.day) && current.month === this.nextMonth5

  isClosedNext6 = (current: {day: number, month: number}) =>
    this.listeClosedNext6.includes(current.day) && current.month === this.nextMonth6
  isMoitieNext6 = (current: {day: number, month: number}) =>
    this.listeMoitieNext6.includes(current.day) && current.month === this.nextMonth6
  isOpenNext6 = (current: {day: number, month: number}) =>
    this.listeOpenNext6.includes(current.day) && current.month === this.nextMonth6

  isClosedNext7 = (current: {day: number, month: number}) =>
    this.listeClosedNext7.includes(current.day) && current.month === this.nextMonth7
  isMoitieNext7 = (current: {day: number, month: number}) =>
    this.listeMoitieNext7.includes(current.day) && current.month === this.nextMonth7
  isOpenNext7 = (current: {day: number, month: number}) =>
    this.listeOpenNext7.includes(current.day) && current.month === this.nextMonth7

  isClosedNext8 = (current: {day: number, month: number}) =>
    this.listeClosedNext8.includes(current.day) && current.month === this.nextMonth8
  isMoitieNext8 = (current: {day: number, month: number}) =>
    this.listeMoitieNext8.includes(current.day) && current.month === this.nextMonth8
  isOpenNext8 = (current: {day: number, month: number}) =>
    this.listeOpenNext8.includes(current.day) && current.month === this.nextMonth8

  isClosedNext9 = (current: {day: number, month: number}) =>
    this.listeClosedNext9.includes(current.day) && current.month === this.nextMonth9
  isMoitieNext9 = (current: {day: number, month: number}) =>
    this.listeMoitieNext9.includes(current.day) && current.month === this.nextMonth9
  isOpenNext9 = (current: {day: number, month: number}) =>
    this.listeOpenNext9.includes(current.day) && current.month === this.nextMonth9

  isClosedNext10 = (current: {day: number, month: number}) =>
    this.listeClosedNext10.includes(current.day) && current.month === this.nextMonth10
  isMoitieNext10 = (current: {day: number, month: number}) =>
    this.listeMoitieNext10.includes(current.day) && current.month === this.nextMonth10
  isOpenNext10 = (current: {day: number, month: number}) =>
    this.listeOpenNext10.includes(current.day) && current.month === this.nextMonth10

  isClosedNext11 = (current: {day: number, month: number}) =>
    this.listeClosedNext11.includes(current.day) && current.month === this.nextMonth11
  isMoitieNext11 = (current: {day: number, month: number}) =>
    this.listeMoitieNext11.includes(current.day) && current.month === this.nextMonth11
  isOpenNext11 = (current: {day: number, month: number}) =>
    this.listeOpenNext11.includes(current.day) && current.month === this.nextMonth11

  // dans Angular 0 pour occupé ou fermé, 1 pour partiel, 2 pour ouvert
  getServiceDispo(): void {
    this.dispoService = [];
    this.interval = this.ordresService.servLoisirsResa(this.idResto).subscribe(jourscalendrierBis => {
      this.dispoService = jourscalendrierBis;
      this.reservationmodes = jourscalendrierBis.infos.reservationmodes;
      this.nomPrestataire = jourscalendrierBis.infos.name;
      this.numeroTelPrestataire = jourscalendrierBis.infos.homephone;
      this.arrInfosLoisirs = jourscalendrierBis.infos;
      if (jourscalendrierBis.listeprix[0]) {
        this.prixAdult =  +parseFloat(jourscalendrierBis.listeprix[0].prix).toFixed(2);
        this.reductionAdult =  +parseFloat(jourscalendrierBis.listeprix[0].reduction).toFixed(2);
      }
      if (jourscalendrierBis.listeprix[1]) {
        this.prixEnfant = +parseFloat(jourscalendrierBis.listeprix[1].prix).toFixed(2);
        this.reductionEnfant = +parseFloat(jourscalendrierBis.listeprix[1].reduction).toFixed(2);
      }
      // console.table(jourscalendrierBis);
      this.getArrDays(jourscalendrierBis);
      this.getNbPersonnes(jourscalendrierBis.infos);
     });
  }

  getNbPersonnes(infos) {
    this.nbrmaxpersonperbooking = infos.nbrmaxpersonperbooking;
    this.nbrminpersonperbooking = infos.nbrminpersonperbooking;
    // Cette boucle fait un tableau pour créer les boutons du nombre de personnes
    if (this.leTableauPersonnes === false) {
      for (let i = 1; i <= this.nbrmaxpersonperbooking; i++) {
        const myArrPer = { nombre: i, class: 'inactifa'};
        const myArrPerEnfants = { nombre: i, class: 'inactife'};
        this.nbrAdultsArr.push(myArrPer);
        this.nbrEnfantsArr.push(myArrPerEnfants);
      }
      this.leTableauPersonnes = true;
    }
  }

  // la fonction qui est lancée lorsque le client choisit un nb de personnes
  resaBtnPersonnes(event: any) {
    let ensembleTotal = 0;
    const messageTrop = 'Le maximum de personnes est ' + this.nbrmaxpersonperbooking;

    if (event.target.id === 'adults') {
      const nbAdult = parseInt(event.target.name, 10);
      ensembleTotal =  nbAdult + this.nbrEnfant;
      if (ensembleTotal > this.nbrmaxpersonperbooking) {
        this.messagePersonnes = messageTrop;
      } else if (nbAdult === this.nbrAdult) {
        this.nbrtotalPers = this.nbrEnfant;
        this.nbrAdult = 0;
        this.nbrAdultsArr[(nbAdult - 1)].class = 'inactifa';
      } else {
        this.messagePersonnes = '';
        this.nbrAdult = nbAdult;
        for (let i = 1; i <= this.nbrmaxpersonperbooking; i++) {
          this.nbrAdultsArr[(i - 1)].class = 'inactifa';
        }
        this.nbrAdultsArr[(nbAdult - 1)].class = 'actifa';
        this.nbrtotalPers = ensembleTotal;
      }
    } else if (event.target.id === 'enfants') {
      const nbEnfant = parseInt(event.target.name, 10);
      ensembleTotal =  nbEnfant + this.nbrAdult;
      if (ensembleTotal > this.nbrmaxpersonperbooking) {
        this.messagePersonnes = messageTrop;
      } else if (nbEnfant === this.nbrEnfant) {
        this.nbrtotalPers = this.nbrAdult;
        this.nbrEnfant = 0;
        this.nbrEnfantsArr[(nbEnfant - 1)].class = 'inactife';
      } else {
        this.messagePersonnes = '';
        this.nbrEnfant = nbEnfant;
        for (let i = 1; i <= this.nbrmaxpersonperbooking; i++) {
          this.nbrEnfantsArr[(i - 1)].class = 'inactife';
        }
        this.nbrEnfantsArr[(nbEnfant - 1)].class = 'actife';
        this.nbrtotalPers = ensembleTotal;
      }
    }
    this.activeBtnResa();
    // Pour effectuer le calcul du prix
    this.valueAdult = this.nbrAdult;
    this.valueEnfant = this.nbrEnfant;
  }

  // Fonction qui construit le tableau pour le calendrier
  getArrDays(jourscalendrier) {
    console.log('This M ' + this.thisMonth);
    this.listeClosed = [];
    this.listeClosedNext1 = [];
    this.listeClosedNext2 = [];
    this.listeClosedNext3 = [];
    this.listeClosedNext4 = [];
    this.listeClosedNext5 = [];
    this.listeClosedNext6 = [];
    this.listeClosedNext7 = [];
    this.listeClosedNext8 = [];
    this.listeClosedNext9 = [];
    this.listeClosedNext10 = [];
    this.listeClosedNext11 = [];

    this.listeMoitie = [];
    this.listeMoitieNext1 = [];
    this.listeMoitieNext2 = [];
    this.listeMoitieNext3 = [];
    this.listeMoitieNext4 = [];
    this.listeMoitieNext5 = [];
    this.listeMoitieNext6 = [];
    this.listeMoitieNext7 = [];
    this.listeMoitieNext8 = [];
    this.listeMoitieNext9 = [];
    this.listeMoitieNext10 = [];
    this.listeMoitieNext11 = [];

    this.listeOpen = [];
    this.listeOpenNext1 = [];
    this.listeOpenNext2 = [];
    this.listeOpenNext3 = [];
    this.listeOpenNext4 = [];
    this.listeOpenNext5 = [];
    this.listeOpenNext6 = [];
    this.listeOpenNext7 = [];
    this.listeOpenNext8 = [];
    this.listeOpenNext9 = [];
    this.listeOpenNext10 = [];
    this.listeOpenNext11 = [];
    for (const lannee in jourscalendrier) {
      if (jourscalendrier[lannee]) {
        for (const leMois in jourscalendrier[lannee]) {
          if (jourscalendrier[lannee][leMois]) {
            let leJourInt = 0;
            // console.log('lemois: ' + leMois);
            // console.table(jourscalendrier[lannee]);
            for (const leJour in jourscalendrier[lannee][leMois]) {
              if (jourscalendrier[lannee][leMois][leJour].general === 0) {
                
                const moisNB = + leMois;
                if (this.thisMonth === moisNB) {
                  leJourInt = + leJour;
                  this.listeClosed.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth1 === moisNB) {
                  leJourInt = + leJour;
                  this.listeClosedNext1.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth2 === moisNB) {
                  leJourInt = + leJour;
                  this.listeClosedNext2.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth3 === moisNB) {
                  leJourInt = + leJour;
                  this.listeClosedNext3.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth4 === moisNB) {
                  leJourInt = + leJour;
                  this.listeClosedNext4.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth5 === moisNB) {
                  leJourInt = + leJour;
                  this.listeClosedNext5.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth6 === moisNB) {
                  leJourInt = + leJour;
                  this.listeClosedNext6.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth7 === moisNB) {
                  leJourInt = + leJour;
                  this.listeClosedNext7.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth8 === moisNB) {
                  leJourInt = + leJour;
                  this.listeClosedNext8.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth9 === moisNB) {
                  leJourInt = + leJour;
                  this.listeClosedNext9.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth10 === moisNB) {
                  leJourInt = + leJour;
                  this.listeClosedNext10.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth11 === moisNB) {
                  leJourInt = + leJour;
                  this.listeClosedNext11.push(leJourInt);  // On ajoute les jours dans le tableau
                }
              } else if (jourscalendrier[lannee][leMois][leJour].general === 1) {
                const moisNB = + leMois;
                if (this.thisMonth === moisNB) {
                  leJourInt = + leJour;
                  this.listeMoitie.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth1 === moisNB) {
                  leJourInt = + leJour;
                  this.listeMoitieNext1.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth2 === moisNB) {
                  leJourInt = + leJour;
                  this.listeMoitieNext2.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth3 === moisNB) {
                  leJourInt = + leJour;
                  this.listeMoitieNext3.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth4 === moisNB) {
                  leJourInt = + leJour;
                  this.listeMoitieNext4.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth5 === moisNB) {
                  leJourInt = + leJour;
                  this.listeMoitieNext5.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth6 === moisNB) {
                  leJourInt = + leJour;
                  this.listeMoitieNext6.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth7 === moisNB) {
                  leJourInt = + leJour;
                  this.listeMoitieNext7.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth8 === moisNB) {
                  leJourInt = + leJour;
                  this.listeMoitieNext8.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth9 === moisNB) {
                  leJourInt = + leJour;
                  this.listeMoitieNext9.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth10 === moisNB) {
                  leJourInt = + leJour;
                  this.listeMoitieNext10.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth11 === moisNB) {
                  leJourInt = + leJour;
                  this.listeMoitieNext11.push(leJourInt);  // On ajoute les jours dans le tableau
                }
              } else if (jourscalendrier[lannee][leMois][leJour].general === 2) {
                const moisNB = + leMois;
                if (this.thisMonth === moisNB) {
                  leJourInt = + leJour;
                  this.listeOpen.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth1 === moisNB) {
                  leJourInt = + leJour;
                  this.listeOpenNext1.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth2 === moisNB) {
                  leJourInt = + leJour;
                  this.listeOpenNext2.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth3 === moisNB) {
                  leJourInt = + leJour;
                  this.listeOpenNext3.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth4 === moisNB) {
                  leJourInt = + leJour;
                  this.listeOpenNext4.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth5 === moisNB) {
                  leJourInt = + leJour;
                  this.listeOpenNext5.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth6 === moisNB) {
                  leJourInt = + leJour;
                  this.listeOpenNext6.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth7 === moisNB) {
                  leJourInt = + leJour;
                  this.listeOpenNext7.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth8 === moisNB) {
                  leJourInt = + leJour;
                  this.listeOpenNext8.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth9 === moisNB) {
                  leJourInt = + leJour;
                  this.listeOpenNext9.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth10 === moisNB) {
                  leJourInt = + leJour;
                  this.listeOpenNext10.push(leJourInt);  // On ajoute les jours dans le tableau
                } else if (this.nextMonth11 === moisNB) {
                  leJourInt = + leJour;
                  this.listeOpenNext11.push(leJourInt);  // On ajoute les jours dans le tableau
                }
              }
            }
          }
        }
      }
    }
  }

  updateMyDate( dt: any ): void {
    const lannee = + this.datePipe.transform(dt, 'yyyy');
    const leMois = + this.datePipe.transform(dt, 'MM');
    const leJour = + this.datePipe.transform(dt, 'dd');
    this.laDateChoisie = this.datePipe.transform(dt, 'dd-MM-yyyy');
    this.laDateChoisie2 = this.datePipe.transform(dt, 'dd.MM.yyyy');
    this.laDateChoisie3 = this.datePipe.transform(dt, 'yyyy.MM.dd');

    if (this.dispoService[lannee][leMois][leJour].matin === 0) {
      this.matinFerme = true;
      console.log('Midi fermé');
    } else {
      this.matinFerme = false;
    }
    if (this.dispoService[lannee][leMois][leJour].midi === 0) {
      this.midiFerme = true;
      console.log('Midi fermé');
    } else {
      this.midiFerme = false;
    }
    if (this.dispoService[lannee][leMois][leJour].soir === 0) {
      this.soirFerme = true;
      console.log('Soir fermé');
    } else {
      this.soirFerme = false;
    }

    // Ici on signale que le jour est totalement fermé ou pas
    if (this.dispoService[lannee][leMois][leJour].general === 1 || this.dispoService[lannee][leMois][leJour].general === 2) {
      this.leDayIsCloed = false;
      console.log('Jour non fermé');
    } else if (this.dispoService[lannee][leMois][leJour].general === 0) {
      this.leDayIsCloed = true;
      this.matinFerme = true;
      this.midiFerme = true;
      this.soirFerme = true;
      console.log('Jour non fermé');
    }
  }

  partOfDay(part: number) {
    if (part === 0) {
      this.partoftheday = part;
      this.vientMatin = !this.vientMatin;
      this.vientMidi = false;
      this.vientSoir = false;
      if (this.vientMatin === false) {
        this.heureSelectionne = '0';
        this.partoftheday = 99;  // juste pour remettre à zéro.
      } else {
        this.heureSelectionne = 'Matinée';
        this.heureBaseDeDonnes = '13:00';
      }
    } else if (part === 1) {
      this.partoftheday = part;
      this.vientMidi = !this.vientMidi;
      this.vientMatin = false;
      this.vientSoir = false;
      if (this.vientMidi === false) {
        this.heureSelectionne = '0';
        this.partoftheday = 99;  // juste pour remettre à zéro.
      } else {
        this.heureSelectionne = 'Après-midi';
        this.heureBaseDeDonnes = '19:00';
      }
    } else if (part === 2) {
      this.partoftheday = part;
      this.vientSoir = !this.vientSoir;
      this.vientMatin = false;
      this.vientMidi = false;
      if (this.vientSoir === false) {
        this.heureSelectionne = '0';
        this.partoftheday = 99;  // juste pour remettre à zéro.
      } else {
        this.heureSelectionne = 'Soirée';
        this.heureBaseDeDonnes = '23:00';
      }
    } else {
      this.partoftheday = 99;  // juste pour remettre à zéro.
    }

    // On regarde si toutes les valeurs sont entrée pour activer le bouton "Réserver"
    this.activeBtnResa();
  }

  activeBtnResa() {
    const min = this.nbrminpersonperbooking;
    const max = this.nbrmaxpersonperbooking;
    const arrPartDay = [0, 1, 2];
    if (arrPartDay.includes(this.partoftheday) && this.nbrtotalPers >= min && this.nbrtotalPers <= max) {
      this.resrationOK = true;
      console.log('Resa OK ' + this.partoftheday + ' - nb: ' + this.nbrtotalPers);
    } else {
      this.resrationOK = false;
      console.log('Resa pas OK ' + this.partoftheday + ' - nb: ' + this.nbrtotalPers);
    }
  }

  add_months(dt, n) {
   return new Date(dt.setMonth(dt.getMonth() + n));
  }

  open(content) {
    this.reponseReservation = 99;
    this.attendsTelephone = false;
    this.onClickCalcul();
    this.minutesLeft = 29;  // 1
    this.secondsLeft = 59;  // 19
    this.codeResa = Math.floor(100000 + Math.random() * 900000);
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed:  ${reason}`;
      if (this.peutAnnuler) {
        this.postResaCanceled();
      }

    });

    // On regarde si la validité de la carte est au-delà de la date de réservation
    const dateLimiteValide = Date.parse(this.currentUserDateFin);
    const dateDeReas = Date.parse(this.laDateChoisie3);
    if (dateDeReas > dateLimiteValide) {
      this.laDateChoisieDepasse = true;
    } else {
      this.reponseResaRetour = true;
      this.laDateChoisieDepasse = false;
      this.startTimer();
    }


  }

  // Compte à rebours pour la fenêtre modal
  startTimer() {
    this.intervalTimer = setInterval(() => {
      if (this.secondsLeft > 0) {
        this.secondsLeft--;
      } else {
        this.secondsLeft = 60;
        this.minutesLeft--;
      }
      if (this.minutesLeft === 0 && this.secondsLeft === 0) {
        // ici il faudra fermer la fenêtre et c'est tout
        this.minutesLeft = 0;
        clearInterval(this.intervalTimer);
        this.modalService.dismissAll();
      }
    }, 1000);
  }

  // Si click sur OUI ou NON pour le téléphone
  telOuiNon(ouinon) {
    if (ouinon === 'oui') {
      this.telOui = true;
      this.telNon = false;
      this.resaRefus = false;
      this.btnEnvoyerActif = true;
    } else {
      this.telOui = false;
      this.telNon = true;
      this.resaRefus = false;
      this.btnEnvoyerActif = true;
    }
  }
  // si clique sur la coche "Le loisris a refusé ma réservation"
  resaRefusee() {
    this.telOui = false;
    this.telNon = false;
    this.resaRefus = !this.resaRefus;
    if (this.resaRefus) {
      this.btnEnvoyerActif = true;
    }
  }

  postResaToDB() {
    this.onClickCalcul();

    const data = {
      adultsnumber: this.nbrAdult,
      childrennumber: this.nbrEnfant,
      partoftheday: this.partoftheday,
      heure: this.heureBaseDeDonnes,
      date: this.laDateChoisie,
      userid: this.currentUserId,
      cartevalide: this.carteValide,
      cartenumber: this.carteNumber,
      restoid: this.idResto,
      refuse: this.resaRefus,
      ouitel: this.telOui,
      nontel: this.telNon,
      heureJournee: this.heureSelectionne,
      reservationmodes: this.reservationmodes,
      letotal: this.leTotalStr,
      rabais: this.discountVert,
      resacode: this.codeResa
    };

    console.table(data);

    this.httpClient.post<any>(this.basebath, data).subscribe(
      (res) => {
        this.reponseReservation = res,
        console.table('Réponse: ' + res);
      },
      (err) => console.log(err),
    );
    this.attendsTelephone = true;
    this.peutAnnuler = false;
    this.getUserData(this.currentUserId);
    console.log('Résa envoyée ' + this.reponseResaRetour);
    console.log('Etat ' + this.reponseReservation);
  }

  postResaCanceled() {
    const leTotal = this.nbrAdult + this.nbrEnfant;
    const data = {
      personnesnumber: leTotal,
      heure: this.heureBaseDeDonnes,
      date: this.laDateChoisie,
      userid: this.currentUser.id,
      restoid: this.idResto,
      isresto: this.isResto
    };

    this.httpClient.post<any>(this.basebathCancel, data).subscribe(
      (res) => console.log(res),
      (err) => console.log(err)
    );
  }

  verifyIfAlreadyUse() {
    // Ici on vérifie si ce resto a déjà été réservé
    this.ceLoisirDejaReserve = false;
    for (const dejaresa in this.arrDejaResa) {
      if (dejaresa) {
        let leRestoDeja = + this.arrDejaResa[dejaresa].id;
        if (leRestoDeja === this.idResto) {
          this.ceLoisirDejaReserve = true;
        }
        leRestoDeja = 0;
      }
    }
    console.log('dejaResaBis ' + this.ceLoisirDejaReserve);
  }

getUserData(userid: string) {
  this.interval = this.infousers.getUserInfos(userid)
      .subscribe(orders => {
        this.infosUser = orders;
        this.arrDejaResa = orders.dejareserveloisirs;
        for (const score of orders) {
          this.arrDejaResa = score.dejareserveloisirs;
          this.aCarteValide = score.cartevalide;
          this.currentUserDateFin = score.datefincartevalide;
        }

        for (const typeArr in this.aCarteValide) {
          if (typeArr) {
            this.carteValide = + this.aCarteValide[typeArr].Id;
            this.carteNumber = + this.aCarteValide[typeArr].CardId;
            this.carteType = + this.aCarteValide[typeArr].Destination;
            console.log('Ta: ' +  this.aCarteValide[typeArr].Id); // prints indexes: 0, 1, 2, 3
          }
        }
        this.verifyIfAlreadyUse();
        this.getServiceDispo();
      });
}

prixAvecCentimes(x) {
  // return Number.parseFloat(x).toFixed(2);
  return x.toFixed(2);
}

onClickCalcul() { // without type info
  // si le prestataire fonctionne avec les réduction et pas système normal
  console.log('Redu A: ' + this.reductionAdult);
  if (this.reductionAdult > 0) {
    const prixNormal: number = (this.valueEnfant * this.prixEnfant) + (this.valueAdult * this.prixAdult);
    const leRabais: number = (this.valueEnfant * this.reductionEnfant) + (this.valueAdult * this.reductionAdult);

    this.leTotal = prixNormal - leRabais;
    this.discountVert = leRabais;
    this.leTotal = + this.leTotal.toFixed(2);
    this.leTotalStr = this.leTotal.toFixed(2);

  } else {
    const totalPersonnes: number = this.valueAdult + this.valueEnfant;
    const adultPlusEnfant: number = this.prixAdult + this.prixEnfant;

    // Faire un calcul pour connaître: le rabais, le prix normal.
    // Avec ça on peut tout calculer
    const prixNormal: number = (this.valueEnfant * this.prixEnfant) + (this.valueAdult * this.prixAdult);
    const nbAdultePayant = Math.ceil(this.valueAdult / 2);
    const nbEnfantPayant = Math.ceil(this.valueEnfant / 2);
    // console.log('valueAdult%2: ' + this.valueAdult % 2);
    // console.log('valueEnfant%2: ' + this.valueEnfant % 2);

    // Si il n'y a pas le nombre minimum de personnes.... else
    if (totalPersonnes < this.nbrminpersonperbooking) {
      const leTotalEnfants = this.valueEnfant * this.prixEnfant;
      const leTotalAdults = this.valueAdult * this.prixAdult;
      this.leTotal = leTotalAdults + leTotalEnfants;
      this.discountVert = 0;
    } else {

      // 1. On fixe prix total enfants et adultes.
      // Puis, s'il y a un adulte de plus que enfants on baisse le prix enfants
      const totalInterAdult = nbAdultePayant * this.prixAdult;
      let totalInterEnfant = nbEnfantPayant * this.prixEnfant;
      const valueAdultModulus = this.valueAdult % 2; // Pour savoir s'il y a un adulte en trop pour offrir à un gosse
      const valueEnfantModulus = this.valueEnfant % 2; // Pour savoir s'il y a un adulte en trop pour offrir à un gosse
      if (this.valueAdult > 1 && valueAdultModulus === 1 && valueEnfantModulus === 1 &&  this.valueEnfant > 0) {
        totalInterEnfant = totalInterEnfant - this.prixEnfant;
      }
      if (this.valueAdult === 1 && this.valueEnfant === 1) {
        totalInterEnfant = totalInterEnfant - this.prixEnfant;
      }
      this.leTotal = totalInterEnfant + totalInterAdult;
      this.discountVert = prixNormal - this.leTotal;
    }
    this.leTotal = + this.leTotal.toFixed(2);
    this.leTotalStr = this.leTotal.toFixed(2);
    console.log('nbAdultePayant: ' + nbAdultePayant);
    console.log('nbEnfantPayant: ' + nbEnfantPayant);
    console.log('prixNormal: ' + prixNormal);
    console.log('total pers: ' + totalPersonnes);
    console.log('Total Str: ' + this.leTotalStr);
  }

}

  ngOnInit() {
    this.idResto = +this.route.snapshot.paramMap.get('id');
    this.getUserData(this.currentUserId);


  }

  ngOnDestroy() {
    this.interval.unsubscribe();
  }

}

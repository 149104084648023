import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../_helpers/app.constants';

@Injectable({
  providedIn: 'root'
})
export class DisporestosService {

  constructor(private http: HttpClient) { }

  servRestoDispo(id: number): Observable<any> {
    const _apiRestoDispo =  AppConstants.baseURL + '/disponibilitesresto.php?token=cPnFKCqNQLKw&id=' + id;
    const myObs2: Observable<any> = this.http.get(`${_apiRestoDispo}`);
    console.log('Service disporesto ');
    return myObs2;
  }

}
